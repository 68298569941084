import axios from "../../../util/axios";

export default {
    /** 保存 */
    save: (data) => axios.post({url: "/alpine/merchant/store/save", data: data}),
    /** 查询列表 */
    queryList: (data) => axios.get({url: "/alpine/merchant/store/queryList", data: data}),
    /** 删除 */
    delete: (id) => axios.del({url: "/alpine/merchant/store/delete", data: {id}}),
    /** 详情 */
    findById: (id) => axios.get({url: "/alpine/merchant/store/findById", data: {id}}),
    /** 查询全部门店 */
    queryAll: () => axios.get({url: "/alpine/merchant/store/queryAll"}),
    /** 查询职员 */
    queryEmployees: (data) => axios.get({url: "/alpine/merchant/store/queryEmployees", data: data}),
    /** 添加职员 */
    addStoreEmployee: (data) => axios.post({url: "/alpine/merchant/store/addStoreEmployee", data: data}),
    /** 删除 */
    deleteEmployee: (id) => axios.del({url: "/alpine/merchant/store/deleteEmployee", data: {id}}),
    /** 查询所有该商户下的审核账号 */
    queryAllAuditAccount: (data) => axios.get({url: "/alpine/merchant/store/queryAllAuditAccount", data: data}),
    /** 修改账号的状态-审核 */
    updateAccountCheckStatus: (data) => axios.post({url: "/alpine/merchant/store/updateAccountCheckStatus", data: data}),
    /** 获取区域编码 */
    findRegionCode: (coordsLng, coordsLat) => axios.get({url: "/alpine/merchant/store/findRegionCode", data: {coordsLng, coordsLat}}),
}