<template>
  <!-- -->
  <div class="defaultViewStyle">
    <separate-page>
      <template #category>
        <el-tree :data="departTree" :props="{children: 'children', label: 'departName'}" @node-click="clickDepart" empty-text="部门为空" node-key="id" default-expand-all :expand-on-click-node="false">
          <template #default="{node, data}">
<!--            <span :class="currentDepart.id === data.id? 'tree-on': ''">{{data.departName}}</span>-->
            <span class="custom-tree-node">
              <span class="node-name">{{ node.label }}</span>
              <span class="node-option">
                <table-button type="primary" @click="toEditDepart('addDepart')"><el-icon><el-icon-plus/></el-icon></table-button>
                <table-button type="primary" @click="toEditDepart('editDepart')"><el-icon><el-icon-edit/></el-icon></table-button>
                <table-button type="delete" class="btn" message="是否确认删除部门，删除部门将移除部门下的所有职员关系" @click="toEditDepart('deleteDepart')"><el-icon><el-icon-delete/></el-icon></table-button>
              </span>
            </span>
          </template>
        </el-tree>
      </template>
      <template #default>
        <list-page style="border: none;box-shadow: none; padding: 0">
          <search-condition>
            <template #default>
              <el-input v-model="searchInfo.searchKey" placeholder="请输入职员名称/手机号"/>
            </template>
            <template #option>
              <page-button type="read" el-type="primary" @click="resetSearch">查询</page-button>
              <page-button type="write" el-type="primary" @click="showImportDialog = true">导入</page-button>
              <page-button type="write" el-type="success" @click="toEditEmployee({})">新建</page-button>
            </template>
          </search-condition>
          <table-list>
            <el-table :data="employeeList" height="100%" border>
              <el-table-column label="序号" type="index" width="60px" :align="'center'"/>
              <el-table-column label="员工编号" prop="employeeCode"/>
              <el-table-column label="职员名称" prop="employeeName"/>
              <el-table-column label="职员电话" prop="employeePhone"/>
              <el-table-column label="主部门" prop="mainDepartName"/>
              <el-table-column label="操作" width="100px" :align="'center'">
                <template v-slot="{row}">
                  <table-button @click="toEditEmployee(row)" type="write">编辑</table-button>
                  <table-button el-type="danger" type="delete" message="是否确认删除数据" @click="toDeleteEmployee(row)">删除</table-button>
                </template>
              </el-table-column>
            </el-table>
          </table-list>
          <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize" :total-count="searchInfo.totalCount" @onLoad="queryList"/>
        </list-page>
      </template>
    </separate-page>

    <el-dialog v-model="showEditDepart" width="40%" title="部门档案" :close-on-click-modal="false">
      <el-form label-width="70px">
        <el-form-item label="部门名称">
          <el-input v-model="editDepart.departName"/>
        </el-form-item>
        <el-form-item label="部门编码">
          <el-input v-model="editDepart.departCode" disabled/>
        </el-form-item>
        <el-form-item label="上级部门">
          <el-tree-select v-model="editDepart.parentId" :disabled="editDepart.id" :data="departTree"  check-strictly :render-after-expand="false" placeholder="请选择上级部门" :props="{children: 'children', label: 'departName'}" node-key="id"/>
        </el-form-item>
      </el-form>
      <div :class=" addStock && isMerchantId !== '10000' ? 'edit-depart' : '' ">
        <el-checkbox v-model="editDepart.isAutoCreateStock" checked v-if="addStock && isMerchantId !== '10000'" label="同步创建仓库" size="large"/>
        <div style="text-align: right">
          <page-button @click="showEditDepart = false">取消</page-button>
          <page-button @click="toSaveDepart" type="write" el-type="primary">保存</page-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog v-model="showEditEmployee" width="40%" title="职员档案" :close-on-click-modal="false">
      <el-form label-width="70px">
        <el-form-item label="员工编号" style="position: relative">
          <el-input v-model="editEmployee.employeeCode"/>
          <el-tooltip
              class="box-item"
              effect="light"
              content="作为系统登录账号"
              placement="left"
          >
            <el-icon style="position:absolute;right: 10px;color:#999"><el-icon-info-filled/></el-icon>
          </el-tooltip>

        </el-form-item>
        <el-form-item label="登录密码">
          <el-input type="password" v-model="editEmployee.password"/>
        </el-form-item>
        <el-form-item label="职员名称">
          <el-input v-model="editEmployee.employeeName"/>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="editEmployee.employeePhone"/>
        </el-form-item>
        <el-form-item label="主部门">
          <el-tree-select v-model="editEmployee.mainDepartCode" :data="departTree"  check-strictly :render-after-expand="false" placeholder="请选择所在部门" :props="{children: 'children', label: 'departName'}" node-key="departCode" default-expand-all/>
        </el-form-item>
        <el-form-item label="角色">
          <el-select multiple v-model="selectedRoles" placeholder="请选择角色">
            <el-option v-for="r in roleList" :value="r.id" :label="r.roleName"/>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="text-align: right">
        <page-button @click="showEditEmployee = false">取消</page-button>
        <page-button el-type="primary" type="write" @click="toSaveEmployee">保存</page-button>
      </div>
    </el-dialog>
    <el-dialog v-model="showImportDialog" title="批量导入用户" width="600px" :close-on-click-modal="false">
      <div>
        <el-upload drag :multiple="false" :auto-upload="false" :limit="1" :on-remove="removeUpload" :on-change="changeUpload" :file-list="fileList">
          <el-icon><el-icon-upload/></el-icon>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div style="text-align: right;padding-top: 10px;display: flex;justify-content: space-between">
        <el-select v-model="uploadType" style="width: 150px">
          <el-option :value="0" label="直接导入系统"/>
          <el-option :value="1" label="导入之前先确认信息"/>
        </el-select>
        <div style="font-size: 12px;color: #999;line-height: 36px">
          温馨提示：请按照模板格式填写导入的数据
        </div>
        <div style="text-align: right">
          <page-button @click="showImportDialog = false">取消</page-button>
          <page-button @click="exportTemp" el-type="primary">下载模板</page-button>
          <page-button type="write" el-type="success" @click="importByExcel">导入</page-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog v-model="showPendingEmployees" title="待确认用户列表" width="80%">
      <dialog-table>
        <el-table :data="pendingList" border height="100%">
          <el-table-column label="序号" type="index" :align="'center'" width="60px"/>
          <el-table-column label="员工编号" width="120px">
            <template v-slot="{row}">
              <el-input v-model="row.employeeCode"/>
            </template>
          </el-table-column>
          <el-table-column label="姓名" width="120px">
            <template v-slot="{row}">
              <el-input v-model="row.employeeName"/>
            </template>
          </el-table-column>
          <el-table-column label="联系电话" width="150px">
            <template v-slot="{row}">
              <el-input v-model="row.employeePhone"/>
            </template>
          </el-table-column>
          <el-table-column label="登录密码" width="150px">
            <template v-slot="{row}">
              <el-input v-model="row.password"/>
            </template>
          </el-table-column>
          <el-table-column label="主部门">
            <template v-slot="{row}">
              <el-tree-select v-model="row.mainDepartCode" :data="departTree"  check-strictly :render-after-expand="false" placeholder="请选择所在部门" :props="{children: 'children', label: 'departName'}" node-key="departCode" default-expand-all/>
            </template>
          </el-table-column>
          <el-table-column label="角色名称" width="180px">
            <template v-slot="{row}">
              <el-select multiple v-model="row.roleIdList" placeholder="请选择角色">
                <el-option v-for="r in roleList" :value="r.id" :label="r.roleName"/>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'" width="60px">
            <table-button el-type="danger">删除</table-button>
          </el-table-column>
        </el-table>
      </dialog-table>
      <div style="text-align: right;padding-top: 10px">
        <page-button >取消</page-button>
        <page-button type="write" el-type="primary" @click="saveBatch">确认导入</page-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import employeeApi from "@/api/main/sys/EmployeeApi";
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import SeparatePage from "@/components/h5/layout/SeparatePage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import DialogTable from "@/components/h5/layout/DialogTable";

import util from "@/util/util";
export default {
  name: "",
  components: {
    TableButton,
    PageButton,
    ListPage, TableList, FormPage, SearchCondition, SeparatePage, DialogTable
  },
  data() {
    return {
      searchInfo: {},
      employeeList: [],
      currentEmployee: {},
      editEmployee: {
        employeeName: "",
        employeePhone: "",
        password: "",
        mainDepartCode: "",
        employeeCode: "",
      },
      showEditEmployee: false,
      departTree: [],
      currentDepart: {},
      editDepart: {
        departName: "",
        departCode: "",
        isAutoCreateStock: true, // 开启同步创建仓库
      },
      isMerchantId: "", // 当前商户的id，如果是平台方，则不显示同步添加仓库的复选框
      showEditDepart: false,
      roleList: [],
      // 选中的角色
      selectedRoles: [],
      // 页面表格高度
      tableHeight: 300,
      // 导入
      showImportDialog: false,
      file: "",
      fileList: [],
      uploadType: 1,
      showPendingEmployees: false,
      pendingList: [],
      addStock: false,  // 是否显示自动添加仓库复选框
    };
  },
  created() {
    // 设置当前商户id
    util.getUserInfo().then(res=>this.isMerchantId=res.merchantId);
    // 查询机构部门
    this.queryDepartList();
    employeeApi.queryAllRoles().then(list => this.roleList = list);
  },
  mounted() {
    // 获取高度
    let doc = document.querySelector(".defaultViewStyle");
    this.tableHeight = doc.clientHeight - 85;
  },
  methods: {
    // 保存职员
    toSaveEmployee() {
      let arr = [];
      this.selectedRoles.forEach(id => arr.push({id: id}))
      let data = {
        ...this.editEmployee,
        roleList: arr
      }
      employeeApi.save(data).then(() => {
        this.queryList();
        this.showEditEmployee = false;
      });
    },
    queryDepartList() {
      employeeApi.queryDepartList().then(list => {
        this.departTree = [{departName: "全部", id: "", children: list}]
      });
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      employeeApi.queryList(this.searchInfo).then(res => {
        this.employeeList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      });
    },
    clickDepart(data) {
      this.currentDepart = {...data};
      // 查询部门职员
      this.searchInfo.departCode = data.departCode;
      this.resetSearch();
    },
    // 开始编辑部门
    toEditDepart(command) {
      if (command === "addDepart") {
        this.addStock = true;
        // 添加新的部门
        this.editDepart = {
          parentId: this.currentDepart.id? this.currentDepart.id: "",
        };
        this.showEditDepart = true;
      } else if (command === "editDepart") {
        this.addStock = false;
        if (!this.currentDepart.id) {
          this.$message.error("根节点不能进行编辑")
          return;
        }
        // 编辑选中的部门
        this.editDepart = {...this.currentDepart};
        this.showEditDepart = true;
      } else if (command === "deleteDepart") {
        if (!this.currentDepart.id) {
          this.$message.error("根节点不能删除")
          return;
        }
        // 删除部门
        this.toDeleteDepart();
      } else if (command === "addEmployee") {
        // 向部门内添加职员
      }
    },
    // 保存部门信息
    toSaveDepart() {
      employeeApi.saveDepart(this.editDepart).then(() => {
        this.queryDepartList();
        this.showEditDepart = false;
      });
    },
    // 删除部门
    toDeleteDepart() {
      employeeApi.deleteDepart(this.currentDepart.id).then(() => this.queryDepartList());
    },
    // 开始打开编辑用户页面
    toEditEmployee(data) {
      this.editEmployee = {
        ...data,
        password: ""
      };
      if (this.editEmployee.id) {
        // 修改数据
        employeeApi.findById(this.editEmployee.id).then(employee => {
          this.editEmployee = {
            ...employee,
            employeeCode: data.employeeCode,
            password: "",
          }
          let arr = [];
          if (employee.roleList) {
            employee.roleList.forEach(r => arr.push(r.id));
          }
          this.selectedRoles = arr;
          this.showEditEmployee = true;
        });
      } else {
        this.selectedRoles = [];
        this.showEditEmployee = true;
      }

    },
    toDeleteEmployee(data) {
      employeeApi.deleteEmployee(data.id).then(() => this.queryList());
    },
    // 导出excel模板
    exportTemp() {
      util.exportExcel("/basedata/system/employee/exportTemp", {});
    },
    importByExcel() {
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("type", this.uploadType);
      if (this.uploadType === 0) {
        // 直接导入，导入完成后关闭并刷新
        employeeApi.uploadEmployeesByTemplate(formData).then(() => {
          this.showImportDialog = false;
          this.queryList();
        });
      } else if (this.uploadType === 1) {
        // 确认后导入，需要显示确认dialog
        employeeApi.uploadEmployeesByTemplate(formData).then((list) => {
          list.forEach(e => {
            if (e.roleList && e.roleList.length > 0) {
              let arr = [];
              for (let i = 0; i < e.roleList.length; i++) {
                arr.push(e.roleList[i].id);
              }
              e.roleIdList = arr;
            } else {
              e.roleIdList = [];
            }
          })
          this.pendingList = list;
          this.showImportDialog = false;
          this.showPendingEmployees = true;
        });
      }
    },
    saveBatch() {
      let data = [...this.pendingList];
      data.forEach(e => {
        if (e.roleIdList && e.roleIdList.length > 0) {
          let arr = [];
          for (let i = 0; i < e.roleIdList.length; i++) {
            arr.push({id: e.roleIdList[i]})
          }
          e.roleList = arr;
        } else {
          e.roleList = [];
        }
      })
      employeeApi.saveEmployeeList(this.pendingList).then(() => {
        this.showPendingEmployees = false;
        this.queryList();
      })
    },
    removeUpload() {
      this.file = "";
    },
    changeUpload(file) {
      this.file = file.raw;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/tree.scss";
.tree-on{
  color: #409EFF;
}
.edit-depart{
  display: flex;
  justify-content: space-between;
  align-items: center
}
</style>