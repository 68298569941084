<template>
  <!-- 通用模板，列表数据 -->
  <list-page v-if="searchType === 'statistic'">
    <search-condition @search="resetSearch" :show-search="true">
      <date-range v-model:start-time="searchInfo.startTime" v-model:end-time="searchInfo.endTime"/>
      <el-select v-if="readAccess !== '1'" v-model="searchInfo.stockId" placeholder="全部仓库" clearable>
        <el-option v-for="(st, i) in stockList" :key="i" :label="st.stockName" :value="st.id"/>
      </el-select>
<!--      <product-one-select v-model="searchInfo.skuId"/>-->
      <el-input v-model="searchInfo.skuName" placeholder="请输入商品名称" />
      <template #option>
        <page-button el-type="primary" type="print" @click="exportSummaryProduct">导出</page-button>
      </template>
    </search-condition>
    <table-list>
      <el-table :data="dataList" show-summary :summary-method="getSummaries" height="100%" border>
        <el-table-column label="序号" width="60px" type="index" :align="'center'" fixed/>
        <el-table-column align="center" label="商品编号" min-width="250px" prop="skuCode" show-overflow-tooltip fixed v-if="type === 1"/>
        <el-table-column align="center" label="商品名称" min-width="250px" prop="skuName" show-overflow-tooltip fixed v-if="type === 1"/>
        <el-table-column align="center" label="仓库名称" min-width="250px" prop="stockName" show-overflow-tooltip fixed v-else-if="type === 2"/>
        <el-table-column label="销售数量" min-width="100px" prop="totalQty" :align="'center'"/>
        <el-table-column label="销售金额" min-width="100px" prop="totalAmount" :align="'center'"/>
        <el-table-column label="退货数量" min-width="100px" prop="refundQty" :align="'center'"/>
        <el-table-column label="退货金额" min-width="100px" prop="refundAmount" :align="'center'"/>
        <el-table-column label="净销售量" min-width="100px" :align="'center'">
          <template v-slot="{row}">
            {{row.totalQty - row.refundQty}}
          </template>
        </el-table-column>
        <el-table-column label="净销售额" min-width="100px" :align="'center'">
          <template v-slot="{row}">
            {{row.totalAmount - row.refundAmount}}
          </template>
        </el-table-column>
        <el-table-column label="成本均价" min-width="100px" prop="buyPrice" :align="'center'"/>
        <el-table-column label="成本金额" min-width="100px" prop="buyAmount" :align="'center'"/>
        <el-table-column label="未税入库均价" min-width="120px" prop="taxExcludePrice" show-overflow-tooltip  :align="'center'"/>
        <el-table-column label="未税入库金额" min-width="120px" prop="taxExcludeAmount" show-overflow-tooltip  :align="'center'"/>
        <el-table-column label="含税毛利" min-width="100px" :align="'center'">
          <template v-slot="{row}">
            {{row.grossProfit}}
          </template>
        </el-table-column>
        <el-table-column label="含税毛利率" min-width="100px" prop="saleProfitRate" :align="'center'">
          <template v-slot="{row}">
            {{ row.grossProfitRate }}
          </template>
        </el-table-column>
        <el-table-column label="未税毛利" min-width="120px" prop="taxExcludeGrossProfit" show-overflow-tooltip  :align="'center'"/>
        <el-table-column label="未税毛利率" min-width="120px" prop="taxExcludeGrossProfitRate" show-overflow-tooltip  :align="'center'"/>
        <el-table-column label="操作" :align="'center'" fixed="right">
          <template v-slot="{row}">
            <table-button @click="toShowOrderList(row)">查看明细</table-button>
          </template>
        </el-table-column>
      </el-table>
    </table-list>
    <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
  </list-page>
  <list-page v-else-if="searchType === 'order'">
    <search-condition @search="resetOrderSearch" :show-search="true">
      <date-range v-model:start-time="searchInfoOrder.startTime" v-model:end-time="searchInfoOrder.endTime"/>
      <el-select v-if="readAccess !== '1'" v-model="searchInfoOrder.stockId" placeholder="全部仓库" clearable>
        <el-option v-for="(st, i) in stockList" :key="i" :label="st.stockName" :value="st.id"/>
      </el-select>
<!--      <product-one-select v-model="searchInfoOrder.skuId"/>-->
      <el-input v-model="searchInfoOrder.skuName" placeholder="请输入商品名称" />
      <template #option>
        <page-button el-type="primary" type="print" @click="exportOrderDetail">导出</page-button>
        <page-button @click="searchType = 'statistic'">返回</page-button>
      </template>
    </search-condition>
    <table-list>
      <el-table :data="orderList" height="100%" border>
        <el-table-column label="序号" width="60px" type="index" :align="'center'" fixed/>
        <el-table-column label="单据日期" min-width="150px" prop="orderTime" show-overflow-tooltip fixed :align="'center'">
          <template v-slot="{row}">{{row.orderTime? row.orderTime.substring(0, 10): ""}}</template>
        </el-table-column>
        <el-table-column label="订单编号" min-width="250px" prop="id" show-overflow-tooltip fixed :align="'center'"/>
        <el-table-column label="仓库名称" min-width="150px" prop="stockName" show-overflow-tooltip :fixed="type === 2" :align="'center'"/>
        <el-table-column label="商品名称" min-width="250px" prop="skuName" show-overflow-tooltip v-if="type === 1" :fixed="type === 1"/>
        <el-table-column label="税率" :align="'center'" min-width="150px" show-overflow-tooltip  v-if="type === 1">
          <template v-slot="{row}">
            {{ row.taxRate * 100 }}%
          </template>
        </el-table-column>
        <el-table-column label="规格" min-width="150px" prop="skuStandard" show-overflow-tooltip v-if="type === 1"/>
        <el-table-column label="型号" min-width="150px" prop="skuType" show-overflow-tooltip v-if="type === 1" />
        <el-table-column label="条码" min-width="150px" prop="barCode" show-overflow-tooltip v-if="type === 1"/>
        <el-table-column label="属性" min-width="150px" prop="stockTypeName" show-overflow-tooltip v-if="type === 1"/>
        <el-table-column label="客户名称" min-width="150px" prop="customerName" show-overflow-tooltip :align="'center'"/>
        <el-table-column label="职员名称" min-width="150px" prop="employeeName" show-overflow-tooltip :align="'center'"/>
        <el-table-column label="数量合计" min-width="100px" prop="qty" show-overflow-tooltip  :align="'center'"/>
        <el-table-column label="金额合计" min-width="100px" prop="amount" show-overflow-tooltip  :align="'center'">
          <template v-slot="{row}">
            {{ row.amount }}
            <!--              {{row.orderType !== 2 ? row.amount : -row.amount}}-->
          </template>
        </el-table-column>
        <el-table-column label="成本均价" min-width="100px" prop="averagePrice" show-overflow-tooltip  :align="'center'"/>
        <el-table-column label="成本金额" min-width="100px" prop="amountCost" show-overflow-tooltip  :align="'center'"/>
        <el-table-column label="未税入库均价" min-width="120px" prop="taxExcludePrice" show-overflow-tooltip  :align="'center'"/>
        <el-table-column label="未税入库金额" min-width="120px" prop="taxExcludeAmount" show-overflow-tooltip  :align="'center'"/>
        <el-table-column label="含税毛利" min-width="100px" prop="totalQty" show-overflow-tooltip :align="'center'">
          <template v-slot="{row}">
            {{row.amount - row.amountCost}}
          </template>
        </el-table-column>
        <el-table-column label="含税毛利率" min-width="100px" prop="totalQty" show-overflow-tooltip :align="'center'">
          <template v-slot="{row}">
            {{((row.amount - row.amountCost) / row.amount * 100).toFixed(2)}} %
          </template>
        </el-table-column>
        <el-table-column label="未税毛利" min-width="120px" prop="taxExcludeGrossProfit" show-overflow-tooltip  :align="'center'"/>
        <el-table-column label="未税毛利率" min-width="120px" prop="taxExcludeGrossProfitRate" show-overflow-tooltip  :align="'center'"/>
        <el-table-column label="单据类型" min-width="150px" :align="'center'" fixed="right">
          <template v-slot="{row}">
            <span v-for="(t, i) in orderTypes" :key="i">
              <span v-if="t.value === row.orderType">{{t.label}}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" :align="'center'" min-width="100px" fixed="right">
          <template v-slot="{row}">
            <table-button @click="viewOrderDetail(row)">查看单据</table-button>
          </template>
        </el-table-column>
      </el-table>
    </table-list>
    <form-page v-model:page-size="searchInfoOrder.pageSize" v-model:page-number="searchInfoOrder.pageNumber" :total-count="searchInfoOrder.totalCount" @onLoad="queryOrderList" :default-load="false"/>
  </list-page>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import orderStatisticApi from "@/api/main/order/orderStatisticApi";
import DateRange from "@/components/h5/date/DateRange.vue";
import config from "@/config/config";
import ProductOneSelect from "@/components/h5/product/ProductOneSelect.vue";
import util from "@/util/util";
import {h} from "vue";
export default {
  props: {
    // 1 按商品 2按仓库
    type: {default: 1},
  },
  name: "",
  components: {
    TableButton,
    PageButton,
    ListPage, TableList, FormPage, SearchCondition, DateRange, ProductOneSelect
  },
  data() {
    return {
      // 查询方式，statistic汇总，order订单
      searchType: "statistic",
      searchInfo: {},
      searchInfoOrder: {},
      dataList: [],
      orderList: [],
      orderTypes: [],
      stockList: [],
      countStatistic: {},
      // 设置读的权限值
      readAccess: null,
    };
  },
  created() {
    // 获取读的权限
    util.getAccess(this.$routerUtil.path, "read").then(res => this.readAccess = res);
    this.orderTypes = config.saleOrderType;
    this.searchInfo.searchType = this.type;
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    this.searchInfoOrder.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfoOrder.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    // 查询仓库
    orderStatisticApi.queryStockList().then(res => {
      this.stockList = res;
    })
  },
  methods: {
    // 导出
    exportSummaryProduct() {
      util.exportExcel("/order/saleOrder/statistic/exportSummaryProduct", this.searchInfo);
    },
    exportOrderDetail(){
      // debugger;
      util.exportExcel("/order/saleOrder/statistic/exportOrderSingleDetail", this.searchInfoOrder);
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    // 查询统计结果
    queryList() {
      orderStatisticApi.queryCountList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
        orderStatisticApi.queryCountStatistic(this.searchInfo).then(res =>{
          this.countStatistic = res;
        })
      })
    },
    // 查询订单
    queryOrderList() {
      orderStatisticApi.queryOrderDetailInfo(this.searchInfoOrder).then(res => {
        this.orderList = res.records;
        this.searchInfoOrder.totalCount = res.totalCount;
      })
    },
    resetOrderSearch() {
      this.searchInfoOrder.pageNumber = 1;
      this.queryOrderList();
    },
    toShowOrderList(data) {
      this.searchType = "order";
      this.searchInfoOrder.skuId = data.skuId;
      this.searchInfoOrder.stockId = data.stockId;
      this.searchInfoOrder.startTime = this.searchInfo.startTime;
      this.searchInfoOrder.endTime = this.searchInfo.endTime;
      this.resetOrderSearch();
    },
    // 查看订单明细
    viewOrderDetail(data) {
      if (data.orderType === 1 || data.orderType === 11) {
        // 销售单
        this.$routerUtil.push(`/report/offline-order/detail?id=${data.id}`)
      } else if (data.orderType === 2 || data.orderType === 12) {
        // 退货单
        this.$routerUtil.push(`/report/refund-order/detail?id=${data.id}`)
      }
    },
    // 合计
    getSummaries(param){
      const {columns} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '总  计',
          ])
          return
        }

        if (index === 3) {
          sums[index] = h('div', [Number(this.countStatistic.totalQty).toFixed(2)])
        }

        if (index === 4) {
          sums[index] = h('div', [Number(this.countStatistic.totalAmount).toFixed(2)])
        }

        if (index === 5) {
          sums[index] = h('div', [Number(this.countStatistic.refundQty).toFixed(2)])
        }

        if (index === 6) {
          sums[index] = h('div', [Number(this.countStatistic.refundAmount).toFixed(2)])
        }

        if (index === 7) {
          sums[index] = h('div', [Number(this.countStatistic.netSaleCount).toFixed(2)])
        }

        if (index === 8) {
          sums[index] = h('div', [Number(this.countStatistic.netSaleAmount).toFixed(2)])
        }
        if (index === 10) {
          sums[index] = h('div', [Number(this.countStatistic.buyAmount).toFixed(2)])
        }
        if (index === 12) {
          sums[index] = h('div', [Number(this.countStatistic.taxExcludeAmount).toFixed(2)])
        }
        if (index === 13) {
          sums[index] = h('div', [Number(this.countStatistic.grossProfit).toFixed(2)])
        }
        if (index === 15) {
          sums[index] = h('div', [Number(this.countStatistic.taxExcludeGrossProfit).toFixed(2)])
        }
      })
      return sums
    },
  }
};
</script>

<style lang="scss" scoped>

</style>