<template>
  <!-- -->
  <div>
    <page-button el-type="primary" v-if="showBtn" @click="toSettlement">结算</page-button>
    <table-button el-type="primary" v-else @click="toSettlement">结算</table-button>
    <el-dialog :close-on-click-modal="false" class="el-dialog-center-area" title="订单结算" width="700px" v-model="showDialog">
      <div>
        <h3>结算信息</h3>
      </div>
      <div class="my-form">
        <el-form-item label="合计金额" style="width: 300px">
          <el-input disabled v-model="saleData.amountShould"/>
        </el-form-item>
        <el-form-item label="合计数量" style="width: 300px">
          <el-input disabled v-model="saleData.amountShould"/>
        </el-form-item>
      </div>
      <div class="my-form">
        <el-form-item label="已收金额" style="width: 300px">
          <el-input disabled v-model="saleData.amountShould"/>
        </el-form-item>
        <el-form-item label="未收金额" style="width: 300px">
          <el-input disabled v-model="saleData.amountShould"/>
        </el-form-item>
      </div>
      <div class="my-form">
        <el-form-item label="支付状态" style="width: 100%">
          <el-input disabled v-model="saleData.amountShould"/>
        </el-form-item>
      </div>
      <hr/>
      <div class="btm-title">
        <h3>支付方式</h3>
        <span>未支付/支付成功/支付失败</span>
      </div>
      <div class="yizhifu">
        <div style="display: inline-block">易支付</div>
        <div style="display: inline-block">
          <el-input placeholder="收款金额" style="width: 120px"/>
        </div>
        <div style="display: inline-block">
          <el-input placeholder="请扫描支付二维码" style="width: 340px">
            <template #suffix>
              <el-icon @click="toRefresh" size="20"><Refresh /></el-icon>
            </template>
          </el-input>
        </div>
        <div style="display: inline-block">支付状态</div>
      </div>
      <div class="pay-detail">
        <div class="pay-left">
          <div v-for="item in payList" :key="item.id">
            <el-form-item :label="item.payName">
              <el-input placeholder="请输入收款金额" />
            </el-form-item>
          </div>
        </div>
        <div class="pay-right">
          <el-card style="height: 100%">
            <template #header>
                <span class="span-amount">支付金额合计：</span>
            </template>
            <div class="span-sty">
              <span>￥</span>
              <span>600</span>
              <span>.</span>
              <span>00</span>
              <span>/元</span>
            </div>
          </el-card>
        </div>
      </div>
      <div style="display: flex;justify-content: flex-end;margin-top: 10px">
        <page-button el-type="success">提交单据</page-button>
        <page-button el-type="primary">结算收款</page-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageButton from "@/components/h5/button/PageButton.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import {Refresh} from "@element-plus/icons-vue";
import systemPayApi from "@/api/main/sys/systemPayApi";

export default {
  name: "",
  props: {
    showBtn: {default: true},
  },
  components: {
    Refresh,
    PageButton, TableButton,
  },
  computed: {
    // getAmount(){
    //
    // }
  },
  data() {
    return {
      showDialog: false,
      // 数据
      saleData: {},
      // 支付方式
      payList: [],
    };
  },
  created() {
    // 查询所有的type为1的支付方式
    systemPayApi.queryByType(0).then(list => this.payList = list);
  },
  methods: {
    toSettlement() {
      this.showDialog = true;
    },
    toRefresh(){
      this.$message.info("点击了刷新按钮！");
    },
  }
};
</script>

<style lang="scss" scoped>
:deep(.el-dialog__body) {
  padding: 0 20px 16px 20px !important;
}

:deep(.el-form-item) {
  margin-bottom: 17px;
}

h3 {
  margin: 5px 0;
}

.my-form {
  display: flex;
  justify-content: space-between;
}

.my-form:nth-child(4) {
  :deep(.el-form-item) {
    margin-bottom: 8px !important;
  }
}

.btm-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -7px;

  span {
    color: #F56C6C;
  }
}

.yizhifu {
  background-color: #409EFF;
  font-size: 15px;
  color: white;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  height: 55px;
  line-height: 55px;
  border-radius: 5px;
}

.pay-detail{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 17px;
  height: 178px;

  .pay-left{
    width: 47%;

    :deep(.el-form-item__label){
      padding: 0 10px 0 0;
      width: 70px;
      display: inline !important;
    }
  }

  .pay-right{
    width: 49%;
    border-radius: 7px;
    box-sizing: border-box;

    .span-amount{
      font-size: 25px;
      font-weight: 600;
      letter-spacing: 2px;
      font-family: cursive;
      color: #606266;
    }

    .span-sty{
      font-family: cursive;
      font-size: 25px;
      text-align: center;
      line-height: 70px;
      color: rgb(196, 86.4, 86.4);

      span:nth-child(2){
        font-size: 37px;
      }
    }
  }
}
:deep(.el-overlay-dialog) {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-dialog{
    margin: 0;
  }
}
</style>