<template>
  <!-- 视频上传组件，可抓取截图 -->
  <div>
    <div v-if="videoUrl">
      <div class="video-container">
        <video class="video" :src="videoUrl" :id="videoId" controls="controls" :width="width" :height="height" crossorigin="anonymous"></video>
        <el-icon class="video-del-btn" @click.stop="removeVideo"><el-icon-close/></el-icon>
      </div>
    </div>
    <div v-else>
      <el-button class="add-video" :style="{width: width + 'px', height: height + 'px'}" @click="clickFile" :mergeUrl="mergeUrl">
        <el-icon class="add-video-icon"><el-icon-plus/></el-icon>
      </el-button>
      <input v-show="false" :id="fileId" type="file" ref="avatarInput" accept="video/*" @change="changeVideo($event)"/>
      <div class="loading">{{ loadText }}</div>
    </div>
  </div>
</template>
<script>
import SparkMD5 from "@/components/h5/image/spark-md5.min";
import axios from "@/util/axios";
import {watch} from "vue";

export default {
  // getVideo 获取视频事件  setCover 抓取截图，由autoGetCover触发  getTimeCast 获取视频时长，由视频上传触发
  emits: ["update:modelValue", "change", "getVideo", "setCover", "getTimeCast"],
  name: "VideoUpload",
  props: {
    modelValue: {
      type: String
    },
    // 默认视频合并分片地址
    mergeUrl: {default: "/basedata/common/uploadFileMerge"},
    // 默认视频分片上传地址
    uploadPieceUrl: {default: "/basedata/common/uploadFilePiece"},
    // 默认小视频上传地址
    uploadSmallFileUrl: {default: "/basedata/common/updateImage"},
    // 是否自动获取封面，如果自动获取封面，会通过回调的方式进行封面的set，自动获取封面需要给一个上传封面的url
    autoGetCover: {default: false},
    // 自动获取封面的上传路径
    coverUploadUrl: {default: "/basedata/common/updateImage"},
    // 视频的大小尺寸，单位是像素，不带px
    width: {default: "400"},
    height: {default: "300"},
    // 不允许修改
    disabled: {default: false},
    // 文件位置，这个文件放在哪里
    folder: {default: "common"},
  },
  data() {
    return {
      // 完整路径
      coverUrl: "",
      videoUrl: "",
      // 基础路径
      url: "",
      imageUrl: "",
      // 上传进度
      loadText: "",
      // 随机的id，用于视频
      videoId: "",
      // 随机id
      fileId: "",
      // 失败事件
      timeout: "",
    };
  },
  unmounted() {
    // 清空失败事件
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  created() {
    // 创建时获取一个随机id
    let ram = new Date().getTime() + (Math.ceil(Math.random() * 100));
    this.videoId = "v_"+ ram;
    this.fileId = "i_" + ram;
    this.init();
    watch(() => this.modelValue, (value) => {
      this.init()
    });
  },
  methods: {
    init() {
      if (this.modelValue.startsWith("{")) {
        // json格式，是我们的数据
        let obj = JSON.parse(this.modelValue);
        this.url = obj.url;
        this.imageUrl = obj.coverUrl;

        this.videoUrl = this.getUrl4Media(obj.url);
        this.coverUrl = this.getUrl4Media(obj.coverUrl);
      } else if (this.modelValue){
        this.url = this.modelValue;
        // 是video链接
        this.videoUrl = this.getUrl4Media(this.modelValue);
      } else {
        this.url = "";
        this.imageUrl = "";
        this.videoUrl = "";
        this.coverUrl = "";
      }
    },
    getUrl4Media(url) {
      return url.startsWith("http")? url: (process.env.VUE_APP_FILE_URL + url);
    },
    // 删掉视频
    removeVideo() {
      this.$emit("update:modelValue", "");
    },
    // 点击文件，开始上传
    clickFile() {
      const input = document.querySelector("#" + this.fileId);
      input.click();
    },
    changeVideo(e) {
      let file = e.target.files[0];
      const isLt300M = file.size / 1048576 < 300;
      if (!isLt300M) {
        this.$message.error("上传视频大小不能超过300M！");
        return;
      }
      // 获取到文件的md5
      this.calculateFileMd5ByDefaultChunkSize(file).then((md5) => {
        // 上传文件
        this.postFile(file, 0, md5);
      }).catch((e) => {
            // 处理异常
            console.error(e);
      });
    },
    // 分块计算文件的md5值，默认分片大小为2097152（2M）
    calculateFileMd5ByDefaultChunkSize(file) {
      return this.calculateFileMd5(file, 2097152);
    },
    // 分块计算文件的md5值
    calculateFileMd5(file, chunkSize) {
      return new Promise((resolve, reject) => {
        let blobSlice =
            File.prototype.slice ||
            File.prototype.mozSlice ||
            File.prototype.webkitSlice;
        let chunks = Math.ceil(file.size / chunkSize);
        let currentChunk = 0;
        let spark = new SparkMD5.ArrayBuffer();
        let fileReader = new FileReader();

        fileReader.onload = function (e) {
          spark.append(e.target.result);
          currentChunk++;
          if (currentChunk < chunks) {
            loadNext();
          } else {
            let md5 = spark.end();
            resolve(md5);
          }
        };

        fileReader.onerror = function (e) {
          reject(e);
        };

        function loadNext() {
          let start = currentChunk * chunkSize;
          let end = start + chunkSize;
          if (end > file.size) {
            end = file.size;
          }
          fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        }
        loadNext();
      });
    },
    // 上传文件
    postFile(file, i, md5) {
      let size = file.size;
      // 文件大小，超过多少M，使用分片上传
      if (size > 5 * 1024 * 1024) {
        // 分片上传
        this.postFileByPiece(file, i, md5);
      } else {
        // 小文件直接上传
        /*  构建form表单进行提交  */
        let form = new FormData();
        form.append("file", file); //slice方法用于切出文件的一部分
        form.append("folder", this.folder);
        axios.post({url: this.uploadSmallFileUrl, data: form}).then(data => {
          this.$message.success("上传完成");
          this.url = data;
          this.$emit("update:modelValue", this.url);
          // 获取默认的封面
          this.$nextTick().then(() => this.getDefaultCover());
        });

      }
    },
    // 执行分片上传
    postFileByPiece(file, i, md5) {
      let name = file.name, //文件名
          size = file.size, //总大小shardSize = 2 * 1024 * 1024,
          shardSize = 5 * 1024 * 1024, //以5MB为一个分片,每个分片的大小
          shardCount = Math.ceil(size / shardSize); //总片数
      if (i >= shardCount) {
        return;
      }

      let start = i * shardSize;
      let end = start + shardSize;
      let packet = file.slice(start, end); //将文件进行切片
      /*  构建form表单进行提交  */
      let form = new FormData();
      form.append("md5", md5); // 前端生成uuid作为标识符传个后台每个文件都是一个uuid防止文件串了
      form.append("file", packet); //slice方法用于切出文件的一部分
      form.append("name", name);
      form.append("totalSize", size);
      form.append("total", shardCount); //总片数
      form.append("index", i + 1); //当前是第几片
      let prex = (i / shardCount) * 100;
      this.loadText = "上传中：" + prex.toFixed(2) + "%";
      axios.post({url: this.uploadPieceUrl, data: form}).then(data => {
        /*  表示上一块文件上传成功，继续下一次  */
        if (data === "5010") {
          form = "";
          i++;
          this.postFile(file, i, md5);
        } else if (data === "5050") {
          form = "";
          /*  失败后，每2秒继续传一次分片文件  */
          this.timeout = setTimeout( () => {
            this.postFile(file, i, md5);
          }, 2000);
        } else if (data === "5000") {
          this.merge(
              shardCount,
              name,
              md5,
              this.getFileType(file.name),
              file.size
          );
          this.loadText = "上传中：100%";
        } else {
          console.log("未知错误");
        }
      });
    },
    // 合并文件
    merge(shardCount, fileName, md5, fileType, fileSize) {
      const info = {
        total: shardCount,
        md5: md5,
        fileType: fileType,
        fileSize: fileSize,
        folder: this.folder
      };
      axios.get({url: this.mergeUrl, data: info}).then(data => {
        // 进度
        this.loadText = "";
        this.url = data;
        this.updateVideo();
        this.$emit("change");
        // 获取默认的封面
        this.$nextTick().then(() => this.getDefaultCover());
      });
    },
    // 向父组件更新数据
    updateVideo() {
      let info = {url: this.url, coverUrl: this.coverUrl};
      console.log(JSON.stringify(info))
      this.$emit("update:modelValue", JSON.stringify(info));
    },
    // 获取默认的封面，只有视频上传完成后才会调用这个功能
    getDefaultCover() {
      if (!this.autoGetCover) {
        // 不自动获取封面
        return;
      }
      // 没有上传的url
      if (!this.coverUploadUrl) {
        return;
      }
      // event js原生事件
      const videoEle = document.getElementById(this.videoId); // 以后video dom节点
      videoEle.currentTime = 1; // 设置视频开始播放工夫（因为有些手机第一帧显示黑屏，所以这里选取视频的第一秒作为封面）
      videoEle.addEventListener("loadeddata",  () => {
        // 监听video的canplay事件
        // 创立canvas元素 并设置canvas大小等于video节点的大小
        const canvas = document.createElement("canvas");
        const scale = 0.8; // 压缩系数
        canvas.width = videoEle.videoWidth * scale;
        canvas.height = videoEle.videoHeight * scale;
        // canvas画图
        canvas
            .getContext("2d")
            .drawImage(videoEle, 0, 0, canvas.width, canvas.height);
        // 把canvas转成base64编码格局
        const imgSrc = canvas.toDataURL("image/png");
        this.uploadImg(imgSrc);
      });
    },
    // 获取文件的后缀名
    getFileType(fileName) {
      return fileName.substr(fileName.lastIndexOf(".") + 1).toLowerCase();
    },
    uploadImg(imgSrc) {
      let file = this.base64toFile(imgSrc, "file"); //base64图片格式转文件流
      let formData = new FormData();
      formData.append("file", file);
      formData.append("folder", this.folder);
      axios.post({url: this.coverUploadUrl, data: formData}).then(data => {
        // 触发事件
        this.$emit("setCover", data);
        this.coverUrl = data;
        this.updateVideo();
      });
    },
    base64toFile(data, fileName) {
      const dataArr = data.split(",");
      const byteString = atob(dataArr[1]);
      const options = {
        type: "image/jpeg",
        endings: "native",
      };
      const u8Arr = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        u8Arr[i] = byteString.charCodeAt(i);
      }
      return new File([u8Arr], fileName + ".jpg", options); //返回文件流
    },
  },
  mounted() {

  },
  beforeDestroy() {
  },
};
</script>

<style lang="scss" scoped>
.add-video{
  width: 150px;
  height: 150px;
  line-height: 150px;
  padding: 0;
  border-radius: 8px;
  border-style: dashed;
  background: #fafafa;
  .add-video-icon{
    font-size: 44px;
    color: #909399;
  }
}
.video-container{
  position: relative;
  display: inline-block;
  .video{
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    object-fit: contain;
  }
  .video-del-btn{
    position: absolute;
    top: 5px;
    right: 5px;
    color: red;
    font-size: 20px;
    border-radius: 20px;
  }
}

</style>
