import axios from "@/util/axios";

export default{
    // 保存挂载分类
    saveCategory: (data)=> axios.post({url: "/basedata/base/common/mini/category/saveCategory",data: data}),

    // 删除挂载分类
    deleteCategory: (data)=> axios.post({url: "/basedata/base/common/mini/category/deleteCategory",data: data}),

    // 查询挂载分类
    queryCategory: (departId)=> axios.get({url: "/basedata/base/common/mini/category/queryCategory",data: {departId}}),

    // 开启店铺
    openStore: (departId,businessId)=> axios.get({url: "/basedata/base/common/mini/category/openStore",data: {departId,businessId}}),

    // 关闭店铺
    closeStore: (departId)=> axios.get({url: "/basedata/base/common/mini/category/closeStore",data: {departId}}),

    // 查询商业类型
    queryBusiness: ()=>axios.get({url: "/basedata/base/common/mini/category/queryBusiness"}),

    // 上架店铺
    findByDepartId: (departId)=> axios.get({url: "/basedata/base/common/mini/category/findByDepartId",data: {departId}}),

    // 修改配送
    updateDelivery: (id,delivery)=> axios.get({url: "/basedata/base/common/mini/category/updateDelivery",data: {id,delivery}}),

    // 查询所有已上架的店铺
    queryDepart: (businessType) => axios.get({url: "/basedata/base/common/mini/category/queryDepart",data:{businessType}}),

    // 保存信息
    save: (data) => axios.post({url: "/basedata/base/common/mini/category/save",data: data}),

    // 修改图片信息
    updateBannerImg: (data) => axios.get({url: "/basedata/base/common/mini/category/updateBannerImg",data: data})
}