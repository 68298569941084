import axios from "../../../util/axios";

export default {
    // 保存数据
    save: (data) => axios.post({url: "/order/house/subscribe/save", data: data}),

    // 删除一笔
    deleteById: (id) => axios.del({url: "/order/house/subscribe/deleteById", data: {id}}),

    // 查询详情
    findById: (id) => axios.get({url: "/order/house/subscribe/findById", data: {id}}),

    // 查询列表
    queryList: (data) => axios.get({url: "/order/house/subscribe/queryList", data: data}),

    // 查询订单详情
    findByCondition: (orderId, customerId) => axios.get({url: "/order/house/subscribe/findByCondition", data: {orderId, customerId}}),

}