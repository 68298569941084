<template>
  <!-- 通用模板，列表数据 -->
  <list-page>
    <search-condition @search="resetSearch" :show-search="true">
      <el-input v-model="searchInfo.searchKey" placeholder="请输入查询条件"/>
      <el-select v-model="searchInfo.stockId" placeholder="请选择仓库" clearable>
        <el-option v-for="(s, i) in stockList" :key="i" :label="s.stockName" :value="s.id"/>
      </el-select>
    </search-condition>
    <table-list>
      <el-table :data="dataList" height="100%" border>
        <el-table-column label="序号" width="60px" type="index"/>
        <el-table-column label="仓库" prop="stockName" show-overflow-tooltip/>
        <el-table-column label="商品名称" prop="skuName" show-overflow-tooltip/>
        <el-table-column label="库存单位" prop="unitName" show-overflow-tooltip/>
        <el-table-column label="预警类型" prop="warnType" show-overflow-tooltip>
          <template v-slot="{row}">
            <span v-if="row.warnType === 1">最小库存预警</span>
            <span v-if="row.warnType === 2">最大库存预警</span>
          </template>
        </el-table-column>
        <el-table-column label="库存量" prop="skuQty" show-overflow-tooltip/>
        <el-table-column label="下限预警值" prop="warnMin" show-overflow-tooltip/>
        <el-table-column label="上限预警值" prop="warnMax" show-overflow-tooltip/>
        <el-table-column label="规则组" prop="ruleGroupName" show-overflow-tooltip/>
<!--        <el-table-column label="操作" :align="'center'">-->
<!--          <template v-slot="{row}">-->
<!--            <table-button type="write" @click="toShowHangOn(row)">不再提示</table-button>-->
<!--            <table-button message="是否确认删除此数据,删除后下次进行库存变更时可能会产生新的预警" type="delete" @click="deleteInfo(row)">删除</table-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
    </table-list>
    <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
  </list-page>
  <el-dialog title="挂起规则" v-model="showHangOn" width="400px">
    <el-form label-width="100px">
      <el-form-item label="规则组名称">{{currentData.ruleGroupName}}</el-form-item>
      <el-form-item label="预警商品">{{currentData.skuName}}</el-form-item>
      <el-form-item label="挂起时间">
        <date-picker v-model="currentData.hangupTime"/>
      </el-form-item>
    </el-form>
    <div style="font-size: 12px;color: #999">在这个时间之前挂起这条规则，并删除预警数据，不再进行提示,如果不设置时间，规则将被永久挂起，只能手动进行规则修改</div>
    <div style="text-align: right;padding-top: 10px">
      <page-button @click="showHangOn = false">取消</page-button>
      <page-button @click="hangOn" type="write">确认</page-button>
    </div>
  </el-dialog>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import stockWarningApi from "@/api/main/stock/stockWarningApi";
import DatePicker from "@/components/h5/date/DatePicker";
export default {
  name: "",
  components: {
    TableButton,
    PageButton,
    ListPage, TableList, FormPage, SearchCondition,DatePicker
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      stockList: [],
      showHangOn: false,
      currentData: {
        ruleGroupName: "",
        warnMax: "",
        warnMin: "",
        hangOnTime: "",
        hangupTime: "",
      }
    };
  },
  created() {
    // 查询仓库
    stockWarningApi.queryStock().then(list => this.stockList = list);
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      stockWarningApi.queryList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      })
    },
    // 挂起规则
    toShowHangOn(data) {
      this.currentData = {...data};
      this.showHangOn = true;
    },
    hangOn() {

    },
    // 删除预警消息
    deleteInfo(row) {

    }
  }
};
</script>

<style lang="scss" scoped>

</style>