<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header/>
      <table-list>
        <el-descriptions :column="2" border size="small">
          <el-descriptions-item label="车主名称">
            <el-input v-model="dataDetail.customerName" placeholder="请输入车主名称"/>
          </el-descriptions-item>
          <el-descriptions-item label="联系电话">
            <el-input v-model="dataDetail.customerPhone" placeholder="请输入车主联系电话"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="车牌号码">
            <el-input v-model="dataDetail.carNo" placeholder="请输入号牌号码"/>
          </el-descriptions-item>
          <el-descriptions-item label="车辆品牌">
            <el-input v-model="dataDetail.brandId" placeholder="请添加车辆品牌"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="车辆型号">
            <el-input v-model="dataDetail.brandModelId" placeholder="请添加车辆型号"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="车架号码">
            <el-input v-model="dataDetail.frameNumber" placeholder="请添加车架号码"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="门店">
            <el-select v-model="dataDetail.storeId" style="width: 100%">
              <el-option v-for="item in storeList" :key="item.id" :label="item.storeName" :value="item.id"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="仓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;库">
            <el-select v-model="dataDetail.stockId" style="width: 100%">
              <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
            </el-select>
          </el-descriptions-item>

          <el-descriptions-item label="创建人员">{{dataDetail.createdName}}</el-descriptions-item>
          <el-descriptions-item label="订单编号">{{dataDetail.id}}</el-descriptions-item>
          <el-descriptions-item label="订单时间">
            <el-date-picker v-model="dataDetail.orderTime" style="width: 100%"/>
          </el-descriptions-item>
          <el-descriptions-item label="订单状态">
            <span v-if="dataDetail.status === 0">草稿</span>
            <span v-else-if="dataDetail.status === 99">完成</span>
          </el-descriptions-item>
          <el-descriptions-item label="施工部位" :span="2">
            <el-checkbox-group v-model="dataDetail.constructorArr">
              <el-checkbox v-for="(c, i) in carPartList" :key="i" :label="c.dictName" :value="c.dictCode" />
              <table-button @click="selectAllPart">全选</table-button>
            </el-checkbox-group>
          </el-descriptions-item>
          <el-descriptions-item label="备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注" :span="2">
            <el-input v-model="dataDetail.remark" type="textarea" :rows="1"/>
          </el-descriptions-item>
          <el-descriptions-item label="施工图片" :span="2">
            <div class="construct-image-title">前方照片</div>
            <image-upload v-model="dataDetail.imageFront" :limit="9" :disabled="isDisabled"/>
            <div class="construct-image-title">45度角照片</div>
            <image-upload v-model="dataDetail.imageBias" :limit="9" :disabled="isDisabled"/>
            <div class="construct-image-title">后方照片</div>
            <image-upload v-model="dataDetail.imageBack" :limit="9" :disabled="isDisabled"/>
            <div class="construct-image-title">质保码照片</div>
            <image-upload v-model="dataDetail.imageSkuCode" :limit="9" :disabled="isDisabled"/>
            <div class="construct-image-title">商品包装+车辆照片</div>
            <image-upload v-model="dataDetail.imageSkuCar" :limit="9" :disabled="isDisabled"/>
            <div class="construct-image-title">车辆与门头</div>
            <image-upload v-model="dataDetail.imageDoor" :limit="9" :disabled="isDisabled"/>
          </el-descriptions-item>
        </el-descriptions>
        <div style="text-align: right" v-if="!isDisabled">
          <div style="display: inline-block;width: 300px;padding-bottom: 10px">
            <product-list-select sku-id-column="skuOriginalCode" product-id-column="originalCode" :column-display="productDisplayColumns" :stock-id="dataDetail.stockId" :is-stock="true" :query-list-api="queryProductApi" @select="selectProduct" @selectOne="selectOneProduct"  :selected-product-list="dataDetail.productList"/>
          </div>
        </div>
        <el-table :data="dataDetail.productList" border>
          <el-table-column label="序号" width="60px" type="index"/>
          <el-table-column show-overflow-tooltip prop="skuName" label="商品名称"/>
          <el-table-column show-overflow-tooltip prop="barCode" label="条码"/>
          <el-table-column show-overflow-tooltip prop="skuOriginalCode" label="溯源码"/>
          <el-table-column label="质保时间" show-overflow-tooltip min-width="250px" v-if="warrantySwitch === '1'" width="250px" :align="'center'">
            <template v-slot="{row}">
              <date-range :disabled="true" :start-time="row.warrantyStart" :end-time="row.warrantyEnd"/>
            </template>
          </el-table-column>
          <el-table-column prop="skuUnitName" label="单位" :align="'center'" width="60px"/>
          <el-table-column prop="skuOriginalPrice" label="单价" :align="'center'" width="100px">
            <template v-slot="{row}">
              <el-input v-model="row.skuOriginalPrice"/>
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'" width="120px">
            <template v-slot="{row}">
              <table-button  :disabled="isDisabled" el-type="danger" message="是否确认删除此数据" type="delete"  @click="deleteRow(row,$index)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page :show-page="false">
        <div>
          <page-button :disabled="isDisabled" el-type="success" type="write" @click="saveDraft">保存草稿</page-button>
          <page-button :disabled="isDisabled" el-type="primary" type="write" @click="saveSubmit">提交数据</page-button>
        </div>
      </form-page>
    </list-page>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import Dictionary from "@/components/h5/Dictionary";
import ImageUpload from "@/components/h5/image/ImageUpload.vue";
import CustomerSelect from "@/components/h5/customer/CustomerSelect";
import SaleOrder from "@/api/alpine/shop/saleOder";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import util from "@/util/util"
import {useRoute, useRouter} from "vue-router";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import commonApi from "@/api/commonApi";
import DateRange from "@/components/h5/date/DateRange.vue";
import TableButton from "@/components/h5/button/TableButton.vue";

export default {
  setup() {
    return {
      router: useRouter(),
      route: useRoute(),
    }
  },
  name: "", components: {
    TableButton,
    PageButton,
    ListPage, TableList, FormPage, Dictionary,ImageUpload,CustomerSelect,ProductListSelect, PageHeader, DateRange
  }, data() {
    return {
      searchInfo:{
        pageNumber:1,
        pageSize:10
      },
      dataDetail: {
        productList: [],
        storeId:"",
        carNo: "",
        payType: 999,
        carId:"",
        orderTime: new Date(),
        constructorArr: [],
        imageFront: "",
        imageBias: "",
        imageBack: "",
        imageSkuCode: "",
        imageSkuCar: "",
        imageDoor: "",
      },
      stockList: [],
      carList:[],
      storeList:[],
      queryProductApi: {},
      isDisabled: false,
      // 质保功能开关
      warrantySwitch: "0",
      // 查询商品时要显示的字段
      productDisplayColumns: [
        {prop: "skuName", label: "商品名称", width: ""},
        {prop: "skuSalePrice", label: "商品价格", width: "100px"},
        {prop: "originalCode", label: "溯源码", width: "250px"},
        {prop: "skuStandard", label: "规格", width: "100px"},
        {prop: "skuType", label: "型号", width: "100px"},
      ],
      carPartList: [],
      originalCodeType: "",
    };
  },
  mounted() {
    if (this.$routerUtil.query.id) {
      // 查询数据
      this.findById(this.$routerUtil.query.id);
    }
  },
  created() {
    this.queryProductApi = this.queryProductSkuOriginal;
    // 查询质保功能开关
    commonApi.findSetting("OD_WARRANTY_SWITCH").then(setting => this.warrantySwitch = setting.settingValue);
    // 溯源码的类型/格式
    commonApi.findSetting("STOCK_ORIGINAL_TYPE").then(setting => this.originalCodeType = setting.settingValue);
    // 车辆部位字典
    SaleOrder.queryCarParts().then(list => this.carPartList = list);
    // 查询仓库
// 获取用户信息
    util.getUserInfo().then((user) => {
      console.log(user.mainDepartId);
      //  根据departId查询仓库，如果只有一个仓库，添加创建
      SaleOrder.queryAllStock(this.searchInfo).then(list => {
        this.stockList = list;
        if (!this.$route.query.id) {
          // 新建的时候
          this.dataDetail.createdName = user.userName;
          this.dataDetail.employeeId = user.id;
          this.dataDetail.departCode = user.mainDepartCode;
          this.dataDetail.customerId = "10000";
          // 如果只有一个仓库，选择这个仓库
          if (list.length === 1) {
            this.dataDetail.stockId = list[0].id;
          }
        }
      });
    });
    //   查询门店信息
    SaleOrder.queryStoreList().then(list =>{
      this.storeList = list
      if (!this.dataDetail.id) {
        this.dataDetail.storeId = this.storeList[0].id
      }
    })

  }, methods:{
    selectAllPart() {
      if (this.dataDetail.constructorArr.length === this.carPartList.length) {
        // 全选，变更为全部取消
        this.dataDetail.constructorArr = [];
      } else {
        let arr = [];
        for (let i = 0; i < this.carPartList.length; i++) {
          arr.push(this.carPartList[i].dictCode);
        }
        this.dataDetail.constructorArr = arr;
        console.log(arr)
      }
    },
    queryProductSkuOriginal(param) {
      param.stockId = this.dataDetail.stockId;
      return SaleOrder.queryStockProductsAndOriginal(param);
    },
    selectProduct(list) {
      let arr = [...this.dataDetail.productList];
      // 有哪些商品，看是否存在，不存在就添加
      for (let i = 0; i < list.length; i++) {
        let contains = false;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].skuId === list[i].id) {
            // 存在，更新数量
            contains = true
            break;
          }
        }
        // 不存在，添加
        if (!contains) {
          arr.push(this.createOrderDetail(list[i]));
        }
      }
      this.dataDetail.productList = arr;
      // 更新数据
      // this.updateOrderData();
    },
    createOrderDetail(data) {
      return {
        skuId: data.id,
        skuName: data.skuName,
        skuType:data.skuType,
        skuOriginalCode: data.originalCode,
        skuCode: data.skuCode,
        barCode: data.barCode,
        skuUnitId: data.skuUnitId,
        qty: 1,
        skuUnitName: data.skuUnitName,
        skuSalePrice: data.skuSalePrice,
        skuBuyPrice: data.skuBuyPrice,
        skuOriginalPrice: data.skuSalePrice,
        rate: 100,}
    },
    // 商品信息
    selectOneProduct(data) {
      let contains = false;
      // 商品列表是否有数据，没有已添加，有就数量+1
      if (!contains) {
        this.dataDetail.productList.push(this.createOrderDetail(data));
      }
    },

    //获取用户格式
    setCustomer(data) {
      this.dataDetail.customerId = data.id;
      this.dataDetail.customerName = data.customerName;
      this.dataDetail.customerPhone = data.customerPhone;
      let id = data.id;
      if (id) {
        //查询汽车信息
        SaleOrder.queryCar(id).then(response =>{
          this.carList = response.carList;
        })
      }
    },
    //  根据车牌号自动输入汽车品牌和型号
    getcarinfo(data){
      // 根据id找到数据
      for (let i = 0; i < this.carList.length; i++) {
        if (data === this.carList[i].id) {
          let res = this.carList[i];
          this.dataDetail.carNo = res.carNo;
          this.dataDetail.brandId = res.brandId;
          this.dataDetail.brandModelId = res.brandModelId;
          this.dataDetail.frameNumber = res.frameNumber;
        }
      }

    },
    findById(id) {
      SaleOrder.findById(id).then((data) => {
        if (data.construction) {
          data.constructorArr = data.construction.split(",");
        }
        this.dataDetail = data;
        this.isDisabled = this.dataDetail.status > 0;
        this.carList = [{
          id: data.carId,
          carNo: data.carNo
        }];
      });
    },
    //删除当前行数据
    deleteRow(row, index) {
      this.dataDetail.productList.splice(index, 1);
    },
    checkData() {
      if(!this.dataDetail.customerName){
        this.$message("请输入用户名称");
        return false;
      }
      if(!this.dataDetail.customerPhone){
        this.$message("请选择车牌号");
        return false;
      }
      return true;
    },
    // 保存草稿
    saveDraft() {
      if (!this.checkData()) {
        return;
      }
      this.formatData();
      SaleOrder.saveDraft(this.dataDetail).then((data) => {
         let id = data.id
         this.findById(id);
      });
    },
    // 提交保存
    saveSubmit(){
      if (!this.checkData()) {
        return;
      }
      this.formatData();
      SaleOrder.saveSubmit(this.dataDetail).then((data)=>{
        let id = data.id
        this.findById(id);
      })
    },
    formatData() {
      this.dataDetail.construction = this.dataDetail.constructorArr.join(",");
      this.dataDetail.originalCodeType = this.originalCodeType;
    }
  }};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/description";
.custom-info{
  min-width: 400px;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  display: flex;
  justify-content: space-between;
  padding-right: 100px;
}
</style>