<template>
  <!-- 客房档案 -->
  <div class="defaultViewStyle">
    <separate-page>
      <template #category>
        <el-tree ref="groupTree" node-key="id" :data="floorCategoryList"
                 :props="{ children: 'children', label: 'typeName' }" @node-click="clickType" default-expand-all
                 :expand-on-click-node="false">
          <template v-slot="{ node, data }">
            <el-tooltip
                class="box-item"
                effect="dark"
                :content="data.describe"
                placement="right-start"
            >
            <span class="custom-tree-node">
              <span class="node-name">{{ node.label }}</span>
              <span class="node-option">
                <table-button v-if="!data.id" type="primary" @click="toAddNewFloor({parentId: data.id})"><el-icon><el-icon-plus/></el-icon></table-button>
                <table-button v-if="data.id" type="primary" @click="toAddNewFloor(data)"><el-icon><el-icon-edit/></el-icon></table-button>
                <table-button v-if="data.id" type="delete" class="btn" message="是否确认删除这个类型"
                              @click="toDeleteFloor(data.id)"><el-icon><el-icon-delete/></el-icon></table-button>
              </span>
            </span>
            </el-tooltip>

          </template>
        </el-tree>
      </template>
      <template #default>
        <list-page>
          <search-condition @search="handleSearch" :show-search="true">
            <template #default>
              <el-input v-model="searchInfo.searchKey" placeholder="请输入客房名称/楼层" @keydown.enter="handleSearch"
                        clearable/>
            </template>
            <template #option>
              <page-button el-type="success" type="write" @click="toEdit({})">新增</page-button>
            </template>
          </search-condition>
          <table-list>
            <el-table :data="dataList" height="100%" border>
              <el-table-column label="序号" type="index" width="60px" :alter="'center'" align="center"/>
              <el-table-column label="房间名称" prop="name" min-width="120px" :alter="'center'" align="center"/>
              <el-table-column label="房间类型" prop="typeName" min-width="120px" :alter="'center'" align="center"/>
              <el-table-column label="楼层" prop="typeFloorName" min-width="120px" :alter="'center'" align="center"/>
              <el-table-column label="关联订单商品" prop="productName" min-width="120px" :alter="'center'"
                               align="center">
                <template v-slot="{ row }">
                  <table-button @click="showProductDetail(row.productId)" v-if="row.productName">{{
                      row.productName
                    }}
                  </table-button>
                  <span v-else>未绑定</span>
                </template>
              </el-table-column>
              <el-table-column label="关联续住商品" prop="productName" min-width="120px" :alter="'center'"
                               align="center">
                <template v-slot="{ row }">
                  <table-button @click="showProductDetail(row.contProductId)" v-if="row.contProductName">
                    {{ row.contProductName }}
                  </table-button>
                  <span v-else>未绑定</span>
                </template>
              </el-table-column>
              <el-table-column label="房间描述" prop="standard" show-overflow-tooltip min-width="120px"
                               :alter="'center'" align="center"/>
              <el-table-column label="操作" :align="'center'" width="120px" fixed="right">
                <template v-slot="{ row }">
                  <table-button @click="toEdit(row)">编辑</table-button>
                  <table-button el-type="danger" type="danger" message="您确定要删除吗？" @click="toDelete(row)">删除
                  </table-button>
                </template>
              </el-table-column>
            </el-table>
          </table-list>
          <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                     :total-count="searchInfo.totalCount" :default-load="false"></form-page>
        </list-page>
      </template>
    </separate-page>

    <el-dialog v-model="showProductDialog" title="商品详情" width="30%">
      <el-form label-width="90px">
        <el-form-item label="商品名称：">
          {{ productDetail.productName }}
        </el-form-item>
        <el-form-item label="商品售价：">
          {{ productDetail.basePrice }}￥
        </el-form-item>
        <el-form-item label="商品规格：">
          {{ productDetail.productStandard }}
        </el-form-item>
        <el-form-item label="商品型号：">
          {{ productDetail.productType }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog @closed="currentType = {}" title="楼层管理" v-model="showFloorDialog" width="40%">
      <el-form label-width="70px">
        <el-form-item label="客房类型">
          <el-input v-model="currentType.typeName"/>
        </el-form-item>
        <el-form-item label="类型描述">
          <el-input type="textarea" v-model="currentType.describe"/>
        </el-form-item>
      </el-form>
      <detail-page-btn :position="'RIGHT'">
        <el-button @click="showFloorDialog = false">取消</el-button>
        <el-button type="primary" @click="saveFloor">确定</el-button>
      </detail-page-btn>
    </el-dialog>

    <el-dialog @closed="currentRoom = {}" title="客房管理" v-model="showRoomDialog" width="40%">
      <el-form label-width="70px">
        <el-form-item label="客房名称">
          <el-input v-model="currentRoom.name"/>
        </el-form-item>
        <el-form-item label="客房类型">
          <el-select v-model="currentRoom.typeId">
            <el-option v-for="item in floorList" :key="item.id" :label="item.typeName" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="选择楼层">
          <el-select v-model="currentRoom.typeFloorId">
            <el-option v-for="item in roomList" :key="item.id" :label="item.typeName" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="关联商品">
          <product-list-select :query-list-api="ProductApi.queryList" :query-params="{category: this.confineCategory}"
                               :btn-name="currentRoom.productName ? currentRoom.productName : '关联订单商品'"
                               :small-btn="true" :show-search="false" @select="selectData"/>
          <product-list-select style="margin-left: 10px" :query-list-api="ProductApi.queryList" :query-params="{category: this.confineCategory}"
                               :btn-name="currentRoom.contProductName ? currentRoom.contProductName : '关联续住商品'"
                               :small-btn="true" :show-search="false" @select="selectContData"/>
        </el-form-item>
        <el-form-item label="客房描述">
          <el-input type="textarea" v-model="currentRoom.standard"/>
        </el-form-item>
      </el-form>
      <detail-page-btn>
        <el-button @click="showRoomDialog = false">取消</el-button>
        <el-button type="primary" @click="saveRoom">确定</el-button>
      </detail-page-btn>
    </el-dialog>

  </div>
</template>

<script>

import ListPage from "@/components/h5/layout/ListPage.vue";
import SeparatePage from "@/components/h5/layout/SeparatePage.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import houseTypeApi from "@/api/main/house/houseTypeApi";
import DetailPageBtn from "@/components/h5/layout/DetailPageBtn.vue";
import houseDocApi from "@/api/main/house/houseDocApi";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import ProductApi from "@/api/main/product/productApi";
import commonApi from "@/api/commonApi";
import productApi from "@/api/main/product/productApi";

export default {
  name: "",
  computed: {
    ProductApi() {
      return ProductApi
    }
  },
  components: {
    ProductListSelect,
    DetailPageBtn,
    Dictionary, SearchCondition, PageButton, TableButton, TableList, FormPage, SeparatePage, ListPage
  },
  data() {
    return {
      roomList: [],
      floorList: [],
      floorCategoryList: [],
      currentType: {},
      showFloorDialog: false,
      searchInfo: {},
      dataList: [],
      currentRoom: {},
      showRoomDialog: false,
      confineCategory: "",
      productDetail: {},
      showProductDialog: false,
    };
  },
  created() {
    this.queryFloorList();
    // 客房类型
    houseTypeApi.queryAllList({searchType: 0}).then(list => this.roomList = list);
    // 月子中心分类
    commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => {
      let category = list.filter(item => item.dictName === "月子中心");
      this.confineCategory = category.dictCode;
    });
    if(this.$routerUtil.query.name){
      this.searchInfo.searchKey = this.$routerUtil.query.name;
      this.queryList().then(() => this.toEdit(this.dataList[0]))
    }else{
      this.queryList();
    }
  },
  methods: {
    showProductDetail(productId) {
      productApi.findById(productId).then(data => {
        this.productDetail = data;
        this.showProductDialog = true;
      });
    },
    // 查询全部楼层
    queryFloorList() {
      houseTypeApi.queryAllList({searchType: 1}).then(list => {
        this.floorList = list;
        this.floorCategoryList = [{typeName: "客房类型", describe: "客房类型", children: list}];
      })
    },
    saveRoom() {
      houseDocApi.save(this.currentRoom).then(() => {
        this.handleSearch();
        this.showRoomDialog = false;
      })
    },
    toEdit(data) {
      if (!data.typeId) {
        data.typeId = this.currentType.id;
      }
      this.showRoomDialog = true;
      this.currentRoom = data;
    },
    selectData(data) {
      if (data.length > 1) {
        this.$message.error("当前只允许选择一件商品");
        return;
      }
      this.currentRoom.productId = data[0].id;
      this.currentRoom.productName = data[0].productName;
    },
    selectContData(data) {
      if (data.length > 1) {
        this.$message.error("当前只允许选择一件商品");
        return;
      }
      this.currentRoom.contProductId = data[0].id;
      this.currentRoom.contProductName = data[0].productName;
    },
    toDelete(row) {
      houseDocApi.deleteById(row.id).then(() => this.handleSearch());
    },
    handleSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      return houseDocApi.queryList(this.searchInfo).then(data => {
        this.dataList = data.records;
        this.searchInfo.totalCount = data.totalCount;
      })
    },
    toAddNewFloor(data) {
      this.currentType = data;
      this.showFloorDialog = true;
    },
    toDeleteFloor(id) {
      houseTypeApi.deleteById(id).then(() => {
        this.queryFloorList();
        this.showFloorDialog = false;
      })
    },
    saveFloor() {
      this.currentType.status = 1;
      houseTypeApi.save(this.currentType).then(() => {
        this.queryFloorList();
        this.showFloorDialog = false;
      })
    },
    clickType(data) {
      this.searchInfo.dataId = data.id;
      this.handleSearch();
      this.currentType = data;
    },
  }
};
</script>

<style lang="scss" scoped>
// 树形组织机构统一样式
:deep(.el-tree-node.is-current > .el-tree-node__content ){
  background: #409EFF;
  color: #FFF;
  position: relative;
  overflow: hidden;
  .custom-tree-node{
    display: block;
    width: 100%;
    line-height: 28px;
    height: 28px;

    .node-option{
      display: inline;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      text-align: right;
      background-color: #409eff;
      line-height: 28px;
      box-sizing: border-box;
      padding: 0 10px;
      .el-icon{
        color: #FFF;
      }
    }
  }
}
:deep(.el-tree-node__content){
  font-size: 12px;
}
.custom-tree-node{
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding-right: 10px;
  .node-option{
    //display: none;
    float: right;
  }
}
</style>