<template>
  <!-- -->
  <list-page class="defaultViewStyle">
    <page-header>
      <page-button el-type="primary" @click="resetSearch">查询</page-button>
      <page-button el-type="success" type="write" @click="showEditSupplier = true">新建</page-button>
    </page-header>
    <search-condition @search="resetSearch">
      <template #default>
        <el-input v-model="searchInfo.searchKey" placeholder="请输入供应商名称"/>
      </template>
    </search-condition>
    <table-list>
      <el-table :data="list" border size="small" height="100%">
        <el-table-column align="center" label="序号" fixed type="index" width="60"/>
        <el-table-column align="center" label="供应商名称" prop="supplierName"/>
        <el-table-column align="center" label="联系电话" prop="customerPhone">
          <template v-slot="{row}">
            <span>{{ row.supplierPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2') }}</span>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" :align="'center'" width="100px">
          <template v-slot="{row}">
            <table-button el-type="primary" @click="toEdit(row)">编辑</table-button>
            <table-button el-type="danger" message="是否确认删除数据" @click="toDelete(row)">删除</table-button>
          </template>
        </el-table-column>
      </el-table>
    </table-list>
    <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList" />


    <el-dialog title="编辑供应商" v-model="showEditSupplier" @close="editSupplier = {}" width="40%">
      <el-form label-width="90px">
        <el-form-item label="供应商名称">
          <el-input v-model="editSupplier.supplierName" placeholder="请输入供应商名称"/>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="editSupplier.supplierPhone" placeholder="请输入联系电话"/>
        </el-form-item>
      </el-form>
      <div style="display: flex;justify-content: flex-end">
        <page-button el-type="info" @click="showEditSupplier = false">取消</page-button>
        <page-button el-type="primary" @click="save">保存</page-button>
      </div>
    </el-dialog>
  </list-page>
</template>

<script>
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import TableButton from "@/components/h5/button/TableButton";
import PageButton from "@/components/h5/button/PageButton";
import ListPage from "@/components/h5/layout/ListPage";
import memberApi from "@/api/main/customer/memberApi";
import DatePicker from "@/components/h5/date/DatePicker";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import stockSupplierApi from "@/api/main/stock/stockSupplierApi";
export default {
  name: "",
  components: {
    SearchCondition, TableList, TableButton, FormPage, PageButton, ListPage, DatePicker, PageHeader
  },
  data() {
    return {
      list: [],
      searchInfo: {},
      showEditSupplier: false,
      editSupplier: {
        supplierName: "",
        supplierPhone: "",
      }
    };
  },
  created() {
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      stockSupplierApi.queryList(this.searchInfo).then(res => {
        this.list = res.records;
        this.searchInfo.totalCount = res.totalCount;
      })
    },
    toDelete(data) {
      stockSupplierApi.deleteById(data.id).then(() => {
        this.queryList();
      })
    },
    toEdit(data){
      this.editSupplier = data;
      this.showEditSupplier = true;
    },
    save(){
      stockSupplierApi.saveSubmit(this.editSupplier).then(()=> {
        this.queryList();
        this.showEditSupplier = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.el-date-editor){
  width: 100%;
}
</style>