import axios from "../../../util/axios";

export default{
    //查询列表数据
    queryList: (searchInfo) => axios.get({url: "/order/product/grant/queryList", data: searchInfo}),
    // 查询商户列表
    queryMerchantList: () => axios.get({url: "/order/product/grant/queryMerchantList"}),
    // 保存授权
    saveGrantByList: (list, merchantId) => axios.post({url: `/order/product/grant/saveGrantByList?merchantId=${merchantId}`, data: list}),
    // 批量删除授权
    deleteGrantByList: (list) => axios.post({url: `/order/product/grant/deleteGrantByList`, data: list}),
    // 删除授权
    deleteGrantSku: (id, authorizeMerchantId, merchantId) => axios.del({url: `/order/product/grant/deleteGrantSku`, data: {id, authorizeMerchantId, merchantId}}),
}