import axios from "@/util/axios";

export default {
    // 列表查询
    queryList: (data)=>axios.get({url: "/order/common/imageControl/queryList", data: data}),

    // 查询树形结构
    queryByTree: ()=> axios.get({url: "/order/common/imageControl/queryByTree"}),

    // 删除
    delete: (ids)=> axios.post({url: "/order/common/imageControl/delete", data: ids}),

    // 修改
    updateBatch: (data)=> axios.post({url: `/order/common/imageControl/updateBatch`, data: data}),

    // 修改单个
    updateSingle: (data)=> axios.get({url: `/order/common/imageControl/updateSingle`, data: data}),

    // 保存图片
    savePic: (data)=> axios.post({url: `/order/common/imageControl/save`, data: data}),

    // 新建文件夹
    createFolder: (data)=> axios.post({url: "/order/common/imageControl/createFolder", data: data}),

    // 上级文件夹
    superiorFolder: (id) => axios.get({url: "/order/common/imageControl/superiorFolder", data: {id}})
}