<template>
  <!-- -->
  <div class="defaultViewStyle">
    <separate-page>
      <template #category>
        <tree-dictionary v-model:dictionary-list="treeList" dictionary-type="MERCHANT_CATEGORY" @clickCategory="clickCategory"/>
      </template>
      <template #default>
        <list-page>
          <search-condition @search="resetSearch" :show-search="true">
            <template #default>
              <el-input v-model="searchInfo.searchKey" placeholder="请输入商户名称" @keydown.enter="resetSearch"/>
            </template>
            <template #option>
              <page-button type="write" el-type="success" @click="toShowEditMerchant({})">新建</page-button>
            </template>
          </search-condition>
          <table-list>
            <el-table :data="merchantList" height="100%" border>
              <el-table-column align="center" label="序号" type="index" width="60px"/>
              <el-table-column align="center" label="商户名称" prop="merchantName" show-overflow-tooltip/>
              <el-table-column align="center" label="负责人" prop="legalPerson" show-overflow-tooltip/>
              <el-table-column align="center" label="有效期" show-overflow-tooltip>
                <template v-slot="{row}">
                  <date-range v-model:start-time="row.validStart" v-model:end-time="row.validEnd" :disabled="true"/>
                </template>
              </el-table-column>
              <el-table-column align="center" label="手机号" prop="merchantPhone" show-overflow-tooltip/>
              <el-table-column align="center" label="所属区域" prop="regionName" show-overflow-tooltip min-width="130px">
                <template v-slot="{row}">
                  <dictionary :dictionaries="regionCodeList" v-model="row.regionCode" :disabled="true"/>
                </template>
              </el-table-column>
              <el-table-column align="center" label="企业地址" prop="address" show-overflow-tooltip/>
              <el-table-column align="center" label="分类目录" min-width="150px" show-overflow-tooltip>
                <template v-slot="{row}">
                  <dictionary :dictionaries="treeList" v-model="row.category" :disabled="true"/>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" :align="'center'">
                <template v-slot="{row}">
                  <table-button @click="toShowEditMerchant(row)">编辑</table-button>
                  <table-button @click="$routerUtil.push(`/plat/merchant/merchant/detail?id=${row.id}`)">管理</table-button>
                  <table-button type="write" el-type="danger" message="是否确认删除数据" @click="toDeleteMerchant(row)">删除</table-button>
                </template>
              </el-table-column>
            </el-table>
          </table-list>
          <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize" :total-count="searchInfo.totalCount" @onLoad="queryList"/>
          <el-dialog v-model="showEditMerchant" width="600px" title="编辑商户信息">
            <el-form label-width="100px">
              <el-form-item label="商户名称" class="required">
                <el-input v-model="editMerchant.merchantName" placeholder="请输入商户名称"/>
              </el-form-item>
              <el-form-item label="负责人" class="required">
                <el-input v-model="editMerchant.legalPerson" placeholder="请输入商户法人"/>
              </el-form-item>
              <el-form-item label="手机号" class="required">
                <el-input v-model="editMerchant.merchantPhone" placeholder="请输入手机号"/>
              </el-form-item>
              <el-form-item label="区域编码" class="required">
                <dictionary v-model="editMerchant.regionCode" :dictionaries="regionCodeList" place-holder="请选择区域编码"
                            style="display: inline-block;width: 100%"/>
              </el-form-item>
              <el-form-item label="有效期">
                <date-range style="width: 100%" v-model:start-time="editMerchant.validStart" v-model:end-time="editMerchant.validEnd" :show-short-cut="false"/>
              </el-form-item>
              <el-form-item label="企业地址">
                <el-input v-model="editMerchant.address" placeholder="请输入企业地址"/>
              </el-form-item>
              <el-form-item label="营业执照">
                <el-input v-model="editMerchant.businessLicense" placeholder="请输入营业执照"/>
              </el-form-item>

              <el-form-item label="商户简介">
                <el-input v-model="editMerchant.merchantDesc" type="textarea" placeholder="商户简介 "/>
              </el-form-item>
              <el-form-item label="分类目录">
                <el-tree-select v-model="editMerchant.category" :data="treeList"  check-strictly :render-after-expand="false" placeholder="请选择分类目录" :props="{children: 'children', label: 'dictName'}" node-key="dictCode" default-expand-all/>
              </el-form-item>
            </el-form>
            <div style="text-align: right">
              <page-button @click="showEditMerchant = false">取消</page-button>
              <page-button type="write" el-type="primary" @click="toSaveMerchant" :loading="saveMerchantLoading">保存</page-button>
            </div>
          </el-dialog>
        </list-page>
      </template>
    </separate-page>
  </div>
</template>

<script>

import SearchCondition from "@/components/h5/layout/SearchCondition";
import FormPage from "@/components/h5/layout/FormPage";
import TableList from "@/components/h5/layout/TableList";
import ListPage from "@/components/h5/layout/ListPage";
import merchantApi from "@/api/saas-plat/merchant/merchantApi";
import DateRange from "@/components/h5/date/DateRange";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import SeparatePage from "@/components/h5/layout/SeparatePage.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import TreeDictionary from "@/components/h5/TreeDictionary.vue";
import commonApi from "@/api/commonApi";

export default {
  name: "",
  components: {
    TableButton,
    PageButton,
    SearchCondition, FormPage, TableList, ListPage, DateRange, SeparatePage, Dictionary, TreeDictionary
  },
  data() {
    return {
      searchInfo: {},
      merchantList: [],
      showEditMerchant: false,
      editMerchant: {
        merchantName: "",
        validStart: "",
        validEnd: "",
        merchantDesc: "",
        legalPerson: "",
        address: "",
        merchantPhone: "",
        businessLicense: "",
        regionCode: "",
      },
      treeList: [],
      // 正在保存商户信息
      saveMerchantLoading: false,
      // 区域编码
      regionCodeList: [],
    };
  },
  created() {
    // 获取区域编码
    commonApi.queryDictionaryTree("ADMINISTRATIVE").then(list => this.regionCodeList = list);
    // 商户分类
    // merchantApi.queryDictionaryTree("MERCHANT_CATEGORY").then(list => this.treeList = list);
  },
  methods: {
    queryList() {
      merchantApi.queryList(this.searchInfo).then(res => {
        this.merchantList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      })
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    toSaveMerchant() {
      let savedMerchant = {
        ...this.editMerchant
      }
      if (!savedMerchant.legalPerson) {
        this.$message.error("请输入商户法人");
        return;
      }
      if (!savedMerchant.merchantName) {
        this.$message.error("请输入商户名称");
        return;
      }
      if (!savedMerchant.merchantPhone) {
        this.$message.error("请输入联系电话");
        return;
      }
      this.saveMerchantLoading = true;

      merchantApi.saveMerchant(savedMerchant).then((data) => {
        this.queryList();
        this.showEditMerchant = false;
        this.saveMerchantLoading = false;
        // 只有新增时，跳转详情
        if (!savedMerchant.id) {
          this.$routerUtil.push(`/plat/merchant/merchant/detail?id=${data.id}`);
        }
      }).catch(() => this.saveMerchantLoading = false);
    },
    toShowEditMerchant(data) {
      this.editMerchant = {...data};
      this.showEditMerchant = true;
    },
    toDeleteMerchant(data) {
      merchantApi.deleteMerchant(data.id).then(() => {
        this.queryList();
      })
    },
    clickCategory(dict) {
      this.searchInfo.category = dict.dictCode;
      this.resetSearch();
    }
  }
};
</script>

<style lang="scss" scoped>

@import "@/assets/css/components/dialog";
@import "@/assets/css/components/form";
</style>