<template>
  <!-- 这是一个模板文件
       1、html部门可以作自定义修改
       2、所有需要的对象及字段信息都在dataInfo中，直接使用dataInfo即可
       -->
  <div class="bg-banner"  :style="{
    height: dataInfo.pictureInfo[0].height ? dataInfo.pictureInfo[0].height : 'auto',
    top: dataInfo.pictureInfo[0].position ? dataInfo.pictureInfo[0].top : '0',
    left: dataInfo.pictureInfo[0].position ? dataInfo.pictureInfo[0].left : '0',
    'border-radius': dataInfo.pictureInfo[0].radius,
  }">
    <div style="display: flex;justify-content: space-between;">
      <span>活动海报</span>
      <span>查看更多</span>
    </div>
    <div v-for="item in dataInfo.pictureInfo">
      <img :src="item.imageUrl" alt="" style="width: 100%; height: 150px;margin-top: 15px"/>
    </div>
  </div>
</template>

<script>

import {watch} from "vue";

export default {
  props: {
    // 修改信息
    configuration: {default: {}},
    // 索引位置，用来寻找匹配的配置信息作渲染遍历
    indexToFind: {default: null},
    // 初始化信息
    initData: {default: {}},
  },
  name: "TempEmpty",
  components: {
  },
  data() {
    return {
      dataInfo: {},
    };
  },
  created() {
    // 监听这个配置信息
    watch([() => this.configuration], () => {
      this.init();
    },{ deep: true })
    // 刷新重载、只在页面初始化时执行一次
    if (this.initData !== null) {
      this.dataInfo = this.initData;
    }
  },
  methods: {
    // 初始化
    init() {
      if (this.indexToFind !== this.configuration.index && !this.dataInfo) {
        return;
      }
      if (this.indexToFind === this.configuration.index) {
        this.dataInfo = this.configuration;
      }
      console.log(this.dataInfo);
    },
  }
};
</script>

<style lang="scss" scoped>
.bg-banner {
  position: relative;
  overflow: hidden;
  margin: 14px;
  padding: 10px;
  //background-color: #fffefe;
  //box-shadow: 0 0 2px 2px #d0cdcd;
}

</style>