<template>
  <!-- 在el-button的type基础上增加了两个类型，write和delete类型，write为写入权限，delete为删除权限，此类型可以关联到系统读写权限 -->
    <span class="page-button" :id="buttonId">
      <el-button id="myBtn" @click.stop="clickEvent" :loading="loading" :class="`pb-${type}`" :disabled="disabled || accessDisabled" :type="elType" :size="size" :icon="icon"><slot /></el-button>
    </span>
</template>

<script>
import {ElMessageBox} from 'element-plus';
import util from "@/util/util";

export default({
  props: {
    loading: {default: false},
    disabled: {default: false},
    // 可用参数 查询      新建      删除      修改       审批       反审批      导入   导出   批量删除         过账        批量过账         打印
    // 1      "read"， "write", "delete", "update", "check", "checkBack", "im", "ex", "batchDelete", "account", "batchAccount", "print"
    type: {
      type: String,
      default: "read",
      validator: function (value) {
        let passCheck = false;
        let arr = ["read", "write", "delete", "update", "check", "checkBack", "im", "ex", "batchDelete", "account", "batchAccount", "print"];
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] === value) {
            passCheck = true;
            break;
          }
        }
        if (!passCheck) {
          console.error(`type参数错误,${value}不是被允许的值`)
        }
        return passCheck;
      },
    },
    elType: {default: ""},
    size: {default: ""},
    // 信息数据，用于操作前的提醒, click是确认操作，cancel事件是取消操作
    message: {default: ""},
    icon: {default: ""},
  },
  name: "PageButton",
  components: {
  },
  watch: {
    disabled: {
      handler(val) {
        this.addButton();
      }
    }
  },
  data() {
    return {
      // 权限禁用
      accessDisabled: false,
      accessModules: [],
      buttonId: "",
    };
  },
  created() {
    this.addButton();
    // 校验权限
    util.getUserInfo().then(user => {
      let access = user.accessModules;
      // 获取path
      let path = this.$routerUtil.path;
      for (let i = 0; i < access.length; i++) {
        if (access[i].url.indexOf(path) > -1) {
          if (this.type === "write") {
            // 写入按钮，如果没有权限，则禁用按钮
            let ch = access[i].accessModel.charAt(1);
            if (ch === "0") {
              // 没有写入权限,禁用
              this.accessDisabled = true;
            }
          } else if (this.type === "delete") {
            // 删除按钮
            let ch = access[i].accessModel.charAt(2);
            if (ch === "0") {
              // 没有写入权限,禁用
              this.accessDisabled = true;
            }
          }
          break;
        }
      }
    })
  },
  methods: {
    // 重新传递数据
    addButton(){
      this.buttonId = "p_" + Math.floor(Math.random() * 1000) + "_" + Math.floor(Math.random() * 100);
      if (this.$parent.addButton) {
        this.$parent.addButton({
          id: this.buttonId,
          type: this.type,
          elType: this.elType,
          icon: this.icon,
          disabled: this.disabled,
          name: this.$slots.default()[0].children
        });
      }
    },
    clickEvent() {
      // 禁用状态
      if (this.disabled || this.accessDisabled) {
        return false;
      }
      // 什么也不做
      if (this.message) {
        ElMessageBox.confirm(this.message, "提示", {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$emit("click");
        }).catch(() => {
          this.$emit("cancel")
        })
      } else {
        this.$emit("click");
      }
    },
  }
});
</script>

<style lang="scss" scoped>
.page-button{
  margin-right: 10px;
  vertical-align: top;
  display: inline-block;
}
.page-button:last-child{
  margin-right: 0;
}
:deep(.el-button){
  padding: 6px 10px;
  font-size: 12px;
}
//:deep(.el-button--write) {
//
//}
//:deep(.el-button--write.el-button:hover){
//  color: #FFF;
//  background: #95d475;
//}

</style>