import axios from "../../../util/axios";

export default {
    // 列表查询
    queryList: (data) => axios.get({url: `/order/stock/supplier/queryList`, data: data}),

    // 保存
    saveSubmit: (data) => axios.post({url: `/order/stock/supplier/saveSubmit`, data: data}),
    
    // 删除
    deleteById: (id) => axios.del({url: `/order/stock/supplier/deleteById`, data: {id}}),
}