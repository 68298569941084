import axios from "../../../util/axios";

export default {
    /** 查询字典分类 */
    queryDictionaryTypes: () => axios.get({url: "/basedata/system/dictionary/queryDictionaryTypes"}),
    /** 查询列表，用于列表类 */
    queryList: (data) => axios.get({url: "/basedata/system/dictionary/queryList", data: data}),
    /** 查询树结构，用于树结构 */
    queryListAsTree: (type, merchantId) => axios.get({url: "/basedata/system/dictionary/queryTree", data: {type, merchantId}}),
    /** 保存字典 */
    save: (data) => axios.post({url: "/basedata/system/dictionary/save", data: data}),
    /** 保存字典,树形字典 */
    saveTreeNode: (data) => axios.post({url: "/basedata/system/dictionary/saveTreeNode", data: data}),
    /** 删除数据 */
    deleteById: (id) => axios.del({url: "/basedata/system/dictionary/delete", data: {id}})
}