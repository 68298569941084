<template>
  <!-- -->
  <div class="view1">
    <div class="left">
      <div class="top border">
        <div style="margin-top: -10px">
          <span class="title">快捷操作</span>
        </div>
        <div class="short-sty">
          <div class="container">
            <div class="layout">
              <div v-for="(item,index) in shortcutList" :key="index.id" class="short-info"
                   @click="$routerUtil.push(item.routerPush)">
                <div>
                  <el-image style="width: 50px;border-radius: 7px" :src="item.url" fit="fill"/>
                </div>
                <div class="text">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <div class="border" style="height: 100%">
          <div class="content-info">
            <div>
              <span class="title">销售数据</span>
              <date-range style="margin-left: 10px" v-model:start-time="orderCountInfo.startTime" v-model:end-time="orderCountInfo.endTime" @change="queryOrderCount"/>
            </div>
            <div>
              <el-select v-model="orderCountInfo.stockId" placeholder="全部仓库" clearable @change="queryOrderCount">
                <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
              </el-select>
            </div>
          </div>
          <div class="order-count-div">
            <div v-for="(o, i) in orderColumns" :key="i" @click="$routerUtil.push(`/plat/order`)">
              <div class="blank"></div>
              <div class="order-info">
                <div class="name">{{o.label}}</div>
                <div>
                  <money :value="orderStatisticInfo[o.column]" v-if="o.type === 'money'"/>
                  <span v-else>{{orderStatisticInfo[o.column] || 0}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom border">
        <div class="content-info">
          <div>
            <span class="title">库存预警</span>
            <span class="span-btn on" @click="resetWarningSearch('')">查看全部</span>
          </div>
          <div>
            <el-select v-model="searchInfo.stockId" placeholder="全部仓库" clearable @change="resetWarningSearch">
              <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
            </el-select>
          </div>
        </div>
        <div style="height: 528px;box-sizing: border-box;" id="stockWarning">
          <el-table :data="dataList" height="100%" :align="'center'" border>
            <el-table-column :align="'center'" label="序号" width="60px" type="index"/>
            <el-table-column :align="'center'" label="仓库" prop="stockName" show-overflow-tooltip/>
            <el-table-column :align="'center'" label="商品名称" prop="skuName" show-overflow-tooltip/>
            <el-table-column :align="'center'" label="库存量" prop="skuQty" show-overflow-tooltip/>
            <el-table-column :align="'center'" label="下限预警值" prop="warnMin" show-overflow-tooltip/>
            <el-table-column :align="'center'" label="上限预警值" prop="warnMax" show-overflow-tooltip/>
          </el-table>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="top border">
        <div style="margin-top: -10px">
          <span class="title">用户信息</span>
        </div>
        <div class="user-info">
          <div class="name">登录用户</div>
          <div>{{userInfo.userName}}</div>
        </div>
        <div class="user-info">
          <div class="name">所属部门</div>
          <div>{{userInfo.mainDepartName}}</div>
        </div>
        <div class="user-info">
          <div class="name">所属机构</div>
          <div>{{userInfo.merchantName}}</div>
        </div>
      </div>
      <div class="bottom">
        <div class="border" style="height: 100%">
          <div  style="margin-top: -10px">
            <span class="title">更新记录</span>
          </div>
          <div style="padding: 0 10px">
            <!-- 最多只能显示5条 -->
            <div class="document-list">
              <a target="_blank" v-for="(r, i) in updateRecordList" :key="i" :href="r.docUrl">{{r.versionName}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import commonApi from "@/api/commonApi";
import DateRange from "@/components/h5/date/DateRange.vue";
import Money from "@/components/h5/Money.vue";
import util from "@/util/util";
export default {
  name: "HomeView",
  components: { DateRange, Money},
  data() {
    return {
      stockList: [],
      dataList: [],
      searchInfo: {
        stockId: "",
        stockName: "全部",
        pageNumber: 1,
        pageSize: 10,
      },
      shortcutList: [
        {
          id: 1,
          name: '销售订单',
          url: require(`../../../assets/image/h5/home/收银流水.png`),
          routerPush: `/alpine/shop/saleOder`,
        },
        {
          id: 3,
          name: '客户档案',
          url: require(`../../../assets/image/h5/home/功能图标/客户档案.png`),
          routerPush: `/alpine/customer/member`,
        },
        {
          id: 5,
          name: '商品档案',
          url: require(`../../../assets/image/h5/home/功能图标/商品档案.png`),
          routerPush: `/product/list`,
        },
        {
          id: 6,
          name: '库存查询',
          url: require(`../../../assets/image/h5/home/功能图标/库存查询.png`),
          routerPush: `/stock/product`,
        },
      ],
      stockWarningHeight: "200px",
      // 销售数据统计的参数
      orderCountInfo: {},
      orderStatisticInfo: {},
      orderCountType: "today",
      orderColumns: [{label: "订单单数", column: "orderCount"},{label: "订单金额", column: "orderAmount", type: "money"},{label: "退单单数", column: "refundCount"},{label: "退单金额", column: "refundAmount", type: "money"},],
      userInfo: {},
      updateRecordList: [],
    };
  },
  created() {
    // 查询版本信息
    commonApi.queryVersionInfo().then(list => {
      let arr = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].type === "1") {
          arr.push(list[i]);
        }
      }
      this.updateRecordList = arr;
    })
    commonApi.queryAllStock().then(list =>  this.stockList = list)
    this.queryWarnList();
    // 销售订单统计时间
    this.orderCountInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.orderCountInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    this.queryOrderCount();
    // 获取登录用户信息
    util.getUserInfo().then(res => {
      this.userInfo = res;
    });
  },
  mounted() {
    this.stockWarningHeight = document.querySelector("#stockWarning").clientHeight + 'px';
  },
  methods: {
    resetWarningSearch(val) {
      this.searchInfo.stockId = val;
      this.queryWarnList();
    },
    queryWarnList() {
      commonApi.queryWarningList(this.searchInfo).then(res => {
        this.dataList = res.records;
      })
    },
    queryOrderCount() {
      commonApi.querySaleStatisticInfo(this.orderCountInfo).then(data => this.orderStatisticInfo = data || {});
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/table";
.view1 {
  background: none;
  border: none;
  height: calc(100vh - 100px);
  overflow: scroll;
  position: relative;
  .left {
    width: calc(100% - 305px);
    box-sizing: border-box;
    padding-right: 10px;
    height: 100%;

    .top {
      height: 200px;
      box-sizing: border-box;
      .short-sty {
        height: calc(100% - 60px);
        padding: 0 60px;
        overflow: hidden;
        position: relative;

        .container {
          position: relative;
          overflow: hidden;
          height: 100%;
          overflow-x: auto;

          .layout {
            white-space: nowrap;
            position: absolute;
            height: 0px;
            top: 50%;
            width: 100%;

            .short-info {
              //display: flex;
              //flex-direction: column;
              //align-items: center;
              //justify-content: center;
              margin-right: 50px;
              margin-top: -38px;
              text-align: center;
              display: inline-block;

              > image {
                width: 50px;
                height: 50px;

              }

              > .text {
                font-size: 12px;
                padding-top: 5px;
              }
            }

            .short-info:last-child {
              margin-right: 0;
            }
          }
        }

        //display: flex;
        //flex-direction: row;
        //padding: 25.5px 0 0 60px;
        //align-items: center;


      }


    }

    .center {
      height: 260px;
      box-sizing: border-box;
      padding: 10px 0;
    }

    .bottom {
    }
  }

  .right {
    width: 300px;
    height: 100%;
    position: fixed;
    right: 22px;
    top: 94px;
    .top {
      height: 230px;
    }
    .bottom {
      height: 230px;
      box-sizing: border-box;
      padding-top: 10px;
    }
  }

  .border {
    border: 1px solid #dcdfe6;
    box-shadow: 0 2px 4px 0 #f1f1f1, 0 0 6px 0 #f1f1f1;
    background: #FFF;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 60px;
  }
}

.el-dropdown, .el-dropdown * {
  outline: none;
}

.el-tooltip__trigger {
  margin-top: 24px;
  color: blue;
  font-size: 11px !important;
  text-decoration: underline;

  .el-icon {
    font-size: 11px !important;
    color: blue;
  }
}
.content-info{
  display: flex;
  justify-content: space-between;
  line-height: 40px;
  margin-bottom: 10px;
  .title{
    line-height: 40px;
  }
  .span-btn{
    margin-left: 10px;
    font-size: 12px;
    color: #999;
    user-select: none;
    cursor: pointer;
  }
  .span-btn.on{
    color: #409EFF;
  }
  :deep(.el-select) {
    width: 240px;
  }
}
.order-count-div{
  display: flex;
  justify-content: space-between;
  height: calc(100% - 50px);
  padding: 20px 60px;
  box-sizing: border-box;
  >div{
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #dddfe7;
    text-align: center;
    padding: 0 20px;
    width: 24%;
    border-radius: 8px;
    background: #f6f7fa;
    box-shadow: 1px 1px 3px #dddfe7;
    .blank{
      height: calc(50% - 50px);
    }
    .order-info{
      height: 100px;
      .name{
        height: 50px;
      }
    }
  }
}
.user-info{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dddfe7;
  font-size: 14px;
  margin: 10px 10px;
  line-height: 30px;
  .name{
    color: #999;
  }
}
.document-list{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 40px;
  border-bottom: 1px solid #dddfe7;
  height: 36px;
  a{
    color: #409eff;
    font-size: 14px;
    text-decoration: auto;
  }
}

@media (max-height: 1200px) {

}
@media (max-height: 800px) {
  .content-info {
    :deep(.el-select) {
      width: 200px;
    }
    .date-range{
      width: 450px;
    }
  }
  .order-count-div>div{
    .blank{
      height: 20px;
    }
    .order-info{
      height: auto;
      .name{
        height: auto;
      }
    }
  }
  .user-info{
  }
  .view1 .right{
    .top{
    }
    .bottom{
    }
  }
}
</style>
