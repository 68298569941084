import axios from "../../../util/axios";

export default {
    // 带分页查询
    queryList: (data) => axios.get({url: "/order/house/doc/queryList", data: data}),

    // 不带分页查询
    queryAllList: (data) => axios.get({url: "/order/house/doc/queryAllList", data: data}),

    // 保存
    save: (data) => axios.post({url: "/order/house/doc/save", data: data}),

    // 删除
    deleteById: (id) => axios.del({url: "/order/house/doc/deleteById", data: {id}}),

    // 更改客房状态
    updateStatus: (docId, roomStatus) => axios.get({url: "/order/house/doc/updateStatus", data: {docId, roomStatus}}),

    // 根据订单id查询出房间的类型
    findByOrderId: (orderId) => axios.get({url: "/order/house/doc/findByOrderId", data: {orderId}})
}