<template>
  <!-- -->
  <div class="defaultViewStyle">
    <el-tabs v-model="activeName">
      <el-tab-pane name="store" title="storeRebate" label="门店返佣" lazy>
        <store-rebate />
      </el-tab-pane>
      <el-tab-pane name="rebate" title="rebateSet" label="返佣配置" lazy>
        <rebate-set />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import RebateSet from "@/views/alpine/rebate/RebateSet.vue";
import StoreRebate from "@/views/alpine/rebate/StoreRebate.vue";

export default {
  name: "",
  components: {
    RebateSet,
    StoreRebate,
  },
  data() {
    return {
      activeName: "store"
    };
  },
  created() {
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>

</style>