<template>
  <!-- -->
  <div class="defaultViewStyle">
    <div class="service-list" :style="{columnCount: columnCount}">
      <div v-for="(item, idx) in serviceList" :key="idx" class="service-container">
        <div class="service-item">
          <div class="title">
            <div>
              <el-icon style="font-size: 20px;vertical-align: top;"><component :is="item.serviceIcon"/></el-icon>
              <span>{{item.serviceName}}</span>
            </div>
            <div>
              <span class="grant-type">{{item.autoGrantSwitch? '自动授权':'手动授权'}}</span><el-switch v-model="item.autoGrantSwitch"/>
              <el-icon style="vertical-align: middle;margin-left: 10px;color: #409eff;cursor: pointer;" @click="toSetting(item)"><el-icon-setting/></el-icon>
            </div>
          </div>
          <div class="desc"> {{item.serviceDesc}}</div>
        </div>
      </div>
    </div>
    <el-drawer :title="currentService.serviceName" v-model="showEdit" direction="rtl" size="30%" :close-on-click-modal="false">
      <el-tree :data="webGrantModule" :props="{children: 'children', label: 'moduleName'}" @node-click="clickModule" node-key="id" default-expand-all :expand-on-click-node="false" style="height: 100%;overflow: auto">
        <template #default="{node, data}">
          <span :class="currentService.id === data.id? 'tree-on': ''"></span>
          <el-tooltip :content="data.moduleDesc" placement="bottom" effect="light">
            <span>{{data.moduleName}}</span>
          </el-tooltip>
          <el-icon v-if="data.selected" style="color: #1BAB3D"><el-icon-check/></el-icon>
        </template>
      </el-tree>
    </el-drawer>
  </div>
</template>

<script>
import systemServiceApi from "@/api/main/sys/systemServiceApi";
export default {
  name: "",
  components: {
  },
  data() {
    return {
      serviceList: [],
      currentService: {
        serviceName: "",
      },
      activeName: 1,
      // 各个端口的功能
      webModules: [],
      appModules: [],
      // 各个商品的授权信息
      webGrantModule: [],
      appGrantModule: [],
      columnCount: 3,
      showEdit: false,
    };
  },
  created() {
    let width = document.querySelector('.main-tabs >.el-tabs--top .el-tabs__content').clientWidth;
    this.columnCount = Math.floor(width / 340);
    // 查询全部的服务
    systemServiceApi.queryAllService().then(list => {
      for (let i = 0; i < list.length; i++) {
        list[i].autoGrantSwitch = list[i].autoGrant === 1;
      }
      this.serviceList = list;
    });
    // 查询各个端口的全部功能
    systemServiceApi.queryAllModules(1).then(list => this.webModules = list);
  },
  methods: {
    toSetting(data) {
      this.showEdit = true;
      this.currentService = {...data};
      this.changeTab();
    },
    changeTab() {
      // 查询功能授权
      systemServiceApi.queryServiceGranted(this.currentService.id).then(list => {
        this.webGrantModule = this.moduleGranted(this.webModules, list);
      });
    },
    // 点击某个功能
    clickModule(data) {
      // 已有授权，回收
      if (data.selected) {
        systemServiceApi.deleteModuleService(data.id, this.currentService.id).then(() => {
          // 刷新数据
          this.changeTab();
        });
      } else {
        // 没有授权，添加
        systemServiceApi.saveModuleService({moduleId: data.id, serviceId: this.currentService.id}).then(() => {
          // 刷新数据
          this.changeTab();
        })
      }
    },
    // 功能是否被授权
    moduleGranted(modules, list) {
      let arr = [];
      for (let i = 0; i < modules.length; i++) {
        let children = [];
        if (modules[i].children && modules[i].children.length > 0) {
          children = this.moduleGranted(modules[i].children, list);
        }
        // 是否有授权
        let m = {
          ...modules[i],
          children: children,
          selected: false,
        }
        for (let j = 0; j < list.length; j++) {
          if (list[j].moduleId === modules[i].id) {
            m.selected = true;
            break;
          }
        }
        arr.push(m);
      }
      return arr;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/drawer";
.service-list{
  column-count: 4;
  column-gap: 20px;
  .service-container{
    display: inline-block;
    width: 340px;
    vertical-align: top;
    box-sizing: border-box;
    margin: 10px 0;
    .service-item{
      border: 1px solid #e4e7ed;
      height: 150px;
      box-sizing: border-box;
      border-radius: 6px;
      .title{
        padding: 10px;
        display: flex;
        justify-content: space-between;
        .grant-type{
          font-size: 12px;
        }
      }
      .desc{
        border-top: 1px solid #e4e7ed;
        padding: 10px;
        color: #999;
        font-size: 12px;
      }
    }
  }

}


</style>