<template>
  <!-- 店铺信息组件 -->
  <div class="bg-banner" :style="{'border-radius': dataInfo.pictureInfo[0].radius,'margin-top': dataInfo.pictureInfo[0].marginTop,}">
    <div class="store-list">
      <div v-for="(item, index) in dataInfo.pictureInfo" :key="index" class="store">
        <img :src="item.imageUrl" style="width: 50px;height: 50px;border-radius: 50% 50%;" alt="">
        <text>{{ item.text }}</text>
      </div>
    </div>
  </div>
</template>

<script>

import {watch} from "vue";

export default {
  props: {
    // 修改信息
    configuration: {default: {}},
    // 索引位置，用来寻找匹配的配置信息作渲染遍历
    indexToFind: {default: null},
    // 初始化信息
    initData: {default: {}},
  },
  name: "CategoryList",
  components: {
  },
  data() {
    return {
      dataInfo: {},
    };
  },
  created() {
    // 监听这个配置信息
    watch([() => this.configuration], () => {
      this.init();
    },{ deep: true })
    // 刷新重载、只在页面初始化时执行一次
    if (this.initData !== null) {
      this.dataInfo = this.initData;
    }
  },
  methods: {
    // 初始化
    init() {
      if (this.indexToFind !== this.configuration.index && !this.dataInfo) {
        return;
      }
      if (this.indexToFind === this.configuration.index) {
        this.dataInfo = this.configuration;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.bg-banner {
  position: relative;
  overflow: hidden;
  margin: 14px;
  padding: 10px;
  background-color: #fffefe;
  box-shadow: 0 0 2px 2px #d0cdcd;
}

:deep(.el-carousel__indicator--horizontal){
  padding: 2px !important;
}
:deep(.el-carousel__button){
  width: 5px;
}
.store-list{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .store{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    font-size: 14px;
    img{
      margin: 0 auto;
    }
  }
}
</style>