import axios from "../../../util/axios";

export default {
    // 查询列表
    queryList: (data)=> axios.get({url: `/order/supplyChain/order/queryList`, data: data}),
    // 保存草稿
    saveDraft: (data)=> axios.post({url: `/order/supplyChain/order/saveDraft`, data: data}),
    // 保存并提交
    saveSubmit: (data)=> axios.post({url: `/order/supplyChain/order/saveSubmit`, data: data}),
    // 删除
    delete: (id)=> axios.del({url: `/order/supplyChain/order/delete`, data: {id}}),
    // 查询明细
    findById: (id)=> axios.get({url: `/order/supplyChain/order/findById`, data: {id}}),
    // 查询状态
    getAllStatus: ()=> axios.get({url: `/order/supplyChain/order/getAllStatus`}),
    // 查询商品
    queryProductAuthorizeList: (data)=> axios.get({url: `/order/supplyChain/order/queryProductAuthorizeList`, data: data}),
    // 查询商品
    checkoutSkuOriginalCode: (list, stockId, skuId)=> axios.post({url: `/order/supplyChain/order/checkoutSkuOriginalCode?stockId=${stockId}&skuId=${skuId}`, data: list}),
    // 二次出库创建一笔调拨单
    createRestock: (orderId) => axios.get({url: `/order/supplyChain/order/createRestock`, data: {orderId}}),
    // 查询小小程序下单时客户填写的信息
    findMiniDetail: (orderId) => axios.get({url: `/order/supplyChain/order/findMiniDetail`, data: {orderId}}),
}