<template>
  <!-- 通用模板，列表数据 -->
  <div class="defaultViewStyle">
    <page-header/>
    <list-page>
      <search-condition @search="resetSearch">
        <el-input v-model="searchInfo.payName" placeholder="请输入支付类型名称"/>
        <el-select v-model="searchInfo.payType" placeholder="请选择支付类型">
          <el-option label="线下管理" value="0"/>
          <el-option label="线上对接" value="1"/>
        </el-select>
        <el-select v-model="searchInfo.status" placeholder="请选择状态">
          <el-option label="禁用" value="0"/>
          <el-option label="启用" value="1"/>
        </el-select>
        <template #option>
          <page-button type="write" el-type="success" @click="showPayType = true">添加</page-button>
          <page-button el-type="primary" @click="resetSearch">查询</page-button>
        </template>
      </search-condition>
      <table-list>
        <el-table :data="dataList" height="100%" border>
          <el-table-column label="序号" min-width="60px" show-overflow-tooltip type="index" :align="'center'"/>
          <el-table-column label="支付名称" min-width="60px" prop="payName" show-overflow-tooltip
                           :align="'center'"/>
          <el-table-column label="支付类型" min-width="60px" show-overflow-tooltip type="index" :align="'center'">
            <template v-slot="{row}">
              {{ row.payType === 0 ? '线下管理' : '线上对接' }}
            </template>
          </el-table-column>
          <el-table-column label="状态" min-width="60px" show-overflow-tooltip type="index" :align="'center'">
            <template v-slot="{row}">
              {{ row.status === 0 ? '禁用' : '启用' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'">
            <template v-slot="{row}">
<!--              <table-button @click="manageInterface(row)" :disabled="row.payCode === 1">管理接口</table-button>-->
              <table-button  @click="editInfo(row)">编辑</table-button>
              <table-button :disabled="defaultPay.includes(row.id)" :message="row.payType !== 1 ? '确认删除此数据？' : '确认删除此数据及其配置信息？'" type="delete" el-type="danger" @click="deleteRow(row)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>
  </div>

  <el-dialog title="保存支付方式" width="40%" v-model="showPayType" @close="closeBefore">
    <el-form label-width="140px">
      <el-form-item label="支付名称">
        <el-input title="请填写支付名称" v-model="payData.payName"/>
      </el-form-item>
      <el-form-item label="支付类型">
        <el-select @change="changeType" v-model="payData.payType" placeholder="请选择支付类型">
          <el-option label="线下管理" :value="0"/>
          <el-option label="线上对接" :value="1"/>
        </el-select>
      </el-form-item>
      <el-form-item v-if="payData.payType === 1" label="对接接口">
        <el-select @change="changePay" v-model="payData.payCode" placeholder="请选择支付类型">
          <el-option v-for="item in interfaceList" :key="item.dictCode" :label="item.dictName" :value="item.dictCode"/>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="payData.status" placeholder="请选择状态">
          <el-option label="禁用" :value="0"/>
          <el-option label="启用" :value="1"/>
        </el-select>
      </el-form-item>
      <div v-if="payData.payCode !== 1&& payTemplate.length > 0">
        <el-form-item v-for="item in payTemplate" :key="item.id+new Date()" :label="item.dictName">
          <el-input v-model="item.paramValue" type="textarea" :rows="1" clearable/>
        </el-form-item>
      </div>
      <div v-if="payData.payCode !== 1 && payTemplate.length === 0">
        <el-form-item v-for="item in interfaceInfo" :key="item.id+new Date()" :label="item.paramName">
          <el-input v-model="item.paramValue" type="textarea" :rows="1" clearable/>
        </el-form-item>
      </div>
    </el-form>
    <div style="display: flex;justify-content: flex-end">
      <div>
        <page-button el-type="info" @click="showPayType = false">取消</page-button>
        <page-button el-type="primary" type="write" @click="saveSubmit">确定</page-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import systemPayApi from "@/api/main/sys/systemPayApi";
import config from "@/config/config";

export default {
  name: "SystemPay",
  components: {
    PageHeader,
    TableButton,
    PageButton,
    ListPage, TableList, FormPage, SearchCondition
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      showPayType: false,
      showInterfaceInfo: false,
      payData: {},
      interfaceList: [],
      payTemplate: [],
      interfaceInfo: [],
      currentRow: {}, // 编辑支付信息时使用
      defaultPay: [], // 默认使用的支付方式，无法删除
    };
  },
  created() {
    // 已经实现的对接支付
    systemPayApi.queryInterfaceList().then(res => {
      res.forEach(item => item.dictCode = Number(item.dictCode));
      this.interfaceList = res;
    });
    this.defaultPay = config.defaultPay;
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      systemPayApi.queryPayList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      })
    },
    // 展开支付模板
    changePay() {
      systemPayApi.findPayTemplate(this.payData.payCode).then(res => this.payTemplate = res)
    },
    // 保存支付接口参数
    saveSubmit() {
      if(this.payTemplate.length > 0){
        this.payData.detailList = this.payTemplate;
      }else{
        this.interfaceInfo.forEach(item=>{
          item.dictName = item.paramName;
          item.dictCode = item.paramColumn;
        })
        this.payData.detailList = this.interfaceInfo;
      }
      systemPayApi.saveSubmit(this.payData).then(() => {
        this.showPayType = false;
        this.queryList();
      })
    },
    // 管理接口
    // manageInterface(row) {
    //   this.showInterfaceInfo = true;
    //   systemPayApi.findInterfaceById(row.id).then(res => {
    //     this.interfaceInfo = res;
    //   });
    // },
    // 保存接口信息
    // saveInterfaceInfo() {
    //   systemPayApi.saveInterfaceInfo(this.interfaceInfo).then(()=>{
    //     this.showInterfaceInfo = false;
    //   });
    // },
    // 删除
    deleteRow(row){
      systemPayApi.deletePayType(row.id).then(()=>{
        this.queryList();
      });
    },
    // 编辑支付方式
    editInfo(row){
      systemPayApi.findById(row.id).then(res=>{
        this.payData = res;
        this.interfaceInfo = res.detailList;
        this.showPayType = true;
      })
    },
    closeBefore(){
      this.payData = {};
      this.payTemplate = [];
      this.interfaceInfo = [];
    },
    changeType(){
      if(this.payData.payType === 0){
        this.payData.payCode = 1;
      }else{
        this.payData.payCode = null;
      }
    }
  },

};
</script>

<style lang="scss" scoped>

</style>