<template>
  <!-- -->
  <div class="defaultViewStyle">
    <el-tabs v-model="activeName">
      <el-tab-pane name="test1" label="入库类型" lazy>
        <inout-by-tab :type="'inStock'" />
      </el-tab-pane>
      <el-tab-pane name="test2" label="出库类型" lazy>
        <inout-by-tab :type="'outStock'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import InoutByTab from "@/views/main/stock/inoutType/InoutByTab.vue";

export default {
  name: "InoutTab",
  components: {
    InoutByTab,
  },
  data() {
    return {
      activeName: "test1",
    };
  },
  created() {
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
//@import "@/assets/css/components/tabsPane";

</style>