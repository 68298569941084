<template>
  <!-- -->
  <div class="defaultViewStyle">
    <page-header>
      <page-button el-type="primary" @click="resetSearch">查询</page-button>
      <page-button el-type="success" @click="editData">新建</page-button>
    </page-header>
    <list-page>
      <search-condition>
        <el-input v-model="searchInfo.searchKey" placeholder="请输入单位名称" clearable />
      </search-condition>
      <table-list>
        <el-table :data="dataList" height="100%" border>
          <el-table-column align="center" type="index" min-width="60px" label="序号" fixed />
          <el-table-column align="center" prop="name" min-width="120px" label="单位名称" />
          <el-table-column align="center" width="150px" label="操作" fixed="right">
            <template v-slot="{row}">
              <table-button el-type="danger" type="delete" message="确定删除吗？" @click="deleteData(row)">删除</table-button>
              <table-button el-type="primary" type="write" @click="editData(row)">编辑</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>

    <el-dialog @close="unitData = {}" v-model="showUnit" width="30%" :title="unitData === {} ? '编辑单位' : '添加单位'">
      <el-form label-width="70px">
        <el-form-item label="单位名称">
          <el-input v-model="unitData.name" placeholder="请输入单位名称" />
        </el-form-item>
      </el-form>
      <div style="display: flex;justify-content: flex-end">
        <el-button @click="showUnit = false">取消</el-button>
        <el-button type="primary" @click="save">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import ListPage from "@/components/h5/layout/ListPage.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import pageHeader from "@/components/h5/layout/PageHeader.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import mtUnitApi from "@/api/main/material/mtUnitApi";
import FormPage from "@/components/h5/layout/FormPage.vue";

export default {
  name: "UnitByTab",
  components: {
    FormPage,
    TableButton,
    ListPage, SearchCondition, PageButton, TableList, pageHeader
  },
  data() {
    return {
      // 基础单位
      searchInfo: {},
      // 数据集合
      dataList: [],
      // 单位数据
      unitData: {},
      // 展示单位
      showUnit: false,
    };
  },
  created() {
  },
  methods: {
    // 重置查询条件
    resetSearch(){
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    // 查询
    queryList(){
      mtUnitApi.queryList(this.searchInfo).then(res=>{
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    // 删除
    deleteData(row){
      mtUnitApi.deleteRow(row.id).then(()=>{
        this.queryList();
      })
    },
    // 编辑、新建
    editData(row){
      if(row){
        this.unitData = row;
      }
      this.showUnit = true;
    },
    // 保存提交
    save(){
      mtUnitApi.saveSubmit(this.unitData).then(()=>{
        this.showUnit = false;
        this.queryList();
      })
    },
  }
};
</script>

<style lang="scss" scoped>

</style>