import axios from "../../../util/axios";

export default {
    // 商户列表
    queryList: (data) => axios.get({url: "/basedata/plat/merchant/queryList", data: data}),
    // 保存商户
    saveMerchant: (data) => axios.post({url: `/basedata/plat/merchant/save`, data: data}),
    // 删除商户
    deleteMerchant: (id) => axios.del({url: `/basedata/plat/merchant/delete`, data: {id}}),
    // 查询商户
    findById: (id) => axios.get({url: `/basedata/plat/merchant/findById`, data: {id}}),
    // 查询职员列表
    queryEmployeeList: (data) => axios.get({url: "/basedata/plat/merchant/queryEmployeeList", data: data}),
    // 保存职员信息
    saveEmployee: (data) => axios.post({url: "/basedata/plat/merchant/saveEmployee", data: data}),
    // 查询职员
    findEmployeeById: (id, merchantId) => axios.get({url: "/basedata/plat/merchant/findEmployeeById", data: {id, merchantId}}),
    // 查询职员
    deleteEmployee: (id, merchantId) => axios.del({url: "/basedata/plat/merchant/deleteEmployee", data: {id, merchantId}}),
    // 查询部门
    queryDepartList: (merchantId) => axios.get({url: "/basedata/plat/merchant/queryDepartList", data: {merchantId}}),
    // 查询角色
    queryAllRoles: (merchantId) => axios.get({url: "/basedata/plat/merchant/queryAllRoles", data: {merchantId}}),
    // 保存部门
    saveDepart: (data) => axios.post({url: "/basedata/plat/merchant/saveDepart", data: data}),
    // 查询全部的功能菜单
    queryAllModules: (merchantId) => axios.get({url: "/basedata/plat/merchant/queryAllModules", data: {merchantId}}),
    // 删除角色
    deleteRole: (id) => axios.del({url: "/basedata/plat/merchant/deleteRole", data: {id}}),
    // 保存角色信息
    saveRole: (data) => axios.post({url: "/basedata/plat/merchant/saveRole", data: data}),
    // 对角色进行授权
    grantModules: (roleId, merchantId, data) => axios.post({url: `/basedata/plat/merchant/grantModules?roleId=${roleId}&merchantId=${merchantId}`, data: data}),
    // 对角色进行授权
    queryGrantModules: (roleId) => axios.get({url: `/basedata/plat/merchant/queryGrantModules`, data: {roleId}}),
    // 查询全部服务
    queryAllService: () => axios.get({url: `/basedata/plat/merchant/queryAllService`}),
    // 查询全部服务
    queryMerchantService: (merchantId) => axios.get({url: `/basedata/plat/merchant/queryMerchantService`, data: {merchantId}}),
    // 查询全部服务
    saveMerchantService: (merchantId, list) => axios.post({url: `/basedata/plat/merchant/saveMerchantService?merchantId=${merchantId}`, data: list}),
    // 超级管理员登录账号
    loginEmployeeByAdmin: (id) => axios.get({url: `/basedata/plat/merchant/loginEmployeeByAdmin`, data: {id}}),
    // 超级管理员登录账号
    deleteDepart: (id) => axios.del({url: `/basedata/plat/merchant/deleteDepart`, data: {id}}),

}