<template>
  <!-- 字典，树结构 -->
  <separate-page>
    <template #category>
      <el-tree ref="groupTree" node-key="id" :data="treeList" :current-node-key="parentData.id"
               :props="{ children: 'children', label: 'dictName' }" @node-click="clickDictionary" default-expand-all
               :expand-on-click-node="false">
        <template v-slot="{ node, data }">
            <span :class="parentData.id === data.id? 'custom-tree-node on': 'custom-tree-node'">
              <span class="node-name">{{ node.label }}</span>
              <span class="node-option">
              </span>
            </span>
        </template>
      </el-tree>
    </template>
    <template #default>
      <div style="text-align: right;padding-bottom: 10px">
        <page-button el-type="danger" type="delete" icon="el-icon-delete" @click="toDelete" message="是否确认删除这个字典" :disabled="!currentData.id || !editAble">删除</page-button>
        <page-button el-type="success" type="write" icon="el-icon-plus" @click="toEditData({})" :disabled="!editAble">新建</page-button>
        <page-button el-type="primary" type="write" icon="el-icon-message-box" @click="saveData" :disabled="!editAble">保存</page-button>
      </div>
      <el-form label-width="100px">
        <el-form-item label="字典名称">
          <el-input v-model="currentData.dictName"/>
        </el-form-item>
        <el-form-item label="字典编码">
          <el-input v-model="currentData.dictCode" disabled/>
        </el-form-item>
        <el-form-item label="上级分类">
          <el-tree-select v-model="currentData.parentId" :data="treeList" clearable  check-strictly :render-after-expand="false" placeholder="请选择上级" :props="{children: 'children', label: 'dictName'}" node-key="id"/>
        </el-form-item>
        <el-form-item label="图片"  v-if="dictionSetting.imageType === 1">
          <image-upload v-model="currentData.imageBackground" :limit="1" :upload-tips="`图片尺寸：${dictionSetting.imageSize}`" folder="dictionary"/>
        </el-form-item>
        <el-form-item label="详情图片" v-if="dictionSetting.imageType === 1">
          <image-upload v-model="currentData.imageInfront" :limit="3" folder="dictionary"/>
        </el-form-item>
      </el-form>
    </template>
  </separate-page>
</template>

<script>
import dictionaryApi from "@/api/main/sys/dictionaryApi";
import PageButton from "@/components/h5/button/PageButton";
import ImageUpload from "@/components/h5/image/ImageUpload";
import SeparatePage from "@/components/h5/layout/SeparatePage";
import util from "@/util/util";
export default {
  props: {
    dictionSetting: {
      default: () => {
        return {
          dictCode: "",
          dictName: "",
          imageType: 0,
          imageBackground: "",
          imageInfront: "",
          // 0:不可编辑，1:可编辑
          editAble: 0,
        }
      }
    }
  },
  name: "",
  components: {
    PageButton, ImageUpload, SeparatePage
  },
  data() {
    return {
      treeList: [],
      currentData: {},
      parentData: {},
      editAble: true,
      userInfo: {},
      merchantId: "",
    };
  },
  created() {
    util.getUserInfo().then(user => {
      this.userInfo = user;
      if (this.dictionSetting.editAble === 0) {
        // 不可编辑，此时查询平台配置的信息
        this.merchantId = "10000";
        this.editAble = user.merchantId === "10000";
      }
      this.init();
    })

  },
  methods: {
    init() {
      dictionaryApi.queryListAsTree(this.dictionSetting.dictCode, this.merchantId).then(list => {
        this.treeList = list;
        this.currentData = {};
      });
    },
    // 选中某个节点
    clickDictionary(data) {
      this.currentData = {...data};
      this.parentData = {...data};
    },
    toEditData(data) {
      if (data.id) {
        // 这个字典已存在
        this.currentData = {...data};
      } else {
        this.currentData = {
          ...data,
          parentId: this.parentData.id
        };
      }
    },
    saveData() {
      dictionaryApi.saveTreeNode({
        ...this.currentData,
        type: this.dictionSetting.dictCode
      }).then(() => {
        this.init();
      });
    },
    toDelete() {
      if (this.currentData && this.currentData.id) {
        dictionaryApi.deleteById(this.currentData.id).then(() => {
          this.init();
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/tree.scss";
</style>