<template>
  <!-- -->
  <div class="condition" :id="id">
    <slot></slot>
<!--    <page-button el-type="primary"></page-button>-->
    <span v-if="hasMoreInfo" style="color: #409eff;line-height: 30px;cursor: pointer;font-size: 12px;user-select: none" @click="toShowMoreInfo">
      <el-icon :style="{'transform': showMoreInfo? 'rotateZ(180deg)': 'rotateZ(0deg)', 'font-size': '14px', 'vertical-align': 'middle'}"><el-icon-arrow-down/></el-icon>
      {{showMoreInfo? "点击收起": "更多选项"}}
    </span>
    <span class="option-span">
      <page-button v-if="showSearch" el-type="primary" @click="search">查询</page-button>
      <slot name="option"></slot>
    </span>
    <br />
    <slot name="moreCondition" v-if="showMoreInfo"></slot>
    <div>

    </div>


  </div>
</template>

<script>
import PageButton from "@/components/h5/button/PageButton";
export default {
  props: {
    showSearch: {default: false}
  },
  emits: ["search"],
  name: "",
  components: {
    PageButton
  },
  data() {
    return {
      id: "",
      // 是否显示更多查询条件
      showMoreInfo: false,
      // 是否设置了更多的查询条件
      hasMoreInfo: false,
      timeOut: "",
    };
  },
  unmounted() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  },
  created() {
    this.id = "search_condition" + (Math.random()*9999).toFixed(0);
    this.hasMoreInfo = !!this.$slots.moreCondition;
  },
  mounted() {
    // 获取当前组件的高度
    // 由于微服务框架本身的问题，mounted无法获取到dom对象
    this.$nextTick().then(() => this.resetParentHeight());
  },
  methods: {
    search() {
      this.$emit('search');
    },
    resetParentHeight() {
      if (this.$parent.setConditionHeight) {
        let docHeight = document.querySelector(`#${this.id}`).clientHeight;
        if (docHeight === 0) {
          this.timeOut = setTimeout(() => this.resetParentHeight(), 100);
          return;
        }
        this.$parent.setConditionHeight(docHeight, "searchCondtion");
      }
    },
    toShowMoreInfo() {
      this.showMoreInfo = !this.showMoreInfo;
      // 渲染完成后再更新
      this.$nextTick().then(() => {
        this.resetParentHeight();
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.condition{
  overflow: hidden;
  //padding-bottom: 20px;
  .more-info{
    color: #409eff;
    cursor: pointer;
  }
  .option-span{
    display: inline-block;
    float: right;
    :deep(.page-button:last-child){
      margin-right: 0;
    }
  }
  :deep(.condition){
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
    margin-right: 10px;
    vertical-align: top;
  }
  :deep(>.el-input){
    width: 240px;
    margin-bottom: 10px;
    box-sizing: border-box;
    margin-right: 10px;
    vertical-align: top;
  }
  :deep(.el-select){
    width: 240px;
    margin-bottom: 10px;
    box-sizing: border-box;
    margin-right: 10px;
    vertical-align: top;
  }
  :deep(.date-range){
    width: 490px;
    margin-bottom: 10px;
    box-sizing: border-box;
    margin-right: 10px;
    vertical-align: top;
  }
  :deep(.page-button){
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 0;
    vertical-align: top;
  }
  :deep(.el-autocomplete) {
    width: 240px !important;
    margin-bottom: 10px;
    box-sizing: border-box;
    margin-right: 10px;
    vertical-align: top;
  }
  :deep(.el-input:nth-last-child(1)){
    margin-right: 0;
  }
  :deep(.page-button:nth-last-child(1)){
    margin-right: 0;
  }
  :deep(.el-select:nth-last-child(1)){
    margin-right: 0;
  }
  :deep(.date-range:nth-last-child(1)){
    margin-right: 0;
  }
  :deep(.el-autocomplete:nth-last-child(1)){
    margin-right: 0;
  }




  :deep(.option-span .page-button:nth-last-of-type(2)){
    margin-right: 10px;
  }
}
:deep(.customer-select){
  width: 240px;
  margin-right: 10px;
  margin-bottom: 0px;
  :deep(.el-input){
    width: 100% !important;
  }
}
</style>