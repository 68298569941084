<template>
  <!-- 通用模板，列表数据 -->
  <list-page>
    <search-condition :show-search="true" @search="resetSearch">
      <el-input v-model="searchInfo.searchKey" placeholder="请输入查询条件"/>
      <template #option>
        <page-button v-if="merchantId" el-type="success" type="write" @click="$routerUtil.push(`/supplyChain/dealPrice/detail`)">新建</page-button>
      </template>
    </search-condition>
    <table-list>
      <el-table :data="dataList" height="100%" border>
        <el-table-column label="序号" width="60px" type="index" :align="'center'"/>
        <el-table-column v-if="merchantId" label="客户" prop="clientName"/>
        <el-table-column v-else label="供应商" prop="supplierName"/>
        <el-table-column label="有效期">
          <template v-slot="{row}">
            <date-range :disabled="true" :start-time="row.startTime" :end-time="row.endTime"/>
          </template>
        </el-table-column>
        <el-table-column label="协议价名称" prop="title"/>
        <el-table-column label="操作" :align="'center'">
          <template v-slot="{row}">
            <table-button @click="$routerUtil.push(`/supplyChain/dealPrice/detail?id=${row.id}`)">编辑</table-button>
            <table-button message="是否确认删除此数据" type="delete" el-type="danger" @click="deleteData(row.id)">删除</table-button>
          </template>
        </el-table-column>
      </el-table>
    </table-list>
    <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList" :default-load="false"/>
  </list-page>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import merchantDealPriceApi from "@/api/main/supplyChain/merchantDealPriceApi";
import DateRange from "@/components/h5/date/DateRange.vue";
import {watch} from "vue";
export default {
  props: {
    // 供应商
    merchantId: {
      default: "",
    },
    // 客户
    clientId: {
      default: "",
    }
  },
  name: "",
  components: {
    TableButton, PageButton, ListPage, TableList, FormPage, SearchCondition, DateRange
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
    };
  },
  created() {
    this.init();
    watch([() => this.merchantId, () => this.clientId], () => {
      this.init()
    })
  },
  methods: {
    init() {
      this.searchInfo.supplierId = this.merchantId;
      this.searchInfo.clientId = this.clientId;
      if (this.merchantId || this.clientId) {
        this.resetSearch();
      }
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      merchantDealPriceApi.queryList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      })
    },
    deleteData(id) {
      merchantDealPriceApi.delete(id).then(() => {
        this.resetSearch();
      })
    }
  }
};
</script>

<style lang="scss" scoped>

</style>