<template>
  <!-- -->
  <div class="defaultViewStyle">
    <separate-page :is-half-width="true">
      <template #category>
        <el-tree class="color-node" :data="departTree" :props="{children: 'children', label: 'departName'}"
                 @node-click="clickDepart" empty-text="部门为空" node-key="id" default-expand-all
                 :expand-on-click-node="false">
          <template #default="{node, data}">
            <div style="display: flex;justify-content: space-between;width: 100%">
              <div style="width: 70%;line-height: 28px;height: 28px;">
                <span class="custom-tree-node" style="">
                  <span class="node-name">{{ node.label }}</span>
                </span>
              </div>
              <div v-if="data.id === ''"
                   style="display: flex;justify-content: space-around;line-height: 28px;height: 28px;">
                <span style="width: 150px;text-align: center">启用门店</span>
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="该功能需先启用门店"
                    placement="top"
                    v-if="!data.isOpen"
                >
                  <span style="width: 150px;display: flex;align-items: center;justify-content: center">启用配送<el-icon><el-icon-warning/></el-icon></span>
                </el-tooltip>
              </div>

              <div v-else
                   style="display: flex;justify-content: space-around;line-height: 28px;height: 28px;background-color: white">
                <span style="width: 150px;text-align: center;z-index: 9"><el-switch
                    v-model="data.isOpen"
                    class="ml-2"
                    inline-prompt
                    @change="switchChange(data.isOpen,data)"
                    active-text="开启"
                    inactive-text="关闭"
                /></span>

                <span style="width: 150px;text-align: center;z-index: 9"><el-switch
                    v-model="data.isDelivery"
                    class="ml-2"
                    inline-prompt
                    :disabled="!data.isOpen"
                    @change="deliveryChange(data.isDelivery,data)"
                    active-text="开启"
                    inactive-text="关闭"
                /></span>
              </div>
            </div>
          </template>
        </el-tree>
      </template>
      <template #default>
        <list-page>
          <table-list style="height: 100%;overflow: hidden">
            <div v-if="!currentDepart.id" style="display: flex;justify-content: center;height: 100%;">
              <el-text size="large" style="letter-spacing: 3px">请选择部门</el-text>
            </div>
            <div style="height: 92%;overflow: scroll;">
              <el-descriptions :column="1" border v-if="currentCategory.size > 0">
                <el-descriptions-item label="排序">
                  <el-input v-model="detail.index" type="number" placeholder="请输入序号"/>
                </el-descriptions-item>
                <el-descriptions-item label="店铺名称">
                  <el-input v-model="detail.name" placeholder="请输入店铺名称"/>
                </el-descriptions-item>
                <el-descriptions-item label="店铺地址">
                  <el-input v-model="detail.address" placeholder="请输入店铺地址"/>
                </el-descriptions-item>
                <el-descriptions-item label="营业时间">
                  <el-time-picker
                      is-range
                      v-model="detail.openHours"
                      value-format="HH:mm:ss"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      placeholder="选择时间范围">
                  </el-time-picker>
                </el-descriptions-item>
                <el-descriptions-item label="店铺封面">
                  <image-upload v-model="detail.coverImage" :limit="1" width="80px" height="80px"
                                :upload-tips="'图片大小为600px*600px'"/>
                </el-descriptions-item>

                <el-descriptions-item label="预配送员" v-if="currentDepart.isDelivery">
                  <el-input v-model="search" size="small" placeholder="请输入职员名称" :prefix-icon="Search"/>
                  <el-checkbox-group v-model="detail.deliverys">
                    <el-checkbox v-for="ep in filterEpList" :key="ep.id" :label="ep.employeeName" :value="ep.id"></el-checkbox>
                  </el-checkbox-group>
                </el-descriptions-item>

                <el-descriptions-item label="分类挂载">
                  <el-tree v-if="currentCategory.size > 0" :data="categoryList"
                           :props="{children: 'children', label: 'dictName'}"
                           @node-click="clickModule" node-key="id" default-expand-all :expand-on-click-node="false"
                           style="height: 100%;overflow: auto">
                    <template #default="{node, data}">
                      <!--                <span :class="currentService.id === data.id? 'tree-on': ''"></span>-->
                      <span :style="{color: data.selected ? '#409EFF' : '#606266'}">{{ node.label }}</span>
                      <el-icon v-if="data.selected" style="color: #1BAB3D">
                        <el-icon-check/>
                      </el-icon>
                    </template>
                  </el-tree>
                </el-descriptions-item>

              </el-descriptions>
            </div>
            <div v-if="currentCategory.size > 0"
                 style="display: flex;align-items: flex-end;justify-content: flex-end;height: 5%">
              <page-button el-type="primary" type="write" @click="save">保存</page-button>
            </div>
          </table-list>
        </list-page>
      </template>
    </separate-page>
  </div>
</template>

<script>

import PageHeader from "@/components/h5/layout/PageHeader.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import commonApi from "@/api/commonApi";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import ListPage from "@/components/h5/layout/ListPage.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import {watch} from "vue";
import SeparatePage from "@/components/h5/layout/SeparatePage.vue";
import miniCategoryApi from "@/api/main/mini/miniCategoryApi";
import ImageUpload from "@/components/h5/image/ImageUpload.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import productGrantDepartApi from "@/api/main/product/productGrantDepartApi";
import {Search} from "@element-plus/icons-vue";

export default {
  name: "StoreSetting",
  components: {
    DateRange,
    ImageUpload,
    SeparatePage,
    Dictionary,
    PageHeader, PageButton, SearchCondition, TableList, ListPage, TableButton
  },
  data() {
    return {
      value5: false,
      value6: false,
      search: "",
      searchInfo: {},
      // 部门树
      departTree: [],
      // 已选择的分类
      currentDepart: {},
      // 分类列表
      categoryList: [],
      // 当前部门的已挂载分类
      currentCategory: new Map(),
      // 当前需要上传的分类
      operationCategory: [],
      // 是否开启店铺模板
      isOpen: false,
      // 属于哪个商业分类
      businessId: 1,
      // 商业分类列表
      businessType: [],
      showSetting: false,
      // 处理部门
      handlerDepartMap: new Map(),
      // 职员列表
      employeeList: [],
      // 图片
      detail: {
        bannerImage: '',
        openHours: ['00:00:00', '23:59:59'],
      },
    };
  },
  created() {
    miniCategoryApi.queryBusiness().then(res => this.businessType = res);
    // 部门
    this.queryDepart();
  },
  computed: {
    Search() {
      return Search
    },
    filterEpList() {
      return this.employeeList.filter(item => !this.search || item.employeeName.includes(this.search));
    }
  },
  methods: {
    // 保存
    save() {
      if (this.detail.deliverys) {
        this.detail.deliverys = JSON.stringify(this.detail.deliverys);
      }
      miniCategoryApi.save({
        departId: this.currentDepart.id, ...this.detail,
        openHours: JSON.stringify(this.detail.openHours)
      }).then(() => this.queryCategory());
    },
    // 查询部门
    queryDepart() {
      // 树形查询部门
      commonApi.queryDepartList().then(data => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].children && data[i].children.length > 0) {
            data[i].children = [];
          }
        }
        // 处理数据
        this.queryDepartSelect().then(() => {
          for (let i = 0; i < data.length; i++) {
            if (this.handlerDepartMap.has(data[i].id)) {
              let dep = this.handlerDepartMap.get(data[i].id);
              data[i].isOpen = true;
              data[i].isDelivery = dep.isDelivery === 1;
            } else {
              data[i].isOpen = false;
              data[i].isDelivery = false;
            }
          }
          this.departTree = [{departName: '全部部门', id: '', children: data}];
        })
      });
    },
    queryDepartSelect() {
      return miniCategoryApi.queryDepart(1).then(data => {
        this.handlerDepartMap.clear();
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            this.handlerDepartMap.set(data[i].departId, data[i]);
          }
        }
      })
    },
    async switchChange(isTrue, data) {
      if (data.parentId) {
        this.$message.warning("二级子部门，依托于父级，不允许单独开启门店！");
        return;
      }
      this.isOpen = isTrue;
      this.currentDepart = data;
      await this.changeOpen();
      this.queryDepart();
    },
    deliveryChange(isTrue, data) {
      this.currentDepart = data;
      if (isTrue) {
        miniCategoryApi.updateDelivery(this.currentDepart.id, 1);
      } else {
        miniCategoryApi.updateDelivery(this.currentDepart.id, 2);
      }
      this.searchInfo.departId = data.id;
      this.resetSearch();
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryCategory();
    },
    queryCategory() {
      miniCategoryApi.queryCategory(this.searchInfo.departId).then(res => {
        // 处理分类信息
        productGrantDepartApi.queryAllByDepartCode(this.currentDepart.departCode).then(res2 => {
          // 过滤掉未授权的分类信息
          this.handlerCategory2(res2);
          if (!this.categoryList[0].children || this.categoryList[0].children.length === 0) {
            // this.$message.warning("该部门尚未添加分类，请联系管理员!");
            this.categoryList[0].dictName = "该部门尚未添加分类，请联系管理员!";
          }
          // 将获取到的挂载分类转换为map
          this.currentCategory.clear();
          res.forEach(item => {
            this.currentCategory.set(item.dictCode, item);
          })
          // 是否开启了部门
          this.currentCategory.size > 0 ? this.isOpen = true : this.isOpen = false;
          // 处理其它数据
          let data = res.find(item => item.dcType === 1);
          this.handlerOtherData(data ? data : {});
          // 处理分类数据
          this.handlerCategory(this.categoryList);
        })
      })
    },
    handlerCategory2(list) {
      // 获取商品的全部分类
      let myMap = new Map();
      for (let i = 0; i < list.length; i++) {
        if (!myMap.has(list[i].productCategory)) {
          myMap.set(list[i].productCategory, "");
        }
      }
      // 过滤分类
      this.filterCategory(this.categoryList[0].children, myMap);
    },
    filterCategory(list, myMap) {
      // 递归list这个树结构,判断分类是否在map中，如果在的话则保留，如果它是子级分类，则连带着父级分类一起保留
      for (let i = 0; i < list.length; i++) {
        if (list[i].children && list[i].children.length > 0) {
          let returnData = this.filterCategory(list[i].children, myMap);
          if (!returnData || returnData.length > 0) {
            continue;
          }
        }

        if (!myMap.has(list[i].dictCode)) {
          // 如果在map中，则保留
          // 如果不在map中，则删除
          list.splice(i, 1);
          i--;
        }
      }
      return list;
    },
    // 处理其它数据
    handlerOtherData(data) {
      if (Object.keys(data).length > 0) {
        // 存在数据
        this.detail = {
          id: data.id,
          bannerImage: data.bannerImage,
          openHours: data.openHours ? JSON.parse(data.openHours) : ['00:00:00', '23:59:59'],
          coverImage: data.coverImage,
          address: data.address,
          name: data.name ? data.name : this.currentDepart.departName,
          index: data.index,
          isDelivery: data.isDelivery,
          deliverys: data.deliverys ? JSON.parse(data.deliverys) : [],
        }
      }
    },
    // 处理分类已挂载的数据
    handlerCategory(list) {
      for (let i = 0; i < list.length; i++) {
        list[i].selected = this.currentCategory.has(list[i].dictCode);
        if (list[i].children && list[i].children.length > 0) {
          this.handlerCategory(list[i].children);
        }
      }
      this.categoryList = list;
    },
    // 当前点击的部门
    clickDepart(data) {
      if (!data.id) {
        this.currentCategory.clear();
        return;
      }
      // 查询商品分类
      commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => {
        this.categoryList = [{dictName: '全部分类', id: '', children: list}];
      });
      this.currentDepart = {...data};
      this.searchInfo.departId = data.id;
      this.resetSearch();
      this.queryAllEmployee();
    },
    queryAllEmployee() {
      // 查询所有职员
      commonApi.queryAllEmployee(this.currentDepart.departCode).then(list => {
        this.employeeList = list.filter(item => item.id !== "100001");
      });
    },
    async clickModule(data) {
      if (!this.categoryList[0].children || this.categoryList[0].children.length === 0) {
        this.$message.warning("该部门尚未挂载分类，请联系管理员添加");
        return;
      }
      const isParent = this.prepareCategory(data);
      // 判断是保存还是删除
      let isSave = false;
      if (isParent) {
        // 为父节点，判断当前是保存还是删除
        for (let i = 0; i < this.operationCategory.length; i++) {
          if (!this.operationCategory[i].selected) {
            isSave = true;
          }
        }
      } else {
        // 非父节点
        this.operationCategory[0].selected ? isSave = false : isSave = true;
      }

      if (!isSave) {
        await miniCategoryApi.deleteCategory(this.operationCategory);
      } else {
        await miniCategoryApi.saveCategory(this.operationCategory);
      }
      this.operationCategory = [];
      this.queryCategory();
    },
    prepareCategory(data) {
      // 当前节点是否为父节点
      let isParent = false;
      if (data.children && data.children.length > 0) {
        for (let i = 0; i < data.children.length; i++) {
          this.prepareCategory(data.children[i]);
        }
        isParent = true;
      } else {
        this.operationCategory.push({...data, departId: this.searchInfo.departId});
      }
      return isParent;
    },
    async changeOpen() {
      if (this.isOpen) {
        if (!this.businessId) {
          this.$message.error('商业分类不能为空');
          return;
        }
        await miniCategoryApi.openStore(this.currentDepart.id, this.businessId);
      } else {
        await miniCategoryApi.closeStore(this.currentDepart.id);
      }
      this.$message.success('保存成功');
      this.showSetting = false;
      this.queryCategory();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/description.scss";
// 树形组织机构统一样式
.color-node {
  :deep(.el-tree-node.is-current > .el-tree-node__content ) {
    background: #409EFF;
    color: #FFF;
    position: relative;
    overflow: hidden;

    .custom-tree-node {
      display: block;
      width: 100%;
      line-height: 28px;
      height: 28px;

      .node-option {
        display: inline;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        text-align: right;
        background-color: #409eff;
        line-height: 28px;
        box-sizing: border-box;
        padding: 0 10px;

        .el-icon {
          color: #FFF;
        }
      }
    }
  }

  .custom-tree-node {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding-right: 10px;

    .node-option {
      display: none;
      float: right;
    }
  }
}

:deep(.el-tree-node__content) {
  font-size: 12px;
}

</style>