<template>
  <!-- -->
  <el-select :multiple="isMultiple"
             collapse-tags
             collapse-tags-tooltip clearable filterable v-model="employeeId" :placeholder="placeholderTit"
             @change="changeData" style="width: 100%">
    <el-option v-for="(e, i) in employeeList" :label="e.employeeName" :value="e.id" :key="i" />
  </el-select>
</template>

<script>
import {watch} from "vue";
import commonApi from "@/api/commonApi";
import util from "@/util/util";

export default {
  emits: ["update:modelValue", "selectOne", "selectMore"],
  props: {
    modelValue: {default: ""},
    placeholderTit: {default: "请选择职员"},
    isMultiple: {default: false}, // 开启多选
    access: {default: null}, // 鉴权
    enabledAccess: {default: true},
  },
  components: {},
  data() {
    return {
      employeeId: "",
      employeeList: [],
    };
  },
  created() {
    this.init();
    watch([() => this.modelValue,()=>this.access], () => this.init());
  },
  methods: {
    init() {
      if (!this.employeeList || this.employeeList.length === 0) {
        util.getUserInfo().then(res=>{
          let departCode = "";
          if(this.access === '2'){
            departCode = res.mainDepartCode;
          }
          commonApi.queryAllEmployee(departCode).then(list => {
            this.employeeList = list;
          });
        })
      }
      if(this.isMultiple){
        let arr = this.modelValue.split(",");
        this.employeeId = arr[0] !== "" ? arr : [];
      }else{
        this.employeeId = this.modelValue;
      }
    },
    changeData(data) {
      if(this.isMultiple){
        this.$emit("selectMore",data);
      }else{
        let result = false;
        for (let i = 0; i < this.employeeList.length; i++) {
          if (this.employeeList[i].id === this.employeeId) {
            result = true
            this.$emit("update:modelValue", this.employeeList[i].id);
            this.$emit("selectOne", this.employeeList[i]);
            break;
          }
        }
        if (!result) {
          this.$emit("update:modelValue", "");
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>