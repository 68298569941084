<template>
  <div class="configuration" v-if="configuration.rightType === 1">
    <el-divider>
      <span style="font-weight: bolder; font-size: 17px;">{{ configuration.title }}</span>
    </el-divider>

    <div class="con-box tips">
      <span style="font-weight: bolder;">注意：</span>
      <div style="display: flex; flex-direction: column;">
        <span class="tip-word" v-for="(item,idx) in configuration.tips" :key="idx">{{ item }}<br/></span>
      </div>
    </div>

    <div class="con-box info-img">
      <el-icon @click="showControl = true" style="position:absolute; right: 5px; top: 5px;color: #409EFF">
        <el-icon-setting/>
      </el-icon>
      <el-form label-width="70px" style="margin-top: 13px">
        <el-form-item label="图片顺序">
          <el-input v-model="configuration.index" @change="changeComIdx" placeholder="请输入图片顺序"
                    :max="dropComponentList.length - 1"
                    :min="0" type="number"
                    style="width: 214px"/>
        </el-form-item>
        <div v-if="configuration.isPictureWord">
          <el-form-item :label="idx === 0 ? '图片文字' : '' " v-for="(item,idx) in configuration.wordInfo"
                        :key="idx">
            <div class="wordInfo">
              <el-input v-model="item.text" placeholder="请输入图片文字" style="margin-right: 4px; width: 214px"/>

              <el-color-picker v-model="item.color" show-alpha/>
              <el-icon v-if="idx === 0" style="margin-left: 4px" @click="addWordInfo">
                <Plus/>
              </el-icon>
              <el-icon v-else style="margin-left: 4px" @click="removeWordInfo(idx)">
                <Minus/>
              </el-icon>
            </div>
          </el-form-item>
        </div>
        <el-form-item v-if="configuration.isPicLink" label="路由管理">
          <dictionary :dictionaries="routeTree" v-model="configuration.routeUri"
                      @clear-data="clearUri"
                      :place-holder="'请选择路由信息'"
                      dict-code="routeUri"
                      dict-name="name"/>
        </el-form-item>
      </el-form>
    </div>

    <div class="con-box" style="display: flex;flex-direction: column">
      <div style="display: flex;justify-content: space-between;align-items: center;border: 1px solid #ecebe9;"
           v-for="(item,idx) in configuration.pictureInfo" :key="idx">
        <div class="img-box">
          <!--                  <image-upload :width="'100px'" :height="'100px'" :limit="1" v-model="item.imageUrl"/>-->
          <div style="width: 50%; margin-right: 20px" v-if="configuration.type !== 4" @click="changeImg(idx)">
            <el-image :src="item.imageUrl" style="width: 100%;" fit="fill"/>
          </div>
          <div style="width: 50%;line-height: 48px;">
            <dictionary :dictionaries="routeTree" v-model="item.routeId"
                        @clear-data="clearUri"
                        :place-holder="'请选择路由信息'"
                        dict-code="id"
                        dict-name="name"/>
            <el-input v-model="item.routeParams"
                      placeholder="请输入路由参数"/>
            <div
                style="display: flex;flex-direction: row; align-items: center;margin-top: 10px;line-height: 100%">
              <div style="position: relative;margin-right: 4px;">
                <el-input class="pad-rgt" v-model="item.text"
                          placeholder="请输入图片文字"/>
                <el-icon
                    size="12px"
                    @click="()=>{ this.showWordOther = true; this.wordOther = {...item,originalIdx: idx};}"
                    style="position:absolute; right: 10px; top: 10px;color: #409EFF">
                  <el-icon-setting/>
                </el-icon>
              </div>
              <div>
                <el-color-picker v-model="item.color" show-alpha/>
              </div>
            </div>

          </div>
        </div>
        <div class="right-func">
          <el-icon @click="updown(true,item.imageUrl)" style="color: black">
            <Top/>
          </el-icon>
          <el-icon @click="updown(false,item.imageUrl)" style="color: black">
            <Bottom/>
          </el-icon>
          <el-icon @click="deletePic(item.imageUrl)" style="color: black">
            <DeleteFilled/>
          </el-icon>
        </div>
      </div>
      <div :style="configuration.pictureInfo.length > 0 ? 'margin-top: 15px' : ''">
        <image-control @selected="selected" style="width: 100%" :show-default="showSelectImg" @before-close="() => showSelectImg = false"/>
      </div>
      <div style="display: flex;justify-content: center;margin-top: 10px">
        <page-button el-type="info" @click="cancel">取消</page-button>
        <page-button el-type="danger" @click="deleteCom">删除</page-button>
        <page-button el-type="primary" @click="saveSubmit">保存</page-button>
      </div>

      <el-dialog title="配置管理" width="30%" v-model="showControl">
        <el-form label-width="70px">
          <el-form-item label="图片文字">
            <el-checkbox style="height: 0" v-model="configuration.isPictureWord" label="开启图片文字" size="large"/>
          </el-form-item>
          <el-form-item label="路由管理">
            <el-checkbox style="height: 0" v-model="configuration.isPicLink" label="开启路由管理" size="large"/>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog title="其它文字信息" width="30%" v-model="showWordOther">
        <el-form label-width="90px">
<!--          <el-form-item label="定位左边距">-->
<!--            <el-input v-model="wordOther.left" v-if="wordOther.position" placeholder="当前单位支持：'px' 或 '%'"/>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="定位上边距">-->
<!--            <el-input v-model="wordOther.top" v-if="wordOther.position" placeholder="当前单位支持：'px' 或 '%'"/>-->
<!--          </el-form-item>-->
          <el-form-item label="文字大小">
            <el-input v-model="wordOther.size" placeholder="当前单位支持：'px'"/>
          </el-form-item>
          <el-form-item label="盒子高度">
            <el-input v-model="wordOther.height" placeholder="当前单位支持：'px'"/>
          </el-form-item>
          <el-form-item label="盒子宽度" v-if="configuration.type === 4 || configuration.type === 3">
            <el-input v-model="wordOther.boxWidth" placeholder="当前单位支持：'px'"/>
          </el-form-item>
          <el-form-item label="盒子边距">
            <el-input v-model="wordOther.marginTop" placeholder="当前单位支持：'px'"/>
          </el-form-item>
          <el-form-item label="盒子圆角">
            <el-input v-model="wordOther.radius" placeholder="注：每个单位之间使用空格相隔、当前单位支持：'px'"/>
          </el-form-item>
<!--          <el-form-item v-if="configuration.type === 4" label="定位元素" style="display: flex; align-items: center">-->
<!--            <el-checkbox v-model="wordOther.position" label="开启后背景图将不占据文档流" size="large"/>-->
<!--          </el-form-item>-->
          <el-form-item label="自动换行" style="display: flex; align-items: center">
            <el-checkbox v-model="wordOther.whiteSpace" label="关闭后文字将在一行显示" size="large"/>
          </el-form-item>
          <el-form-item label="换行宽度">
            <el-input :disabled="!wordOther.whiteSpace" v-model="wordOther.width" placeholder="当前单位支持：'px'"/>
          </el-form-item>
        </el-form>
        <div style="display: flex;justify-content: flex-end">
          <page-button el-type="info" @click="showWordOther = false">取消</page-button>
          <page-button el-type="primary" @click="setWordInfo">保存</page-button>
        </div>
      </el-dialog>

      <el-dialog title="图片配置" width="30%" v-model="showPicConfig">
        <el-form label-width="70px">
          <el-form-item label="图片高度">
            <el-input v-model="configuration.width" placeholder="当前单位支持：'px' 或 '%'"/>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Dictionary from "@/components/h5/Dictionary.vue";
import ImageControl from "@/components/h5/image/ImageControl.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import {watch} from "vue";
import miniConfig from "@/config/miniConfig";
import {ElMessageBox} from "element-plus";
import miniTempApi from "@/api/main/mini/miniTempApi";
import {Bottom, DeleteFilled, Minus, Plus, Top} from "@element-plus/icons-vue";
import miniRouteApi from "@/api/main/mini/miniRouteApi";
import config from "@/config/config";

export default {
  name: "DefaultSelect",
  components: {
    DeleteFilled,
    Bottom,
    Top,
    Minus,
    Plus,
    Dictionary,
    ImageControl,
    PageButton,
  },
  emits: ["refresh"],
  watch: {
    'configuration.index': {
      handler(nv, ov) {
        console.log(nv, ov);
        if (ov && ov !== '') {
          this.recordIdx = ov;
        }
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    maxLength:{
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      isDraggingOver: false,
      dynamicWidth: 0, // 初始化宽度，与原始伪元素宽度一致
      dynamicHeight: 0,
      dynamicLeft: 0, // 初始化边框左偏移
      dynamicTop: 0, // 初始化边框顶偏移

      showPicConfig: false,
      // 记录组件的原顺序
      recordIdx: null,
      // 文本的其它信息
      wordOther: {},
      showWordOther: false,
      showControl: false,
      tempList: [],
      showEdit: false,
      dropComponentList: [],
      configuration: {},
      wordInfo: [], // 文字存储信息
      routeTree: [],
      showSelectImg: false, // 展示选择图片
      imgIndex: -1, // 图片索引
    }
  },
  created() {
    watch(() => this.config, () => {

      this.configuration = this.config;
      if(this.configuration.pictureInfo.length === 0 && this.configuration.rightType === 1){
        let arr = [];
        for (let i = 0; i < this.configuration.max; i++) {
          if(this.configuration.max > 10){
            arr.push(config.fileUrl + "20240409190231498121/common/common_20240630164457340111.png");
            break;
          }else{
            arr.push(config.fileUrl + "20240409190231498121/common/common_20240630164457340111.png");
          }
        }
        this.selected(arr);
      }
    });
    this.tempList = miniConfig.miniTemplate;
    miniRouteApi.queryByTree().then(res => this.routeTree = res)
    // 初始化
    this.queryTemp();
  },

  methods: {
    // 修改图片
    changeImg(idx){
      this.imgIndex = idx;
      this.showSelectImg = true;
    },
    // 开始拖动
    handleDragStart(event, item) {
      this.isDraggingOver = true;
      event.dataTransfer.setData('text/plain', JSON.stringify(item)); // 传递数据
      this.setWidHei(item.type);
    },
    // 拖动结束
    handleDragEnd() {
      this.isDraggingOver = false;
    },
    // 拖动到目标元素
    handleDrop(event) {
      event.preventDefault();
      const data = JSON.parse(event.dataTransfer.getData('text/plain'));
      // 根据value添加一个组件
      this.addComponent(event, data.type);
      // 显示配置信息
      this.configuration = miniConfig.miniEdit.find(item => item.type === data.type);
      // 计算索引顺序、多存储一份，用来寻找对应的渲染组件
      this.configuration.index = this.dropComponentList.length - 1;
      this.configuration.indexToFindComponet = this.dropComponentList.length - 1;
      // 文字信息
      if (this.configuration.wordInfo.length === 0) {
        this.addWordInfo();
      }
      if (this.configuration.pictureInfo.length > 0) {
        this.configuration.pictureInfo = [];
      }
    },
    // 添加一组文字
    addWordInfo() {
      this.configuration.wordInfo.push({text: '', color: 'rgba(19, 206, 102, 0.8)'});
    },
    // 删除一组文字信息
    removeWordInfo(index) {
      this.configuration.wordInfo.splice(index, 1);
    },

    // 设置宽高
    setWidHei(val) {
      // if (val === 1) {
      //   // 轮播图
      //   this.dynamicWidth = 222;
      //   this.dynamicHeight = 120;
      // } else if (val === 2) {
      //   // 联动图片
      //   this.dynamicWidth = 222;
      //   this.dynamicHeight = 180;
      // }
    },
    // 清除路由
    clearUri() {
      this.configuration.routeUri = null;
    },
    // 图片选中的数据
    selected(val) {
      // 判断是否超过了最大图片数
      if(this.imgIndex !== -1){
        val.splice(1, val.length);
        this.configuration.pictureInfo[this.imgIndex].imageUrl = val[0];
        this.imgIndex = -1;
        return;
      }else{
        if ((val.length + this.configuration.pictureInfo.length) > this.configuration.max) {
          this.$message.warning(`当前模板最多只能选择${this.configuration.max}张图片`)
          // 删除多余的图片
          val.splice((this.configuration.max - this.configuration.pictureInfo.length), val.length);
        }
      }
      for (let i = 0; i < val.length; i++) {
        this.configuration.pictureInfo.push({
          imageUrl: val[i],
          routeUri: null,
          text: '',
          color: 'rgba(19, 206, 102, 0.8)',
          top: '0px', // 高
          left: '0px', // 左
          size: '14px', // 大小
          whiteSpace: true, // 自动换行
          width: '50px', // 换行宽度
          height: this.configuration.type === 2 ? '180px' : '120px',
          radius: '5px 5px', // 圆角大小
          position: false, // true：绝对定位 false：相对定位
          marginTop: '0px', // true：绝对定位 false：相对定位
          boxWidth: 'auto',
          complexIndex: this.configuration.pictureInfo.length
        });
      }
    },
    // 移动图片
    updown(isUp, imageUrl) {
      let index = this.configuration.pictureInfo.findIndex(item => item.imageUrl === imageUrl);
      if (isUp) {
        if (index > 0) {
          let temp = this.configuration.pictureInfo[index];
          this.configuration.pictureInfo[index] = this.configuration.pictureInfo[index - 1];
          this.configuration.pictureInfo[index - 1] = temp;
        }
      } else if (index !== this.configuration.pictureInfo.length - 1) {
        if (index < this.configuration.pictureInfo.length - 1) {
          let temp = this.configuration.pictureInfo[index];
          this.configuration.pictureInfo[index] = this.configuration.pictureInfo[index + 1];
          this.configuration.pictureInfo[index + 1] = temp;
        }
      }
      this.checkComplexIndex();
    },
    // 校对索引顺序
    checkComplexIndex() {
      // 重新校对复合索引顺序
      if (this.configuration.pictureInfo.length > 0) {
        for (let i = 0; i < this.configuration.pictureInfo.length; i++) {
          this.configuration.pictureInfo[i].complexIndex = i;
        }
      }
    },
    // 删除本张图片
    deletePic(imageUrl) {
      let index = this.configuration.pictureInfo.findIndex(item => item.imageUrl === imageUrl);
      this.configuration.pictureInfo.splice(index, 1);
      // 校对顺序
      this.checkComplexIndex();
    },
    // 取消当前编辑
    cancel() {
      ElMessageBox.confirm('您确定要取消编辑吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dropComponentList.splice(this.configuration.index, 1);
        this.configuration = {};
      })
    },
    // 移除
    deleteCom() {
      ElMessageBox.confirm('您确定要删除该模板吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        miniTempApi.delete(this.configuration.index).then(() => {
          this.$emit("refresh");
        });
      })
    },
    // 保存编辑并提交
    saveSubmit() {
      let data = this.handlerEditInfo();
      // 保存提交
      miniTempApi.saveSubmit(data.pictureInfo).then(() => {
        // this.findByIndex(this.configuration.index);
        this.$emit("refresh");
      });
    },
    // 处理图片编辑信息
    handlerEditInfo() {
      let data = {
        ...this.configuration,
        pictureInfo: this.configuration.pictureInfo.map(item => ({
          ...item,
          imageUrl: item.imageUrl // 这里先保留原始imageUrl，稍后在循环中再修改
        })),
      };
      if (!data.isPicLink) {
        // 未开启路由
        delete data.routeUri;
      }
      if (!data.isPictureWord) {
        // 未开启标题文字信息
        delete data.wordInfo;
      }
      if (data.pictureInfo.length > 0) {
        for (let i = 0; i < data.pictureInfo.length; i++) {
          // 处理链接图片
          let img = data.pictureInfo[i].imageUrl;
          const startIndex = img.indexOf("/", img.indexOf(":") + 3) + 1;
          data.pictureInfo[i].imageUrl = img.substring(startIndex);
          // 处理文字信息
          let wordInfo = {
            text: data.pictureInfo[i].text,
            color: data.pictureInfo[i].color,
            top: data.pictureInfo[i].top,
            left: data.pictureInfo[i].left,
            size: data.pictureInfo[i].size,
            whiteSpace: data.pictureInfo[i].whiteSpace,
            width: data.pictureInfo[i].width,
            height: data.pictureInfo[i].height,
            radius: data.pictureInfo[i].radius,
            position: data.pictureInfo[i].position,
            marginTop: data.pictureInfo[i].marginTop,
            boxWidth: data.pictureInfo[i].boxWidth,
          };
          data.pictureInfo[i].wordInfo = JSON.stringify(wordInfo);
          data.pictureInfo[i].type = data.type;
          data.pictureInfo[i].index = data.index;
        }
      }
      // 没用的东西
      delete data.isPicLink;
      delete data.isPictureWord;
      delete data.tips;
      delete data.title;
      return data;
    },
    // 手机组件内模块被选中
    handleClick(index) {
      for (let i = 0; i < this.dropComponentList.length; i++) {
        if (i === index) {
          this.dropComponentList[i].isSelected = true;
          this.findByIndex(i).catch(() => {
            // 显示配置信息
            this.configuration = miniConfig.miniEdit.find(item => item.type === this.dropComponentList[index].val);
            // 计算索引顺序、多存储一份，用来寻找对应的渲染组件
            this.configuration.index = this.dropComponentList.length - 1;
            this.configuration.indexToFindComponet = this.dropComponentList.length - 1;
            // 图片信息置空
            // this.configuration.pictureInfo = [];
            // 文字信息
            if (this.configuration.wordInfo.length === 0) {
              this.addWordInfo();
            }
          });
        } else {
          this.dropComponentList[i].isSelected = false;
        }
      }
    },
    // 设置文字的其它信息
    setWordInfo() {
      this.configuration.pictureInfo[this.wordOther.originalIdx] = {
        ...this.configuration.pictureInfo[this.wordOther.originalIdx],
        ...this.wordOther
      };
      console.log(this.configuration.pictureInfo[this.wordOther.originalIdx])
      this.$message.success("保存成功");
      this.showWordOther = false;
    },
    // 修改图片顺序
    changeComIdx() {
      // 根据每个index重新进行数组的排序
      if (!this.configuration.index || this.configuration.index === '' || Number(this.configuration.index) === Number(this.recordIdx)) {
        this.configuration.index = Number(this.recordIdx);
        return;
      }
      if (this.configuration.index > this.maxLength - 1) {
        this.$message.error("索引值不能超过已添加组件的最大值");
        this.configuration.index = Number(this.recordIdx);
        return;
      }
    }
  },
}
</script>
<style lang="scss" scoped>


.container {
  display: flex;
  flex-direction: row;
  height: 100%; /* 确保容器高度为100% */
  overflow: hidden;
}

.flex-item {
  flex-grow: 1; /* 让所有item默认等分剩余空间 */
  //border: 1px solid black;
  height: 100%;
}

.first {
  width: 25%; /* 第一个和第二个div占30% */
  border-right: 1px solid #424141;
  box-sizing: border-box;
  padding-right: 14px;
  white-space: nowrap;

  .asse {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .content {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 15px;
      border: 1px solid #d5d1d1;
      //width: 25%;
      //height: 25%;
      cursor: move;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.second {
  width: 35%; /* 第一个和第二个div占30% */
  border-right: 1px solid #424141;
  overflow: scroll;
}

.third {
  width: 40%; /* 第三个div占40% */
  overflow: scroll;
  height: 100%;

  .tips {
    .tip-word {
      padding-left: 5px;
      font-size: 13px;
    }
  }

  .con-box {
    margin-top: 10px;
    border: 1px solid #d0cccc;
    margin-left: 10px;
    padding: 10px;
    color: #cc5353;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;

    .wordInfo {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;
    }
  }

  .info-img {
    position: relative;
  }

  .img-box {
    width: 60%;
    margin: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
  }

  .right-func {
    width: 27%;
    display: flex;
    justify-content: space-between;
    margin-right: 20px;

  }
}

.phone {
  position: relative;
  width: 425px;
  height: 795px;
  margin: 0 auto;

  img {
    position: absolute;
    width: 100%;
    height: 795px;
  }

  .bg-color {
    top: 32px;
    position: absolute;
    width: 360px;
    height: 744px;
    background-color: white;
    border: 1px solid black;
    left: 33px;
    border-radius: 23px 23px 36px 36px;
    overflow: scroll;
  }

  .bg-color::-webkit-scrollbar {
    display: none; /* 隐藏滚动条 */
  }
}

.drop-indicator {
  border: 1px dashed black; /* 选择你喜欢的颜色和样式 */
  opacity: 0;
  transition: opacity 0.2s ease-out;
  pointer-events: none; /* 防止遮挡下方元素的点击事件 */
}

.bg-color.drag-over .drop-indicator {
  opacity: 1;
}

.image-upload {

  :deep(.el-upload-list__item-delete) {
    display: none !important;
  }

  :deep(.el-upload-list__item-status-label) {
    display: none !important;
  }

}

.pad-rgt {
  :deep(.el-input__inner) {
    padding-right: 12px !important;
  }
}

</style>