import axios from "../../../util/axios";
 export default {
     /**查询  */
     queryCustomerCaseList:(data) => axios.get({url:"/alpine/case/queryList",data:data}),

     /**删除  */
     delete: (id) => axios.del({url: "/alpine/case/delete", data: {id}}),
     /**保存更新  */
      save:(data) =>axios.post({url:"/alpine/case/save",data:data}),
     /**查询  */
     findById: (id) => axios.get({url: "/alpine/case/findById", data: {id}}),
     // 查询全部门店
     queryStoreList: () => axios.get({url: "/alpine/case/queryStoreList"}),

 }