import axios from "../../../util/axios";

export default {
    // 查询客户列表
    queryList: (searchInfo) => axios.get({url: "/basedata/customer/member/queryList", data: searchInfo}),
    // 查询客户列表
    save: (data) => axios.post({url: "/basedata/customer/member/save", data: data}),
    // 查询客户列表
    delete: (id) => axios.del({url: "/basedata/customer/member/delete", data: {id}}),
    // 查询详情
    findById: (id) => axios.get({url: "/basedata/customer/member/findById", data: {id}}),
    // 同步erp客户信息
    queryErpCustomer: () => axios.get({url: "/order/common/queryErpCustomer"}),
}