<template>
  <!-- 商品选择组件，只选择一个商品，占满一整行 -->
  <el-autocomplete class="auto-input" style="width: 100%;margin-right: 10px" v-model="barCode" @change="onChange" :fetch-suggestions="setCallBack" @select="selectProductOne" @keydown="keyDown" value-key="skuName" clearable placeholder="条码/拼音码/名称/编码并按回车"/>
</template>

<script>

import FormPage from "@/components/h5/layout/FormPage.vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import Money from "@/components/h5/Money.vue"
import commonApi from "@/api/commonApi";
import {watch} from "vue";

export default {
  // 选中商品回调, select选中多条数据, selectOne选中一条数据
  emits: ["selectOne", "update:modelValue"],
  props: {
    modelValue: {default: ""},
    // 按sku条码查询到商品
    findSkuByCodeApi: {default: null},
  },
  name: "ProductList",
  components: {
    PageButton,
    SearchCondition,  DialogTable, FormPage, Money
  },
  data() {
    return {
      barCode: "",
      showSaveProduct: false,
      productList: [],
      searchInfo: {},
      // eltable被选中的数据
      elSelected: [],
      // 设置el-auto组件的回调
      setDataList: {},
      enter: true,
    };
  },
  created() {
    watch(()=>this.modelValue,()=>this.$emit("update:modelValue",this.barCode));
  },
  methods: {
    setCallBack(val, callBack) {
      this.setDataList = callBack;
      callBack([]);
    },
    // 选中某一个商品
    selectProductOne(data) {
      this.enter = false;
      this.$emit("update:modelValue", data.id);
      this.$emit("selectOne", data);
    },
    onChange(data) {
      if (data === "") {
        // 清空事件
        this.$emit("update:modelValue", "");
      }
    },
    keyDown(key) {
      this.$nextTick().then(() => {
        if (key.keyCode === 13) {
          if (!this.enter) {
            this.enter = true;
            return;
          }
          let findByCode;
          if (this.findSkuByCodeApi) {
            findByCode = this.findSkuByCodeApi;
          } else {
            findByCode = commonApi.querySkuListByCode;
          }
          // 回车时，进行查询
          findByCode(this.barCode).then(list => {
            if (list.length === 1) {
              this.$emit("update:modelValue", list[0].id);
              this.$emit("selectOne", list[0]);
            } else {
              this.setDataList(list);
            }
          });
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.input-line{
  width: calc(100% - 60px);
  margin-right: 10px;
  :deep(.el-input-group__append) {
    color: #409eff;
    //background: #409eff;
    //border-color: #409eff;
  }
}
.auto-input{
  :deep(.el-input){
    width: 100% !important;
  }
}
</style>