<template>
  <!-- -->
  <div>
    <div style="display: none;">
      <slot></slot>
    </div>
    <el-dropdown :placement="'left'" size="default" style="padding-top: 6px"
                 @command="handleCommand({ command: $event})">
                <span class="el-dropdown-link">
                  <table-button>操作<el-icon class="el-icon--right"><arrow-down/></el-icon></table-button>
                </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item v-for="(b, i) in btnList" :icon="b.icon" :key="i" :disabled="b.disabled" :command="b.id">
            {{b.name}}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>

import TableButton from "@/components/h5/button/TableButton.vue";
import {ArrowDown} from "@element-plus/icons-vue";

export default {
  name: "",
  components: {
    ArrowDown,
    TableButton
  },
  data() {
    return {
      btnList: [],
    };
  },

  created() {
  },
  methods: {
    handleCommand({command}) {
      document.querySelector(`#${command}`).click();
    },
    // 向插槽添加数据
    addButton(data) {
      let isTrue = false;
      let idx = -1;
      for (let i = 0; i < this.btnList.length; i++) {
        if(this.btnList[i].name === data.name){
          isTrue = true;
          idx = i;
          break;
        }
      }
      if(isTrue && idx !== -1){
        this.btnList[idx] = data;
      }else{
        this.btnList.push(data);
      }
    },
    // 插槽里面的button变更
    changeButton(data) {

    },
  }
};
</script>

<style lang="scss" scoped>

</style>