<template>
  <!-- -->
  <div class="defaultViewStyle">
    <long-form>
      <long-form-panel label="基本信息">
        <el-descriptions :column="3" border>
          <el-descriptions-item label="物资分类">
            <dictionary :disabled="true" v-model="dataDetail.category" :dictionaries="productCategories" place-holder="物资分类"
                        style="display: inline-block;width: 100%"/>
          </el-descriptions-item>

          <el-descriptions-item label="物资编号">
            <el-input :disabled="true" v-model="dataDetail.productCode" placeholder="物资编号"/>
          </el-descriptions-item>

          <el-descriptions-item label="物资名称">
            <el-input :disabled="true" v-model="dataDetail.productName" placeholder="物资名称"/>
          </el-descriptions-item>

          <el-descriptions-item label="规格">
            <el-input :disabled="true" v-model="dataDetail.productStandard" placeholder="物资规格"/>
          </el-descriptions-item>
          <el-descriptions-item label="型号">
            <el-input :disabled="true" v-model="dataDetail.productType" placeholder="物资型号"/>
          </el-descriptions-item>
          <el-descriptions-item label="品牌">
            <dictionary :disabled="true" v-model="dataDetail.productBrade" :place-holder="'请选择商品品牌'"
                        :dictionaries="productBrades"
                        dict-code="dictCode"
                        dict-name="dictName"/>
          </el-descriptions-item>
          <el-descriptions-item label="计量单位">
            <el-select :disabled="true" v-model="dataDetail.productUnitId">
              <el-option v-for="item in unitList" :key="item.id" :label="item.unitName" :value="item.id"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="价格">
            <el-input :disabled="true" v-model="dataDetail.buyPrice" placeholder="物资价格"/>
          </el-descriptions-item>
          <el-descriptions-item label="报废时间">
            <el-input :disabled="true" v-model="dataDetail.scrapTime" placeholder="报废时间"/>
          </el-descriptions-item>
        </el-descriptions>
      </long-form-panel>

      <long-form-panel label="物资图片">
        <el-form-item label="商品主图">
          <image-upload :disabled="true" v-model="dataDetail.titleImage" :limit="10" width="80px" height="80px"/>
        </el-form-item>
      </long-form-panel>

      <long-form-panel label="物资详情">
        <el-descriptions :column="3" border>
          <el-descriptions-item label="物资状态">
            <el-input v-model="wzStatus" :disabled="true" />
          </el-descriptions-item>
          <el-descriptions-item label="维保状态">
            <el-input v-model="wbStatus" :disabled="true" />
          </el-descriptions-item>
          <el-descriptions-item label="维保码">
            <el-input v-model="detail.originalCode" :disabled="true" />
          </el-descriptions-item>

          <el-descriptions-item label="入库时间">
            <el-date-picker style="width: 100%" v-model="detail.firstInstockTime" :disabled="true" />
          </el-descriptions-item>
          <el-descriptions-item label="供应商">
            <el-select :disabled="true" v-model="dataDetail.supplierId" placeholder="供应商" size="default"
                       style="width: 100%;">
              <el-option v-for="(item,idx) in supplierList" :key="idx" :label="item.supplierName" :value="item.id"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="维保单位">
            <el-input v-model="detail.maintainUnitName" :disabled="true" />
          </el-descriptions-item>

          <el-descriptions-item label="领用时间">
            <el-input :prefix-icon="Calendar" style="width: 100%" v-model="detail.lastUseDate" :disabled="true" />
          </el-descriptions-item>
          <el-descriptions-item label="使用机构">
            <el-input v-model="detail.recipientDepartName" :disabled="true" />
          </el-descriptions-item>
          <el-descriptions-item label="使用人">
            <el-input v-model="detail.recipientName" :disabled="true" />
          </el-descriptions-item>

          <el-descriptions-item label="上次维保">
            <el-date-picker style="width: 100%" v-model="detail.lastMtTime" :disabled="true" />
          </el-descriptions-item>
          <el-descriptions-item label="维保类型">
            <el-select :disabled="true" v-model="detail.mtType" size="default"
                       style="width: 100%;">
              <el-option v-for="(item,idx) in mtTypeList" :key="idx" :label="item.dictName" :value="item.dictCode"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="下次维保">
            <el-date-picker style="width: 100%" v-model="detail.nextMtTime" :disabled="true" />
          </el-descriptions-item>
        </el-descriptions>
      </long-form-panel>
    </long-form>
  </div>
</template>

<script>
import PageHeader from "@/components/h5/layout/PageHeader";
import LongForm from "@/components/h5/layout/LongForm";
import LongFormPanel from "@/components/h5/layout/LongFormPanel";
import productApi from "@/api/main/product/productApi";
import ImageUpload from "@/components/h5/image/ImageUpload";
import commonApi from "@/api/commonApi";
import Dictionary from "@/components/h5/Dictionary";
import mtLegderApi from "@/api/main/material/mtLegderApi";
import util from "@/util/util";
import {Calendar} from "@element-plus/icons-vue";
export default {
  name: "",
  computed: {
    Calendar() {
      return Calendar
    }
  },
  components: {
    LongForm, LongFormPanel, PageHeader, ImageUpload, Dictionary
  },
  data() {
    return {
      supplierList: [],
      dataDetail: {},
      productCategories: [],
      productBrades: [],
      unitList: [],
      detail: {},
      wzStatus: "",
      wbStatus: "",
      mtTypeList: [],
    };
  },
  watch:{
    'detail.wzType'(){
      if(this.detail.wzType === 0){
        this.wzStatus = "在库";
        return "在库";
      }else if(this.detail.wzType === 1){
        this.wzStatus = "使用";
        return "使用";
      }else if(this.detail.wzType === 2){
        this.wzStatus = "报废";
        return "报废";
      }else{
        this.wzStatus = "未知";
        return null;
      }
    },
    'detail.stockStatus'(){
      if(this.detail.stockStatus === 1){
        this.wbStatus = "正常";
        return "正常";
      }else if(this.detail.stockStatus === 2){
        this.wbStatus = "维修";
        return "维修";
      }else if(this.detail.stockStatus === 3){
        this.wbStatus = "正常";
        return "正常";
      }else if(this.detail.stockStatus === 4){
        this.wbStatus = "报废";
        return "报废";
      }else{
        this.wbStatus = "未知";
        return null;
      }
    }
  },
  created() {
    // 查询字典
    util.getUserInfo().then((user) => {
      commonApi.queryDictionaryList("MATERIAL_TYPE", user.merchantId).then(list => this.mtTypeList = list);
    });
    commonApi.querySupplierList().then(list => this.supplierList = list);
    if (this.$routerUtil.query.id) {
      this.findById(this.$routerUtil.query.id);
    }
    if(this.$routerUtil.query.code){
      mtLegderApi.queryList({searchKey: this.$routerUtil.query.code}).then(data => {
        this.detail = data.records[0];
        if(this.detail.wzType === 1){
          mtLegderApi.findLastUseDate(this.detail.originalCode).then(data => {
            if(data){
              this.detail.lastUseDate = data.substring(1,data.length - 1);
            }
          })
        }
      });
    }
    // 查询商品分类
    commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => this.productCategories = list);
    // // 商品品牌
    commonApi.queryDictionaryList("PRODUCT_BRADE").then(list => this.productBrades = list);
    productApi.queryUnits().then(list => {
      this.unitList = [...list];
    });
  },
  methods: {
    findById(id) {
      productApi.findById(id).then(data => {
        this.dataDetail = data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/form.scss";
@import "@/assets/css/theme/handle.scss";
@import "@/assets/css/components/description.scss";
@import "@/assets/css/components/table.scss";

</style>