<template>
  <!-- -->
  <list-page>
    <search-condition @search="resetSearch" :show-search="true">
      <el-input v-model="searchInfo.searchKey" placeholder="请输入门店名称"/>
      <template #option>
        <page-button type="write" el-type="success" @click="showEdit = true">新建</page-button>
      </template>
    </search-condition>
    <table-list>
      <el-table :data="dataList" height="100%" border>
        <el-table-column label="序号" width="60px" type="index" :align="'center'" fixed/>
        <el-table-column align="center" label="门店名称" min-width="250px" prop="storeName" show-overflow-tooltip/>
        <el-table-column align="center" label="返佣类型" min-width="250px" show-overflow-tooltip>
          <template v-slot="{row}">
            {{ row.type === 1 ? '毛利返佣' : '数量返利' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="返佣商品" min-width="200px" prop="productName" show-overflow-tooltip/>
        <el-table-column align="center" label="返佣规则" min-width="250px" prop="rebateName" show-overflow-tooltip/>
        <el-table-column label="操作" :align="'center'" fixed="right">
          <template v-slot="{row}">
            <table-button @click="toEdit(row)">编辑</table-button>
            <table-button @click="deleteRow(row)" type="delete" el-type="danger" message="确认删除吗？">删除
            </table-button>
          </template>
        </el-table-column>
      </el-table>
    </table-list>
    <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
               :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    <el-dialog title="编辑配置" width="40%" v-model="showEdit" @closed="() => detail = {}">
      <el-form label-width="70px">
        <el-form-item label="选择门店">
          <el-select v-model="detail.storeId" placeholder="请选择门店" clearable filterable>
            <el-option v-for="(item,idx) in storeList" :key="idx" :label="item.storeName" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="返佣类型">
          <el-select v-model="detail.type" placeholder="请选择返佣类型">
            <el-option label="毛利返佣" :value="1"/>
            <el-option label="数量返利" :value="2"/>
          </el-select>
        </el-form-item>
        <el-form-item label="指定商品" v-if="detail.type === 2">
          <product-list-select :small-btn="true" :query-list-api="ProductApi.queryList" :show-search="false"
                               @select="selectProduct" :btn-name="detail.productName ? detail.productName : '筛选'"/>
        </el-form-item>
        <el-form-item label="返佣规则">
          <div style="width: 100%;">
            <table-button @click="showSelectType = true">添加</table-button>
            <table-button @click="showRule = true" el-type="warning">快速选择</table-button>
          </div>
          <br/>
          <div v-for="(item,idx) in detail.detail" :key="idx" style="width: 100%;">
            <div v-if="item.type === 1" style="display: flex;flex-direction: row;align-items: center;letter-spacing: 4px;">
              <span>小于等于</span>
              <el-input type="number" size="small" style="width: 100px;height: 25px" v-model="item.less"/>
              <span>时,返佣</span>
              <el-input type="number" style="width: 100px;height: 25px" size="small" v-model="item.rebateRatio"/>
              <span style="font-weight: 600">%</span>
              <table-button el-type="delete" style="margin-left: 10px" @click="deleteOne(idx)" message="确认删除吗？">删除</table-button>
            </div>

            <div v-if="item.type === 2" style="display: flex;flex-direction: row;align-items: center;letter-spacing: 4px;">
              <span>大于</span>
              <el-input type="number" style="width: 100px;height: 25px" size="small" v-model="item.greater"/>
              <span>,且小于等于</span>
              <el-input type="number" size="small" style="width: 100px;height: 25px" v-model="item.less"/>
              <span>时,返佣</span>
              <el-input type="number" size="small" style="width: 100px;height: 25px" v-model="item.rebateRatio"/>
              <span style="font-weight: 600">%</span>
              <table-button el-type="delete" message="确认删除吗？" style="margin-left: 10px" @click="deleteOne(idx)">删除</table-button>
            </div>

            <div v-if="item.type === 3" style="display: flex;flex-direction: row;align-items: center;letter-spacing: 4px;">
              <span>大于</span>
              <el-input type="number" style="width: 100px;height: 25px" size="small" v-model="item.greater"/>
              <span>时,返佣</span>
              <el-input type="number" size="small" style="width: 100px;height: 25px" v-model="item.rebateRatio"/>
              <span style="font-weight: 600">%</span>
              <table-button el-type="delete" style="margin-left: 10px" message="确认删除吗？" @click="deleteOne(idx)">删除</table-button>
            </div>
          </div>
          <span style="font-size: 13px;color: #9b9999">注意：</span>
        </el-form-item>
      </el-form>
      <div style="display: flex;justify-content: flex-end">
        <page-button el-type="info" @click="showEdit = false">取消</page-button>
        <page-button type="write" el-type="success" @click="saveSubmit">保存</page-button>
      </div>
      <el-dialog title="添加类型" width="30%" v-model="showSelectType">
        <el-form label-width="70px">
          <el-form-item label="类型">
            <el-select v-model="addType" placeholder="请选择类型">
              <el-option label="小于" :value="1"/>
              <el-option label="小于且大于" :value="2"/>
              <el-option label="大于" :value="3"/>
            </el-select>
          </el-form-item>
          <div style="display: flex;justify-content: flex-end">
            <page-button el-type="primary" @click="toAdd">确认</page-button>
          </div>
        </el-form>
      </el-dialog>
    </el-dialog>

    <el-dialog title="返佣规则" width="50%" v-model="showRule" @opened="openBefore">
      <search-condition @search="ruleRestSearch" :show-search="true">
        <template #default>
          <el-input v-model="ruleSearch.searchKey" placeholder="请输入规则名称"/>
        </template>
      </search-condition>
      <dialog-table>
        <el-table height="100%" :data="ruleList">
          <el-table-column align="center" label="名称" width="150px" prop="name" show-overflow-tooltip/>
          <el-table-column align="center" label="返佣类型" width="150px" show-overflow-tooltip>
            <template v-slot="{row}">
              {{ row.type === 1 ? '毛利返佣' : '数量返利' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="返佣商品" min-width="200px" prop="productName" show-overflow-tooltip/>
          <el-table-column align="center" label="返佣规则" min-width="250px" prop="rebateName" show-overflow-tooltip/>
          <el-table-column align="center" label="操作" width="150px" fixed="right">
            <template v-slot="{row}">
              <table-button @click="toSelect(row)">选中</table-button>
            </template>
          </el-table-column>
        </el-table>
      </dialog-table>
      <form-page v-model:page-size="ruleSearch.pageSize" v-model:page-number="ruleSearch.pageNumber"
                 :total-count="ruleSearch.totalCount" :default-load="false"></form-page>
    </el-dialog>
  </list-page>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import rebateApi from "@/api/alpine/rebate/rebateApi";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import ProductApi from "@/api/main/product/productApi";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import commonApi from "@/api/commonApi";

export default {
  name: "",
  computed: {
    ProductApi() {
      return ProductApi
    }
  },
  components: {
    ProductListSelect,
    TableList, FormPage, TableButton,
    PageButton, SearchCondition, DateRange,
    ListPage, DialogTable
  },
  data() {
    return {
      searchInfo: {
        searchType: 2,
      },
      dataList: [],
      showEdit: false,
      detail: {
        detail: [],
      },
      addType: null,
      showSelectType: false,
      showRule: false,
      ruleSearch: {
        searchType: 1,
      },
      ruleList: [],
      storeList: [],
    };
  },
  created() {
    // 查询所有门店列表
    commonApi.queryStoreListNotCondition().then(res => this.storeList = res);
  },
  methods: {
    toSelect(data){
      data.id = this.detail.id;
      data.storeId = this.detail.storeId;
      this.detail = data;
      this.showRule = false;
    },
    ruleRestSearch(){
      this.ruleSearch.pageNumber = 1;
      this.openBefore();
    },
    openBefore(){
      rebateApi.queryList(this.ruleSearch).then(res => {
        this.ruleSearch.totalCount = res.totalCount;
        this.ruleList = res.records;
      })
    },
    selectProduct(list){
      if(list.length === 0 || list.length > 1){
        this.$message.error("未选择商品或选择商品数大于1");
        return;
      }
      const data = list[0];
      this.detail.productId = data.id;
      this.detail.productName = data.productName;
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      rebateApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    deleteRow(row) {
      rebateApi.delete(row.id);
    },
    toEdit(row) {
      this.detail = row;
      this.showEdit = true;
    },
    deleteOne(idx) {
      this.detail.detail.splice(idx, 1);
    },
    saveSubmit() {
      this.detail.dataType = 2;
      this.detail.detail.forEach(item => item.storeId = this.detail.storeId);
      rebateApi.saveSubmit(this.detail).then(() => {
        this.resetSearch();
        this.showEdit = false;
      })
    },
    toAdd() {
      this.detail.detail.push({
        type: this.addType,
      });
      this.addType = null;
      this.showSelectType = false;
    },
  }
};
</script>

<style lang="scss" scoped>

</style>