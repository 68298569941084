<template>
  <!-- 通用模板，列表数据 -->
  <div class="defaultViewStyle">
    <el-tabs v-model="activeName">
      <el-tab-pane name="warningList" label="库存预警" lazy>
        <warning-info/>
      </el-tab-pane>
      <el-tab-pane name="warningRule" label="预警规则" lazy>
        <warning-rule/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import WarningRule from "@/views/main/stock/warning/WarningRule";
import WarningInfo from "@/views/main/stock/warning/WarningInfo";
export default {
  name: "",
  components: {
    WarningRule, WarningInfo,
  },
  data() {
    return {
      activeName: "warningList",
    };
  },
  created() {
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {

    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/tabsPane.scss";
</style>