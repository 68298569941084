<template>
  <!-- -->
  <span v-for="(item,index) in infoList" :key="index">
    <span v-if="item.dictCode === value">{{ item.dictName }}</span>
  </span>
</template>

<script>

export default {
  name: "EchoName",
  props: {
    value: {
      type: [String, Number]
    },
    infoList: {default: () => []},

  },
  components: {},
  data() {
    return {
      // dataList: [],
    }
  },
  created() {
    // this.handleList();
  },
  methods: {
    //处理传入的List
    // handleList() {
    //   //遍历传入list，如果属性名中有包含name这个字符串的属性，则将它的值赋给name
    //   let list = this.infoList;
    //   for (let i = 0; i < list.length; i++) {
    //     for (let key in list[i]) {
    //       if (key.includes(this.nameColumn)) {
    //         list[i].name = list[i][key];
    //       }
    //     }
    //   }
    //   this.$nextTick(()=>{
    //     this.dataList = list;
    //   })
    // }
  }
};
</script>

<style lang="scss" scoped>

</style>