import axios from "../../../util/axios";

export default {
    /** 查询部门 */
    queryDepartList: () => axios.get({url: "/basedata/system/employee/queryDepartList"}),
    /** 保存部门 **/
    saveDepart: (data) => axios.post({url: "/basedata/system/employee/saveDepart", data: data}),
    /** 保存部门 **/
    deleteDepart: (id) => axios.del({url: "/basedata/system/employee/deleteDepart", data: {id}}),
    /** 保存职员 **/
    save: (data) => axios.post({url: "/basedata/system/employee/save", data: data}),
    /** 查询部门 */
    queryList: (data) => axios.get({url: "/basedata/system/employee/queryList", data: data}),
    /** 查询全部的角色 */
    queryAllRoles: () => axios.get({url: "/basedata/system/employee/queryAllRoles"}),
    /** 查询职员信息 */
    findById: (id) => axios.get({url: "/basedata/system/employee/findById", data: {id}}),
    /** 查询职员的角色 */
    deleteEmployee: (id) => axios.del({url: "/basedata/system/employee/delete", data: {id}}),
    /** 导出模板 */
    exportTemp: () => axios.get({url: "/basedata/system/employee/exportTemp", data: {}}),
    /** 按excel导入 */
    uploadEmployeesByTemplate: (data) => axios.post({url: "/basedata/system/employee/uploadEmployeesByTemplate", data: data}),
    /** 批量保存 */
    saveEmployeeList: (data) => axios.post({url: "/basedata/system/employee/saveEmployeeList", data: data}),

}