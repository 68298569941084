import axios from "../../../util/axios";
export default {
    // 查询订单列表
    queryList: (data) => axios.get({url: "/alpine/sale/order/queryList", data:data}),
    // 查询订单列表
    queryListWarranty: (data) => axios.get({url: "/alpine/sale/order/queryListWarranty", data:data}),
    // 提交单据
    saveSubmit: (data) => axios.post({url: "/alpine/sale/order/saveSubmit", data: data}),
    // 保存草稿信息
    saveDraft: (data) => axios.post({url: "/alpine/sale/order/saveDraft", data: data}),

    //查询门店信息
    queryStoreList:() => axios.get({url: "/alpine/sale/order/queryStoreList"}),

    //查询仓库信息
    queryAllStock: (data) => axios.get({url: "/alpine/sale/order/queryStockList", data: data}),

    queryCar:(id) => axios.get({url: "/alpine/sale/order/findCustomerById", data: {id}}),

//     查看详情
    findById:(id)=> axios.get({url: "/alpine/sale/order/findById", data: {id}}),

//     查看溯源码
    findByCode: (originalCode) => axios.get({url: "/alpine/sale/order/findProductByOriginalCode", data: {originalCode}}),
    // 查询商品库存中的sku列表，包涵溯源码
    queryStockProductsAndOriginal: (originalCode) => axios.get({url: "/alpine/sale/order/queryStockProductsAndOriginal", data: originalCode}),
// 删除
    delete: (id) => axios.del({url: "/alpine/sale/order/delete", data: {id}}),
    // 字典
    queryCarParts: () => axios.get({url: "/alpine/sale/order/queryCarParts"}),



}