<template>
  <!-- 通用模板，列表数据 -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
      </page-header>
      <search-condition>
        <date-range v-model:start-time="searchInfo.startTime" v-model:end-time="searchInfo.endTime"/>
        <div class="condition" v-if="merchantId === '10000'">
          <client-select v-model="searchInfo.merchantId"  :query-api="queryClientApi"/>
        </div>
        <div style="display: inline-block;" class="customer-select">
          <customer-select v-model="searchInfo.customerId" :name="searchInfo.customerName" @selectOne="setCustomer"/>
        </div>
        <el-input v-model="searchInfo.originalCode" placeholder="请输入溯源码"/>
      </search-condition>
      <table-list>
        <el-table :data="dataList" height="100%" border>
          <el-table-column label="序号" width="60px" type="index" :align="'center'" fixed/>
          <el-table-column label="客户名称" prop="customerName" min-width="100px" show-overflow-tooltip fixed/>
          <el-table-column label="商户名称" prop="merchantName" fixed show-overflow-tooltip min-width="150px" v-if="merchantId === '10000'"/>
          <el-table-column label="门店名称" prop="storeName" fixed show-overflow-tooltip min-width="150px" v-if="merchantId === '10000'"/>
          <el-table-column label="联系电话" prop="customerPhone" min-width="150px" show-overflow-tooltip :align="'center'"/>
          <el-table-column label="订单编号" prop="orderId" :align="'center'" width="200px"/>
          <el-table-column label="订单时间" prop="orderTime" :align="'center'" width="200px">
            <template v-slot="{row}">
              {{row.orderTime?row.orderTime.substr(0, 10): ""}}
            </template>
          </el-table-column>
          <el-table-column label="质保商品" prop="skuName" show-overflow-tooltip min-width="250px"/>
          <el-table-column label="车牌号" prop="carNo" width="150px" :align="'center'"/>
          <el-table-column label="质保时间" :align="'center'" width="250px">
            <template v-slot="{row}">
              <date-range :disabled="true" :start-time="row.warrantyStart" :end-time="row.warrantyEnd"/>
            </template>
          </el-table-column>
          <el-table-column label="溯源码" prop="skuOriginalCode" :align="'center'" width="200px"/>
          <el-table-column label="操作" :align="'center'" fixed="right" width="60px">
            <template v-slot="{row}">
              <table-button type="read" el-type="primary" @click="toShowDetail(row)">查看</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList" :default-load="false"></form-page>
    </list-page>
  </div>
  <el-dialog v-model="showDetail" title="质保详情">
    <dialog-table :auto-height="true">
      <el-descriptions :column="2" border>
        <el-descriptions-item label="客户名称" min-width="100px">{{currentData.customerName}}</el-descriptions-item>
        <el-descriptions-item label="联系电话" min-width="100px">{{currentData.customerPhone}}</el-descriptions-item>
        <el-descriptions-item label="订单编号">{{currentData.orderId}}</el-descriptions-item>
        <el-descriptions-item label="车牌号码">{{currentData.carNo}}</el-descriptions-item>
        <el-descriptions-item label="车辆型号">{{currentData.brandModelId}}</el-descriptions-item>
        <el-descriptions-item label="车架号码">{{currentData.frameNumber}}</el-descriptions-item>
        <el-descriptions-item label="施工门店">{{currentData.storeName}}</el-descriptions-item>
        <el-descriptions-item label="施工日期">{{currentData.orderTime? currentData.orderTime.substr(0, 10): ""}}</el-descriptions-item>
        <el-descriptions-item label="施工价格">
          <el-input v-if="changeWarrantyModel" v-model="currentData.skuSalePrice"/>
          <span v-else>{{currentData.skuSalePrice}}</span>
        </el-descriptions-item>
        <el-descriptions-item label="质保年限">
          <div v-if="changeWarrantyModel">
            <el-input style="width: 40%" v-model="currentData.warrantyPeriod"/>
            <el-input style="width: 40%" v-model="currentData.warrantyType"/>
          </div>
          <span v-else>{{currentData.warrantyPeriod}}{{ currentData.warrantyType }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="质保时间">
          <date-range :show-short-cut="false" :disabled="!changeWarrantyModel" v-model:start-time="currentData.warrantyStart" v-model:end-time="currentData.warrantyEnd"/>
        </el-descriptions-item>
        <el-descriptions-item label="产品型号">{{currentData.skuType}}</el-descriptions-item>
        <el-descriptions-item label="质保编号">{{currentData.skuOriginalCode}}</el-descriptions-item>
        <el-descriptions-item label="添加时间">{{currentData.createdTime}}</el-descriptions-item>
        <el-descriptions-item label="施工部位" :span="2">
          <el-checkbox-group v-model="currentData.constructionArr" :disabled="!changeWarrantyModel">
            <el-checkbox v-for="(c, i) in carPartList" :key="i" :value="c.dictCode" :label="c.dictName"/>
          </el-checkbox-group>
        </el-descriptions-item>
        <el-descriptions-item label="质保商品" :span="2">{{currentData.skuName}}</el-descriptions-item>
        <el-descriptions-item label="施工图片">
          <div v-if="currentData.carImage">
            <div class="construct-image-title">施工图片</div>
            <image-upload v-model="currentData.carImage" :disabled="!changeWarrantyModel"/>
          </div>
          <div v-else>
            <div class="construct-image-title">前方照片</div>
            <image-upload v-model="currentData.imageFront" :disabled="!changeWarrantyModel"/>
            <div class="construct-image-title">45度角照片</div>
            <image-upload v-model="currentData.imageBias" :disabled="!changeWarrantyModel"/>
            <div class="construct-image-title">后方照片</div>
            <image-upload v-model="currentData.imageBack" :disabled="!changeWarrantyModel"/>
            <div class="construct-image-title">质保码照片</div>
            <image-upload v-model="currentData.imageSkuCode" :disabled="!changeWarrantyModel"/>
            <div class="construct-image-title">商品包装+车辆照片</div>
            <image-upload v-model="currentData.imageSkuCar" :disabled="!changeWarrantyModel"/>
            <div class="construct-image-title">车辆与门头</div>
            <image-upload v-model="currentData.imageDoor" :disabled="!changeWarrantyModel"/>
          </div>
        </el-descriptions-item>
      </el-descriptions>
      <div>质保次数:{{currentWarrantyInfo.detail.length}} &nbsp;&nbsp;&nbsp;剩余百分比: {{((1-currentWarrantyInfo.warrantyRate) * 100 ).toFixed(0)}}%</div>
      <el-table :data="currentWarrantyInfo.detail" border>
        <el-table-column label="序号" width="60px" :align="'center'" type="index"/>
        <el-table-column label="售后时间" prop="warrantyTime" min-width="200px" :align="'center'"/>
        <el-table-column label="车辆部位" min-width="100px" prop="carPartName" :align="'center'"/>
        <el-table-column label="工时费" prop="handicraftCost" min-width="100px" :align="'right'">
          <template v-slot="{row}">
            <money :value="row.handicraftCost"/>
          </template>
        </el-table-column>
        <el-table-column label="百分比" min-width="100px" :align="'center'">
          <template v-slot="{row}">{{(row.warrantyRate * 100).toFixed(0)}}%</template>
        </el-table-column>
        <el-table-column label="类型" min-width="60px" :align="'center'">
          <template v-slot="{row}">
            <span v-if="row.warrantyType === 1">保内</span>
            <span v-else>保外</span>
          </template>
        </el-table-column>
        <el-table-column label="售后人员" prop="employeeName" min-width="100px" :align="'center'" show-overflow-tooltip/>
        <el-table-column label="门店名称" prop="storeName" min-width="150px" show-overflow-tooltip/>
        <el-table-column label="施工图片" min-width="300px" show-overflow-tooltip>
          <template v-slot="{row}">
            <image-upload v-model="row.warrantyImage" :disabled="true" width="60px" height="45px"/>
          </template>
        </el-table-column>
      </el-table>
    </dialog-table>
    <div style="text-align: right;padding-top: 10px">
      <page-button el-type="primary" type="update" @click="changeWarrantyModel = !changeWarrantyModel">变更质保时间</page-button>
      <page-button :disabled="!changeWarrantyModel" el-type="success" type="update" @click="saveWarrantyChange">提交</page-button>
      <page-button el-type="primary" @click="showDetail = false">确定</page-button>
    </div>
  </el-dialog>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import warrantyApi from "@/api/alpine/shop/warrantyApi";
import DateRange from "@/components/h5/date/DateRange.vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import ImageUpload from "@/components/h5/image/ImageUpload.vue";
import util from "@/util/util";
import Money from "@/components/h5/Money.vue";
import ClientSelect from "@/components/h5/customer/ClientSelect.vue";
import commonApi from "@/api/commonApi";
export default {
  name: "",
  components: {
    ClientSelect,
    Money, TableButton, PageButton, ListPage, TableList, FormPage, SearchCondition, PageHeader, CustomerSelect, DateRange, DialogTable, ImageUpload
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      currentData: {},
      showDetail: false,
      merchantId: "",
      currentWarrantyInfo: {},
      carPartList: [],
      // 变更质保时间
      changeWarrantyModel: false,
      queryClientApi: null,
    };
  },
  created() {
    util.getUserInfo().then(user => {
      this.merchantId = user.merchantId;
      this.queryList();
      this.queryClientApi = commonApi.queryMerchantList;
    });
    this.searchInfo.startTime = new Date(new Date().getTime() - 7 * 24 * 3600 * 1000).format('yyyy-MM-dd') + " 00:00:00";
    this.searchInfo.endTime = new Date().format('yyyy-MM-dd') + " 23:59:59";
    if (this.$routerUtil.query.customerId) {
      this.searchInfo.customerId = this.$routerUtil.query.customerId;
      this.searchInfo.customerName = this.$routerUtil.query.customerName;
    }
    // 查询车辆部位
    warrantyApi.queryCarParts().then(list => {
      this.carPartList = list;
    });
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      if (this.merchantId === "10000") {
        warrantyApi.queryList4Server(this.searchInfo).then(res => {
          this.dataList = res.records;
          this.searchInfo.totalCount = res.totalCount;
        })
      } else {
        warrantyApi.queryList(this.searchInfo).then(res => {
          this.dataList = res.records;
          this.searchInfo.totalCount = res.totalCount;
        })
      }

    },
    setCustomer(c) {
      this.searchInfo.customerId = c.id;
      this.searchInfo.customerName = c.customerName;
    },
    toShowDetail(data) {
      this.currentData = {
        ...data,
        constructionArr: data.construction?data.construction.split(","): [],
      };
      this.showDetail = true;
      this.changeWarrantyModel = false;
      // 查询保修数据
      warrantyApi.queryWarrantyInfo(data.id).then(res => {
        this.currentWarrantyInfo = res;
      });
    },
    saveWarrantyChange() {
      if (!this.currentData.warrantyStart || !this.currentData.warrantyEnd) {
        this.$message.error("请选择质保时间");
        return;
      }
      // 施工部位，整理数据
      let data = {
        ...this.currentData,
        construction: this.currentData.constructionArr.join(","),
      }
      warrantyApi.changeWarrantyInfo(data).then(() => {
        this.$message.success("质保信息已更新成功");
        this.queryList();
        this.showDetail = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/description";
</style>