<template>
  <!-- 字典，列表结构 -->
  <list-page>
    <search-condition @search="resetSearch" :show-search="true">
      <el-input v-model="searchInfo.searchKey" placeholder="请输入字典名称"/>
      <template #option>
        <page-button el-type="success" type="write" :disabled="!editAble" icon="el-icon-plus" @click="toEditData({})">新建</page-button>
      </template>
    </search-condition>
    <table-list>
      <el-table height="100%" border :data="dataList">
        <el-table-column align="center" label="序号" type="index" width="60px"/>
        <el-table-column align="center" label="字典名称" prop="dictName"/>
        <el-table-column align="center" label="字典编码" prop="dictCode"/>
        <el-table-column label="图片" v-if="dictionSetting.imageType === 1" :align="'center'">
          <template v-slot="{row}">
            <image-upload :disabled="true" v-model="row.imageBackground" width="60px" height="60px"/>
          </template>
        </el-table-column>
<!--        <el-table-column label="前景图片" v-if="dictionSetting.imageType === 1" :align="'center'">-->
<!--          <template v-slot="{row}">-->
<!--            <image-upload :disabled="true" v-model="row.imageInfront" width="60px" height="60px"/>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column label="操作" :align="'center'">
          <template v-slot="{row}">
            <table-button :disabled="inStockDic.includes(row.dictCode) || outStockDic.includes(row.dictCode) || !editAble" type="write" @click="toEditData(row)">编辑</table-button>
            <table-button :disabled="inStockDic.includes(row.dictCode) || outStockDic.includes(row.dictCode) || !editAble" type="write" el-type="danger" message="是否确认删除此数据?" @click="toDelete(row)">删除</table-button>
          </template>
        </el-table-column>
      </el-table>
    </table-list>
    <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize" :total-count="searchInfo.totalCount" @onLoad="queryList" :default-load="false"/>
  </list-page>
  <el-dialog title="编辑字典" width="400px" v-model="showDialog">
    <el-form label-width="100px">
      <el-form-item label="字典名称">
        <el-input v-model="currentData.dictName"/>
      </el-form-item>
      <el-form-item label="字典编码">
        <el-input v-model="currentData.dictCode" disabled/>
      </el-form-item>
      <el-form-item label="图片"  v-if="dictionSetting.imageType === 1">
        <image-upload v-model="currentData.imageBackground" :limit="1" :upload-tips="`图片尺寸: ${dictionSetting.imageSize}`" folder="dictionary"/>
      </el-form-item>
      <el-form-item label="详情图片" v-if="dictionSetting.imageType === 1">
        <image-upload v-model="currentData.imageInfront" :limit="1" folder="dictionary"/>
      </el-form-item>
    </el-form>
    <div style="text-align: right">
      <page-button @click="showDialog = false">取消</page-button>
      <page-button el-type="primary" @click="saveData" type="write">保存</page-button>
    </div>
  </el-dialog>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import dictionaryApi from "@/api/main/sys/dictionaryApi";
import TableButton from "@/components/h5/button/TableButton";
import PageButton from "@/components/h5/button/PageButton";
import ImageUpload from "@/components/h5/image/ImageUpload";
import config from "@/config/config";
import util from "@/util/util";
export default {
  props: {
    dictionSetting: {
      default: () => {
        return {
          dictCode: "",
          dictName: "",
          imageType: 0,
          imageBackground: "",
          imageInfront: "",
          // 0:不可编辑，1:可编辑
          editAble: 0,
        }
      }
    }
  },
  name: "",
  components: {
    PageButton,
    TableButton,
    ListPage, SearchCondition, TableList, FormPage, ImageUpload
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      showDialog: false,
      currentData: {},
      inStockDic: [],
      outStockDic: [],
      editAble: true,
      userInfo: {},
    };
  },
  created() {
    this.searchInfo.type = this.dictionSetting.dictCode;
    util.getUserInfo().then(user => {
      this.userInfo = user;
      if (this.dictionSetting.editAble === 0) {
        // 不可编辑，此时查询平台配置的信息
        this.searchInfo.merchantId = "10000";
        this.editAble = user.merchantId === "10000";
      }
      this.resetSearch();
    })
    this.inStockDic = config.inStock;
    this.outStockDic = config.outStock;
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      dictionaryApi.queryList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      })
    },
    toEditData(data) {
      this.currentData = {...data};
      this.showDialog = true;
    },
    saveData() {
      dictionaryApi.save({
        ...this.currentData,
        type: this.dictionSetting.dictCode
      }).then(() => {
        this.queryList();
        this.showDialog = false;
      });
    },
    toDelete(data) {
      dictionaryApi.deleteById(data.id).then(() => this.queryList());
    }
  }
};
</script>

<style lang="scss" scoped>

</style>