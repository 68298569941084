import axios from "../../../util/axios";

export default {
    /** 开通哪些服务 */
    queryAllService: () => axios.get({url: "/basedata/system/service/queryAllService"}),
    /** 查询全部功能 */
    queryAllModules: (type) => axios.get({url: "/basedata/system/service/queryAllModules", data: {type}}),
    /** 查询服务已开通的功能 */
    queryServiceGranted: (serviceId) => axios.get({url: "/basedata/system/service/queryServiceGranted", data: {serviceId}}),
    /** 查询服务已开通的功能 */
    deleteModuleService: (moduleId, serviceId) => axios.del({url: "/basedata/system/service/deleteModuleService", data: {moduleId,serviceId}}),
    /** 为服务开通功能 */
    saveModuleService: (data) => axios.post({url: "/basedata/system/service/saveModuleService", data: data}),
}