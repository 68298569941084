// 微服务加载声明路由
import {addGlobalUncaughtErrorHandler, registerMicroApps, start} from "qiankun";
import Config from "@/config/config";
import {ref, onMounted} from "vue";

registerMicroApps([
    {
        name: 'alpine',
        entry: Config.routerLocation.alpine,
        container: '#remoteView',
        activeRule: '#/alpine',
    },
    // {
    //     name: 'fylwz',
    //     entry: Config.routerLocation.fylwz,
    //     container: '#remoteView',
    //     activeRule: 'fylwz',
    // },
]);


// 获取异常
addGlobalUncaughtErrorHandler((event) => {
    if(typeof event !== 'string') {
        const mircoAppName = event?.error?.appOrParcelName
        if(mircoAppName && event.type === 'error') {
            console.error(`${mircoAppName}应用加载失败`)
        }
    }
})

const isCollapsed = ref(false);
const targetEl = ref(null);
const routerArray = ref([]);

onMounted(() => {
    if (!window.qiankunStarted) {
        window.qiankunStarted = true;
        start();
    }
})
