<template>
  <!-- -->
  <page-button v-if="showBtn" @click="showSub">客房预订</page-button>
  <el-dialog v-model="showSubscribe" title="客房预订" width="40%" @closed="detail = {}">
    <el-form label-width="70px">
      <el-form-item label="客房类型">
        <el-select v-model="detail.typeId" placeholder="请选择客房类型" style="width: 100%">
          <el-option v-for="item in typeList" :key="item.id" :label="item.typeName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="入住时间">
        <el-date-picker ref="dataPicker" @blur="checkDate" v-model="detail.startTime" type="date" placeholder="选择日期" style="width: 100%"></el-date-picker>
      </el-form-item>
      <el-form-item label="退房时间">
        <el-date-picker v-model="detail.endTime" type="date" placeholder="选择日期" style="width: 100%"></el-date-picker>
      </el-form-item>
    </el-form>
    <el-dialog title="预订详情" v-model="showDetailDialog" width="60%">
        <span style="font-size: 15px">类型为
          <b>
            {{ curTypeName }}
          </b>
          的客房共
          <b>
            {{ roomList.length }}
          </b>
          间，当前已预定
          <b>
            {{ subList.length }}
          </b>
          间，
          <span v-if="roomList.length >= subList.length">未订<b>
            {{ roomList.length - subList.length }}
          </b>
          间</span>
          <span v-else>
            预订客房数已超过客房总数，共超过<b>{{ subList.length - roomList.length }}</b>间
          </span>
        </span>
      <dialog-table>
        <el-table :data="subList" height="100%" border>
          <el-table-column align="center" label="序号" type="index" width="60px"/>
          <el-table-column align="center" label="订单编号" min-width="100px" show-overflow-tooltip prop="orderId">
            <template v-slot="{row}">
              <span>{{ row.orderId }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="120px" label="客户名称" prop="customerName"/>
          <el-table-column align="center" min-width="100px" show-overflow-tooltip label="联系电话" prop="customerPhone"/>
          <el-table-column align="center" min-width="150px" label="预订时间">
            <template v-slot="{row}">
              <span>{{ row.startTime.split(" ")[0] }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="150px" label="退房时间">
            <template v-slot="{row}">
              <span>{{ row.endTime.split(" ")[0] }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="100px" label="状态" >
            <template v-slot="{row}">
              <span v-if="row.status === 1">预约</span>
              <span v-if="row.status === 2">已入住</span>
              <span v-if="row.status === 3">已完成</span>
              <span v-if="row.status === -1">已废弃</span>
            </template>
          </el-table-column>
        </el-table>
      </dialog-table>
    </el-dialog>
    <div style="display: flex; justify-content: space-between">
      <el-checkbox v-model="isAutoShow" label="自动展开预约详情" size="large"/>
      <div>
        <page-button el-type="primary" :disabled="!detail.startTime" @click="showDetail">预订详情</page-button>
        <page-button el-type="info" @click="showSubscribe = false">取消</page-button>
        <page-button el-type="primary" @click="save" v-if="showSave">确认</page-button>
        <page-button el-type="primary" @click="createdOrder" v-else>创建订单</page-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>

import PageButton from "@/components/h5/button/PageButton.vue";
import {watch} from "vue";
import houseTypeApi from "@/api/main/house/houseTypeApi";
import houseDocApi from "@/api/main/house/houseDocApi";
import houseSubscribeApi from "@/api/main/house/houseSubscribeApi";
import commonApi from "@/api/commonApi";
import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
export default {
  emits: ["getSubId"],
  props: {
    productId: {default: ""},
    productName: {default: ""},
    customerId: {default: ""},
    showBtn: {default: true},
    checkInfo: {default: true},
    orderId: {default: ""},
  },
  name: "",
  components: {
    TableButton, DialogTable,
    PageButton,
  },
  data() {
    return {
      isAutoShow: false,
      customerIdDb: "",
      showSubscribe: false,
      detail:{},
      typeList: [],
      day: null,
      subList: [],
      roomCount: null,
      roomList: [],
      showDetailDialog: false,
      showSave: false,
      curTypeName: "",
    };
  },
  watch: {
  },
  created() {
    watch(() => this.productId, () => this.handlerProduct());
    // 客房类型
    houseTypeApi.queryAllList({searchType: 1}).then(list => this.typeList = list);
    // 获取时间配置
    commonApi.findSetting("ROOM_TIME").then(setting => {
      if(setting.settingValue){
        this.day = Number(setting.settingValue);
      }
    });
  },
  methods: {
    findTypeName(){
      let idx = this.typeList.findIndex(item => item.id === this.detail.typeId);
      this.curTypeName = this.typeList[idx].typeName;
    },
    createdOrder(){
      if(!this.detail.typeId){
        this.$message.error("请选择客房类型！");
        return;
      }
      // 获取对应的房间类型
      houseDocApi.queryAllList({searchKey: this.detail.typeId}).then(list => {
        if(list.length === 0){
          this.$message.error("当前类型暂未绑定客房！");
          return;
        }
        let roomData = list[0];
        this.$routerUtil.push(`/report/offline-order/detail?productId=${roomData.productId}`);
      })
    },
    queryRoom(){
      return houseDocApi.queryAllList({searchKey: this.detail.typeId}).then(list => this.roomList = list);
    },
    // 检查时间信息
    checkDate(isChange = true){
      if(!this.detail.typeId){
        this.$message.error("请先选择客房类型！");
        this.detail.startTime = null;
        return;
      }
      if(isChange){
        let dateArr = this.detail.startTime.format("yyyy-MM-dd").split("-");
        this.detail.endTime = new Date(Number(dateArr[0]), Number(dateArr[1]) - 1, Number(dateArr[2]) + 28);
      }
      if(this.isAutoShow){
        this.showDetail();
      }
      // 获取当前间隔时间段的所有订单信息
      houseSubscribeApi.queryList({startTime: this.detail.startTime, searchKey: this.day}).then(list => {
        if(this.detail.id){
          // 删除自己的
          list.splice(list.findIndex(item => item.id === this.detail.id),1);
        }
        // 删除废弃和已完成的
        this.subList = list.filter(item => item.status !== -1 && item.status !== 3);
        this.queryRoom().then(() => {
          if(this.subList.length >= this.roomList.length){
            this.$message.error("当前时间段，暂无空房！");
          }
        })
      });
    },
    showDetail(){
      this.findTypeName();
      this.showDetailDialog = true;
    },
    handlerProduct(){
      if(!this.productId){
        return;
      }
      // 根据商品id，获取这个商品属于哪个客房类型
      houseDocApi.queryAllList({searchKey: this.productId}).then(data =>{
        this.roomCount = data.length;
        this.detail.typeId = data[0].typeId;
      })
    },
    showSub(id, isCheck = false, customerId, typeId, showSave = true){
      this.showSave = showSave;
      if(typeId){
        this.detail.typeId = typeId;
      }
      if(customerId){
        this.customerIdDb = customerId;
      }else{
        this.customerIdDb = null;
      }
      if(!this.customerId && isCheck){
        this.$message.error("请先选择客户");
        return;
      }
      if(!this.productId && isCheck){
        this.$message.error("请先选择商品");
        return;
      }
      if(!this.detail.typeId && this.productId){
        this.handlerProduct();
      }
      if(id){
        houseSubscribeApi.findById(id).then(data => {
          this.detail = data;
          this.checkDate(false);
        });
      }
      this.showSubscribe = true;
    },
    save(){
      if(this.customerId){
        this.detail.customerId = this.customerId;
      }
      if(this.customerIdDb){
        this.detail.customerId = this.customerIdDb;
      }
      houseSubscribeApi.save(this.detail).then(data => {
        this.showSubscribe = false;
        if(this.orderId){
          // 需要单独增加附件id
          offlineOrderApi.addAttachment({
            orderId: this.orderId,
            dataId: data.id,
            dataType: 2
          })
        }
        this.$emit("getSubId", data.id);
      });
    }
  }
};
</script>

<style lang="scss" scoped>

</style>