<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header/>
      <table-list>
        <el-descriptions :column="2" border >
          <el-descriptions-item label="门店名称" label-class-name="require">
            <el-input v-model="storeInfo.storeName" placeholder="请输入门店名称"/>
          </el-descriptions-item>
          <el-descriptions-item label="负责人" label-class-name="require">
            <el-input v-model="storeInfo.storeManager" placeholder="请输入负责人姓名"/>
          </el-descriptions-item>
          <el-descriptions-item label="手机号" label-class-name="require">
            <el-input v-model="storeInfo.storeMobile" placeholder="请输入手机号码"/>
          </el-descriptions-item>
          <el-descriptions-item label="门店地址">
            <el-input v-model="storeInfo.storeAddress"/>
          </el-descriptions-item>
          <el-descriptions-item label="区域编码">
            <span style="margin-right: 15px">{{ storeInfo.regionCode }}</span>
            <table-button @click="getRegionCode">获取</table-button>
          </el-descriptions-item>
          <el-descriptions-item label="门店授权码" :span="2">
            {{storeInfo.grantNumber}}
          </el-descriptions-item>
          <el-descriptions-item label="抬头照" :span="2">
            <image-upload v-model="storeInfo.titleImage" :limit="3" folder="store"/>
          </el-descriptions-item>
          <el-descriptions-item label="定位坐标" :span="2">{{storeInfo.storeCoordsLng}},{{storeInfo.storeCoordsLat}}</el-descriptions-item>
          <el-descriptions-item label="定位" :span="2">
            <tx-map :lat="storeInfo.storeCoordsLat" :lng="storeInfo.storeCoordsLng" :address="storeInfo.storeAddress" @getLocation="mapGetLocation"/>
          </el-descriptions-item>
          <el-descriptions-item label="宣传资料" :span="2">
            <rich-text v-model="storeInfo.storeInfo" folder="store/info"/>
          </el-descriptions-item>
        </el-descriptions>
      </table-list>
      <form-page :show-page="false">
        <page-button el-type="success" type="write" @click="saveData">保存</page-button>
      </form-page>
    </list-page>
  </div>

</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import ImageUpload from "@/components/h5/image/ImageUpload";
import RichText from "@/components/h5/richText/RichText";
import TxMap from "@/components/h5/map/TxMap2.vue";
import storeApi from "@/api/alpine/merchant/storeApi";
import PageHeader from "@/components/h5/layout/PageHeader.vue";

export default {
  name: "",
  components: {
    PageButton,
    ListPage, TableList, FormPage, ImageUpload, RichText, TxMap, PageHeader
  },
  data() {
    return {
      storeInfo: {
        storeName: "",
        titleImage: "",
        grantNumber: "",
        storeCoordsLat: "",
        storeAddress: "",
        storeInfo: "",
        storeMobile: "",
        storeCoordsLng:"",
        storeManager: "",

      }
    };
  },
  created() {
  },
  mounted() {
    if (this.$routerUtil.query.id) {
      this.findById(this.$routerUtil.query.id);
    }
  },
  methods: {
    getRegionCode(){
      if(this.storeInfo.storeCoordsLng && this.storeInfo.storeCoordsLat){
        storeApi.findRegionCode(this.storeInfo.storeCoordsLng,this.storeInfo.storeCoordsLat).then(res => this.storeInfo.regionCode = res);
      }else{
        this.$message.error("请先获取当前定位地址");
      }
    },
    findById(id) {
      storeApi.findById(id).then(data => this.storeInfo = data);
    },
    saveData() {
      let data = {
        ...this.storeInfo
      };
      if (!data.storeName) {
        this.$message.error("请输入门店名称");
        return;
      }
      if (!data.storeManager) {
        this.$message.error("请输入负责人姓名");
        return;
      }
      if (!data.storeMobile) {
        this.$message.error("请输入手机号码");
        return;
      }
      storeApi.save(this.storeInfo).then(data => this.storeInfo = data);
    },
    handleDataFromChild(data) {
      // 在这里处理从子组件传递过来的数据
      //console.log(data); // 输出：{ message: '这是来自子组件的数据' }
       this.storeInfo.storeAddress = data.address
      // 在这里可以调用父组件的其他方法，或者更新父组件的数据
      this.storeInfo.storeCoordsLat = data.lat
      this.storeInfo.storeCoordsLng = data.lng
    },
    mapGetLocation({lat, lng, address}) {
      this.storeInfo.storeAddress = address;
      this.storeInfo.storeCoordsLat = lat;
      this.storeInfo.storeCoordsLng = lng;
    }

  }

};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/description";
:deep(.el-descriptions__label.require:before) {
  content: "*";
  font-size: 13px;
  color: #F56C6C;
  padding-right: 4px;
}
</style>