<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <el-empty v-if="dictionaryTypes.length === 0"/>
      <el-tabs v-model="activeName" v-else>
        <el-tab-pane v-for="(dic, i) in dictionaryTypes" :key="i" :name="dic.dictCode" :label="dic.dictName" style="height: 100%" lazy>
          <dictionary-list v-if="dic.treeType === 0" :diction-setting="dic"/>
          <dictionary-tree v-else  :diction-setting="dic"/>
        </el-tab-pane>
      </el-tabs>
    </list-page>
  </div>

</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import dictionaryApi from "@/api/main/sys/dictionaryApi";
import DictionaryList from "@/views/main/plat/system/dictionary/DictionaryList";
import DictionaryTree from "@/views/main/plat/system/dictionary/DictionaryTree";
export default {
  name: "",
  components: {
    ListPage, DictionaryList, DictionaryTree
  },
  data() {
    return {
      activeName: "",
      dictionaryTypes: [],
    };
  },
  created() {
    // 查询字典分类
    dictionaryApi.queryDictionaryTypes().then(list => {
      this.dictionaryTypes = list;
      //如果有多个菜单，选中选一个
      if (list.length > 0) {
        this.activeName = list[0].dictCode;
      }
    });
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/tabsPane.scss";
</style>