<template>
  <!-- -->
  <div class="update-price">
    <div v-if="isShow" class="product-title">{{ currentProduct.skuName }}</div>
    <div v-if="isShow">条码:{{ currentProduct.barCode }}</div>
    <div v-if="isShow">规格:{{ currentProduct.skuStandard }}</div>
    <div v-if="isShow">单位:{{ currentProduct.skuUnitName }}</div>
    <div :class="isShow ? 'product-separator' : 'product-separator-no-border'">
      <div :class="isShow ? 'product-info' : 'product-info-is-show' ">
        <div v-if="isShow">
          <span>原价</span>
          <span><money :value="currentProduct.skuOriginalPrice"/></span>
        </div>
        <div>
          <span>现价</span>
          <span>
              <el-input @focus="changeFocus('skuSalePrice')"
                        :class="currentEditColumn === 'skuSalePrice'? 'current-input': ''"
                        v-model="currentProduct.skuSalePrice" style="text-align: right"/>
            </span>
        </div>
        <div>
          <span>数量</span>
          <span>
              <el-input @focus="changeFocus('qty')"
                        :class="currentEditColumn === 'qty'? 'current-input': ''"
                        v-model="currentProduct.qty" style="text-align: right" :align="'right'"/>
            </span>
        </div>
        <div>
          <span style="font-weight: 600">小计</span>
          <span><money :value="currentProduct.skuSalePrice * currentProduct.qty"/></span>
        </div>
        <div>
          <page-button v-if="isShow" el-type="danger" @click="$emit('deleteRow', currentProduct.skuId)">删除
          </page-button>
          <page-button el-type="primary" style="width: 100%;" @click="currentProduct.skuSalePrice = 0">赠送
          </page-button>
        </div>
      </div>
      <div>
        <soft-key-bord style="padding: 10px;line-height: 40px" v-model="editNumber"/>
      </div>
    </div>
  </div>
  <!-- 分割 -->
  <el-divider v-if="!isShow"/>
  <div style="text-align: center;padding-top: 10px">
    <page-button el-type="info" @click="$emit('cancel')">取消</page-button>
    <page-button el-type="primary" @click="submit">确定</page-button>
  </div>
</template>

<script>
import SoftKeyBord from "@/components/h5/button/SoftKeyBord.vue";
import Money from "@/components/h5/Money.vue";
import {watch} from "vue";

export default {
  // 更新数据
  emits: ["confirm", "cancel", "deleteRow"],
  props: {
    value: {
      default: () => {
      }
    },
    // 控制显示
    isShow: {default: true},
  },
  name: "UpdatePrice",
  components: {
    SoftKeyBord, Money
  },
  data() {
    return {
      currentProduct: {},
      // 软键盘操作的数据
      editNumber: "",
      currentEditColumn: "",
    };
  },
  created() {
    this.init();
    watch(() => this.value, () => this.init(), {deep: true});
    // 软键盘操作，映射回来
    watch(() => this.editNumber, () => {
      this.softKeyChange();
    });
  },
  methods: {
    init() {
      this.currentProduct = {...this.value};
        this.changeFocus("skuSalePrice");
    },
    submit() {
      this.$emit("confirm", this.currentProduct);
    },
    // 软键盘操作
    softKeyChange() {
      this.currentProduct[this.currentEditColumn] = this.editNumber;
    },
    changeFocus(column) {
      this.currentEditColumn = column;
      this.editNumber = this.currentProduct[column];
    }
  }
};
</script>

<style lang="scss" scoped>
// 改价功能样式
.update-price {
  line-height: 26px;
  font-size: 14px;
  user-select: none;

  .product-title {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    line-height: 40px;
  }

  .product-separator {
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    display: flex;
    justify-content: space-between;

    .product-info {
      width: 200px;
      line-height: 35px;

      > div {
        display: flex;
        justify-content: space-between;
        text-align: left;

        > * {
          min-width: 60px;
        }

        > *:last-child {
          text-align: right;
        }

        .page-button {
          width: 45%;

          :deep(.el-button) {
            width: 100%;
          }
        }
      }

      :deep(.el-input__inner) {
        text-align: right;
      }
    }

    .soft-key-bord {
      width: 300px;
      height: 190px;
    }
  }

  .product-separator-no-border {
    display: flex;
    justify-content: space-between;

    .product-info-is-show {
      width: 200px;
      line-height: 45px;
      margin-top: 2px;

      > div {
        display: flex;
        justify-content: space-between;
        text-align: left;

        > * {
          min-width: 60px;
        }

        > *:last-child {
          text-align: right;
        }

        .page-button {
          width: 45%;

          :deep(.el-button) {
            width: 100%;
          }
        }
      }

      :deep(.el-input__inner) {
        text-align: right;
      }
    }

    .soft-key-bord {
      width: 300px;
      height: 190px;
    }
  }
}

.current-input {
  :deep(.el-input__wrapper) {
    border-color: #409eff;
    box-shadow: 0 0 0 1px #409eff inset;
  }
}
</style>