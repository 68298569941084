import axios from "../../../util/axios";

export default {
    // 获取所有的支付方式列表
    queryPayList:(data) => axios.get({url: "/order/common/payInfo/queryPayList",data: data}),
    // 添加支付信息
    saveSubmit:(data) => axios.post({url: "/order/common/payInfo/saveSubmit",data:data}),
    // 信息回显编辑
    findById:(id) => axios.get({url: "/order/common/payInfo/findById",data:{id}}),
    // 获取对接的支付方式
    queryInterfaceList:() => axios.get({url: "/order/common/payInfo/queryInterfaceList"}),
    // 获取对接的支付模版
    findPayTemplate:(payCode) => axios.get({url: "/order/common/payInfo/findPayTemplate",data: {payCode}}),
    // 接口信息回显编辑
    findInterfaceById:(id) => axios.get({url: "/order/common/payInfo/findInterfaceById",data: {id}}),
    // 保存接口信息
    saveInterfaceInfo:(data) => axios.post({url: "/order/common/payInfo/saveInterfaceInfo",data: data}),
    // 删除
    deletePayType:(id) => axios.get({url: "/order/common/payInfo/deletePayType",data: {id}}),
    // 获取所有支付方式
    queryAllList:() => axios.get({url: "/order/common/payInfo/queryAllList"}),
    // 根据type查询
    findByType: (payType) => axios.get({url: "/order/common/payInfo/findByType",data: {payType}}),
    // 根据type查询
    queryByType: (payType) => axios.get({url: "/order/common/payInfo/queryByType",data: {payType}})
}