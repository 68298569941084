import axios from "../../../util/axios";

export default {
    /** 查询全部功能权限 */
    queryAccessRight: () => axios.get({url: "/basedata/plat/menu/queryAccessRight"}),
    /** 查询菜单，返回树 */
    queryModuleTree: () => axios.get({url: "/basedata/plat/menu/queryModuleTree"}),
    /** 保存菜单 */
    saveModule: (data) => axios.post({url: "/basedata/plat/menu/saveModule", data: data}),
    /** 删除 */
    delete: (id) => axios.del({url: "/basedata/plat/menu/delete", data: {id}}),

}