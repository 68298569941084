<template>
  <!-- 通用模板，列表数据 -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
        <page-button el-type="success" @click="toShowEdit({})" type="write">新建</page-button>
      </page-header>
      <table-list>
        <el-table :data="dataList" height="100%" border>
          <el-table-column label="序号" width="60px" type="index" :align="'center'"/>
          <el-table-column label="部位" :align="'center'">
            <template v-slot="{row}">
              <span v-for="(d,i) in dictionaryList" :key="i">
                <span v-if="d.dictCode === row.carPart">{{d.dictName}}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="百分比" :align="'center'">
            <template v-slot="{row}">{{(row.warrantyRate * 100).toFixed(0)}}%</template>
          </el-table-column>
          <el-table-column label="工时费" prop="handicraftCost" :align="'center'"/>
          <el-table-column label="操作" :align="'center'">
            <template v-slot="{row}">
              <table-button @click="toShowEdit(row)" type="update">编辑</table-button>
              <table-button message="是否确认删除此数据" type="delete" el-type="danger" @click="deleteData(row)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>
  </div>
  <el-dialog v-model="showDialog" title="保修设置" width="30%" :close-on-click-modal="false">
    <el-form label-width="70px">
      <el-form-item label="车辆部位">
        <el-select v-model="currentData.carPart">
          <el-option v-for="(c,i) in dictionaryList" :label="c.dictName" :value="c.dictCode" :key="i"/>
        </el-select>
      </el-form-item>
      <el-form-item label="费用比例">
        <el-input v-model="currentData.warrantyRate" placeholder="费用比例为小数"/>
      </el-form-item>
      <el-form-item label="工时费">
        <el-input v-model="currentData.handicraftCost" placeholder="请输入工时费用" @keydown.enter="saveSetting"/>
      </el-form-item>
    </el-form>
    <form-page :show-page="false">
      <page-button @click="showDialog = false">取消</page-button>
      <page-button el-type="primary" type="write" @click="saveSetting">保存</page-button>
    </form-page>
  </el-dialog>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import warrantySettingApi from "@/api/alpine/shop/warrantySettingApi";
import commonApi from "@/api/commonApi";
export default {
  name: "",
  components: {
    TableButton, PageButton, ListPage, TableList, FormPage, SearchCondition, PageHeader
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      dictionaryList: [],
      showDialog: false,
      currentData: {
        carPart: "",
        warrantyRate: "",
        handicraftCost: "",
      }
    };
  },
  created() {
    // 查询字典
    commonApi.queryDictionaryList("CAR_PART").then(list => this.dictionaryList = list);
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      warrantySettingApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    toShowEdit(data) {
      this.currentData = {...data};
      this.showDialog = true;
    },
    saveSetting() {
      warrantySettingApi.save(this.currentData).then(() => {
        this.queryList()
        this.showDialog = false;
      });
    },
    deleteData(row) {
      warrantySettingApi.delete(row.id).then(() => this.queryList());
    }
  }
};
</script>

<style lang="scss" scoped>

</style>