<template>
  <div class="defaultViewStyle">
    <list-page>
        <page-header>
          <page-button el-type="primary" @click="resetSearch">查询</page-button>
          <page-button el-type="success" type="write" @click="toAddData">添加</page-button>
        </page-header>
      <search-condition>
        <el-input v-model="searchInfo.searchKey" placeholder="搜索论坛标题" clearable @keydown.enter="resetSearch"/>
        <el-select v-model="searchInfo.status"  clearable placeholder="审核状态">
          <el-option v-for="item in statusList" :key="item.id" :label="item.statusName" :value="item.id"/>
        </el-select>
        <el-select v-model="searchInfo.searchType" placeholder="发贴人类型" clearable>
          <el-option :value="2" label="职员"/>
          <el-option :value="99" label="客户"/>
        </el-select>
      </search-condition>
      <table-list>
        <el-table :data="dataList" height="100%" border>
          <el-table-column label="序号" width="60px" type="index" :align="'center'"/>
          <el-table-column label="发贴人姓名">
            <template v-slot="{row}">{{row.customerName || row.employeeName}}</template>
          </el-table-column>
          <el-table-column label="发帖人类型">
            <template v-slot="{row}">
              <span v-if="row.type === 2">职员</span>
              <span v-else-if="row.type === 99">客户</span>
            </template>
          </el-table-column>
          <el-table-column label="论坛标题" prop="forumTitle" show-overflow-tooltip/>
          <el-table-column label="发布时间" prop="forumTime" show-overflow-tooltip/>
          <el-table-column label="状态" prop="status" width="100px">
            <template v-slot="{ row }">
              <el-tag type="warning" v-if="row.status === 0">待审核</el-tag>
              <el-tag type="success" v-else-if="row.status === 1">通过</el-tag>
              <el-tag type="danger" v-else-if="row.status === 2">未通过</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'">
            <template v-slot="{row}">
              <table-button type="write" @click="toStaus(row)">审核</table-button>
              <table-button type="write" @click="$routerUtil.push( `/plat/replay/replayList/replayDetail?id=${row.id}`)">详情</table-button>
              <table-button el-type="danger" type="delete" message="是否确认删除此数据" @click="toDelete(row)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize" :total-count="searchInfo.totalCount" @onLoad="queryList"/>
<!--      审核信息-->
      <el-dialog  v-model="showForumDialog" width="600px" title="编辑论坛信息">
        <el-form label-width="100px">
          <el-form-item label="审核">
            <el-select v-model="editDataList.status" style="width: 100%">
              <el-option v-for="item in statusList" :key="item.id" :label="item.statusName" :value="item.id"/>
            </el-select>
          </el-form-item>
        </el-form>
        <div style="text-align: right">
          <page-button @click="showForumDialog = false">取消</page-button>
          <page-button type="write" el-type="primary" @click="toSaveForumReply">保存</page-button>
        </div>
      </el-dialog>
    </list-page>
  </div>
</template>
<script>
import ListPage from "@/components/h5/layout/ListPage.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import ReplyApi from "@/api/alpine/replay/ReplyApi";
export default {
  setup() {
  },
  name: "forumReplyList",
  components: {
    TableButton,
    PageButton,
    SearchCondition,
    ListPage, TableList, FormPage, Dictionary,PageHeader
  },
  data(){
    return{
      searchInfo:{
        searchKey: "",
      },
      dataList:[],
      showForumDialog:false,
      editDataList:[],
      statusList:[{id:0,statusName:"待审核"},{id:1,statusName:"通过"},{id:2,statusName:"不通过"}]
    }
  },
  methods:{
    //查询
    resetSearch(){
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },

    queryList(){
      ReplyApi.queryList(this.searchInfo).then((list)=>{
        this.dataList = list.records;
        this.searchInfo.totalCount = list.totalCount
        console.log(list);

      })
    },
    //跳转到详情页面
    toAddData(){
      this.$routerUtil.push('/plat/replay/replayList/replayDetail')
    },
    //删除
    toDelete(data){
      ReplyApi.delete(data.id).then(()=>{
        this.queryList();
      })
    },
    toStaus(data){
      this.editDataList = {...data};
      this.showForumDialog = true;
    },
    toSaveForumReply(){
      ReplyApi.saveSubmit(this.editDataList).then(()=>{
        this.queryList()
        this.showForumDialog = false;
      })
      //console.log(this.editDataList)

    }
  }
}
</script>

<style scoped>

</style>