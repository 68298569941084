<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header/>
      <table-list>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="头像" :span="2">
            <image-upload v-model="customerInfo.iconUrl" :disabled="true"/>
          </el-descriptions-item>
          <el-descriptions-item label="姓名">
            <el-input v-model="customerInfo.customerName"/>
          </el-descriptions-item>
          <el-descriptions-item label="手机号">
            <el-input v-model="customerInfo.customerPhone"/>
          </el-descriptions-item>
          <el-descriptions-item label="紧急联系人">
            <el-input v-model="customerInfo.emergencyContact"/>
          </el-descriptions-item>
          <el-descriptions-item label="性别">
            <el-select v-model="customerInfo.gender" placeholder="请选择">
              <el-option label="男" :value="1"/>
              <el-option label="女" :value="2"/>
              <el-option label="未知" :value="0"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="生日">
            <el-date-picker style="width: 100%"
              v-model="customerInfo.birthday"
              type="date"
              placeholder="选择日期"
              value-format="YYYY-MM-DD"
            />
          </el-descriptions-item>
          <el-descriptions-item label="证件类型">
            <el-select v-model="customerInfo.cardType" placeholder="请选择">
              <el-option label="身份证" :value="1"/>
              <el-option label="护照" :value="2"/>
              <el-option label="其它" :value="0"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="证件号码">
            <el-input v-model="customerInfo.cardNo" @change="changeCardNo"/>
          </el-descriptions-item>
          <el-descriptions-item label="档案编号">
            <el-input v-model="customerInfo.bankupId"/>
          </el-descriptions-item>
          <el-descriptions-item label="微信号">
            <span v-if="customerInfo.wxOpenId">已关联</span>
            <span v-else>未关联</span>
          </el-descriptions-item>
          <el-descriptions-item label="家庭地址" :span="2">
            <el-input v-model="customerInfo.address"/>
          </el-descriptions-item>

          <el-descriptions-item label="备注" :span="2">
            <el-input v-model="customerInfo.remark" type="textarea" :rows="5" class="remark-text"/>
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions :column="2" border>
          <el-descriptions-item label="家属姓名">
            <el-input v-model="customerInfo.babyName"/>
          </el-descriptions-item>
          <el-descriptions-item label="家属性别">
            <el-select v-model="customerInfo.babyGender" placeholder="请选择">
              <el-option label="男" :value="1"/>
              <el-option label="女" :value="2"/>
              <el-option label="未知" :value="0"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="家属生日">
            <el-date-picker style="width: 100%"
                            v-model="customerInfo.babyBirthday"
                            type="date"
                            placeholder="选择日期"
                            value-format="YYYY-MM-DD"
            />
          </el-descriptions-item>
          <el-descriptions-item label="创建时间" :span="2">
            <span>{{ new Date().format("yyyy-MM-dd hh:mm:ss") }}</span>
          </el-descriptions-item>
        </el-descriptions>
      </table-list>
      <form-page :show-page="false">
        <page-button el-type="success" type="write" @click="saveData">保存</page-button>
      </form-page>
    </list-page>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import ImageUpload from "@/components/h5/image/ImageUpload";
import PageButton from "@/components/h5/button/PageButton";
import memberApi from "@/api/main/customer/memberApi";
import PageHeader from "@/components/h5/layout/PageHeader";
export default {
  name: "MemberDetail",
  components: {
    PageButton,
    ListPage, TableList, FormPage, ImageUpload, PageHeader
  },
  data() {
    return {
      customerInfo: {
        remark: "",
        cardType: "",
        cardNo: "",
        birthday: "",
        bankupId: "",
        wxOpenId: ""
      },
    };
  },
  created() {
    if (this.$routerUtil.query.id) {
      this.initData(this.$routerUtil.query.id);
    }

  },
  methods: {
    initData(id) {
      memberApi.findById(id).then(data => this.customerInfo = data);
    },
    saveData() {
      memberApi.save(this.customerInfo).then(data => this.customerInfo = data);
    },
    // 获取生日
    changeCardNo() {
      if (this.customerInfo.cardType === 1 && this.customerInfo.cardNo.length === 18) {
        // 生日
        this.customerInfo.birthday = this.customerInfo.cardNo.substring(6, 10) + "-" + this.customerInfo.cardNo.substring(10, 12) + "-" + this.customerInfo.cardNo.substring(12, 14);
        // 性别
        // this.customerInfo.gender = parseInt(this.customerInfo.cardNo.charAt(16)) % 2 === 0 ? 1 : 2;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.remark-text{
  :deep(textarea){
    box-sizing: border-box;
    padding: 10px;
  }
}
</style>