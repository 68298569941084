<template>
  <!-- -->
  <div class="" style="height: 100%">
    <separate-page>
      <template #category>
        <div style="height: 100%;overflow: auto;" class="left">
          <div v-for="(data, i) in dataList" :key="i"
            :class="data.id === currentData.id ? 'attribute-item on' : 'attribute-item'" @click="selectData(data)">
            <span class="attribute-name">{{ data.columnName }}</span>
            <span class="btn">
              <table-button type="delete" @click="deleteData" message="是否确认删除数据">删除</table-button>
              <!-- <confirm-btn :message="`是否确认删除[${data.columnName}]`" @click="deleteData">
                <i class="el-icon-delete" />
              </confirm-btn> -->
              <i class="el-icon-edit" @click="showEdit = true" />
            </span>
          </div>
        </div>
        <table-button class="bottom" @click="addNew">添加</table-button>
      </template>
      <template #default>
        <list-page>
          <table-list style="height: 100%">
            <el-table height="100%" :data="currentData.optionList" border>
              <el-table-column label="序号" type="index" width="60px" :align="'center'" />
              <el-table-column label="规格名称" prop="name" />
              <el-table-column label="规格类型">
                <template #default="scope">
                  {{ currentData.columnName }}
                </template>
              </el-table-column>
            </el-table>
          </table-list>
        </list-page>
      </template>
    </separate-page>
    <el-dialog v-model="showEdit" title="编辑属性" width="400px">
      <attribute-info :data="currentData" @close="showEdit = false" @submit="saveData" />
    </el-dialog>
  </div>
</template>

<script>
import attributeApi from "@/api/main/product/attributeApi";
import TableList from "@/components/h5/layout/TableList";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import SeparatePage from "@/components/h5/layout/SeparatePage";
import AttributeInfo from "@/views/main/product/attribute/components/AttributeInfo";
import TableButton from "@/components/h5/button/TableButton";
import productApi from "@/api/main/product/productApi";
import ListPage from "@/components/h5/layout/ListPage";
export default {
  name: "",
  components: {
    productApi, SeparatePage, TableList, SearchCondition, AttributeInfo, TableButton, ListPage
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      currentData: {
        columnName: "",
      },
      showEdit: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      attributeApi.queryAttributeList().then(list => this.dataList = list)
    },
    selectData(data) {
      this.currentData = {
        ...data,
        optionList: data.options ? JSON.parse(data.options) : []
      };
    },
    addNew() {
      this.currentData = {
      };
      this.showEdit = true;
    },
    deleteData() {
      attributeApi.deleteAttribute(this.currentData.id).then(() => {
        this.getList()
      });
    },
    saveData() {
      this.showEdit = false;
      this.getList();
      productApi.queryAttributeList().then(list => {
        this.$emit("toUpdateMerchant",list)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/theme/handle.scss";

.attribute-item {
  box-sizing: border-box;
  height: 36px;
  line-height: 36px;
  padding-left: 30px;
  cursor: pointer;
  font-size: 14px;
  position: relative;

  .btn {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 5px;
    letter-spacing: 8px;
    display: none;

    .el-icon-delete {
      @include font_color("danger");
    }

    .el-icon-edit {
      color: #FFF;
    }
  }
}

.attribute-item:hover {
  background: #f1f1f1;
}

.attribute-item.on {
  background: #f5f7fa;
  color: #333;
  .btn {
    display: inline-block;
  }
}

.attribute-item.on:hover {
  background: #409EFF;
}

.left {
  background: #FFF;
  padding-bottom: 36px;
  position: relative;
  height: 100%;
  box-sizing: border-box;

  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-left-color: transparent;
    border-right-color: transparent;
    font-size: 14px;
    color: #FFF;
    cursor: pointer;
    @include background_color("success");
  }
}
</style>