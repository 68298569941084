<template>
  <!-- 商品选择组件，可选择多个商品，占满一整行 -->
  <div>
    <div>
      <el-autocomplete id="myatc" ref="autocomplete" v-if="showSearch" :style="!otherSet ? {width: 'calc(100% - 60px)', 'margin-right': '10px'} : {width: '100%'}" v-model="barCode" :fetch-suggestions="setCallBack" @select="selectProductOne" @keydown="keyDown" value-key="skuName" clearable :placeholder="inputDesc">
        <template v-if="queryParams.enabledOriginal" #default="{ item }">
          <div style="display: flex;justify-content: space-between">
            <span>{{ item.skuName }}</span>
            <span>{{ item.originalCode }}</span>
          </div>
        </template>
      </el-autocomplete>
      <page-button v-if="showInputSearch" :el-type="btnType" @click="toSearch">搜索</page-button>
      <page-button v-if="commonBtn && !showInputSearch" :disabled="disabled" :el-type="btnType" type="write" @click="toShowDialog">{{ btnName }}</page-button>
      <table-button v-else-if="!showInputSearch && smallBtn" :disabled="disabled" :el-type="btnType" type="write" @click="toShowDialog">{{ btnName }}</table-button>
    </div>

    <el-dialog v-model="showSaveProduct" @open="openBefore" title="选择商品" width="65%" style="z-index: 999">
      <search-condition @search="resetSearch" :show-search="true">
        <template #default>
          <el-input v-model="searchInfo.searchKey" placeholder="请输入商品名称"/>
        </template>
      </search-condition>
      <dialog-table>
        <el-table v-if="columnDisplay.length > 0" :data="productList" height="100%" border ref="multipleTable" @selection-change="selectionChange" @row-click="clickRow">
          <el-table-column type="selection" width="55" :align="'center'"/>
          <el-table-column :align="'center'" type="index" label="序号" width="60px"/>
          <el-table-column v-for="(c, i) in columnDisplay" :key="i" :prop="c.prop" :label="c.label" :width="c.width" :show-overflow-tooltip="true"/>
        </el-table>
        <el-table v-else :data="productList" height="100%" border ref="multipleTable" @selection-change="selectionChange" @row-click="clickRow">
          <el-table-column type="selection" width="55" :align="'center'"/>
          <el-table-column :align="'center'" type="index" label="序号" width="60px"/>
          <el-table-column :align="'center'" :prop="productList[0].skuCode ? 'skuCode' : 'productCode'" label="商品编号" min-width="120px" show-overflow-tooltip/>
          <el-table-column :align="'center'" :prop="productList[0].skuName ? 'skuName' : 'productName'" label="商品名称" min-width="180px" show-overflow-tooltip/>
          <el-table-column :align="'center'" prop="barCode" label="商品条码" min-width="120px" show-overflow-tooltip/>
          <el-table-column :align="'center'" :prop="productList[0].skuName ? 'skuUnitName' : 'productUnitName'" label="基本单位" width="100px" show-overflow-tooltip/>
          <!--        <el-table-column :align="'center'" prop="skuAttributes" label="属性" width="100px" show-overflow-tooltip/>-->
          <el-table-column :align="'center'" :prop="productList[0].skuName ? 'skuStandard' : 'productStandard'" label="商品规格" width="100px" show-overflow-tooltip/>
          <el-table-column :align="'center'" :prop="productList[0].skuName ? 'skuType' : 'productType'" label="商品型号" width="100px" show-overflow-tooltip/>
          <el-table-column :align="'center'" v-if="queryParams.enabledOriginal" prop="originalCode" label="溯源码" width="100px" show-overflow-tooltip/>
          <el-table-column :align="'center'" prop="productBrade" label="商品品牌" width="100px" show-overflow-tooltip>
            <template v-slot="{row}">
              <dictionary :disabled="true" v-model="row.productBrade"
                          :dictionaries="productBrades"
                          dict-code="dictCode"
                          dict-name="dictName"/>
            </template>
          </el-table-column>
          <el-table-column v-if="isStock" :align="'center'" prop="stockQty" label="库存余量" width="100px" show-overflow-tooltip fixed="right">
            <template v-slot="{row}">
              {{ row.stockType === 1 ? row.stockQty : '非库存'  }}
            </template>
          </el-table-column>
          <!--        <el-table-column :align="'center'" prop="skuBuyPrice" label="成本单价" width="100px" v-if="showCost" show-overflow-tooltip>-->
          <!--          <template v-slot="{row}">-->
          <!--            <money :value="row.skuBuyPrice"/>-->
          <!--          </template>-->
          <!--        </el-table-column>-->
          <el-table-column :align="'center'" prop="skuSalePrice" label="销售单价" width="100px" v-if="showPrice && showCost" show-overflow-tooltip>
            <template v-slot="{row}">
              <money :value="row.skuSalePrice"/>
            </template>
          </el-table-column>
          <el-table-column :align="'center'" label="成本金额" width="100px" v-if="showCost" show-overflow-tooltip>
            <template v-slot="{row}">
              <money :value="row.skuQty * row.skuBuyPrice"/>
            </template>
          </el-table-column>
          <el-table-column :align="'center'" label="销售金额" width="100px" v-if="showPrice && showCost" show-overflow-tooltip>
            <template v-slot="{row}">
              <money :value="row.skuQty * row.skuSalePrice"/>
            </template>
          </el-table-column>
          <el-table-column v-if="showStock" :align="'center'" label="仓库" width="180px" show-overflow-tooltip>
            <template v-slot="{row}">
              {{ row.stockName }}
            </template>
          </el-table-column>
        </el-table>
      </dialog-table>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize"
                 :total-count="searchInfo.totalCount" @onLoad="queryList" :default-load="false">
        <template #default>
          <page-button @click="showSaveProduct = false">取消</page-button>
          <page-button el-type="primary" @click="saveProduct">选中</page-button>
        </template>
      </form-page>
    </el-dialog>
  </div>

</template>

<script>

import FormPage from "@/components/h5/layout/FormPage.vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import Money from "@/components/h5/Money.vue"
import commonApi from "@/api/commonApi";
import Dictionary from "@/components/h5/Dictionary.vue";
import util from "@/util/util";
import TableButton from "@/components/h5/button/TableButton.vue";
import stockApi from "@/api/main/stock/stockApi";

export default {
  // 选中商品回调, select选中多条数据, selectOne选中一条数据
  emits: ["select", "selectOne"],
  props: {
    otherSet: {default: false},
    showInputSearch: {default: false},
    inputDesc: {default: "条码/拼音码/名称/编码并按回车"},
    smallBtn: {default: false},
    commonBtn: {default: true},
    // 查询列表的api，需要返回一个分页查询的接口
    queryListApi: {default: null},
    // 按sku条码查询到商品
    findSkuByCodeApi: {default: null},
    // 已经被选中的商品，包括数量 {productQty: 1, }
    selectedProductList: {default: () => []},
    // 商品数量字段映射
    qtyColumn: {default: "skuQty"},
    // 商品skuId字段映射, 即selectedProductList的商品id
    skuIdColumn: {default: "skuId"},
    // 商品skuId字段映射，即productList的关键id
    productIdColumn: {default: "id"},
    // 显示价格，默认不显示
    showPrice: {default: false},
    // 显示成本，默认不显示
    showCost: {default: false},
    // 开启部门查询
    isDepart: {default: false},
    // 是否开启仓库默认查询
    isStock: {default: false},
    stockId: {default: ""},
    plaTitle: {default: "请选择仓库！"},
    // 显示的字段, 格式：{prop, label, width} 每个字段都必须指定
    columnDisplay: {default: () => []},
    // 展示搜索框
    showSearch: {default: true},
    // 按钮名称
    btnName: {default: "筛选"},
    // 按钮类型
    btnType: {default: "primary"},
    // 是否禁用筛选按钮
    disabled: {default: false},
    // 商品授权时使用，只查询未被授权的商品信息(1：未授权，2：已授权)
    isAuthorize: {default: null},
    // 需要自定义的查询参数，结合参数检查可进行查询前的必要条件检查,这里必须传一个对象不能用字段
    queryParams: {default: {}},
    // 自定义查询参数的检查，默认为空，检查通过返回true，不通过返回false
    beforeQuery: {default: null},
    // 开始库存类型查询-开启后默认只查询库存商品
    stockType: {default: false},
    // 展示仓库信息
    showStock: {default: false},
    // 条件搜索
    addExists: {default: false},
    // 1 - 在库 2 - 领用
    exists: {default: null},
  },
  name: "ProductList",
  components: {
    TableButton,
    Dictionary,
    PageButton,
    SearchCondition,  DialogTable, FormPage, Money
  },
  data() {
    return {
      barCode: "",
      showSaveProduct: false,
      productList: [],
      searchInfo: {
        isDepartGrant: this.isAuthorize,
        status: -2, // 不查询停用的商品
      },
      // 仓库
      stockList: [],
      // eltable被选中的数据
      elSelected: [],
      // 设置el-auto组件的回调
      setDataList: [],
      enter: true,
      // 品牌字典
      productBrades: [],
    };
  },
  created() {
    // // 商品品牌
    stockApi.queryListByNotCondition().then(res => {
      this.stockList = res;
    });
  },
  // watch:{
  //   stockId: {
  //     handler(){
  //       this.queryList();
  //     }
  //   }
  // },
  methods: {
    openBefore(){
      // 每次授权后，需要刷新可授权的商品列表
      if(this.isAuthorize !== null){
        this.queryList();
      }
    },
    // clear() {
    //   this.$emit("clear");
    // },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    toShowDialog() {
      if(!this.checkIsStock()) return;
      this.showSaveProduct = true;
      if(this.isStock){
        this.queryList();
        return;
      }

      if (this.productList.length === 0) {
        // 数据不存在，查询
        this.queryList();
      } else {
        // 数据已存在。显示
        this.showTableList();
      }
    },
    toSearch(){
      this.$refs.autocomplete.focus();
    },
    queryByDepart() {
      util.getUserInfo().then(res=>{
        this.searchInfo.departCode = res.mainDepartCode;
        if (this.queryListApi) {
          this.queryListApi(this.searchInfo).then(res => {
            this.productList = res.records;
            this.searchInfo.totalCount = res.totalCount;
            this.showTableList();
          });
        } else {
          // 没有api，使用默认
          commonApi.querySkuList(this.searchInfo).then(res => {
            this.productList = res.records;
            this.searchInfo.totalCount = res.totalCount;
            this.showTableList();
          });
        }
      })
    },
    queryList() {
      if(this.stockType){
        // 后端查询为!=
        this.searchInfo.stockType = 0;
      }
      let params = {
        ...this.searchInfo,
        ...this.queryParams,
      };
      if(this.queryParams.isMerBrade){
        commonApi.queryDictionaryList("PRODUCT_BRADE", this.queryParams.merchantId).then(list => this.productBrades = list);
      }else{
        commonApi.queryDictionaryList("PRODUCT_BRADE").then(list => this.productBrades = list);
      }
      if(this.isDepart){
        this.queryByDepart();
        return;
      }

      if (this.queryListApi) {
        this.queryListApi(params).then(res => {
          this.productList = res.records;
          this.searchInfo.totalCount = res.totalCount;
          this.showTableList();
        });
      } else {
        // 没有api，使用默认
        commonApi.querySkuList(params).then(res => {
          this.productList = res.records;
          this.searchInfo.totalCount = res.totalCount;
          this.showTableList();
        });
      }
    },
    // 显示table数据
    showTableList() {
      // 哪些商品被选中，数量是多少
      for (let j = 0; j < this.productList.length; j++) {
        this.productList[j].skuQty = 1;
        this.productList[j].checked = false;
        // 仓库
        let idx = this.stockList.findIndex(item => item.id === this.productList[j].stockId);
        if(idx !== -1){
          this.productList[j].stockName = this.stockList[idx].stockName;
        }
        for (let i = 0; i < this.selectedProductList.length; i++) {
          if (this.selectedProductList[i][this.skuIdColumn] === this.productList[j][this.productIdColumn]) {
            // 已选中，选中的数据
            this.productList[j].skuQty = this.selectedProductList[i][this.qtyColumn];
            this.productList[j].checked = true;
            break;
          }
        }
      }
      // 被选中的
      this.$nextTick().then(() => {
        for (let j = 0; j < this.productList.length; j++) {
          this.$refs.multipleTable.toggleRowSelection(this.productList[j], this.productList[j].checked);
        }
        // 商品仓库信息

      });
    },
    selectionChange(list) {
      this.elSelected = list;
    },
    saveProduct() {
      let arr = [];
      for (let j = 0; j < this.productList.length; j++) {
        for (let i = 0; i < this.elSelected.length; i++) {
          if (this.elSelected[i][this.productIdColumn] === this.productList[j][this.productIdColumn]) {
            arr.push(this.productList[j]);
          }
        }
      }
      this.$emit("select", arr);
      this.showSaveProduct = false;
    },
    // searchByBarCode() {
    //   if (this.findSkuByCodeApi) {
    //     this.findSkuByCodeApi(this.barCode).then(list => {
    //       console.log(list)
    //     });
    //   } else {
    //     commonApi.querySkuListByCode(this.barCode).then(list => {
    //     });
    //   }
    // },
    clickRow(data) {
      data.checked = !data.checked
      this.$refs.multipleTable.toggleRowSelection(data, data.checked);
    },
    setCallBack(val, callBack) {
      // if(!this.checkIsStock()) return;
      this.setDataList = callBack;
      callBack([]);
    },
    // 选中某一个商品
    selectProductOne(data) {
      if(!this.checkIsStock()) return;
      this.enter = false;
      this.barCode = "";
      console.log("选中了某一个商品")
      console.log(data)
      this.$emit("selectOne", data);
    },

    keyDown(key) {
      if(!key){
        key = {};
        key.keyCode = 13;
      }
      this.$refs.autocomplete.focus();
      this.$nextTick().then(() => {
        if(!this.checkIsStock()) return;
        if (key.keyCode === 13) {
          if (!this.enter) {
            this.enter = true;
            return;
          }

          if(this.addExists){
            if(!this.exists){
              this.$message.error("请选择是否在库！");
            }else{
              // 在库
              commonApi.querySkuListByCode(this.barCode,this.queryParams.enabledOriginal ? this.exists : null).then(list => {
                if(list.length === 0){
                  this.$message.warning("暂无物资！");
                }
                if (list.length === 1) {
                  this.$emit("selectOne", list[0]);
                  this.barCode = "";
                } else {

                  this.setDataList(list);
                }
              });
            }
          }else{
            // 回车时，进行查询
            commonApi.querySkuListByCode(this.barCode,this.queryParams.enabledOriginal ? 1 : null).then(list => {
              if (list.length === 1) {
                this.$emit("selectOne", list[0]);
                this.barCode = "";
              } else {
                this.setDataList(list);
              }
            });
          }
        }
      });
    },
    checkIsStock(){
      if(this.isStock){
        if(this.stockId !== ""){
          this.searchInfo.stockId = this.stockId;
          return true;
        }else if(this.stockId === "001"){
          return true;
        }else{
          this.$message.warning(this.plaTitle);
          return false;
        }
      }
      if (this.beforeQuery) {
        return this.beforeQuery();
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.input-line{
  width: calc(100% - 60px);
  margin-right: 10px;
  :deep(.el-input-group__append) {

    color: #409eff;
    //background: #409eff;
    //border-color: #409eff;
  }
}
</style>