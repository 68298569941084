<template>
  <!-- 通用模板，列表数据 -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
        <page-button el-type="success" type="write" @click="toDetail({id: ''})">新建</page-button>
      </page-header>
      <search-condition>
        <date-range v-model:start-time="searchInfo.startTime" v-model:end-time="searchInfo.endTime"/>
        <el-input v-model="searchInfo.searchKey" placeholder="请输入单据编号"/>
        <el-select v-model="searchInfo.stockId" filterable clearable placeholder="请选择仓库">
          <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
        </el-select>
        <template #moreCondition>
          <div style="display: inline-block;width: 240px;margin-right: 10px;">
            <dictionary class="condition" v-model="searchInfo.clientDepartCode" :dictionaries="departList"
                        place-holder="请选择部门" dict-name="departName" dict-code="departCode"/>
          </div>
          <div style="display: inline-block;width: 240px;margin-right: 10px;">
            <client-select class="condition" v-model="searchInfo.clientId" placeholder="请选择客户"/>
          </div>
          <el-select v-model="searchInfo.status" filterable clearable placeholder="请选择订单状态">
            <el-option v-for="item in statusList" :key="item.value" :label="item.name" :value="item.code"/>
          </el-select>
        </template>

      </search-condition>
      <table-list>
        <el-table :data="dataList" height="100%" border>
          <el-table-column label="序号" width="60px" type="index" :align="'center'"/>
          <el-table-column align="center" label="客户名称" property="clientName" width="150px" show-overflow-tooltip/>
          <el-table-column label="订单时间" property="orderTime" width="200px" :align="'center'"/>
          <el-table-column align="center" label="部门" min-width="150px" show-overflow-tooltip>
            <template v-slot="{row}">
              <dictionary :dictionaries="departList" :disabled="true" v-model="row.departCode" dict-code="departCode"
                          dict-name="departName"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="仓库" min-width="150px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-for="(item, i) in stockList" :key="i"><span
                  v-if="item.id === row.stockId">{{ item.stockName }}</span></span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="业务人员" property="employeeName" min-width="150px"
                           show-overflow-tooltip/>
          <el-table-column label="订单状态" :align="'center'" width="100px">
            <template v-slot="{row}">
              <span v-for="(item, i) in statusList" :key="i"><el-tag :type="item.type"
                                                                     v-if="item.code == row.status">{{ item.name }}</el-tag></span>
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'" width="180px">
            <template v-slot="{row}">
              <table-button v-if="row.status < 20" :disabled="row.status >= 20" @click="toDetail(row)">编辑
              </table-button>
              <table-button v-else @click="toDetail(row)">查看</table-button>
              <table-button v-if="row.orderType === 11" @click="toRemark(row)">备注</table-button>
              <!-- 退款信息 -->
              <table-button v-if="row.orderType === 11" :disabled="row.payStatus !== 99 || row.refundStatus === 1" el-type="primary" @click="toRefund(row.id)">退款</table-button>
              <table-button :disabled="row.status > 0 || row.orderType === 11" message="是否确认删除此数据" type="delete" el-type="danger">
                删除
              </table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>

    <el-dialog title="退款详情" v-model="showRefund" width="60%">
      <div class="">
        <el-descriptions :column="2" border>
          <el-descriptions-item label="下游客户" label-class-name="require">
            <dictionary v-model="orderDetail.clientId" :disabled="true" :dictionaries="storeList" :dict-name="'storeName'" :dict-code="'id'" />
<!--            <client-select v-else :disabled="orderDetail.status > 0 || orderDetail.orderType === 11" v-model="orderDetail.clientId" :merchant-name="orderDetail.clientName" :query-api="queryClientApi"/>-->
          </el-descriptions-item>
          <el-descriptions-item label="仓库名称" label-class-name="require">
            <dictionary  v-model="orderDetail.stockId" :disabled="true" :dictionaries="stockList" :dict-name="'stockName'" :dict-code="'id'" />
          </el-descriptions-item>
          <el-descriptions-item label="部门" label-class-name="require">
            <dictionary v-model="orderDetail.departCode" :disabled="true"
                       :dictionaries="departList"
                        dict-code="departCode"
                        dict-name="departName"/>
          </el-descriptions-item>
          <el-descriptions-item label="业务人员">{{orderDetail.employeeName}}</el-descriptions-item>
          <el-descriptions-item label="订单时间">
            <text>{{ orderDetail.orderTime }}</text>
<!--            <el-date-picker v-else v-model="orderDetail.orderTime" type="datetime" style="width: 100%"/>-->
          </el-descriptions-item>
          <el-descriptions-item label="订单状态">{{orderDetail.statusName}}</el-descriptions-item>
        </el-descriptions>
        <dialog-table style="box-shadow: 3px 3px 15px #e5e0e0">
          <el-table :data="orderDetail.detail" border height="100%">
            <el-table-column align="center" label="序号" width="60px" type="index" :align="'center'" fixed/>
            <el-table-column align="center" label="商品名称" prop="skuName" min-width="150px" show-overflow-tooltip/>
            <el-table-column align="center" label="商品编码" prop="skuCode" min-width="100px" show-overflow-tooltip/>
            <el-table-column label="单位" prop="unitName" width="100px" :align="'center'"/>
            <el-table-column label="退货价格" prop="salePrice" width="100px">
              <template v-slot="{row}">
                <el-input v-model="row.salePrice" type="number" style="width: 100%"/>
              </template>
            </el-table-column>
            <el-table-column label="退货数量" prop="qty" width="100px">
              <template v-slot="{row}">
                <el-input v-model="row.qty" type="number" style="width: 100%"/>
              </template>
            </el-table-column>
            <el-table-column label="退货金额" width="100px" :align="'right'">
              <template v-slot="{row}">
                <money :value="row.salePrice * row.qty"/>
              </template>
            </el-table-column>
          </el-table>
        </dialog-table>
        <div style="display: flex;justify-content: space-between;margin-top: 15px">
          <div>
            <text>总金额：</text>
            <money :value="getRefundAmount" :show-mark="true" :show-color="true"/>
          </div>
          <page-button el-type="primary" @click="submitRefund" v-loading.fullscreen.lock="fullscreenLoading">退款</page-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="小程序信息" v-model="showMini" width="30%">
        <el-form label-width="70px">
          <el-form-item label="取货方式">
            <text>{{ this.miniDetail.type === 1 ? '门店自提' : '商户配送' }}</text>
          </el-form-item>
          <el-form-item label="门店" v-if="miniDetail.type === 2">
            <text>{{ miniDetail.storeName }}</text>
          </el-form-item>
          <el-form-item label="门店地址" v-if="miniDetail.type === 2">
            <text>{{ miniDetail.address }}</text>
          </el-form-item>
          <el-form-item label="取货时间" v-if="miniDetail.type === 1">
            <text>{{ miniDetail.arriveTime }}</text>
          </el-form-item>
        </el-form>
    </el-dialog>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import ClientSelect from "@/components/h5/customer/ClientSelect.vue";

import merchantOrderApi from "@/api/main/supplyChain/merchantOrderApi";
import commonApi from "@/api/commonApi";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import storeApi from "@/api/alpine/merchant/storeApi";
import Money from "@/components/h5/Money.vue";
import util from "@/util/util";
import {ElLoading} from "element-plus";

export default {
  name: "",
  components: {
    Money,
    DialogTable,
    TableButton,
    PageButton,
    ListPage,
    TableList,
    FormPage,
    SearchCondition,
    PageHeader,
    DateRange,
    Dictionary,
    ClientSelect
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      stockList: [],
      departList: [],
      statusList: [],
      // 溯源码开关
      originalSwitch: false,
      // 退款
      showRefund: false,
      // 单条订单详情
      orderDetail: {},
      // 门店列表
      storeList: [],
      fullscreenLoading: false,
      // 小程序的信息
      miniDetail: {},
      showMini: false,
    };
  },
  computed:{
    // 退款金额
    getRefundAmount(){
      let totalAmount = 0;
      for (let i = 0; i < this.orderDetail.detail.length; i++) {
        totalAmount += this.orderDetail.detail[i].salePrice * this.orderDetail.detail[i].qty;
      }
      return totalAmount;
    },
  },
  created() {
    // 门店
    commonApi.queryAll().then(res => this.storeList = res);
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    commonApi.queryAllStock().then(list => this.stockList = list);
    commonApi.queryDepartList().then(list => this.departList = list);
    // 是否开启溯源码功能
    commonApi.findSetting("STOCK_ORIGINAL").then(setting => this.originalSwitch = setting.settingValue === "1");
    merchantOrderApi.getAllStatus().then(list => {
      // 给list加上显示的样式
      for (let i = 0; i < list.length; i++) {
        // 完成
        if (list[i].code === '99') {
          list[i].type = "success";
        } else if (list[i].code === '0') {
          // 草稿
          list[i].type = "info";
        } else if (list[i].code === '10') {
          // 待确认
          list[i].type = "primary";
        } else if (list[i].code === '20') {
          // 已确认
          list[i].type = "warning";
        }
      }
      this.statusList = list
    });
  },
  methods: {
    toRemark(row){
      merchantOrderApi.findMiniDetail(row.id).then(res => {
        this.miniDetail = res;
        this.miniDetail.storeName = row.clientName;
        this.showMini = true;
      })
    },
    // 提交退款
    async submitRefund(){
      // 弹框遮罩层
      let loading = ElLoading.service({
        lock: true,
        text: '正在退款,请勿刷新页面...',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      // 处理退款数据
      this.orderDetail.originalId = this.orderDetail.id;
      this.orderDetail.id = "";
      this.orderDetail.amount = this.getRefundAmount;
      this.orderDetail.amountTotal = this.getRefundAmount;
      this.orderDetail.orderType = 12; // 小程序提交单据的退款类型
      // 数量
      let count = 0;
      for (let i = 0; i < this.orderDetail.detail.length; i++) {
        this.orderDetail.detail[i].id = "";
        count += this.orderDetail.detail[i].qty;
      }
      this.orderDetail.orderTime = new Date().format("yyyy-MM-dd hh:mm:ss");
      this.orderDetail.qty = count;
      this.orderDetail.status = 0;
      this.orderDetail.payStatus = 0;
      this.orderDetail.outStatus = 0;
      this.orderDetail.inStatus = 0;
      this.orderDetail.inoutStatus = 0;
      // 详情信息
      for (let i = 0; i < this.orderDetail.detail.length; i++) {
        this.orderDetail.detail[i].outStockQty = 0;
        this.orderDetail.detail[i].inStockQty = 0;
      }
      // 保存退款数据，并且开始退款操作
      merchantOrderApi.saveSubmit(this.orderDetail).then(() =>{
        loading.close();
        this.showRefund = false;
      }).catch(() => loading.close());
    },
    toRefund(orderId) {
      // 查询订单的详情信息
      merchantOrderApi.findById(orderId).then(res => {
        this.orderDetail = res;
        this.showRefund = true;
      })
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      merchantOrderApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    toDetail(data) {
      if (this.originalSwitch) {
        // 溯源码
        this.$routerUtil.push(`/supplyChain/sale/detail-original?id=${data.id}`);
      } else {
        this.$routerUtil.push(`/supplyChain/sale/detail-simple?id=${data.id}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.el-descriptions__label) {
  width: 5%;
}
:deep(.el-descriptions__label:after) {
}
:deep(.el-descriptions__content) {
  width: 15%;
  position: relative;
}
:deep(.el-textarea__inner) {
  box-sizing: border-box;
  padding: 10px !important;
}
:deep(.el-descriptions__label.require:before) {
  content: "*";
  font-size: 13px;
  color: #F56C6C;
  padding-right: 4px;
}

</style>