<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
        <page-button el-type="success" type="write" @click="toEditStock({})">添加</page-button>
      </page-header>
      <search-condition>
        <el-input v-model="searchInfo.searchKey" placeholder="请输入仓库名称"/>
      </search-condition>
      <table-list>
        <el-table :data="dataList" height="100%" border ref="resizableElement" :id="tableId">
          <el-table-column label="序号" width="60px" type="index"/>
          <el-table-column label="仓库名称" prop="stockName"/>
          <el-table-column label="仓库地址" prop="stockAddress"/>
          <el-table-column label="所属门店" v-if="needStoreId">
            <template v-slot="{row}">
            <span v-for="(s,i) in storeList" :key="'s'+i">
              <span v-if="s.id === row.bankupId">{{s.storeName}}</span>
            </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'">
            <template v-slot="{row}">
              <table-button type="write" @click="toEditStock(row)">编辑</table-button>
              <table-button el-type="danger" type="delete" message="是否确认删除此数据" @click="deleteData(row)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize" :total-count="searchInfo.totalCount" @onLoad="queryList"/>
    </list-page>
  </div>
  <el-dialog v-model="showDialog" title="仓库管理" width="400px">
    <el-form label-width="100px">
      <el-form-item label="仓库名称">
        <el-input v-model="currentStock.stockName"/>
      </el-form-item>
      <el-form-item label="仓库地址">
        <el-input v-model="currentStock.stockAddress"/>
      </el-form-item>
      <el-form-item label="所属门店" v-if="needStoreId">
        <el-select v-model="currentStock.bankupId">
          <el-option v-for="(s,i) in storeList" :value="s.id" :label="s.storeName" :key="'d'+i"/>
        </el-select>
      </el-form-item>
    </el-form>
    <div style="text-align: right">
      <page-button @click="showDialog = false">取消</page-button>
      <page-button el-type="success" type="write" @click="saveStock">保存</page-button>
    </div>
  </el-dialog>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";

import commonApi from "@/api/commonApi";
import stockApi from "@/api/alpine/merchant/stockApi";
import Dictionary from "@/components/h5/Dictionary";
import PageHeader from "@/components/h5/layout/PageHeader.vue";

export default {
  name: "",
  components: {
    TableButton,
    PageButton,
    SearchCondition,
    ListPage, TableList, FormPage, Dictionary,PageHeader
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      currentStock: {
        stockName: "",
        stockAddress: "",
        bankupId: "",
      },
      showDialog: false,
      // 是否需要部门id
      needStoreId: false,
      storeList: [],
      resizeObserver: {},
      tableId: "stockTable",
    };
  },
  created() {
    this.tableId = `stockTable${(Math.random()*9999).toFixed(0)}`;
    commonApi.findSetting("STOCK_BANKUP").then(data => {
      let need = data.settingValue === "1";
      if (need) {
        // 查询门店
        stockApi.queryAllStores().then(list => {
          this.storeList = list;
          this.resizeObserver.disconnect();
          this.needStoreId = need;
          this.resizeObserver.observe(document.querySelector(`#${this.tableId}`));
        });
      }
    });
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(() => {});
  },
  beforeUnmount() {
    // 组件卸载前断开观察器连接
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      stockApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    toEditStock(data) {
      this.currentStock = {...data};
      this.showDialog = true;
    },
    deleteData(data) {
      stockApi.delete(data.id).then(() => this.queryList());
    },
    saveStock() {
      stockApi.save(this.currentStock).then(() => {
        this.queryList();
        this.showDialog = false;
      })
    }
  }
};
</script>

<style lang="scss" scoped>

</style>