import axios from "../../../util/axios";

export default {
    // 带分页查询
    queryList: (data) => axios.get({url: "/order/house/status/queryList", data: data}),
    // 入住
    checkIn: (subId, docId) => axios.get({url: "/order/house/status/checkIn", data: {subId, docId}}),
    // 退房
    checkOut: (docId) => axios.get({url: "/order/house/status/checkOut", data: {docId}}),
    // 续住
    addContinueLive: (data) => axios.post({url: "/order/house/status/addContinueLive", data: data})
}