import axios from "../../../util/axios";

export default {
    // 查询
    queryList: (searchInfo) => axios.get({url: `/order/material/unit/queryList`, data: searchInfo}),
    // 保存提交
    saveSubmit: (unitData) => axios.post({url: `/order/material/unit/saveSubmit`, data: unitData}),
    // 删除
    deleteRow: (id) => axios.del({url: `/order/material/unit/delete`,data:{id}}),
    // 查询全部
    queryAll: () => axios.get({url: `/order/material/unit/queryAll`}),
}