import axios from "../../../../util/axios";

export default {
    save: (data) => axios.post({url: "/order/yanguangdan/save", data: data}),

    findById: (id) => axios.get({url: "/order/yanguangdan/findById", data: {id: id}}),

    queryByCustomer: (customerId) => axios.get({url: "/order/yanguangdan/queryByCustomer", data: {customerId}}),

    // 查询验光单列表
    queryList: (searchInfo) => axios.get({url: "/order/yanguangdan/queryList", data: searchInfo}),
    // 删除一条验光单
    deleteById: (id) => axios.del({url: "/order/yanguangdan/deleteById", data: {id}}),

}