
Date.prototype.format =  function (fmt) {  // author: meizz
    let o = {
        "M+":  this.getMonth() + 1,  // 月份
        "d+":  this.getDate(),  // 日
        "h+":  this.getHours(),  // 小时
        "m+":  this.getMinutes(),  // 分
        "s+":  this.getSeconds(),  // 秒
        "q+": Math.floor(( this.getMonth() + 3) / 3),  // 季度
        "S":  this.getMilliseconds()  // 毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, ( this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for ( let k  in o)
        if ( new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
// 从数组中移除一个对象，返回新的数组
Array.prototype.remove = function(index) {
    return this.slice(0, index).concat(this.slice(index + 1, this.length));
}
// 两个数组拼接
Array.prototype.pushAll = function(arr) {
    if (arr instanceof Array) {
        for (let i = 0; i < arr.length; i++) {
            this.push(arr[i])
        }
    } else {
        this.push(arr)
    }
}
// 数组中去重添加，可设置回调
Array.prototype.distinctPush = function(data, compares) {
    if (!compares) {
        // 没有设置回调，使用等号
        let contains = false;
        for (let i = 0; i < this.length; i++) {
            if (this[i] === data) {
                // 已经有这个对象了
                contains = true;
                break;
            }
        }
        if (!contains) {
            this.push(data);
        }
    } else {
        // 通过回调判定是否相同
        let contains = false;
        for (let i = 0; i < this.length; i++) {
            if (compares(data, this[i])) {
                // 已经有这个对象了
                contains = true;
                break;
            }
        }
        if (!contains) {
            this.push(data);
        }
    }
}
function deepClone(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }
    let result = Array.isArray(obj)? []: {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            result[key] = deepClone(obj[key]);
        }
    }
    return result;
}

/**
 * 获取图片的地址
 * @param url
 */
export function getImageUrl(url) {
    if (url.startsWith("http")) {
        return url;
    } else if (url.startsWith("data:")) {
        return url;
    } else {
        // 需要拼接
        let base = process.env.VUE_APP_FILE_URL;
        if (!base.endsWith("/")) {
            base += "/";
        }
        if (url.startsWith("/")) {
            // 去掉开头的/
            url = url.substring(1);
        }
        return base + url;
    }
}

/**
 * 动态创建一个音频
 */
function createAudio(url,audioNode){
    return new Promise((resolve, reject) => {
        let body = document.querySelector("body");
        const audioElement = document.createElement('audio');
        audioElement.src = url; // 替换为你的音频文件路径
        audioElement.controls = false; // 不显示控制器
        audioElement.autoplay = true; // 自动播放
        audioElement.loop = false; // 不循环播放
        audioElement.onended = () => {
            // 音频播放结束后，可以选择性地从DOM中移除音频元素
            body.removeChild(audioElement);
        };
        // 将音频元素添加到页面
        body.appendChild(audioElement);
        resolve();
    })
}

export default {
    register(app) {
        // 注册一个深度复制函数
        app.config.globalProperties.$deepClone = deepClone;
        app.config.globalProperties.$getImageUrl = getImageUrl;
    },
    createAudio
}