import axios from "../../../util/axios";

export default {
    // 查询数据列表
    queryList: (data) => axios.get({url: `/order/stock/inventory/queryList`,data: data}),
    // 保存草稿
    saveDraft: (data) => axios.post({url: `/order/stock/inventory/saveDraft`,data: data}),
    // 保存提交盘点单
    saveSubmit: (data) => axios.post({url: `/order/stock/inventory/saveSubmit`,data: data}),
    // 删除草稿
    delete: (id) => axios.del({url: `/order/stock/inventory/delete`,data: {id}}),
}