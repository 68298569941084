import axios from "../../../util/axios";

export default {
    // 带分页查询
    queryList: (data) => axios.get({url: "/order/house/type/queryList", data: data}),

    // 不带分页查询
    queryAllList: (data) => axios.get({url: "/order/house/type/queryAllList", data: data}),

    // 保存
    save: (data) => axios.post({url: "/order/house/type/save", data: data}),

    // 删除
    deleteById: (id) => axios.del({url: "/order/house/type/deleteById", data: {id}})
}