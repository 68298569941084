import axios from "../../../util/axios";
export default {
    // 查询订单列表
    queryList: (data) => axios.get({url: "/alpine/sale/warranty/queryList", data:data}),
    queryList4Server: (data) => axios.get({url: "/alpine/sale/warranty/queryList4Server", data:data}),
    // 查询售后
    queryWarrantyInfo: (id) => axios.get({url: "/alpine/sale/warranty/queryWarrantyInfo", data: {id}}),
    // 车辆部位
    queryCarParts: () => axios.get({url: "/alpine/sale/warranty/queryCarParts", }),
    // 变更质保的相关信息
    changeWarrantyInfo: (data) => axios.post({url: "/alpine/sale/warranty/changeWarrantyInfo", data: data}),
    // 变更质保时间
    changeWarrantyTime: (startTime, endTime, detailId) => axios.get({url: "/order/saleOrder/offlineOrder/changeWarrantyTime", data: {startTime, endTime, detailId}}),
}