import axios from "../../../util/axios";

export default {
    // 列表查询
    queryList: (searchInfo) => axios.get({url: "/order/shortCut/queryList", data: searchInfo}),
    // 保存提交
    saveSubmit: (data) => axios.post({url: "/order/shortCut/saveSubmit", data: data}),
    // 删除
    delete: (id) => axios.del({url: "/order/shortCut/delete", data: {id}}),
    // 查询当前类型的所有菜单
    queryAllInfo: (data) => axios.get({url: "/order/shortCut/queryAllInfo",data: data}),
}