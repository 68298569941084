<template>
  <!-- 联动图片组件 -->
  <div class="bg-linked" :style="{'margin-top': dataInfo.pictureInfo[0].marginTop,height: dataInfo.pictureInfo[0].height,border: dataInfo.pictureInfo[0].height ? '' : '1px solid #ccc'}">
    <div class="left">
      <div style="position:relative;width: 100%">
        <img :src="dataInfo.pictureInfo[0].imageUrl" alt="">
        <span
            :style="{position: 'absolute',left: dataInfo.pictureInfo[0].left, top: dataInfo.pictureInfo[0].top,
             'font-size': dataInfo.pictureInfo[0].size,color: dataInfo.pictureInfo[0].color,'white-space': dataInfo.pictureInfo[0].whiteSpace ? 'normal' : 'nowrap',
              'width': dataInfo.pictureInfo[0].width,'border-radius': dataInfo.pictureInfo[0].radius}">{{
            dataInfo.pictureInfo[0].text
          }}</span>
      </div>
    </div>
    <div class="right">
      <div style="position:relative;width: 100%">
        <img :src="dataInfo.pictureInfo[1].imageUrl" alt="">
        <span
            :style="{position: 'absolute',left: dataInfo.pictureInfo[1].left, top: dataInfo.pictureInfo[1].top,
            'font-size': dataInfo.pictureInfo[1].size,color: dataInfo.pictureInfo[1].color,'white-space': dataInfo.pictureInfo[1].whiteSpace ? 'normal' : 'nowrap',
            'width': dataInfo.pictureInfo[1].width,'border-radius': dataInfo.pictureInfo[1].radius}">{{
            dataInfo.pictureInfo[1].text
          }}</span>
      </div>
      <div style="position:relative;width: 100%">
        <img :src="dataInfo.pictureInfo[2].imageUrl" alt="">
        <span :style="{position: 'absolute',left: dataInfo.pictureInfo[2].left, top: dataInfo.pictureInfo[2].top, 'font-size': dataInfo.pictureInfo[2].size,color: dataInfo.pictureInfo[2].color,'white-space': dataInfo.pictureInfo[2].whiteSpace ? 'normal' : 'nowrap',
       'width': dataInfo.pictureInfo[2].width,'border-radius': dataInfo.pictureInfo[2].radius}">{{ dataInfo.pictureInfo[2].text }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import {watch} from "vue";

export default {
  props: {
    // 修改信息
    configuration: {default: {}},
    // 索引位置，用来寻找匹配的配置信息作渲染遍历
    indexToFind: {default: null},
    // 初始化信息
    initData: {default: {}},
  },
  name: "LinkedImg",
  components: {},
  data() {
    return {
      dataInfo: {},
    };
  },
  created() {
    // 监听这个配置信息
    watch([() => this.configuration], () => {
      this.init();
    }, {deep: true})
    // 刷新重载、只在页面初始化时执行一次
    if (this.initData !== null) {
      this.dataInfo = this.initData;
    }
  },
  methods: {
    // 初始化
    init() {
      if (this.indexToFind !== this.configuration.index && !this.dataInfo) {
        return;
      }
      if (this.indexToFind === this.configuration.index) {
        this.dataInfo = this.configuration;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.bg-linked {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: space-around;

  img {
    border-radius: 5px 5px;
  }

  .left {
    width: 47%;

    img {
      width: 100%;
      height: 180px;
    }
  }

  .right {
    width: 47%;
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      height: 88px;
    }

    img:nth-child(2) {
      margin-top: 5px;
    }
  }
}
</style>