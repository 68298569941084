<template>
  <!-- 通用模板，列表数据 -->
  <div class="defaultViewStyle">
    <el-tabs v-model="activeName">
      <el-tab-pane label="销售协议价" name="client" lazy>
        <mer-chant-deal-price-list :merchant-id="userInfo.merchantId"/>
      </el-tab-pane>
      <el-tab-pane label="采购协议价" name="supplier" lazy>
        <mer-chant-deal-price-list :client-id="userInfo.merchantId"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import merChantDealPriceList from "@/views/main/supplyChain/dealPrice/MerChantDealPriceList.vue";
import util from "@/util/util";
export default {
  name: "",
  components: {
    merChantDealPriceList
  },
  data() {
    return {
      activeName: "client",
      userInfo: {}
    };
  },
  created() {
    util.getUserInfo().then(user => {
      this.userInfo = user;
    })
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>

</style>