<template>
  <!-- 详情表统一Header -->
  <div class="my-header" :id="id">
<!--    <div>{{title}}</div>-->
    <div>
      <span v-if="isCusTitle" class="title">{{ cusTitle }}</span>
      <span v-else class="title" v-for="(t, i) in pageHeaders" :key="i" @click="toGoBack(t)">{{t.label}}</span>
    </div>
    <div>
      <page-button v-if="pageHeaders.length > 1 && isShow" @click="goBack">返回</page-button>
      <slot></slot>
    </div>
  </div>
</template>

<script>

import util from "@/util/util";
import {useRoute, useRouter} from "vue-router";

export default {
  setup() {
    return {
      router: useRouter(),
      route: useRoute(),
    }
  },
  props: {
    title: {default: ""},
    // 自定义title，是否开启
    isCusTitle: {default: false},
    // 自定义title名称
    cusTitle: {default: ""},

    isShow: {default: true}
  },
  name: "PageHeader",
  components: {
  },

  data() {
    return {
      id: "",
      pageHeaders: [],
      timeOut: "",
      path: ""
    };
  },
  created() {
    this.id = "page_header" + (Math.random()*9999).toFixed(0);
    this.init();
    // 禁用浏览器返回按钮
    window.history.pushState(null, null, document.URL);
    window.addEventListener("popstate", this.beforeLeave)
  },
  unmounted() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
    window.removeEventListener("popstate", this.beforeLeave);
  },
  mounted() {
    this.$nextTick().then(() => this.resetParentHeight());
  },
  methods: {
    beforeLeave() {
      window.history.pushState(null, null, document.URL);
    },
    init() {
      // 找到菜单,path只能通过$parent向上级，找到el-tab-pane
      let path = this.getPath(this.$parent);
      this.path = path;
      // 通过path，找到所有相关的路由参数
      let allRouters = this.getAllRouter(path);
      allRouters = allRouters.sort((a, b) => a.path.split("/").length - b.path.split("/").length);
      let arr = [];
      for (let i = 0; i < allRouters.length; i++) {
        arr.push({label: allRouters[i].meta.label, path: allRouters[i].path})
      }
      this.pageHeaders = arr;
    },
    getPath(doc) {
      if (doc.name && doc.name.startsWith("/")) {
        return doc.name;
      }
      if (!doc.$parent) {
        // 没有上级
        return "";
      }
      return this.getPath(doc.$parent);
    },
    getAllRouter(path) {
      let arr = [];
      let routeList = this.router.getRoutes();
      for (let i = 0; i < routeList.length; i++) {
        if (path.startsWith(routeList[i].path)&& routeList[i].path !== "/") {
          arr.push(routeList[i]);
        }
      }
      return arr;
    },
    goBack() {
      this.$routerUtil.push(this.pageHeaders[this.pageHeaders.length - 2].path);
    },
    toGoBack(history) {
      if (this.$routerUtil.path === history.path) {
        return;
      }
      this.$routerUtil.push(history.path);
    },
    resetParentHeight() {
      if (this.$parent.setConditionHeight) {
        let docHeight = document.querySelector(`#${this.id}`).clientHeight;
        if (docHeight === 0) {
          this.timeOut = setTimeout(() => this.resetParentHeight(), 100);
          return;
        }
        this.$parent.setConditionHeight(docHeight, "pageHeader");
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.my-header{
  font-size: 14px;
  height: 45px;
  line-height: 45px;
  display: flex;
  justify-content: space-between;
  .title{
    cursor: pointer;
    margin-right: 10px;
    user-select: none;
  }
  .title:first-child{
    font-size: 16px;
    font-weight: 600;
    //color: #409eff;
  }
  .title::after{
    content: "/";
    margin-left: 10px;
    color: #333;
    font-weight: 500;
  }
  .title:last-child::after{
    content: "";
  }
}
</style>