import axios from "@/util/axios";

export default {

    // 查询模板
    queryTemp: ()=> axios.get({url: "/basedata/base/common/mini/home/temp/queryTemp"}),

    // 保存
    saveSubmit: (data)=> axios.post({url: "/basedata/base/common/mini/home/temp/saveSubmit", data: data}),

    // 删除
    delete: (index)=> axios.del({url: "/basedata/base/common/mini/home/temp/delete", data: {index}}),

    // 根据索引进行回显
    findByIndex: (index)=> axios.get({url: "/basedata/base/common/mini/home/temp/findByIndex", data: {index}})
}