<template>
  <!-- -->
  <div>
    <original-code-list />
  </div>
</template>

<script>
import OriginalCodeList from "@/views/alpine/merchant/stock/OriginalCodeList.vue";
export default {
  name: "",
  components: {
    OriginalCodeList
  },
  data() {
    return {

    };
  },
  created() {
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>

</style>