<template>
  <!-- -->
  <div class="defaultViewStyle">
    <el-tabs v-model="activeName">
      <el-tab-pane name="test1" label="订单详情" lazy>
        <daily-settle-detail :type="true" :search-vo="searchInfoVo" />
      </el-tab-pane>
      <el-tab-pane name="test2" label="押金详情" lazy>
        <daily-settle-detail :type="false" :search-surplus-vo="searchInfoSurplusVo" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import DailySettleDetail from "@/views/main/plat/finance/daily/DailySettleDetail.vue";
export default {
  name: "RestockType",
  components: {
    DailySettleDetail
  },
  data() {
    return {
      activeName: "test1",
      searchInfoVo: {},
      searchInfoSurplusVo: {},
    };
  },
  watch:{
  },
  created() {
    this.searchInfoVo = {
      employeeId: this.$routerUtil.query.employeeId,
      payTypeId: this.$routerUtil.query.payTypeId,
      departCode: this.$routerUtil.query.departCode,
      startTime: this.$routerUtil.query.startTime,
      endTime: this.$routerUtil.query.endTime,
      readAccess: this.$routerUtil.query.readAccess,
    }
    this.searchInfoSurplusVo = {
      employeeId: this.$routerUtil.query.employeeId,
      payTypeId: this.$routerUtil.query.payTypeId,
      startTime: this.$routerUtil.query.startTime,
      endTime: this.$routerUtil.query.endTime,
      readAccess: this.$routerUtil.query.readAccess,
    }
  },

  methods: {
  }
};
</script>

<style lang="scss" scoped>

</style>