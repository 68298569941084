import axios from "../../../util/axios";

export default {
    // 获取发票列表
    queryList: (searchInfo) => axios.get({url: "/order/common/invoice/queryList", data: searchInfo}),
    // 获取/开具发票
    findInvoice: (data) => axios.post({url: "/order/common/invoice/findInvoice", data: data}),
    // 获取所有发票接口信息
    queryInvoiceTypeList: () => axios.get({url: "/order/common/invoice/queryInvoiceTypeList"}),
    // 获取对应的发票模板
    findInvoiceTemplate: (invoiceCodeType) => axios.get({url: "/order/common/invoice/findInvoiceTemplate", data: {invoiceCodeType}}),
    // 保存发票
    saveSubmit: (invoiceVo) => axios.post({url: "/order/common/invoice/saveSubmit", data: invoiceVo}),
    // 删除
    delete: (id) => axios.del({url: "/order/common/invoice/deleteById", data: {id}}),
    // 回显
    findById: (id) => axios.get({url: "/order/common/invoice/findById", data: {id}}),
    // 获取所有发票
    queryAllList: () => axios.get({url: "/order/common/invoice/queryAllList"}),
    // 获取发票结果
    findResult: (settleCode,invoiceCodeType) => axios.get({url: "/order/common/invoice/findResult", data: {settleCode,invoiceCodeType}}),
    // 开红冲
    findRedInvoice: (orderId, invoiceCodeType) => axios.get({url: "/order/common/invoice/findRedInvoice", data: {orderId, invoiceCodeType}})
}