import axios from "../../../../util/axios";

export default {
    // 查询订单列表
    queryList: (searchInfo) => axios.get({url: "/order/saleOrder/refund/queryList", data: searchInfo}),
    // 查询仓库
    queryAllStock: (bankupId) => axios.get({url: "/order/saleOrder/refund/queryAllStock", data: {bankupId}}),
    // 查询详情
    findRefundById: (id) => axios.get({url: "/order/saleOrder/refund/findRefundById", data: {id}}),
    //退货
    refund: (orderId) => axios.get({url: "/order/saleOrder/refund/refund", data: {orderId}}),
    //删除
    delete: (id) => axios.del({url: "/order/saleOrder/refund/delete", data: {id}}),
    //保存草稿
    saveDraft: (data) => axios.post({url: "/order/saleOrder/refund/toSaveDraft", data: data}),
    //换货退款
    cdRefund: (orderId) => axios.get({url: "/order/saleOrder/refund/cdRefund", data: {orderId}}),
}