import axios from "../util/axios";

export default {
    /** 职员通过密码登录 */
    loginEmployeeByPassword: (user) => axios.post({url: "/basedata/base/loginEmployeeByPassword", data: user}),
    /** 平台管理员通过密码登录 */
    loginPlatUserByPassword: (user) => axios.post({url: "/basedata/base/loginPlatUserByPassword", data: user}),
    /** 职员通过code扫码登录 */
    loginEmployeeByQywxCode: (code) => axios.get({url: "/basedata/base/loginEmployeeByQywxCode", data: {code}}),
    /** 企业微信的code登录 */
    loginEmployeeByQywxCode2: (code, loginType) => axios.get({url: "/basedata/base/loginEmployeeByQywxCode2", data: {code, loginType}}),
    /** 企业微信的code登录 */
    loginEmployeeByQywxCode3: (code) => axios.get({url: "/basedata/base/loginEmployeeByQywxCode3", data: {code}}),
    /** 获取职员信息 */
    getUserInfo: (employeeId) => axios.get({url: "/basedata/base/getUserInfo", data: {employeeId}}),
    /** 解析企业微信code,解析app应用的user */
    analysisQywxCode: (code) => axios.get({url: "/basedata/base/analysisQywxCode", data: {code}}),
    /** 解析企业微信code，解析服务商的user */
    analysisQywxCode3: (code) => axios.get({url: "/basedata/base/analysisQywxCode3", data: {code}}),
    /** 绑定企业微信账号 */
    createdEmployeeQywxRelation: (data) => axios.get({url: "/basedata/base/createdEmployeeQywxRelation", data: data}),
    /** 查询角色可用菜单 */
    getMenu: (systemType = 1, roleId) => axios.get({url: "/basedata/common/getMenu", data: {systemType, roleId}}),
    /** 已登录用户退出登录 */
    logout: () => axios.del({url: "/basedata/common/logout"}),
    /** 获取验证码*/
    getCheckNumber: () => axios.get({url: "/basedata/base/getCheckNumber"}),
    /**获取自定义流程*/
    queryAllFlows: () => axios.get({url: "/basedata/common/process/queryAllFlows"}),
    // 获取二维码
    getQrCode: (skuId, count) => axios.get({url: "/order/base/getQrCode", data: {skuId, count}}),
    /** 分享对账单，按id查询对账单详情 */
    findStatementById: (id) => axios.get({url: "/business/base/statement/findById", data: {id}}),
    /** 分享对账单，查看erp单据明细 */
    findStatementErpOrder: (orderErpId, merchantId) => axios.get({url: "/business/base/statement/findThirdOrderDetail", data: {orderErpId, merchantId}}),
    /** 重新发起支付 */
    toPay: (id, merchantId) => axios.get({url: "/business/base/statement/toPay", data: {id, merchantId}}),
    /** 重新发起支付 */
    payResult: (id, merchantId) => axios.get({url: "/business/base/statement/payResult", data: {id, merchantId}}),
    // 获取门店码
    getStoreCode: (storeId, storeName) => axios.get({url: "/order/base/getStoreCode", data: {storeId, storeName}}),
    text: (id)=>axios.get({url: `/order/saleOrder/offlineOrder/findCountByCustomerId`,data :{customerId: id}})

}