import axios from "../../../util/axios";

export default {
    // 查询预警消息列表
    queryList: (data)=> axios.get({url: `/order/warn/stockWarn/queryList`, data: data}),
    // 查询预警规则列表
    queryRuleGroupList: (data)=> axios.get({url: `/order/warn/stockWarn/queryRuleGroupList`, data: data}),
    // 保存预警规则
    saveGroup: (data)=> axios.post({url: `/order/warn/stockWarn/saveGroup`, data: data}),
    // 预警规则挂起
    hangupRule: (data)=> axios.post({url: `/order/warn/stockWarn/hangupRule`, data: data}),
    // 删除预警规则
    deleteRuleGroup: (id)=> axios.del({url: `/order/warn/stockWarn/deleteRuleGroup`, data: {id}}),
    // 删除预警规则
    queryStock: ()=> axios.get({url: `/order/warn/stockWarn/queryStock`,}),
    // 查询预警规则详情
    findById: (id)=> axios.get({url: `/order/warn/stockWarn/findById`,data: {id}}),
    // 查询预警规则详情
    closeRuleGroup: (id)=> axios.get({url: `/order/warn/stockWarn/closeRuleGroup`,data: {id}}),
    // 查询预警规则详情
    openRuleGroup: (id)=> axios.get({url: `/order/warn/stockWarn/openRuleGroup`,data: {id}}),
    // 查询库存类的商品
    queryStockProducts: (searchInfo)=> axios.get({url: `/order/warn/stockWarn/queryStockProducts`,data: searchInfo}),
}