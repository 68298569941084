<template>
  <!-- 客房类型 -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>
          <page-button el-type="primary" @click="resetSearch">查询</page-button>
          <page-button type="write" @click="toEdit({})" el-type="success">新建</page-button>
        </template>
      </page-header>
      <search-condition @search="resetSearch">
        <el-input v-model="searchInfo.searchKey" placeholder="类型名称"/>
      </search-condition>
      <table-list>
        <el-table :data="dataList" height="100%" border>
          <el-table-column align="center" label="序号" width="100px" type="index"/>
          <el-table-column align="center" label="类型名称" prop="typeName"/>
          <el-table-column label="操作" :align="'center'" width="120px">
            <template v-slot="{row}">
              <table-button type="write" @click="toEdit(row)">编辑</table-button>
              <table-button type="delete" el-type="danger" message="是否确认删除此数据" @click="deleteData(row)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize" :total-count="searchInfo.totalCount" @onLoad="queryList"/>
    </list-page>

    <el-dialog @closed="detail = {}" v-model="showDialog" title="客房档案" width="40%">
      <el-form label-width="70px">
        <el-form-item label="类型名称">
          <el-input v-model="detail.typeName"/>
        </el-form-item>
      </el-form>
      <div style="text-align: right">
        <page-button @click="showDialog = false">取消</page-button>
        <page-button type="write" @click="save" el-type="primary">保存</page-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import Dictionary from "@/components/h5/Dictionary.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import ListPage from "@/components/h5/layout/ListPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import houseTypeApi from "@/api/main/house/houseTypeApi";

export default {
  name: "",
  components: {
    TableButton, SearchCondition, PageHeader, PageButton, ListPage, FormPage, TableList, Dictionary
  },
  data() {
    return {
      searchInfo: {
        searchType: 0, // 客房类型
      },
      dataList: [],
      showDialog: false,
      detail: {},
    };
  },
  created() {
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      houseTypeApi.queryList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      })
    },
    toEdit(row){
      this.detail = row;
      this.showDialog = true;
    },
    deleteData(row){
      houseTypeApi.deleteById(row.id).then(() => this.resetSearch());
    },
    save(){
      // 类型
      this.detail.status = 0;
      houseTypeApi.save(this.detail).then(() => {
        this.showDialog = false;
        this.resetSearch();
      });
    }
  }
};
</script>

<style lang="scss" scoped>

</style>