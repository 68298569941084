<template>
  <!-- 专用于在card中显示金额 -->
  <span :class="'show-money ' + (showColor? 'show-color': '')">
    <span class="show-money-mark">
      <span v-if="showMark">￥</span>
      <span style="color: #073fda;font-weight: 600;" v-if="value < 0">-</span>
    </span>
    <span class="show-money-main" v-for="(a, i) in data" :key="i">{{a}}<span v-if="notation && i < data.length - 1">,</span></span>
    <span>.</span>
    <span class="show-money-float">{{float}}</span>
    <span class="show-money-unit" v-if="showUnit">元</span>
  </span>
</template>

<script>

// 统一进行金额数据显示
import {watch} from "vue";

export default {
  props: {
    color: {default: null},
    // 是否显示金额符号
    showMark: {default: false},
    // 是否显示单位
    showUnit: {default: false},
    // 是否使用科学计数法
    notation: {default: true},
    // 是否显示为彩色
    showColor: {default: false},
    // 值，value和amount一样的
    value: {
      type: [String, Number]
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  name: "Money",
  components: {
  },
  data() {
    return {
      data: [],
      float: "00",
    };
  },
  created() {
    this.init();
    watch(() => this.value, () => this.init());
  },
  methods: {
    init() {
      let amount = this.value || 0;
      this.data = [];
      let data = Math.abs(amount).toFixed(2).split(".");
      this.float = data[1];
      let am = parseInt(data[0]);
      while (am > 1000) {
        this.data.push(Math.floor(am / 1000));
        am = am % 1000;
      }
      if (this.data.length > 0) {
        // 至少有两段数据，剩下的需要补0
        if (am >= 0) {
          // 需要补0
          if (am < 10) {
            this.data.push("00" + am);
          } else if (am < 100) {
            this.data.push("0" + am);
          } else {
            this.data.push(am);
          }
        }
      } else {
        this.data.push(am);
      }

    }
  }
};
</script>

<style lang="scss" scoped>
.show-money{
  font-size: 14px;
  color: #999;
  .show-money-mark{

  }
  .show-money-main{
    font-size: 14px;
    font-weight: 600;
    color: #303133;
  }
  .show-money-float{
    font-size: 12px;
    color: #606266;
  }
  .show-money-unit{
    font-size: 12px;
  }
}
.show-color{
  .show-money-main{
    color: #073fda;
  }
  .show-money-float{
    color: #073fda;
  }
}
</style>