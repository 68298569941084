<template>
  <!-- 通用模板，列表数据 -->

  <list-page v-if="type">
    <search-condition @search="resetSearch">
      <date-range v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期"
                  v-model:end-time="searchInfo.endTime"/>

      <div class="customer-select" style="display: inline-block; width: 240px; margin-right: 10px">
        <employee-select v-if="readAccess !== '1'" :placeholder-tit="'请选择收费员'" v-model="searchInfo.employeeId"
                         @selectOne="setEmployee" :access="readAccess"/>
      </div>
      <el-select v-model="searchInfo.payTypeId" placeholder="请选择账户类型" clearable style="width: 240px">
        <el-option
            v-for="item in payTypeList"
            :key="item.value"
            :label="item.payName"
            :value="item.id"
        />
      </el-select>
      <template #moreCondition>
        <el-input v-model="searchInfo.orderId" placeholder="请扫描/输入订单编号"/>
      </template>
      <template #option>
        <page-button @click="$routerUtil.push(`/plat/dailySettle`)">返回</page-button>
        <page-button el-type="primary" type="print" @click="exportDataOrder">导出</page-button>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
      </template>
    </search-condition>
    <table-list>
      <!--        -->
      <el-table :data="dataList" height="100%" border>
        <el-table-column align="center" label="序号" type="index" min-width="60px" fixed/>
        <el-table-column align="center" label="单据日期" prop="payTime" show-overflow-tooltip min-width="200px" fixed/>
        <el-table-column align="center" label="订单编号" show-overflow-tooltip min-width="200px" fixed>
          <template v-slot="{row}">
            <span>{{ row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="机构" prop="departName" show-overflow-tooltip min-width="120px"/>
        <el-table-column align="center" label="仓库" show-overflow-tooltip min-width="120px">
          <template v-slot="{row}">
            <show-specific :info-list="stockList" :value="row.stockId" :name-column="'stockName'"/>
          </template>
        </el-table-column>
        <el-table-column align="center" label="客户" prop="customerName" min-width="150px"/>
        <el-table-column align="center" label="职员" prop="employeeName" min-width="150px"/>
        <el-table-column align="center" prop="qty" label="交易笔数" min-width="150px" />
        <el-table-column prop="amount" align="center" label="单据金额" min-width="150px" />
        <el-table-column align="center" prop="amountCollect" label="流水金额" min-width="150px" />
        <el-table-column align="center" label="账户名称" show-overflow-tooltip min-width="150px">
          <template v-slot="{row}">
              <span v-for="(p, i) in payTypeList" :key="i">
                <span v-if="row.payTypeId === p.id">{{ p.payName }}</span>
              </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="单据类型" prop="orderTypeName" show-overflow-tooltip min-width="150px" />
        <el-table-column label="操作" :align="'center'" min-width="200px" fixed="right">
          <template v-slot="{row}">
            <table-button @click="showOrderDetail(row)">查看单据</table-button>
          </template>
        </el-table-column>
      </el-table>
    </table-list>
    <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
               :total-count="searchInfo.totalCount" @onLoad="queryOrderList"></form-page>
  </list-page>

  <list-page v-if="!type">
    <search-condition @search="resetSearchSurplus">
      <date-range v-model:start-time="searchInfoSurplus.startTime" placeholder="请选择开始日期"
                  v-model:end-time="searchInfoSurplus.endTime"/>

      <div class="customer-select" style="display: inline-block; width: 240px; margin-right: 10px">
        <employee-select :placeholder-tit="'请选择收费员'" v-model="searchInfoSurplus.employeeId"
                         @selectOne="setEmployeeSurplus" :access="readAccess"/>
      </div>
      <el-select v-model="searchInfoSurplus.payTypeId" placeholder="请选择账户类型" clearable style="width: 240px">
        <el-option
            v-for="item in payTypeList"
            :key="item.value"
            :label="item.payName"
            :value="item.id"
        />
      </el-select>
      <template #moreCondition>
        <el-input v-model="searchInfoSurplus.orderId" placeholder="请扫描/输入订单编号"/>
      </template>
      <template #option>
        <page-button @click="$routerUtil.push(`/plat/dailySettle`)">返回</page-button>
        <page-button el-type="primary" type="print" @click="exportDataSurplus">导出</page-button>
        <page-button el-type="primary" @click="resetSearchSurplus">查询</page-button>
      </template>
    </search-condition>
    <table-list>
      <!--    border show-summary :summary-method="getSummary"     -->
      <el-table :data="surplusList" height="100%" border>
        <el-table-column align="center" label="序号" type="index" min-width="60px" fixed/>
        <el-table-column align="center" label="单据日期" prop="payTime" show-overflow-tooltip min-width="200px" fixed/>
        <el-table-column align="center" label="单据编号" show-overflow-tooltip min-width="200px" fixed>
          <template v-slot="{row}">
            <span>{{ row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="机构" prop="departName" show-overflow-tooltip min-width="120px"/>
        <el-table-column align="center" label="客户" prop="customerName" min-width="150px"/>
        <el-table-column align="center" label="职员" prop="payeeName" min-width="150px"/>
        <el-table-column align="center" label="销售金额" min-width="150px">
          <template v-slot="{row}">
            <span v-if="row.surplusPayType === 1"> {{ row.amountPay }} </span>
            <span v-else-if="row.surplusPayType === 2"> {{ row.refundAmount }} </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="账户名称" show-overflow-tooltip min-width="150px">
          <template v-slot="{row}">
            <span v-for="(p, i) in payTypeList" :key="i">
                <span v-if="row.payTypeId === p.id">{{ p.payName }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="depositTypeName" label="押金类型" show-overflow-tooltip
                         min-width="150px"/>
        <el-table-column align="center" label="单据类型" show-overflow-tooltip min-width="150px">
          <template v-slot="{row}">
            <span v-if="row.surplusPayType === 1">押金付款</span>
            <span v-else-if="row.surplusPayType === 2">押金退款</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" :align="'center'" min-width="200px" fixed="right">
          <template v-slot="{row}">
            <table-button @click="showOrderDetail(row)">查看单据</table-button>
          </template>
        </el-table-column>
      </el-table>
    </table-list>
    <form-page v-model:page-size="searchInfoSurplus.pageSize" v-model:page-number="searchInfoSurplus.pageNumber"
               :total-count="searchInfoSurplus.totalCount" @onLoad="querySurplusList"></form-page>
  </list-page>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import TableButton from "@/components/h5/button/TableButton";
import DateRange from "@/components/h5/date/DateRange.vue";
import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import ShowSpecific from "@/components/h5/product/ShowSpecific.vue";
import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import orderStatisticApi from "@/api/main/order/orderStatisticApi";
import {h} from "vue";
import systemPayApi from "@/api/main/sys/systemPayApi";
import util from "@/util/util";

export default {
  name: "DailyDetail",
  props: {
    type: {default: true},
    searchVo: {
      type: Object, default: () => {
      }
    },
    searchSurplusVo: {
      type: Object, default: () => {
      }
    }
  },
  components: {
    ShowSpecific,
    PageButton,
    EmployeeSelect,
    DateRange,
    TableButton,
    ListPage, TableList, FormPage, SearchCondition
  },
  data() {
    return {
      searchInfo: {},
      searchInfoSurplus: {},
      dataList: [],
      surplusList: [],
      payTypeList: [],
      stockList: [],
      readAccess: null,
    };
  },
  created() {
    // 仓库
    offlineOrderApi.queryAllStock().then(list => this.stockList = list);
    // 支付方式
    systemPayApi.queryAllList().then(res => this.payTypeList = res);
    // 回显数据
    if (this.type) {
      this.searchInfo = this.searchVo;
      this.readAccess = this.searchVo.readAccess;
    } else {
      this.searchInfoSurplus = this.searchSurplusVo;
      this.readAccess = this.searchSurplusVo.readAccess;
    }
  },
  methods: {
    exportDataOrder() {
      util.exportExcel("/order/saleOrder/statistic/exportOrderList", this.searchInfo);
    },
    exportDataSurplus() {
      util.exportExcel("/order/saleOrder/statistic/exportSurplusList", this.searchInfoSurplus);
    },
    showOrderDetail(row) {
      if (row.orderType === 1) {
        // 销售单
        this.$routerUtil.push(`/report/offline-order/detail?id=${row.id}`)
      } else if (row.orderType === 2) {
        // 退货单
        this.$routerUtil.push(`/report/refund-order/detail?id=${row.id}&isFinish=2`)
      } else if (row.surplusPayType === 1) {
        this.$routerUtil.push(`/advance/list/detail?id=${row.id}`)
      } else if (row.surplusPayType === 2) {
        this.$routerUtil.push(`/advance/list/refund?id=${row.id}`)
      }
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryOrderList();
    },
    resetSearchSurplus() {
      this.searchInfoSurplus.pageNumber = 1;
      this.querySurplusList();
    },
    queryOrderList() {
      orderStatisticApi.queryAccountDetailList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      })
    },
    querySurplusList() {
      orderStatisticApi.querySurplusList(this.searchInfoSurplus).then(res => {
        this.surplusList = res.records;
        this.searchInfoSurplus.totalCount = res.totalCount;
      })
    },

    /**
     * 非查询方法：
     */
    setEmployee(data) {
      this.searchInfo.employeeId = data.id;
      this.searchInfo.employeeName = data.employeeName;
    },
    setEmployeeSurplus(data) {
      this.searchInfoSurplus.employeeId = data.id;
      this.searchInfoSurplus.employeeName = data.employeeName;
    },
    // 自定义统计-账户
    getSummaries(param) {
      const {columns} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {textDecoration: 'underline', fontWeight: 'bolder'}}, [
            '合  计',
          ])
          return
        }

        if (index === 7) {
          sums[index] = h('div', [(this.dataList[0].totalQty)])
        }

        if (index === 8) {
          sums[index] = h('div', [(this.dataList[0].totalAmount)])
        }

        if (index === 9) {
          sums[index] = h('div', [(this.dataList[0].saleTotalAmount)])
        }


      })
      return sums
    },
    // getSummary(param){
    //   const {columns, data} = param
    //   const sums = []
    //   columns.forEach((column, index) => {
    //     if (index === 0) {
    //       sums[index] = h('div', {style: {textDecoration: 'underline', fontWeight: 'bolder'}}, [
    //         '合  计',
    //       ])
    //       return
    //     }
    //     if (index === 6) {
    //       let amount = 0;
    //       for (let i = 0; i < data.length; i++) {
    //         if(data[i].surplusPayType === 1){
    //           amount += Number(data[i].amountPay);
    //         }else{
    //           amount -= Number(data[i].amountPay);
    //         }
    //       }
    //       console.log(amount)
    //       sums[index] = h('div', ["￥ " + (Number(amount))])
    //     }
    //     //   Number(sums[2].substring(1, sums.length)) +   - Number(sums[4].substring(1, sums.length))
    //   })
    //   return sums
    // }
  }
};
</script>

<style lang="scss" scoped>
.customer-select {
  width: 240px;
  margin-bottom: 10px;
  box-sizing: border-box;
  margin-right: 10px;
  vertical-align: top;
}
</style>