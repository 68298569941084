let miniConfig = {};

// 小程序首页模板
miniConfig.miniTemplate = [
    {type: 1, label: '轮播图片', icon: require('@/assets/image/mini/banner.png'), comName: 'BannerImg'},
    {type: 2, label: '联动图片', icon: require('@/assets/image/mini/linked-img.png'), comName: 'LinkedImg'},
    {type: 3, label: '通用图片', icon: require('@/assets/image/mini/picture.png'), comName: 'CommonImg'},
    {type: 4, label: '背景占位', icon: require('@/assets/image/mini/picture.png'), comName: 'BgImg'},
    {type: 5, label: '门店选择', icon: require('@/assets/image/mini/picture.png'), comName: 'MiniStoreList'},
    {type: 6, label: '商品选择', icon: require('@/assets/image/mini/picture.png'), comName: 'MiniProductList'},
    {type: 7, label: '平衡图片', icon: require('@/assets/image/mini/picture.png'), comName: 'BalanceImg'},
    {type: 8, label: '活动海报', icon: require('@/assets/image/mini/picture.png'), comName: 'PosterInfo'},
    {type: 9, label: '分类列表', icon: require('@/assets/image/mini/picture.png'), comName: 'CategoryList'},
]
// 每个模板的具体配置信息
miniConfig.miniEdit = [
    {
        type: 1,   // 对应组件-轮播图
        title: '轮播图组件编辑',   // 名称
        tips: ['1、盒子高度以第一张图片设置的高度为准','2、当前只支持添加图片内部文字'], // 提示信息
        isPictureWord: false, // 启用图片文字
        wordInfo: [], // 图片中的文字信息
        isPicLink: false, // 启用图片链接
        routeUri: "", // 图片链接
        pictureInfo: [], // 图片信息
        max: 99, // 上传的最大图片数量
        rightType: 1, // 使用哪种类型
    },
    {
        type: 2,   // 对应组件-联动组件
        title: '联动图组件编辑',
        tips: ['1、盒子高度以第一张图片设置的高度为准','2、该组件必须添加三张图片'],
        isPictureWord: false,
        wordInfo: [],
        isPicLink: false,
        routeUri: "",
        pictureInfo: [],
        max: 3,
        rightType: 1, // 使用哪种类型
    },
    {
        type: 3,   // 对应组件-通用图组件
        title: '通用图组件编辑',
        tips: ['1、通用图的高度默认为120px','2、该组件只允许添加一张图片'],
        isPictureWord: false,
        wordInfo: [],
        isPicLink: false,
        routeUri: "",
        pictureInfo: [],
        max: 1,
        rightType: 1, // 使用哪种类型
    },
    {
        type: 4,
        title: '背景组件编辑', // 对应组件-背景占位
        tips: ['1、该组件目前只支持使用背景色填充', '2、必须选择一张图片，该图片不会生效，只作为设置的依托'],
        isPictureWord: false,
        wordInfo: [],
        isPicLink: false,
        routeUri: "",
        pictureInfo: [],
        max: 1,
        rightType: 1, // 使用哪种类型
    },
    {
        type: 5,
        title: '门店选择组件',
        tips: ['1、通用图的高度默认为120px','2、该组件只允许添加一张图片'],
        isPictureWord: false,
        wordInfo: [],
        isPicLink: false,
        routeUri: "",
        pictureInfo: [],
        max: 99,
        rightType: 2, // 使用哪种类型
    },
    {
        type: 6,
        title: '商品选择组件',
        tips: ['1、通用图的高度默认为120px','2、该组件只允许添加一张图片'],
        isPictureWord: false,
        wordInfo: [],
        isPicLink: false,
        routeUri: "",
        pictureInfo: [],
        max: 99999,
        rightType: 3, // 使用哪种类型
    },
    {
        type: 7,
        title: '平衡图组件',
        tips: ['1、通用图的高度默认为120px','2、该组件只允许添加一张图片'],
        isPictureWord: false,
        wordInfo: [],
        isPicLink: false,
        routeUri: "",
        pictureInfo: [],
        max: 2,
        rightType: 1, // 使用哪种类型
    },
    {
        type: 8,
        title: '活动海报组件',
        tips: ['1、通用图的高度默认为120px','2、该组件只允许添加一张图片'],
        isPictureWord: false,
        wordInfo: [],
        isPicLink: false,
        routeUri: "",
        pictureInfo: [],
        max: 2,
        rightType: 4, // 使用哪种类型
    },
    {
        type: 9,
        title: '分类列表组件',
        tips: ['1、通用图的高度默认为120px','2、该组件只允许添加一张图片'],
        isPictureWord: false,
        wordInfo: [],
        isPicLink: false,
        routeUri: "",
        pictureInfo: [],
        max: 2,
        rightType: 5, // 使用哪种类型
    }
]

export default miniConfig;