<template>
  <!--
    1、新增具体文字样式，需要改的地方( 行数 ) ：162、366、467、552
    2、新增组件步骤：a、在miniConfig中添加对应配置
                  b、addComponent方法中新增组件判断
                  c、在同目录的components中写对应组件
                  d、自定义右侧组件，导入右侧组件，在50行进行添加
  -->
  <div class="defaultViewStyle">
    <div class="container">
      <div class="flex-item first">
        <!-- 组件列表-->
        <div>
          <el-divider>
            <span style="font-weight: bolder; font-size: 17px;">组件列表</span>
          </el-divider>
          <div class="asse">
            <div class="content" draggable="true" @dragstart="handleDragStart($event, item)" @dragend="handleDragEnd"
                 v-for="item in tempList" :key="item.type">
              <img :src="item.icon" alt="">
              <span style="font-size: 12px">{{ item.label }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-item second">
        <div class="phone">
          <img src="../../../../assets/image/mini/phone.jpg" alt="">

          <div class="bg-color" @drop="handleDrop($event)" @dragover.prevent
               :class="{ 'drag-over': isDraggingOver }">

            <div :style=" item.isSelected ? 'border: 1px dashed black;' : ''"
                 v-for="(item, index) in dropComponentList"
                 :key="index" @click="handleClick(index)">

              <component style="box-sizing: border-box" :is="item.component" :index-to-find="index"
                         :configuration="configuration"
                         :init-data="item.initData"/>

            </div>

            <div
                v-if="isDraggingOver"
                ref="dropIndicator"
                class="drop-indicator"
                :style="{ width: dynamicWidth + 'px', height: dynamicHeight + 'px', left: dynamicLeft + 'px', top: dynamicTop + 'px' }">
            </div>
          </div>
        </div>
      </div>
      <div class="flex-item third">
        <!-- 具体信息配置 -->
        <default-select :config="configuration" @refresh="refreshData" :max-length="dropComponentList.length"
                        v-show="configuration.rightType === 1"/>
        <store-select :config="configuration" v-show="configuration.rightType === 2" :max-length="dropComponentList.length" @refresh="refreshData"/>
        <product-select :config="configuration" v-show="configuration.rightType === 3" :max-length="dropComponentList.length" @refresh="refreshData"/>
        <poster-select :config="configuration" v-show="configuration.rightType === 4" :max-length="dropComponentList.length" @refresh="refreshData"/>
        <category-select :config="configuration" v-show="configuration.rightType === 5" :max-length="dropComponentList.length" @refresh="refreshData"/>
      </div>
    </div>
  </div>
</template>

<script>

import DefaultSelect from "@/views/main/mini/homeSetting/ComponentsRight/DefaultSelect.vue";
import ImageControl from "@/components/h5/image/ImageControl.vue";
import miniConfig from "@/config/miniConfig";
import PageButton from "@/components/h5/button/PageButton.vue";
import {Bottom, DeleteFilled, Minus, Plus, Top} from "@element-plus/icons-vue";
import miniRouteApi from "@/api/main/mini/miniRouteApi";
import Dictionary from "@/components/h5/Dictionary.vue";
import imageUpload from "@/components/h5/image/ImageUpload.vue";
import miniTempApi from "@/api/main/mini/miniTempApi";
import BannerImg from "@/views/main/mini/homeSetting/components/BannerImg.vue";
import LinkedImg from "@/views/main/mini/homeSetting/components/LinkedImg.vue";
import CommonImg from "@/views/main/mini/homeSetting/components/CommonImg.vue";
import BgImg from "@/views/main/mini/homeSetting/components/BgImg.vue";
import StoreSelect from "@/views/main/mini/homeSetting/ComponentsRight/StoreSelect.vue";
import MiniStoreList from "@/views/main/mini/homeSetting/components/MiniStoreList.vue";
import BalanceImg from "@/views/main/mini/homeSetting/components/BalanceImg.vue";
import PosterSelect from "@/views/main/mini/homeSetting/ComponentsRight/PosterSelect.vue";
import PosterInfo from "@/views/main/mini/homeSetting/components/PosterInfo.vue";
import CategoryList from "@/views/main/mini/homeSetting/components/CategoryList.vue";
import CategorySelect from "@/views/main/mini/homeSetting/ComponentsRight/CategorySelect.vue";
import MiniProductList from "@/views/main/mini/homeSetting/components/MiniProductList.vue";
import ProductSelect from "@/views/main/mini/homeSetting/ComponentsRight/ProductSelect.vue";

export default {
  name: "TD",
  components: {
    DeleteFilled,
    Bottom,
    Top,
    imageUpload,
    Minus,
    Plus,
    PageButton,
    Dictionary,
    ImageControl,
    DefaultSelect,
    StoreSelect,
    BannerImg,
    LinkedImg,
    CommonImg,
    BgImg,
    MiniStoreList,
    BalanceImg,
    PosterSelect,
    PosterInfo,
    CategoryList,
    CategorySelect,
    MiniProductList,
    ProductSelect,
  },

  data() {
    return {
      isDraggingOver: false,
      dynamicWidth: 0, // 初始化宽度，与原始伪元素宽度一致
      dynamicHeight: 0,
      dynamicLeft: 0, // 初始化边框左偏移
      dynamicTop: 0, // 初始化边框顶偏移
      showPicConfig: false,
      // 记录组件的原顺序
      recordIdx: null,
      // 文本的其它信息
      wordOther: {},
      showWordOther: false,
      showControl: false,
      tempList: [],
      showEdit: false,
      dropComponentList: [],
      configuration: {},
      wordInfo: [], // 文字存储信息
      routeTree: [],
    };
  },
  created() {
    this.tempList = miniConfig.miniTemplate;
    miniRouteApi.queryByTree().then(res => this.routeTree = res)
    // 初始化
    this.queryTemp();
  },
  methods: {
    // 开始拖动
    handleDragStart(event, item) {
      this.isDraggingOver = true;
      event.dataTransfer.setData('text/plain', JSON.stringify(item)); // 传递数据
      this.setWidHei(item.type);
    },
    // 拖动结束
    handleDragEnd() {
      this.isDraggingOver = false;
    },
    // 拖动到目标元素
    handleDrop(event) {
      event.preventDefault();
      const data = JSON.parse(event.dataTransfer.getData('text/plain'));
      // 根据value添加一个组件
      this.addComponent(event, data.type);
      // 显示配置信息
      this.configuration = miniConfig.miniEdit.find(item => item.type === data.type);
      // 计算索引顺序、多存储一份，用来寻找对应的渲染组件
      this.configuration.index = this.dropComponentList.length - 1;
      this.configuration.indexToFindComponet = this.dropComponentList.length - 1;
      // 文字信息
      if (this.configuration.wordInfo.length === 0) {
        this.addWordInfo();
      }
      if (this.configuration.pictureInfo.length > 0) {
        this.configuration.pictureInfo = [];
      }
    },
    // 添加组件
    addComponent(event, val, initData) {
      // 动态引入
      let arr = miniConfig.miniTemplate;
      // 根据val找到对应的组件信息
      let myCom = arr.find(item => item.type === val);
      this.dropComponentList.push({
        component: myCom.comName,
        val: val,
        initData: initData,
      });
    },
    // 清除路由
    // 保存编辑并提交
    refreshData() {
      this.queryTemp().then(() => this.configuration = {});
    },
    // 手机组件内模块被选中
    handleClick(index) {
      for (let i = 0; i < this.dropComponentList.length; i++) {
        if (i === index) {
          this.dropComponentList[i].isSelected = true;
          this.findByIndex(i).catch(() => {
            // 显示配置信息
            this.configuration = miniConfig.miniEdit.find(item => item.type === this.dropComponentList[index].val);
            // 计算索引顺序、多存储一份，用来寻找对应的渲染组件
            this.configuration.index = this.dropComponentList.length - 1;
            this.configuration.indexToFindComponet = this.dropComponentList.length - 1;
            // 图片信息置空
            // this.configuration.pictureInfo = [];
            // 文字信息
            if (this.configuration.wordInfo.length === 0) {
              this.addWordInfo();
            }
          });
        } else {
          this.dropComponentList[i].isSelected = false;
        }
      }
    },
    // 信息回显
    findByIndex(index) {
      return miniTempApi.findByIndex(index).then(res => {
        this.configuration = this.handlerReturn(res);
      })
    },
    // 查询所有的模板信息
    queryTemp() {
      return miniTempApi.queryTemp().then(res => {
        if (this.dropComponentList.length > 0) {
          this.dropComponentList = [];
        }
        // 根据index进行升序排序
        res.sort((a, b) => a.index - b.index);
        for (let i = 0; i < res.length; i++) {
          let data = this.handlerReturn(res[i]);
          this.addComponent(null, data.type, data);
        }
      })
    },
    // 处理返回信息
    handlerReturn(data) {
      let obj = {
        ...miniConfig.miniEdit.find(item => item.type === data.type),
        ...data,
        pictureInfo: data.tempList.map(item => ({
          ...item,
          imageUrl: process.env.VUE_APP_FILE_URL + item.imageUrl, // 这里先保留原始imageUrl，稍后在循环中再修改
        })),
      }
      for (let i = 0; i < obj.pictureInfo.length; i++) {
        let wordInfo = JSON.parse(obj.pictureInfo[i].wordInfo);
        obj.pictureInfo[i].text = wordInfo.text;
        obj.pictureInfo[i].color = wordInfo.color;
        obj.pictureInfo[i].top = wordInfo.top;
        obj.pictureInfo[i].left = wordInfo.left;
        obj.pictureInfo[i].size = wordInfo.size;
        obj.pictureInfo[i].whiteSpace = wordInfo.whiteSpace;
        obj.pictureInfo[i].width = wordInfo.width;
        obj.pictureInfo[i].height = wordInfo.height;
        obj.pictureInfo[i].radius = wordInfo.radius;
        obj.pictureInfo[i].position = wordInfo.position;
        obj.pictureInfo[i].marginTop = wordInfo.marginTop;
        obj.pictureInfo[i].boxWidth = wordInfo.boxWidth;
        obj.pictureInfo[i].basePrice = wordInfo.basePrice;
        obj.pictureInfo[i].productId = wordInfo.productId;
      }
      return obj;
    },
  },
}


</script>

<style lang="scss" scoped>


.container {
  display: flex;
  flex-direction: row;
  height: 100%; /* 确保容器高度为100% */
  overflow: hidden;
}

.flex-item {
  flex-grow: 1; /* 让所有item默认等分剩余空间 */
  //border: 1px solid black;
  height: 100%;
}

.first {
  width: 25%; /* 第一个和第二个div占30% */
  border-right: 1px solid #424141;
  box-sizing: border-box;
  padding-right: 14px;
  white-space: nowrap;

  .asse {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .content {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 15px;
      border: 1px solid #d5d1d1;
      //width: 25%;
      //height: 25%;

      img {
        width: 30px;
        height: 30px;
        margin: 0 auto;
      }
    }
  }
}

.second {
  width: 35%; /* 第一个和第二个div占30% */
  border-right: 1px solid #424141;
  overflow: scroll;
}

.third {
  width: 40%; /* 第三个div占40% */
  overflow: scroll;
  height: 100%;

  .tips {
    .tip-word {
      padding-left: 5px;
      font-size: 13px;
    }
  }

  .con-box {
    margin-top: 10px;
    border: 1px solid #d0cccc;
    margin-left: 10px;
    padding: 10px;
    color: #cc5353;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;

    .wordInfo {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;
    }
  }

  .info-img {
    position: relative;
  }

  .img-box {
    width: 60%;
    margin: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
  }

  .right-func {
    width: 27%;
    display: flex;
    justify-content: space-between;
    margin-right: 20px;

  }
}

.phone {
  position: relative;
  width: 425px;
  height: 795px;
  margin: 0 auto;

  img {
    position: absolute;
    width: 100%;
    height: 795px;
  }

  .bg-color {
    top: 32px;
    position: absolute;
    width: 360px;
    height: 744px;
    background-color: #f6f6f6;
    border: 1px solid black;
    left: 33px;
    border-radius: 23px 23px 36px 36px;
    overflow: scroll;
  }

  .bg-color::-webkit-scrollbar {
    display: none; /* 隐藏滚动条 */
  }
}

.drop-indicator {
  border: 1px dashed black; /* 选择你喜欢的颜色和样式 */
  opacity: 0;
  transition: opacity 0.2s ease-out;
  pointer-events: none; /* 防止遮挡下方元素的点击事件 */
}

.bg-color.drag-over .drop-indicator {
  opacity: 1;
}

.image-upload {

  :deep(.el-upload-list__item-delete) {
    display: none !important;
  }

  :deep(.el-upload-list__item-status-label) {
    display: none !important;
  }

}

.pad-rgt {
  :deep(.el-input__inner) {
    padding-right: 12px !important;
  }
}

</style>