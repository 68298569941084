import axios from "../../../util/axios";

export default {
    // 查询列表
    queryList: (data)=> axios.get({url: `/basedata/merchant/supplier/queryList`, data: data}),
    // 按名称查询列表
    queryByName: (data)=> axios.get({url: `/basedata/merchant/supplier/queryByName`, data: data}),
    // 保存
    save: (data)=> axios.post({url: `/basedata/merchant/supplier/save`, data: data}),
    // 保存关联
    saveRelation: (data)=> axios.post({url: `/basedata/merchant/supplier/saveRelation`, data: data}),
    // 删除关联
    delete: (id)=> axios.del({url: `/basedata/merchant/supplier/delete`, data: {id}}),

}