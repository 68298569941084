<template>
  <!-- 预约订单 -->
  <div>
    <order-list ref="orderList" :enable-room="true" />
  </div>
</template>

<script>
import OrderList from "@/views/main/sale/order/saleOrder/offflineOrder/OrderList.vue";
export default {
  name: "",
  components: {
    OrderList
  },
  data() {
    return {
      orderId: "",
    };
  },
  created() {
    if(this.$routerUtil.query.orderId){
      this.toReset();
    }
  },
  methods: {
    toReset(){
      this.$nextTick(() => {
        this.$refs.orderList.otherResetSearch(this.$routerUtil.query.orderId);
      })
    }
  }
};
</script>

<style lang="scss" scoped>

</style>