import util from "@/util/util"
export default {
    // 当前的路由
    path: "",
    query: {},
    routerChangeCallBack: null,
    refresh: true, // 是否刷新页面
    // 路由层级，格式为{path: "", name: ""}
    routerList: [],
    // 添加路由的回调事件
    setRouterChange(callBack) {
        this.routerChangeCallBack = callBack;
    },
    // 跳转到之前的页面，index必须小于0
    go(index) {
        if (index > -1) {
            return;
        }
        if (this.routerList.length === 0) {
            // 没有路径
            return;
        }
        if (Math.abs(index) > this.routerList.length) {
            // 路径不足，跳转到第一个
            this.push(this.routerList[0])
            return;
        }
        let param = this.routerList[this.routerList.length + index - 1];
        // 跳转到指定页面
        this.routerList = this.routerList.splice(0, this.routerList.length + index - 1);
        this.push(param);
    },
    // 打开页面，但不刷新,此接口只接收obj参数
    pushNotRefresh(any) {
        if (any instanceof Object) {
            this.refresh = false;
            this.push({...any, refresh: false});
        } else {
            console.error("pushNotRefresh参数错误");
        }
    },
    // 路由跳转，此操作默认将刷新页面
    push(any, callBack) {
        // 将传递的参数进行缓存
        util.setSession("routerParams", JSON.stringify(any));
        let routerParams = {};
        this.refresh = true;
        // 传递的是obj
        if (any instanceof Object) {
            this.path = any.path;
            this.query = any.query || {};
            if (any.refresh != null) {
                this.refresh = any.refresh;
            }
            routerParams = any;
        } else {
            // 传递的是string
            let str = any.split("?");
            this.path = str[0];
            if (str.length > 1) {
                let queryStr = str[1].split("&");
                let query = {};
                queryStr.forEach(item => {
                    let arr = item.split("=");
                    query[arr[0]] = arr[1];
                });
                this.query = query;
            } else {
                this.query = {};
            }
            routerParams = {path: this.path};
        }
        // 这里这个push，放到回调里面去，不然不能拿到name
        // this.routerList.push({path: this.path});
        if (this.routerChangeCallBack) {
            this.routerChangeCallBack(routerParams);
        }
        if (callBack) {
            callBack();
        }
    },
    // 获取路由
    getRouter() {
        return this.path;
    },
    getRouterList() {
        return this.routerList;
    },
    // 找到菜单，返回菜单中匹配度最近的
    findMenuSingleByPathMatch(path, list) {
        // 找到菜单，按path的长度排序，取第一个
        let arr = this.findMenuByPathMatch(path, list);
        return arr.sort((a1, a2) => a2.moduleComponentUri.length - a1.moduleComponentUri.length);
    },
    // 找到菜单，返回一个数组
    findMenuByPathMatch(path, list) {
        let arr = [];
        for (let i = 0; i < list.length; i++) {
            // 菜单能匹配上
            if (path.startsWith(list[i].moduleComponentUri)) {
                arr.push(list[i]);
            }
            // 递归，子菜单匹配
            if (list[i].children && list[i].children.length > 0) {
                let ch = this.findMenuByPathMatch(path, list[i].children);
                if (ch.length > 0) {
                    arr = arr.concat(ch);
                }
            }
        }
        return arr;
    },
    findMenuParentById(id, list) {
        let  arr = [];
        for (let i = 0; i < list.length; i++) {
            if (id === list[i].id) {
                arr.push(list[i]);
            }
            // 如果有子菜单
            if (list[i].children && list[i].children.length > 0) {
                let ch = this.findMenuParentById(id, list[i].children);
                if (ch.length > 0) {
                    arr = [{...list[i]}, ...ch];
                }
            }
        }
        return arr;
    },
}