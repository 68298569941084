// 微服务的路由，定义其它服务的地址
import {shallowRef} from "vue";
import AlpineSaleOrderList from "@/views/alpine/shop/order/saleOderList.vue";
import AlpineSaleOrderDetail from "@/views/alpine/shop/order/saleOderDetail.vue";
import AlpineCustomerList from "@/views/alpine/customer/member/MemberList.vue";
import AlpineCustomerDetail from "@/views/alpine/customer/member/MemberDetail.vue"
import AlpineStockList from "@/views/alpine/merchant/stock/StockList.vue"
import AlpineStoreList from "@/views/alpine/merchant/store/StoreList.vue"
import AlpineStoreDetail from "@/views/alpine/merchant/store/StoreDetail.vue"
import AlpineOriginalCodeList from "@/views/alpine/merchant/stock/OriginalCodeList.vue"
import AlpineCustomerCaseList from "@/views/alpine/shop/case/CustomerCaseList.vue"
import AlpineCustomerCaseDetail from "@/views/alpine/shop/case/CustomerCaseListDetail.vue"
import AlpineProductOfferList from "@/views/alpine/shop/offer/offerList.vue"
import AlpineProductOfferDetail from "@/views/alpine/shop/offer/offerDetail.vue"
import WarrantyList from "@/views/alpine/shop/warranty/WarrantyList.vue";
import WarrantySettingList from "@/views/alpine/shop/warrantySetting/WarrantySettingList.vue";
import AlpineForumReplyList from "@/views/alpine/reply/forumReplyList.vue";
import AlpineForumReplyDetail from "@/views/alpine/reply/forumReplyDetail.vue";
import QuotePriceList from "@/views/alpine/shop/quote/QuotePriceList.vue";
import WarrantyOrderList from "@/views/alpine/shop/order/WarrantyOrderList.vue";
import WarrantyOrderDetail from "@/views/alpine/shop/order/WarrantyOrderDetail.vue";
import RebateTab from "@/views/alpine/rebate/RebateTab.vue";
import RebateReport from "@/views/alpine/rebate/RebateReport.vue";
import ControlQrcode from "@/views/main/stock/qrcode/ControlQrcode.vue";

// 车膜小程序
export default [
    // 订单
    {path: "/alpine/shop/saleOder", component: shallowRef(AlpineSaleOrderList), meta: {component: shallowRef(AlpineSaleOrderList), label: "质保订单"}},
    {path: "/alpine/shop/saleOder/add", component: shallowRef(AlpineSaleOrderDetail), meta: {component: shallowRef(AlpineSaleOrderDetail), label: "添加质保"}},
    {path: "/warranty/saleOrder", component: shallowRef(WarrantyOrderList), meta: {component: shallowRef(WarrantyOrderList), label: "质保订单"}},
    {path: "/warranty/saleOrder/detail", component: shallowRef(WarrantyOrderDetail), meta: {component: shallowRef(WarrantyOrderDetail), label: "质保详情"}},

    // 客户管理
    {path: "/alpine/customer/member", component: shallowRef(AlpineCustomerList), meta: {component: shallowRef(AlpineCustomerList), label: "客户列表"}},
    {path: "/alpine/customer/member/detail", component: shallowRef(AlpineCustomerDetail), meta: {component: shallowRef(AlpineCustomerDetail), label: "客户档案"}},
    // 仓库
    {path: "/alpine/stock/list", component: shallowRef(AlpineStockList), meta: {component: shallowRef(AlpineStockList), label: "仓库档案"}},
    {path: "/alpine/stock/originalCode", component: shallowRef(AlpineOriginalCodeList), meta: {component: shallowRef(AlpineOriginalCodeList), label: "溯源码查询"}},
    {path: "/control/qrcode", component: shallowRef(ControlQrcode), meta: {component: shallowRef(ControlQrcode), label: "二维码管理"}},

    // 门店
    {path: "/alpine/merchant/store", component: shallowRef(AlpineStoreList), meta: {component: shallowRef(AlpineStoreList), label: "门店管理"}},
    {path: "/alpine/merchant/store/detail", component: shallowRef(AlpineStoreDetail), meta: {component: shallowRef(AlpineStoreDetail), label: "门店信息"}},
    // 案例
    {path: "/alpine/shop/customerCase", component: shallowRef(AlpineCustomerCaseList), meta: {component: shallowRef(AlpineCustomerCaseList), label: "客户案例"}},
    {path: "/alpine/shop/customerCase/detail", component: shallowRef(AlpineCustomerCaseDetail), meta: {component: shallowRef(AlpineCustomerCaseDetail), label: "客户案例详情"}},

    //报价
    {path: "/alpine/shop/productOffer",component: shallowRef(AlpineProductOfferList),meta: {component: shallowRef(AlpineProductOfferList),label: "商品报价"}},
    {path: "/alpine/shop/productOffer/add",component: shallowRef(AlpineProductOfferDetail),meta: {component: shallowRef(AlpineProductOfferDetail),label: "商品报价详情"}},
    {path: "/plat/order/quote", component: shallowRef(QuotePriceList), meta: {component: shallowRef(QuotePriceList), label: "报价管理"}},

    //论坛管理
    {path: "/plat/replay/replayList",component: shallowRef(AlpineForumReplyList),meta: {component: shallowRef(AlpineForumReplyList),label:"论坛列表"}},
    {path: "/plat/replay/replayList/replayDetail",component: shallowRef(AlpineForumReplyDetail),meta: {component: shallowRef(AlpineForumReplyDetail),label:"论坛详情"}},
    // 质保
    {path: "/report/warrantyList", component: shallowRef(WarrantyList), meta: {component: shallowRef(WarrantyList), label: "质保查询"}},
    {path: "/plat/order/warrantySetting", component: shallowRef(WarrantySettingList), meta: {component: shallowRef(WarrantySettingList), label: "保存配置"}},

    // 返佣
    {path: "/store/rebate", component: shallowRef(RebateTab), meta: {component: shallowRef(RebateTab), label: "门店返佣"}},
    {path: "/store/report", component: shallowRef(RebateReport), meta: {component: shallowRef(RebateReport), label: "返佣报表"}},

]