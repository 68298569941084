<template>
  <!-- 轮播图片组件 -->
  <div class="bg-img"  :style="{
    border: dataInfo.pictureInfo[0].height ? '' : '1px solid #ccc',
    height: dataInfo.pictureInfo[0].height ? dataInfo.pictureInfo[0].height : '120px',
    position: dataInfo.pictureInfo[0].position ? 'absolute' : 'relative',
    top: dataInfo.pictureInfo[0].position ? dataInfo.pictureInfo[0].top : '0',
    left: dataInfo.pictureInfo[0].position ? dataInfo.pictureInfo[0].left : '0',
    'border-radius': dataInfo.pictureInfo[0].radius,
    'background-color': dataInfo.pictureInfo[0].color ? dataInfo.pictureInfo[0].color : 'black',
    'margin-top': dataInfo.pictureInfo[0].marginTop,
    width: dataInfo.pictureInfo[0].boxWidth,
  }">
    这是一个多功能组件，由后期添加
  </div>
</template>

<script>

import {watch} from "vue";

export default {
  props: {
    // 修改信息
    configuration: {default: {}},
    // 索引位置，用来寻找匹配的配置信息作渲染遍历
    indexToFind: {default: null},
    // 初始化信息
    initData: {default: {}},
  },
  name: "BgImg",
  components: {
  },
  data() {
    return {
      dataInfo: {},
    };
  },
  created() {
    // 监听这个配置信息
    watch([() => this.configuration], () => {
      this.init();
    },{ deep: true })
    // 刷新重载、只在页面初始化时执行一次
    if (this.initData !== null) {
      this.dataInfo = this.initData;
    }
  },
  methods: {
    // 初始化
    init() {
      if (this.indexToFind !== this.configuration.index && !this.dataInfo) {
        return;
      }
      if (this.indexToFind === this.configuration.index) {
        this.dataInfo = this.configuration;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.bg-img {
  //z-index: 1;
  width: 100%;
  height: 120px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

</style>