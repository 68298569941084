<template>
  <!-- -->
  <div class="defaultViewStyle">
    <!--  <div style="height: 100%">-->
    <el-descriptions border :column="1">
      <el-descriptions-item label="部门名称">
        <div style="width: 240px;">
          <dictionary v-model="detail.departCode" @clearData="()=>this.detail.departCode = {}"
                      :place-holder="'请选择部门'"
                      :dictionaries="departList"
                      dict-code="departCode"
                      dict-name="departName"/>
        </div>
      </el-descriptions-item>
    </el-descriptions>

    <separate-page style="height: calc(100% - 100px)">
      <template #category>
        <span class="title">授权分类</span>
        <el-tree ref="groupTree" node-key="id" :data="productCategoriesAll"
                 :props="{ children: 'children', label: 'dictName' }" @node-click="clickCategory" default-expand-all
                 :expand-on-click-node="false">
          <template v-slot="{ node, data }">
            <span class="custom-tree-node" style="display: flex;align-items: center">
              <el-checkbox v-if="!data.children || !data.children.length > 0" v-model="data.isChecked" size="small"
                           @change="changeCategory(data)" style="margin-right: 5px" @click.stop/>
              <span class="node-name">{{ node.label }}</span>
              <span class="node-option">
                <table-button type="primary" @click="toAddNewCategory({parentId: data.id})"><el-icon><el-icon-plus/></el-icon></table-button>
                <table-button type="primary" @click="toAddNewCategory(data)"><el-icon><el-icon-edit/></el-icon></table-button>
                <table-button type="delete" class="btn" message="是否确认删除这个分类"
                              @click="toDeleteCategory(data.id)"><el-icon><el-icon-delete/></el-icon></table-button>
              </span>
            </span>
          </template>
        </el-tree>
      </template>
      <template #default>
        <list-page>
          <span class="title">授权商品</span>
          <search-condition>
            <el-input v-model="searchInfo.searchKey" placeholder="请输入商品名称" @keydown.enter="handleSearch"/>
            <page-button el-type="primary" @click="resetSearch">查询</page-button>
          </search-condition>
          <table-list>
            <el-table ref="table" :data="productList" height="100%" border @row-click="clickRow">
              <el-table-column type="selection" width="55" :selectable="checkSelectable"/>
              <el-table-column label="序号" type="index" width="60px" :alter="'center'" align="center"/>
              <el-table-column label="商品编号" prop="productCode" show-overflow-tooltip min-width="120px"/>
              <el-table-column label="商品类目" show-overflow-tooltip min-width="120px">
                <template v-slot="{ row }">
                  <dictionary :dictionaries="productCategories" v-model="row.category" :disabled="true"/>
                </template>
              </el-table-column>
              <el-table-column label="商品名称" prop="productName" show-overflow-tooltip align="center"
                               min-width="120px"/>
              <el-table-column label="规格" prop="productStandard" show-overflow-tooltip min-width="120px"/>
              <el-table-column label="型号" prop="productType" show-overflow-tooltip min-width="120px"/>
              <el-table-column label="条码" prop="barCode" show-overflow-tooltip min-width="120px"/>
              <el-table-column label="品牌" prop="productBrade" show-overflow-tooltip min-width="120px">
                <template v-slot="{row}">
                  <dictionary :disabled="true" v-model="row.productBrade"
                              :dictionaries="productBrades"
                              dict-code="dictCode"
                              dict-name="dictName"/>
                </template>
              </el-table-column>
            </el-table>
          </table-list>

          <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize"
                     :total-count="searchInfo.totalCount" @onLoad="queryList" :start-search="false">
            <div style="display: flex;justify-content: flex-end;">
              <page-button type="write" el-type="success" @click="saveGrant(1)">授权分类</page-button>
              <page-button type="write" el-type="success" @click="saveGrant(2)">授权商品</page-button>
            </div>
          </form-page>
        </list-page>
      </template>
    </separate-page>
  </div>
  <el-dialog title="商品分类管理" v-model="showProductDialog" width="400px">
    <el-form label-width="100px">
      <el-form-item label="上级分类">
        <dictionary v-model="currentCategory.parentId" :dictionaries="productCategories" dict-code="id"
                    dict-name="dictName" style="width: 100%"/>
      </el-form-item>
      <el-form-item label="分类名称">
        <el-input v-model="currentCategory.dictName"/>
      </el-form-item>
      <el-form-item label="分类编码">{{ currentCategory.departCode }}</el-form-item>
    </el-form>
    <detail-page-btn>
      <el-button @click="showProductDialog = false">取消</el-button>
      <el-button type="primary" @click="saveProductCategory">确定</el-button>
    </detail-page-btn>
  </el-dialog>
</template>
<script>
import TableList from "@/components/h5/layout/TableList";
import TableButton from "@/components/h5/button/TableButton";
import Dictionary from "@/components/h5/Dictionary";
import DetailPageBtn from "@/components/h5/layout/DetailPageBtn";
import productApi from "@/api/main/product/productApi";
import commonApi from "@/api/commonApi";
import util from "@/util/util";
import SeparatePage from "@/components/h5/layout/SeparatePage";
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import Money from "@/components/h5/Money.vue"
import ImportComponent from "@/components/h5/ImportComponent.vue";
import productGrantDepartApi from "@/api/main/product/productGrantDepartApi";

export default {
  name: "ProductList",
  components: {
    PageButton,
    FormPage,
    TableList,
    SearchCondition,
    TableButton,
    Dictionary,
    SeparatePage,
    DetailPageBtn,
    ListPage,
    Money,
    ImportComponent
  },
  data() {
    return {
      searchInfo: {
        pageSize: 20,
      },
      productList: [],
      // 商品分类
      productCategories: [],
      productCategoriesAll: [],
      showSyncDialog: false, // 显示同步设置
      syncSetting: {
        deleteOld: 1,
        deleteCategory: 1,
        lastLevel: 1,
        deleteUnit: 1,
        priceColumn: "retailPrice", // 单价字段
        buyPriceColumn: "preBuyPrice1",
      },
      currentCategory: {},
      showProductDialog: false,
      userInfo: {},
      // 选择的信息
      detail: {},
      departList: [],
      // 当前部门
      currentSelectCategory: [],
      categoryInfo: {},
    };
  },
  created() {
    // 查询部门，查询成树结构返回
    commonApi.queryDepartList().then(list => this.departList = list);
    util.getUserInfo().then(user => this.userInfo = user);
    // 获取缓存的查询条件，并进行查询
    // this.searchInfo = util.listGetParams();
    this.init();
  },
  mounted() {

  },
  methods: {
    async init() {

      this.queryDictionTree()
      this.queryList()

    },
    clickRow(data) {
      data.checked = !data.checked;
      this.$refs.table.toggleRowSelection(data, data.checked);
    },
    findById(id) {
      productGrantDepartApi.findById(id).then(data => {
        if (data.grantType === 1) {
          // 分类
          this.echoCategory(data, this.productCategoriesAll[0].children);
        } else {
          // 商品
          this.echoProduct(data);
        }
      })
    },
    echoCategory(data, list) {
      this.$nextTick(() => {
        for (let i = 0; i < list.length; i++) {
          if (list.children && list.children.length > 0) {
            this.echoCategory(data, list[i].children);
          } else if (list[i].dictCode === data.productCategory) {
            list[i].isChecked = true;
            this.currentSelectCategory.push({...list[i]});
            this.productCategoriesAll[0].children = list;
            return;
          }
        }
      })
    },
    echoProduct(data) {
      this.$nextTick(() => {
        const idx = this.productList.findIndex(item => item.id === data.productId);
        this.$refs.table.toggleRowSelection(this.productList[idx], true);
      })
    },
    checkSelectable() {
      const idx = this.currentSelectCategory.findIndex(item => item.id === this.categoryInfo.id);
      if (idx === -1) {
        return true
      }
      return false;
    },
    changeCategory(data) {
      // 判断是否为一个父级分类，是的话，则勾选它所有的子集
      const idx = this.currentSelectCategory.findIndex(item => item.id === data.id);
      if (idx === -1) {
        this.currentSelectCategory.push({...data});
      } else {
        this.currentSelectCategory.splice(idx, 1);
      }
    },
    // compareIsParent(data){
    //   if(data.children && data.children.length > 0){
    //     data.children.forEach(item => {
    //       this.compareIsParent(item);
    //     })
    //   }else{
    //     const idx = this.productCategories.findIndex(item => item.id === data.id);
    //     if(!this.productCategories[idx].isChecked || this.productCategories[idx].isChecked){
    //       this.productCategories[idx].isChecked = true;
    //     }else{
    //       this.productCategories[idx].isChecked = false;
    //     }
    //     this.currentSelectCategory.push({...this.productCategories[idx]});
    //     console.log(this.currentSelectCategory);
    //     // productApi.saveGrant(arr).then(res => {
    //     //   this.$message.success("保存成功");
    //     //   this.$refs.table.clearSelection();
    //     // })
    //   }
    // },
    saveGrant(grantType) {
      if (this.detail.departCode === "" || !this.detail.departCode) {
        this.$message.error("部门信息不能为空！");
        return;
      }
      let arr = [];
      if (grantType === 1) {
        // 授权分类
        for (let i = 0; i < this.currentSelectCategory.length; i++) {
          let data = {
            departCode: this.detail.departCode,
            grantType: 1, // 商品
            productCategory: this.currentSelectCategory[i].dictCode,
          }
          arr.push({...data});
        }
      } else {
        // 授权商品
        const productList = this.$refs.table.getSelectionRows();
        for (let i = 0; i < productList.length; i++) {
          let data = {
            departCode: this.detail.departCode,
            productId: productList[i].id,
            grantType: 2, // 商品
            productCategory: productList[i].category,
          }
          arr.push({...data});
        }
      }
      productGrantDepartApi.saveGrant(arr);
    },
    queryDictionTree() {
      commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => {
        this.productCategories = list;
        this.productCategoriesAll = [{dictName: "全部分类", children: list}];
        if (this.$routerUtil.query.id) {
          this.findById(this.$routerUtil.query.id);
        }
      });
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      commonApi.queryProductList(this.searchInfo).then(data => {
        this.productList = data.records;
        this.searchInfo.totalCount = data.totalCount;
        if (this.$routerUtil.query.id) {
          this.findById(this.$routerUtil.query.id);
        }
      })
    },
    //搜索
    handleSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    clickCategory(data) {
      this.searchInfo.category = data.dictCode;
      this.categoryInfo = data;
      this.resetSearch();
    },
    // 编辑商品
    toEdit(data) {
      util.beforeList2Detail(this.searchInfo);
      // 如果是自己的商品，进行编辑，如果不是自己的商品，只能修改价格
      if (this.userInfo.merchantId === data.merchantId) {
        this.$routerUtil.push(`/product/list/detail?id=${data.id}`);
      } else {
        this.$routerUtil.push(`/product/list/detailView?id=${data.id}`);
      }
    },
    // 删除商品
    toDelete(data) {
      this.$confirm("是否确认删除商品").then(() => {
        productApi.delete(data.id).then(() => this.queryList());
      })
    },
    // 保存商品分类
    saveProductCategory() {
      productApi.saveCategory(this.currentCategory).then(() => {
        this.queryDictionTree();
        this.showProductDialog = false;
      });
    },
    // 编辑，修改分类
    toAddNewCategory(data) {
      this.currentCategory = data;
      this.showProductDialog = true;
    },
    // 删除分类
    toDeleteCategory(id) {
      productApi.deleteCategory(id).then(() => {
      });
    },
    exportTemp() {
      util.exportExcel("/order/product/exportTemp", {});
    },
  }
}
;
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/tree.scss";

.title {
  display: block;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #f5f7fa;
  box-sizing: border-box;
  padding: 0 10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--el-text-color-regular);
  font-weight: 600;
}

:deep(.el-descriptions__label) {
  width: 240px !important;
}

:deep(.el-descriptions__label:after) {
}

:deep(.el-descriptions__content) {
  width: calc(100% - 240px);
  position: relative;
}

.custom-tree-node {
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
    border: 1px solid #08b766;
    background-color: #08b766;
  }
}


:deep(.el-textarea__inner) {
  box-sizing: border-box;
  padding: 10px !important;
}

:deep(.el-descriptions__label.require:before) {
  content: "*";
  font-size: 13px;
  color: #F56C6C;
  padding-right: 4px;
}

</style>