<template>
  <!-- -->
  <span style="margin-right: 10px">
    <table-button @click="toShowCodeList">{{ disabled? '维保码': '添加维保码' }}</table-button>
    <el-drawer v-model="showOriginalDialog" :title="skuName + '维保码'" dir="ltr" size="500px" :close-on-click-modal="false" append-to-body class="drawer-class-default">
      <!--    自动生成溯源码-->
      <div style="height: calc(100vh - 180px)">
        <div style="display: flex;justify-content: space-between">
          <table-button v-if="!disabled" @click="toCreate">自动生成维保码</table-button>
          <table-button v-if="!disabled" @click="toUpload">导入维保码</table-button>
        </div>

        <el-table :id="id" :data="skuOriginalList" height="100%" border>
          <el-table-column label="序号" type="index" width="60px" :align="'center'"/>
          <el-table-column label="维保码" prop="originalCode" v-if="disabled" :align="'center'"/>
          <el-table-column label="维保码" v-else>
            <template v-slot="{row}">
              <el-input v-model="row.originalCode" placeholder="请输入维保码" @change="addTableOriginalCode"/>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: right;padding-top: 10px;margin-top: 20px">
        <page-button @click="showOriginalDialog = false">返回</page-button>
        <page-button v-if="!disabled" el-type="primary" @click="addOriginalCode2List">确定</page-button>
      </div>
    </el-drawer>

    <!--  生成溯源码-->
    <el-drawer  v-model="createOriginalDialog" size="500px" append-to-body :close-on-click-modal="false" title="按编号自动添加连接的编码">
      <div>
        <table-list>
          <el-descriptions :column="1" border >
            <el-descriptions-item label="开始编码值">
              <el-input v-model="startValue" placeholder="请输入开始编码的值" />
            </el-descriptions-item>
            <el-descriptions-item label="截至编码值">
              <el-input v-model="endValue" placeholder="请输入截至编码的值" />
            </el-descriptions-item>
          </el-descriptions>
        </table-list>
        <page-button v-if="!disabled" el-type="primary"  @click="toShengOriginalCode">生成</page-button>
      </div>
    </el-drawer>
    <import-component v-model="showExport" title="批量导入商品" @exportTemp="exportTemp" :importByExcel="importByExcel"/>
  </span>
</template>

<script>

import TableButton from "@/components/h5/button/TableButton.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import {watch} from "vue";
import ImportComponent from "@/components/h5/ImportComponent.vue";
import commonApi from "@/api/commonApi";
import util from "@/util/util";

export default {
  emits: ["addOriginalCode"],
  props: {
    disabled: {default: false},
    // 溯源码类型
    originalCodeType: {default: "ALPINE"},
    skuName: {default: ""},
    codeList: {default: []},
  },
  name: "",
  components: {
    ImportComponent,
    PageButton, TableList,
    TableButton
  },
  data() {
    return {
      showExport: false,
      createOriginalDialog: false,
      showOriginalDialog: false,
      skuOriginalList: [],
      startValue: "",
      endValue: "",
      id: "",
      exportTemp: null,
      readTempData: null,
    };
  },
  created() {
    this.id = "skuOriginalTable" + (Math.random() * 1000).toFixed(0);
    this.initList();
    // 导入和导出
    this.exportTemp = this.downloadTemp;
    this.readTempData = this.importByExcel;
    watch(() => this.codeList, () => {
      this.initList();
    })
  },
  methods: {
    initList() {
      this.skuOriginalList = [...this.codeList, {}];
    },
    toCreate() {
      this.createOriginalDialog = true;
    },
    addTableOriginalCode() {
      // 如果没有空白行，添加一个溯源码
      let blank = false;
      for (let i = 0; i < this.skuOriginalList.length; i++) {
        if (!this.skuOriginalList[i].originalCode) {
          blank = true;
          break;
        }
      }
      if (!blank) {
        this.skuOriginalList.push({});
      }
      this.$nextTick().then(() => {
        // 让新增的input进行聚焦
        let input = document.querySelector(`#${this.id} tr:last-child input:last-child`);
        input.focus();
      });
    },
    addOriginalCode2List() {
      // 没有码的数据不要
      let res = [];
      for (let i = 0; i < this.skuOriginalList.length; i++) {
        if (this.skuOriginalList[i].originalCode) {
          res.push(this.skuOriginalList[i])
        }
      }
      this.$emit("addOriginalCode", res);
      this.showOriginalDialog = false
    },
    toShengOriginalCode(){
      if(this.originalCodeType === "XYYQGJ"){
        this.toGenerateOriginalCodeXYYQGJ();
      }else{
        this.toGenertOriginalCode();
      }
    },
    toGenerateOriginalCodeXYYQGJ(){
      if(this.startValue.length < 15 || this.endValue.length < 15){
        this.$message.error("编号异常，请检查编号");
        return;
      }
      if(this.startValue.length > 19 || this.endValue.length > 20){
        this.$message.error("编号异常，请检查编号");
        return;
      }
      let start = this.startValue.split("-")[1];
      if(isNaN(Number(start))){
        this.$message.error("编号异常，请检查编号");
        return;
      }
      let end = this.endValue.split("-")[1];
      if(isNaN(Number(end))){
        this.$message.error("编号异常，请检查编号");
        return;
      }
      // 生成编号
      let list = [];
      for (let i = 0; i <= Number(end) - Number(start); i++) {
        let count = "";
        if(i.toString().length < 5){
          for (let j = 0; j < 5 - (i + 1).toString().length; j++) {
            count += "0";
          }
        }
        count += (i + 1).toString();
        list.push({originalCode: this.startValue.substring(0, 14) + count});
      }
      this.skuOriginalList.pushAll(list);
      this.createOriginalDialog = false;
      this.addOriginalCode2List();
    },
    //自动生成溯源码
    toGenertOriginalCode(){
      let start = this.startValue.substring(0, this.startValue.length - 2);
      let end = this.endValue.substring(0, this.endValue.length - 2);
      let startYear = this.startValue.substring(this.startValue.length - 2);
      let endYear = this.endValue.substring(this.endValue.length - 2);
      if (startYear !== endYear) {
        this.$message.error("编号异常，请检查编号");
        return;
      }
      // 获取编号
      let strStart = this.getCode(start);
      let strEnd = this.getCode(end);
      if (strStart !== strEnd) {
        this.$message.error("编号异常，请检查编号");
        return;
      }
      // ALHZ0470PPF000124
      // 开始和结束，除code外其它应该一样
      let startValue = parseInt(start.replace(strStart, ""));
      let endValue = parseInt(end.replace(strEnd, ""));
      let org = [];
      for (let o = startValue; o <= endValue; o++) {
        const codeObject = {
          originalCode: `${strStart}${o.toString().padStart(4, '0')}${startYear}`
        };
        org.push(codeObject);
      }
      this.skuOriginalList = org;
      this.createOriginalDialog = false;
      this.addOriginalCode2List();
    },
    toShowCodeList() {
      this.showOriginalDialog = true;
    },
    getCode(str) {
      let i = parseInt(str.charAt(str.length - 1));
      if (isNaN(i)) {
        return str;
      }
      return this.getCode(str.substring(0, str.length - 1))
    },
    toUpload() {
      // 显示导入功能
      this.showExport = true;
    },
    downloadTemp() {
      util.exportExcel("/order/common/downOriginalCodeTemp", {});
    },
    // 读取模板数据
    importByExcel(formData) {
      return new Promise(resolve => {
        commonApi.readOriginalCodeTempData(formData).then(list => {
          let org = [];
          for (let i = 0; i < list.length; i++) {
            org.push({
              originalCode: list[i]
            })
          }
          this.skuOriginalList = org;
          this.createOriginalDialog = false;
          this.addOriginalCode2List();
          resolve();
        });
      });
    },
  }
};
</script>

<style lang="scss" scoped>

:deep(.drawer-class-default){
  color: red;
  .el-drawer__header {
    margin-bottom: 0;
    white-space: nowrap;
  }
}
:deep(.el-drawer__header>:first-child) {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>