import axios from "../../../util/axios";

export default {
    queryList: (searchInfo)=> axios.get({url: `/order/stock/product/queryList`, data: searchInfo}),
    queryProductDetail: (searchInfo) =>axios.get({url: `/order/stock/product/queryProductDetail`, data: searchInfo}),
    // 查询历史变更记录
    queryChangeHistory: (searchInfo) =>axios.get({url: `/order/stock/product/queryChangeHistory`, data: searchInfo}),
    // 查询历史变更记录
    queryOriginalCode: (searchInfo) =>axios.get({url: `/order/stock/product/queryOriginalCode`, data: searchInfo}),
    // 查询历史变更记录
    queryOriginal2Code: (searchInfo) =>axios.get({url: `/order/stock/product/queryOriginal2Code`, data: searchInfo}),
    // 获取商品库存列表
    queryProductStockList: (searchInfo)=> axios.get({url: `/order/stock/product/queryProductStockList`, data: searchInfo}),
    // 查询库存统计信息
    queryProductStockStatistic: (searchInfo) =>axios.get({url: `/order/stock/product/queryProductStockStatistic`, data: searchInfo}),
}