<template>
  <!-- -->
  <div class="defaultViewStyle">
    <plat-role-component />
  </div>
</template>

<script>
import PlatRoleComponent from "@/views/main/plat/system/role/PlatRoleComponent.vue";
export default {
  props: {
    merchantId: {default: ""}
  },
  name: "",
  components: {
    PlatRoleComponent
  },
  data() {
    return {
    };
  },
  created() {

  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
</style>