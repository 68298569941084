import axios from "../../../util/axios";

export default {
    // 查询列表
    queryList: (searchInfo)=> axios.post({url: "/order/saleOrder/receipt/queryList", data: searchInfo}),

    // 修改退款方式
    updateRefundType: (id, payTypeId, payType)=> axios.get({url: "/order/saleOrder/receipt/updateRefundType", data: {id, payTypeId, payType}}),

    // 收款记录
    queryPayRecords: (orderId)=> axios.get({url: "/order/saleOrder/receipt/queryPayRecords", data: {orderId}}),

    // 保存一条支付记录
    savePaydetail: (data) => axios.post({url: "/order/saleOrder/receipt/savePaydetail", data: data})
}
