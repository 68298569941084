<template>
  <div>
    <div>
      <el-form label-width="70px" style="">
        <div>
          <el-form-item v-if="!isRefund && isPay" label="扫码支付">
            <div style="position: relative;width: 100%;overflow: hidden">
              <el-input v-model="saleData.qrCode" autofocus :disabled="saleData.payType !== 2" ref="inputRef"
                        placeholder="支付码" style="width: 100%"
                        @change="pay"/>
              <div v-if="saleData.payStatus === 20"
                   style="position: absolute;right: 0px;top: 0px;line-height: 40px;border-left: 1px solid #dcdfe6;width: 32px;text-align: center;">
                <el-tooltip :disabled="isRefreshPay" content="支付中，请稍后刷新支付状态" effect="light">
                  <el-icon :class="isRefreshPay ? 'rotating-element' : ''">
                    <el-icon-refresh @click="toFindPayStatus"/>
                  </el-icon>
                </el-tooltip>
              </div>
            </div>
          </el-form-item>
          <!--          <el-form-item v-if="isRefund" label="退款人">-->
          <!--            <el-input disabled v-model="saleData.payeeName"/>-->
          <!--          </el-form-item>-->
          <el-form-item label="合计金额">
            <el-input disabled v-model="saleData.amount"/>
          </el-form-item>
          <el-form-item label="合计数量">
            <el-input disabled v-model="saleData.qty"/>
          </el-form-item>
          <el-form-item label="支付方式">
            <pay-type-select v-model="saleData.payTypeId" :default-pay="true" @select-one="changePay" @change="changeRefundPay"/>
          </el-form-item>
          <el-form-item v-if="isPay" label="本单未收">
            <el-input disabled v-model="saleData.amountShould"/>
          </el-form-item>
          <el-form-item v-if="isPay" label="本次收款">
            <el-input :disabled="isDisabled" v-model="saleData.amountCollect"/>
          </el-form-item>
          <div v-if="isRefund">
            <el-form-item label="退款方式" v-for="item in saleData.payList" :key="item.id">
              <div style="display: flex;flex-direction: row;width: 100%">
                <el-input style="width: 20%" disabled v-model="item.payName"/>
                <el-input style="width: 80%" disabled v-model="item.refundCurrent"/>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div style="display: flex;justify-content: space-between">
        <slot/>
        <!--        分期支付时使用-->
        <div v-if="isBatchPay">
          <page-button el-type="warning" :loading="preparePay" @click="batchSubmit" :disabled="disableToClickPay">提交收款
          </page-button>
          <page-button el-type="primary" :loading="preparePay" @click="pay" :disabled="disableToClickPay">结算确认
          </page-button>
        </div>
        <div v-else>
          <page-button el-type="info" @click="closePage">取消</page-button>
          <page-button el-type="primary" :loading="preparePay" @click="pay" :disabled="disableToClickPay">确认
          </page-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PayTypeSelect from "@/components/h5/PayTypeSelect.vue";
import PageButton from "@/components/h5/button/PageButton.vue";

export default {
  name: "saleBalance",
  emits: ["closePage", "findSaleStatus", "changePay", "pay", "changeRefund", "batchSubmit"],
  props: {
    saleInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isRefreshPay: {
      type: Boolean,
      default: false,
    },
    disableToClickPay: {
      type: Boolean,
      default: false,
    },
    preparePay: {
      type: Boolean,
      default: false,
    },
    isPay: {
      type: Boolean,
      default: false,
    },
    isRefund: {
      type: Boolean,
      default: false,
    },
    isBatchPay: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    payTypeList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    saleInfo: {
      handler(newValue) {
        this.saleData = newValue;
        if(!this.saleData.payType){
          this.saleData.payType = 2;
          this.saleData.payTypeId = "20240515191223949211";
        }
      },
      deep: true,
      immediate: true,
    },
    'saleData.payType'(newValue) {
      if (newValue !== 1) {
        this.$nextTick(() => {
          this.$refs.inputRef.focus();
        })
      }
    },
  },
  components: {
    PayTypeSelect,
    PageButton,
  },
  data() {
    return {
      saleData: {
        payType: 2,
      },
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    init(data){
      this.saleData = data;
    },
    initInput() {
      this.$refs.inputRef.focus();
    },
    closePage() {
      this.$emit("closePage");
    },
    toFindPayStatus() {
      this.$emit("findSaleStatus");
    },
    changeRefundPay() {
      this.$emit("changeRefund");
    },
    changePay(data) {
      this.saleData.payTypeId = data.id;
      this.saleData.payType = data.payCode;
      this.$emit("changePay", data);
    },
    pay() {
      this.$emit("pay", this.saleData);
    },
    batchSubmit() {
      this.$emit("batchSubmit", this.saleData);
    },

  }
};
</script>

<style lang="scss" scoped>

.rotating-element {
  animation: rotate 1.5s linear infinite;
}
</style>
