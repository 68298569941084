<template>
  <div class="">
<!--    <div :class="'padding-' + position" style=""></div>-->
    <div :class="'slot-' + position">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    // 页面通用按钮位置，RT右上角，BT下方,RIGHT右方
    position: {default: "BT"}
  },
  name: "",
  components: {
  },
  data() {
    return {

    };
  },
  created() {
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
:deep( .el-button){
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 3px;
  margin: 0 5px;
}
.padding-RT{
  display: none;
}
.padding-BT{
  height: 32px
}
.slot-RT{
  position: fixed;
  padding: 5px;
  text-align: center;
  top: 110px;
  right: 30px;
  z-index: 1;
  background: #FFF;
}
.slot-BT{
  text-align: center;
}
.slot-RIGHT{
  text-align: right;
  margin-bottom: 10px;
  .el-button:last-child{
    margin-right: 0;
  }
}
</style>