import axios from "../../../util/axios";


export default {
   /**
     * 获取该商户可以进货的所有商品列表
     * @returns {Promise | Promise<unknown>}
     */
    queryProductAuthorizeList: (pageInfo)=> axios.get({url: `/order/stock/inStorage/queryProductAuthorizeList`,data: pageInfo}),

    findUser: ()=> axios.get({url: `/order/stock/inStorage/findUser`}),

    findDepart: (stockId) => axios.get({url: `/order/stock/inStorage/findDepart`, data: {stockId: stockId}}),

    saveDraft: (data) => axios.post({url: `/order/stock/inStorage/saveDraft`,data: data}),

    saveSubmit: (data) => axios.post({url: `/order/stock/inStorage/saveSubmit`,data: data}),

    queryList: (data,stockId) => axios.get({url: `/order/stock/inStorage/queryList?stockId=${stockId}`,data: data}),

    findByInOrderId: (inoutStorageId) => axios.get({url: `/order/stock/inStorage/findByInOrderId`, data: {inoutStorageId: inoutStorageId}}),

    deleteRow: (id) => axios.del({url: `/order/stock/inStorage/delete`, data: {id}}),

    querySaleStatisticInfo: (data) => axios.get({url: `/order/stock/inStorage/querySaleStatisticInfo`, data: data}),
   /** 导入模板数据 */
   readInStorageTemplate: (data) => axios.post({url: `/order/stock/inStorage/readInStorageTemplate`, data: data}),

    genericCode: (count, skuId) => axios.get({url: `/order/stock/inStorage/genericCode`, data: {count: count, skuId: skuId}}),
}