<template>
  <!-- 创建一个新的客户 -->
  <page-button el-type="primary" type="write" @click="toShowEditCustomer({})"><slot></slot></page-button>
  <el-dialog v-model="showEditCustomer" width="40%" title="编辑会员信息">
    <el-form label-width="80px">
      <el-form-item label="会员姓名" :required="true">
        <el-input v-model="editCustomer.customerName"/>
      </el-form-item>
      <el-form-item label="手机号码" :required="true">
        <el-input v-model="editCustomer.customerPhone"/>
      </el-form-item>
      <el-form-item label="出生日期">
        <date-picker v-model="editCustomer.birthday" style="width: 100%"/>
      </el-form-item>
      <el-form-item label="性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别">
        <el-select v-model="editCustomer.gender">
          <el-option :value="1" label="男"/>
          <el-option :value="2" label="女"/>
        </el-select>
      </el-form-item>
      <el-form-item label="证件类型" :required="true">
        <el-select v-model="editCustomer.cardType" placeholder="请选择">
          <el-option label="身份证" :value="1"/>
          <el-option label="护照" :value="2"/>
          <el-option label="其它" value=""/>
        </el-select>
      </el-form-item>
      <el-form-item label="证件号码" :required="true">
        <el-input v-model="editCustomer.cardNo" @change="changeCardNo"/>
      </el-form-item>
      <el-form-item label="家庭地址" :span="2">
        <el-input v-model="editCustomer.address"/>
      </el-form-item>
    </el-form>
    <div style="text-align: right">
      <page-button el-type="info"  @click="showEditCustomer = false">取消</page-button>
      <page-button el-type="success" type="primary" @click="toSaveCustomer">保存</page-button>
    </div>
  </el-dialog>
</template>

<script>

import DatePicker from "@/components/h5/date/DatePicker";
import memberApi from "@/api/main/customer/memberApi";
export default {
  name: "",
  emits: ["updateCustomer"],
  props:{
    isQuery:{
      type: Boolean,
      default: true
    }
  },
  components: {
    DatePicker
  },
  data() {
    return {
      showEditCustomer: false,
      editCustomer:{},
    };
  },
  created() {
  },
  methods: {
  numberValidateForm(){},
    toShowEditCustomer(data) {
      this.editCustomer = {...data};
      this.showEditCustomer = true;
    },
    toSaveCustomer() {
      memberApi.save(this.editCustomer).then((res) => {
        this.$emit("updateCustomer",res);
        this.showEditCustomer = false;
        if(this.isQuery){
          this.queryList();
        }
      })
    },
    changeCardNo() {
      if (this.editCustomer.cardType === 1 && this.editCustomer.cardNo.length === 18) {
        this.editCustomer.birthday = this.editCustomer.cardNo.substring(6, 10) + "-" + this.editCustomer.cardNo.substring(10, 12) + "-" + this.editCustomer.cardNo.substring(12, 14);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/dialog";
</style>