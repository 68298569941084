<template>
  <!-- -->
  <span v-if="disabled">{{date}}</span>
  <el-date-picker v-else
                  v-model="date"
                  type="date"
                  range-separator="至"
                  :placeholder="placeholder"
                  @change="changeDate"
                  style="vertical-align: top"
  />
</template>

<script>
import {watch} from "vue";

export default {
  emits: ["update:value"],
  props: {
    disabled: {default: false},
    value: {default: ""},
    placeholder: {default: "请选择日期"},
  },
  name: "",
  components: {
  },
  data() {
    return {
      date: "",
    };
  },
  created() {
    this.date = this.value? new Date(this.value).format("yyyy-MM-dd"): "";
    // watch的三个参数source, hander, option
    watch([() => this.value], (val, old) => {
      this.date = this.value? new Date(this.value).format("yyyy-MM-dd"): "";
    }, {
      // deep: true
    })
  },
  methods: {
    changeDate() {
      if (this.date) {
        this.$emit("update:value", this.date.format('yyyy-MM-dd'));
      } else {
        this.$emit("update:value", "");
      }

    }
  }
};
</script>

<style lang="scss" scoped>

</style>