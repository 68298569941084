<template>
  <!-- 店铺信息组件 -->
  <div class="bg-banner" :style="{'border-radius': dataInfo.pictureInfo[0].radius,'margin-top': dataInfo.pictureInfo[0].marginTop,}">
    <div class="text-box">
      <text>精选商品</text>
      <text>查看更多</text>
    </div>
    <div class="product-list">
      <div class="box" v-for="(item, index) in dataInfo.pictureInfo" :key="index">
        <img :src="item.imageUrl" alt="" />
        <text>{{ item.text }}</text>
        <text>￥{{ item.basePrice }}</text>
      </div>
    </div>
  </div>
</template>

<script>

import {watch} from "vue";

export default {
  props: {
    // 修改信息
    configuration: {default: {}},
    // 索引位置，用来寻找匹配的配置信息作渲染遍历
    indexToFind: {default: null},
    // 初始化信息
    initData: {default: {}},
  },
  name: "MiniProductList",
  components: {
  },
  data() {
    return {
      dataInfo: {},
    };
  },
  created() {
    // 监听这个配置信息
    watch([() => this.configuration], () => {
      this.init();
    },{ deep: true })
    // 刷新重载、只在页面初始化时执行一次
    if (this.initData !== null) {
      this.dataInfo = this.initData;
    }
  },
  methods: {
    // 初始化
    init() {
      if (this.indexToFind !== this.configuration.index && !this.dataInfo) {
        return;
      }
      if (this.indexToFind === this.configuration.index) {
        this.dataInfo = this.configuration;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.bg-banner {
  position: relative;
  margin: 14px;
  padding: 10px;
  overflow: hidden;
  //background-color: #fffefe;
  //box-shadow: 0 0 2px 2px #d0cdcd;
}

:deep(.el-carousel__indicator--horizontal){
  padding: 2px !important;
}
:deep(.el-carousel__button){
  width: 5px;
}

.text-box{
  display: flex;
  justify-content: space-between;
}

.product-list{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  margin-top: 10px;


  .box{
    width: 90%;
    box-shadow: 0 0 2px 2px #d0cdcd;
    padding: 10px;

    img{
      width: 100%;
      height: 170px;
    }

    text:nth-child(2){
      display: block;
      width: 125px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>