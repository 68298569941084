<template>
  <!-- -->
  <div class="defaultViewStyle">

    <list-page>
      <table-list>
        <page-header>
          <template #default>
          </template>
        </page-header>
        <div>
          <el-descriptions :column="3" border size="small">
            <el-descriptions-item label="单据日期">
<!--              <el-date-picker-->
<!--                  style="width: 100%"-->
<!--                  :disabled="isDisabled"-->
<!--                  v-model="headInfo.createdTime"-->
<!--                  type="date"-->
<!--                  placeholder="选择日期时间"-->
<!--              />-->
              {{ headInfo.createdTime }}
            </el-descriptions-item>

            <el-descriptions-item label="入库门店">
              <dictionary :disabled="true" @clearData="clearDepart"
                          v-model="headInfo.departCode"
                          :dictionaries="storeList" dict-code="id" dict-name="storeName"/>
            </el-descriptions-item>

            <el-descriptions-item label="入库仓库">
              <dictionary :disabled="true"
                          v-model="headInfo.stockId"
                          :dictionaries="stockList" dict-code="id" dict-name="stockName"/>
            </el-descriptions-item>

            <el-descriptions-item label="单据编号">
              <span>{{ headInfo.id ? headInfo.id : '请先保存' }}</span>
            </el-descriptions-item>

            <el-descriptions-item label="出库部门">
              <dictionary :disabled="isDisabled" v-model="headInfo.targetDepartCode"
                          :place-holder="'请选择目标部门'"
                          :dictionaries="departList" dict-code="departCode" dict-name="departName"/>
            </el-descriptions-item>

            <el-descriptions-item label="出库仓库">
              <el-select v-model="headInfo.targetStockId" :disabled="isDisabled" placeholder="请先选择目标部门"
                         size="default"
                        >
                <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
              </el-select>
            </el-descriptions-item>

            <el-descriptions-item label="创建人">
              {{ headInfo.createdName }}
            </el-descriptions-item>

            <el-descriptions-item label="备注" :row="2">
              <el-input :disabled="isDisabled" v-model="headInfo.remark" type="textarea" :rows="1"
                        class="el-input-text"/>
            </el-descriptions-item>

          </el-descriptions>

<!--          <div style="display: flex;justify-content: flex-end; height: 42px;">-->
<!--            <div style="width: 300px">-->
<!--              <product-list-select :is-depart="true" :query-list-api="queryListApi" :selected-product-list="headInfo.detailList"-->
<!--                                   :stock-id="headInfo.targetStockId" :is-stock="true"-->
<!--                                   :pla-title="'请先在头部信息中选择目标仓库！'"-->
<!--                                   @select="selectProduct" @clear="deleteBatch"/>-->
<!--            </div>-->
<!--          </div>-->

        </div>
        <el-table :data="headInfo.detailList" border ref="finalTable">
          <el-table-column type="selection" width="55"/>
          <el-table-column align="center" type="index" label="序号" width="80"/>
          <el-table-column align="center" prop="skuName" label="商品名称" min-width="150px" show-overflow-tooltip/>
          <el-table-column align="center" prop="skuUnitName" label="基本单位" width="120px"/>
          <el-table-column align="center" prop="skuCode" label="商品条码" min-width="150px"/>

          <el-table-column align="center" label="数量" width="200">
            <template v-slot="{row}">
              <el-input-number :disabled="isDisabled" min="0" v-model="row.qtyPackage"/>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="buyPrice" label="成本单价">
            <template v-slot="{row}">
              <el-input :disabled="isDisabled" v-model="row.buyPrice" type="number"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="成本金额">
            <template v-slot="{row}">
              <money :value="row.skuQty * row.buyPrice"/>
            </template>
          </el-table-column>

          <el-table-column align="center" label="操作" fixed="right">
            <template v-slot="{row,$index}">
              <table-button type="danger" message="确定移除该条数据吗？" el-type="danger"
                            @click="deletedetailList(row,$index)">
                删除
              </table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>

      <form-page :show-page="false">
        <page-button :disabled="isDisabled" type="write" el-type="success" @click="showOrder = true">调入订单</page-button>
        <page-button :disabled="isDisabled" type="write" el-type="primary" @click="saveSubmit">保存单据</page-button>
      </form-page>
    </list-page>

    <el-dialog title="订单列表" v-model="showOrder">
      <search-condition @search="resetSearch" :show-search="true">
        <date-range v-model:start-time="searchInfo.startTime" v-model:end-time="searchInfo.endTime"/>
        <el-input v-model="searchInfo.searchKey" placeholder="请输入单据编号"/>
      </search-condition>
      <dialog-table>
        <el-table height="100%" :data="orderList" border>
          <el-table-column label="序号" width="60px" type="index" :align="'center'"/>
          <el-table-column align="center" label="客户名称" property="clientName" width="150px" show-overflow-tooltip/>
          <el-table-column label="订单时间" property="orderTime" width="200px" :align="'center'"/>
          <el-table-column align="center" label="部门" min-width="150px" show-overflow-tooltip>
            <template v-slot="{row}">
              <dictionary :dictionaries="departList" :disabled="true" v-model="row.departCode" dict-code="departCode"
                          dict-name="departName"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="仓库" min-width="150px" show-overflow-tooltip>
            <template v-slot="{row}">
              <span v-for="(item, i) in stockList" :key="i"><span
                  v-if="item.id === row.stockId">{{ item.stockName }}</span></span>
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'" width="150px">
            <template v-slot="{row}">
              <table-button @click="toSelect(row)">调入</table-button>
            </template>
          </el-table-column>
        </el-table>
      </dialog-table>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="resetSearch"></form-page>
    </el-dialog>
  </div>
</template>

<script>

import ListPage from '@/components/h5/layout/ListPage.vue';
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import stockApi from "@/api/main/stock/stockApi";
import stockInoutApi from "@/api/main/stock/stockInStorageApi";
import elementElMessage from "@/util/elementElMessage";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import commonApi from "@/api/commonApi";
import Dictionary from "@/components/h5/Dictionary.vue";
import util from "@/util/util";
import PageHeader from "@/components/h5/layout/PageHeader";
import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import Money from "@/components/h5/Money.vue";
import restockApi from "@/api/main/stock/restockApi";
import storeApi from "@/api/alpine/merchant/storeApi";
import merchantOrderApi from "@/api/main/supplyChain/merchantOrderApi";
import DateRange from "@/components/h5/date/DateRange.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import ClientSelect from "@/components/h5/customer/ClientSelect.vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";

export default {
  name: "InStorageRecordDetail",
  computed: {},
  components: {
    ClientSelect, SearchCondition,
    DateRange,
    Money,
    TableButton,
    ListPage, TableList, ProductListSelect, Dictionary, PageHeader, FormPage, DialogTable
  },
  data() {
    return {
      isDisabled: false, //是否禁用部分组件
      searchInfo: {},
      showSaveProduct: false,
      productList: [],  //该商户可以入库的所有商品
      skuList: [], //入库商品对应的所有sku
      stockList: [], //仓库列表
      stockProduct: {}, //入库商品信息
      headInfo: {
        remark: "", //备注
        createdName: "", //创建
        createdTime: null, //时间
        stockId: "",  //选择仓库
        status: null,
        departCode: "",
        detailList: [], //需要入库的商品
      },
      queryListApi: {},
      allUnits: [],
      // 是否开启仓库挂载部门功能
      stockDepartSwitch: false,
      // 部门
      departList: [],
      // 当前选中的商品
      currentProduct: {
        skuOriginalCode: "",
      },
      storeList: [],
      showOrder: false,
      orderList: [],
    };
  },
  created() {
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";

    storeApi.queryAll().then(res => this.storeList = res);
    // 查询单位
    commonApi.queryUnits().then(list => this.allUnits = list);
    // 查询配置项
    commonApi.findSetting("STOCK_BANKUP").then(setting => this.stockDepartSwitch = setting.settingValue === "1");
    this.queryListApi = stockInoutApi.queryProductAuthorizeList;
    this.queryStockList();
    // 查询部门，查询成树结构返回
    commonApi.queryDepartList().then(list => this.departList = list);
  },
  mounted() {
    if (this.$routerUtil.query.id) {
      this.findById(this.$routerUtil.query.id);
    } else {
      this.headInfo.createdTime = new Date().format("yyyy-MM-dd hh:mm:ss");
      // 新建
      commonApi.findSetting("STOCK_ORIGINAL_TYPE").then(setting => this.headInfo.originalCodeType = setting.settingValue);
      util.getUserInfo().then(user => {
        this.headInfo.createdName = user.userName;
        // 查询仓库
        this.queryAllStockList();
      })
    }
  },
  methods: {
    toSelect(row){
      merchantOrderApi.createRestock(row.id).then(res => {
        this.headInfo = res;
        if (res.status !== 0) {
          this.isDisabled = true;
        }
        this.changeDepart(res.departCode);
        this.changeTargetDepart(res.targetDepartCode);
        this.showOrder = false;
      })
    },
    findById(id) {
      restockApi.findById(id).then(res => {
        this.headInfo = res;
        if (res.status !== 0) {
          this.isDisabled = true;
        }
        this.changeDepart(res.departCode);
        this.changeTargetDepart(res.targetDepartCode)
      })
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      this.searchInfo.orderType = 11;
      this.searchInfo.isSelectRestock = 1;
      merchantOrderApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.orderList = res.records;
      })
    },
    //获取仓库列表
    queryStockList() {
      stockApi.queryListByNotCondition().then(res => {
        this.stockList = res;
      })
    },
    deleteBatch() {
      let rows = this.$refs.finalTable.getSelectionRows();
      for (let i = 0; i < rows.length; i++) {
        this.headInfo.detailList.splice(this.headInfo.detailList.indexOf(rows[i]), 1);
      }
    },
    toSelectStockChange() {
      // 仓库没有挂载部门
      if (!this.stockDepartSwitch) {
        return;
      }
      // 部门id
      for (let i = 0; i < this.stockList.length; i++) {
        if (this.headInfo.stockId === this.stockList[i].id) {
          // 部门

          this.headInfo.departId = this.stockList[i].bankupId;
          break;
        }
      }
      for (let i = 0; i < this.departList.length; i++) {
        if (this.headInfo.departId === this.departList[i].id) {
          this.headInfo.departCode = this.departList[i].departCode;
        }
      }
    },
    deletedetailList(row, index) {
      this.headInfo.detailList.splice(index, 1);
    },
    saveDraft() {
      this.headInfo.status = 0;
      if (this.checkData()) {
        restockApi.saveDraft(this.headInfo).then(res => this.headInfo = res);
        // this.$routerUtil.push("/stock/instock");
      }
    },
    saveSubmit() {
      this.headInfo.status = 99;
      // debugger;
      for (let i = 0; i < this.headInfo.detailList.length; i++) {
        this.headInfo.detailList[i].skuQty = this.headInfo.detailList[i].qtyPackage;
      }
      if (this.checkData()) {
        restockApi.saveSubmitWarranty(this.headInfo).then(res => {
          this.findById(res.id)
          // this.$routerUtil.push("/stock/instock");
        });
      }
    },
    checkData() {
      if (!this.headInfo.departCode) {
        this.$message.error("请选择部门");
        return false;
      }
      if (!this.headInfo.stockId) {
        this.$message.error("请选择仓库");
        return false;
      }
      if (this.headInfo.detailList.length === 0) {
        elementElMessage.toShowMessage("入库商品不能为空", "warning");
        return false;
      }
      return true;
    },
    // 商品组件选中商品后，回调显示
    selectProduct(productSkuList) {
      // 向detail中添加数据
      let arr = [...this.headInfo.detailList];
      console.log(arr)
      for (let i = 0; i < productSkuList.length; i++) {
        // 去重添加
        let contains = false;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].skuId === productSkuList[i].id) {
            contains = true;
            break;
          }
        }
        if (!contains) {
          arr.push({
            skuId: productSkuList[i].id,
            skuQty: productSkuList[i].skuQty,
            skuName: productSkuList[i].skuName,
            skuCode: productSkuList[i].skuCode,
            buyPrice: productSkuList[i].skuBuyPrice,
            salePrice: productSkuList[i].skuSalePrice,
            skuUnitId: productSkuList[i].skuUnitId,
            skuUnitName: productSkuList[i].skuUnitName,
          });
        }
      }
      this.headInfo.detailList = arr;
    },
    clearDepart() {
      this.headInfo.departCode = "";
      this.headInfo.stockId = "";
      // 重新获取仓库
      this.queryStockList();
    },
    queryAllStockList() {
      // 获取用户信息
      util.getUserInfo().then((user) => {
        if (!this.$routerUtil.query.id) {
          // 新建的时候
          this.headInfo.createdName = user.userName;
          this.headInfo.employeeId = user.id;
          this.headInfo.departCode = user.mainDepartCode;
          this.headInfo.customerId = "10000";
          // //  根据departId查询仓库，如果只有一个仓库，添加创建
          this.queryAllStockList2(user.mainDepartId)
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.custom-info {
  min-width: 400px;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

//:deep(.el-dialog__title) {
//  font-size: 13px !important;
//}

:deep(.el-divider--horizontal) {
  margin: 10px 0 20px 0 !important;
}

.rotating-element {
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.attachment-item {
  position: relative;
  color: #409eff;
  margin-right: 10px;

  .close {
    color: #F56C6C;
    margin-left: 5px;
    line-height: 31px;
  }
}

.attachment-item:last-child {
  margin-right: 0;
}

.table-all {
  height: calc(100% - 223px);
}

.table-all-suc {
  height: calc(100% - 185px);
}

.table-part {
  height: calc(100% - 97px);
}

.table-part-suc {
  height: calc(100% - 85px);
}


.top-all {
  height: 223px;
}

.top-all-suc {
  height: 185px;
}

.top-part {
  height: 97px;
}

.top-part-suc {
  height: 85px;
}


.el-input-text {
  :deep(textarea) {
    padding: 7px;
    box-sizing: border-box;
  }
}

.custom-info {
  min-width: 400px;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  display: flex;
  justify-content: space-between;
  padding-right: 100px;
}


</style>