import axios from "@/util/axios";

export default {
    // 查询列表
    queryList: (data) => axios.get({url: "/basedata/base/common/mini/notice/queryList", data: data}),

    // 保存提交
    saveSubmit: (data) => axios.post({url: "/basedata/base/common/mini/notice/saveSubmit", data: data}),

    // 删除
    delete: (id) => axios.del({url: "/basedata/base/common/mini/notice/delete", data: {id}})
}