<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>
          <div style="display: flex; align-items: center">
<!--            <el-dropdown split-button trigger="click" type="primary" @command="handleSetPrint"-->
<!--                         style="margin: 2px 15px 0 0;">-->
<!--              打印设置-->
<!--              <template #dropdown>-->
<!--                <el-dropdown-menu>-->
<!--                  <el-dropdown-item :icon="Edit" :command="1">押金收款</el-dropdown-item>-->
<!--                  <el-dropdown-item :icon="Edit" :command="2">押金退款</el-dropdown-item>-->
<!--                </el-dropdown-menu>-->
<!--              </template>-->
<!--            </el-dropdown>-->
            <page-button el-type="primary" @click="resetSearch">查询</page-button>
            <page-button el-type="primary" type="ex" @click="exportList">导出</page-button>
            <page-button type="write" @click="$routerUtil.push(`/advance/list/detail`)" el-type="success">新建</page-button>
          </div>
        </template>
      </page-header>
      <search-condition @search="resetSearch">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期" v-model:end-time="searchInfo.endTime" />
          <el-input v-model="searchInfo.searchKey" @change="resetSearch" clearable placeholder="请输入押金订单编号"/>
          <div style="display: inline-block;" class="customer-select">
            <customer-select v-model="searchInfo.customerId" :name="searchInfo.customerName" @selectOne="setCustomer" />
          </div>
        </template>
        <template #moreCondition>
          <div style="display: inline-block; margin-right: 10px">
            <dictionary v-model="searchInfo.departCode" :place-holder="'请选择部门'" @clearData="clearDic" :dictionaries="departList"
                        dict-code="departCode"
                        dict-name="departName"/>
          </div>
          <el-select v-model="searchInfo.surPlusPayType" placeholder="选择单据类型" clearable>
            <el-option label="收押金" :value="1" />
            <el-option label="退押金" :value="99" />
          </el-select>
        </template>
      </search-condition>
      <table-list>
        <el-table :data="dataList" height="100%" border show-summary :summary-method="getSummary">
          <el-table-column align="center" label="序号" type="index" min-width="60px" fixed/>
          <el-table-column align="center" label="单据编号" show-overflow-tooltip min-width="200px" fixed>
            <template v-slot="{row}">
              <table-button class="btn-id" v-if="row.surplusPayType === 1" el-type="primary" type="write" @click="$routerUtil.push(`/advance/list/detail?id=${row.id}`)">{{row.id}}</table-button>
              <table-button class="btn-id" v-else el-type="primary" type="write" @click="$routerUtil.push(`/advance/list/refund?id=${row.id}`)">{{row.id}}</table-button>
            </template>
          </el-table-column>
          <el-table-column :align="'center'" label="单据时间" show-overflow-tooltip min-width="150px" prop="createdTime"/>
          <el-table-column align="center" label="客户名称" show-overflow-tooltip min-width="100px" prop="customerName" />
          <el-table-column align="center" label="部门" show-overflow-tooltip min-width="100px" prop="departName" />
          <el-table-column align="center" label="金额" prop="amountPay" show-overflow-tooltip min-width="100px">
            <template v-slot="{row}">
              {{row.surplusPayType === 1 ? row.amountPay : - row.refundAmount}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="经销人员" show-overflow-tooltip min-width="100px" prop="employeeName" />
          <el-table-column align="center" label="押金类型" show-overflow-tooltip min-width="100px">
            <template v-slot="{row}">
              <echo-dic :info-list="dictionaryList" :value="row.depositType" />
            </template>
          </el-table-column>
          <el-table-column align="center" label="单据类型" show-overflow-tooltip min-width="100px">
            <template v-slot="{row}">
              <el-tag :type="row.surplusPayType === 1 ? 'primary' : 'warning'">{{ row.surplusPayType === 1 ? '收押金' : '退押金' }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="是否退款" show-overflow-tooltip min-width="100px">
            <template v-slot="{row}">
              <el-tag v-if="!row.isRefund" type="info">无</el-tag>
              <el-tag v-else-if="row.isRefund === 1" type="primary">未退款</el-tag>
              <el-tag v-else-if="row.isRefund === 2" type="primary">已退款</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="订单状态" show-overflow-tooltip min-width="100px">
            <template v-slot="{row}">
              <el-tag v-if="row.status === 0" type="info">草稿</el-tag>
              <el-tag v-else-if="row.status === 50" type="warning">未完成</el-tag>
              <el-tag v-else-if="row.status === 99" type="success">已完成</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="支付状态" show-overflow-tooltip min-width="100px">
            <template v-slot="{row}">
              <el-tag v-if="row.payStatus === 0" type="info">未支付</el-tag>
              <el-tag v-if="row.payStatus === 20" type="info">支付中</el-tag>
              <el-tag v-else-if="row.payStatus === 30" type="error">支付失败</el-tag>
              <el-tag v-else-if="row.payStatus === 99" type="success">支付成功</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="支付方式" show-overflow-tooltip min-width="100px">
            <template v-slot="{row}">
              <pay-type-select :echo-pay-type-id="row.payTypeId" :echo-pay-type-list="payTypeList" />
            </template>
          </el-table-column>

          <el-table-column align="center" label="操作" show-overflow-tooltip width="200px" fixed="right">
            <template v-slot="{row}">
              <table-button type="write" v-if="row.surplusPayType === 1" :disabled="row.status !== 0" @click="toShowPay(row)">收款</table-button>
              <table-button type="write" v-else-if="row.surplusPayType === 2" :disabled="row.status !== 0" @click="toShowPay(row)">付款</table-button>
              <table-button type="write" :disabled="row.surplusPayType === 2 || row.payStatus < 50" @click="toRefund(row)">退押金</table-button>
              <table-button el-type="danger" type="write" message="是否确定删除该草稿" :disabled="row.status !== 0" @click="toDelete(row)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>
  </div>

  <el-dialog v-model="showOrder" width="500px" height="310px" :title=" currentPayRow.surplusPayType === 1 ? isPaying ? '收款确认&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支付中...' : '收款确认' : '退款确认' " @opened="openBefore">
    <div style="height: 238px;overflow: auto">
      <el-form label-width="70px" style="">
        <div style="height: 198px">
          <el-form-item label="扫码支付">
            <div style="position: relative;width: 100%;overflow: hidden">
              <el-input v-model="currentPayRow.qrCode" ref="inputRef" placeholder="支付码" style="width: 100%" @change="pay" :disabled="currentPayRow.surplusPayType === 2 || currentPayRow.payType !== 2" />
              <div v-if="currentPayRow.payStatus === 20"
                   style="position: absolute;right: 0;top: 0;line-height: 40px;border-left: 1px solid #dcdfe6;width: 32px;text-align: center;">
                <el-tooltip :disabled="isRefreshPay" content="支付中，请稍后刷新支付状态" effect="light">
                  <el-icon :class="isRefreshPay ? 'rotating-element' : ''">
                    <el-icon-refresh @click="toFindPayStatus"/>
                  </el-icon>
                </el-tooltip>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="客户名称">
            <div style="position: relative;width: 100%;overflow: hidden">
              <el-input v-model="currentPayRow.customerName" disabled style="width: 100%" />
            </div>
          </el-form-item>

          <el-form-item v-if="currentPayRow.surplusPayType === 1" label="金额合计">
            <el-input disabled v-model="currentPayRow.amountPay"/>
          </el-form-item>
          <el-form-item v-else-if="currentPayRow.surplusPayType === 2" label="退款金额">
            <el-input disabled v-model="currentPayRow.refundAmount"/>
          </el-form-item>

          <el-form-item :label="currentPayRow.surplusPayType === 1 ? '支付方式' : '退款方式'">
            <pay-type-select :disabled="currentPayRow.surplusPayType === 2" v-model="currentPayRow.payTypeId" @select-one="changePay"  />
          </el-form-item>
        </div>
      </el-form>
      <div style="display: flex;justify-content: space-between">
        <div>
          <el-checkbox v-model="isAutoPrint" label="打印收款凭证" size="large"/>
          <el-icon color="#409EFF" style="margin-left: 2px;" @click="toSetPrint">
            <el-icon-setting/>
          </el-icon>
        </div>
        <div>
          <page-button el-type="info" @click="showOrder = false">取消</page-button>
          <page-button el-type="primary" @click="pay" :loading="preparePay" :disabled="disableToClickPay">确认</page-button>
        </div>
      </div>
    </div>
  </el-dialog>

</template>
<script>
import ListPage from "@/components/h5/layout/ListPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import advanceApi from "@/api/main/advance/advanceApi";
import TableButton from "@/components/h5/button/TableButton.vue";
import PageHeader from "@/components/h5/layout/PageHeader";
import commonApi from "@/api/commonApi";
import EchoDic from "@/components/h5/EchoDic.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import GjpLoaclPrint from "@/util/GjpLoaclPrint";
import PayTypeSelect from "@/components/h5/PayTypeSelect.vue";
import AdvanceApi from "@/api/main/advance/advanceApi";
import systemPayApi from "@/api/main/sys/systemPayApi";
import {h} from "vue";
import orderStatisticApi from "@/api/main/order/orderStatisticApi";
import {Edit} from "@element-plus/icons-vue";
import memberApi from "@/api/main/customer/memberApi";
import util from "@/util/util";

export default {
  name: "AdvanceList",
  computed: {
    Edit() {
      return Edit
    }
  },
  components: {
    PayTypeSelect,
    Dictionary,
    EchoDic,
    TableButton,
    TableList, FormPage,
    SearchCondition, CustomerSelect, PageButton,
    ListPage, PageHeader, DateRange
  },
  data() {
    return {
      searchInfo: {
        customerId:"",
        surplusPay: null,
        surPlusPayType: null,
        advanceType: null,
      },
      dataList: [],
      dictionaryList: [], // 字典列表
      customerList: [],
      employeeList: [],
      departList: [],
      showOrder: false,

      // 支付或打印的信息
      payTypeList: [],
      currentPayRow: {},
      isAutoPrint: true,
      disableToClickPay: false,
      preparePay: false,
      bottomInfo:{},
      // payTypeList: [],
      setPrintSelect: "",
      isPaying: false,
      isRefreshPay: false,
      timerToPayResult: null, // 计时器
    };
  },
  watch: {
    'searchInfo.startTime': {
      handler() {
        this.resetSearch();
      }
    },
    'currentPayRow.payType':{
      handler(val){
        if(val === 2){
          this.$refs.inputRef.focus();
        }
      }
    },
  },
  created() {
    systemPayApi.queryAllList().then(res=> this.payTypeList = res);
    commonApi.queryAllEmployee().then(res => this.employeeList = res);
    commonApi.queryDepartList().then(res => this.departList = res);
    //获取字典列表
    commonApi.queryDictionaryList("DEPOSIT_TYPE").then(list => this.dictionaryList = list);
    // 初始化时间
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
  },
  methods: {
    // 导出
    exportList(){
      util.exportExcel("/order/advance/exportList", this.searchInfo);
    },
    toFindPayStatus() {
      if(this.isRefreshPay) this.$message.warning("正在获取最新状态，请耐心等待！！！");
      this.isRefreshPay = true;
      advanceApi.findSurplusStatus(this.currentPayRow.payId).then(res => {
        this.findById(this.currentPayRow.id).then(() => {
          if (this.currentPayRow.payStatus >= 50) {
            this.successResult();
            if (this.isAutoPrint) this.toPrint();
            this.$message.success(res);
            clearInterval(this.timerToPayResult);
            return;
          }
          if(this.currentPayRow.payStatus === 30){
            this.successResult();
            this.$message.error(res);
            clearInterval(this.timerToPayResult);
            return;
          }
        })
        if (res === "订单状态更新失败") {
          this.$message.error(res);
          clearInterval(this.timerToPayResult);
          return;
        } else if (res !== "支付成功,订单状态已刷新" && res !== "支付失败,请重试") {
          this.$message.warning("正在努力获取结果，请耐心等待！！！");
        }
        this.isRefreshPay = false;
        clearTimeout(this.timerToPayResult);
        this.timerToPayResult = setTimeout(() => {
          this.toFindPayStatus();
        }, 1000);
      }).catch(()=>{
        this.isRefreshPay = false;
      })
    },
    handleSetPrint(command) {
      this.setPrintSelect = command;
      this.toSetPrint();
    },
    changePay(data){
      this.currentPayRow.payTypeId = data.id;
      this.currentPayRow.payType = data.payCode;
    },
    clearDic(){
      this.searchInfo.departCode = "";
    },
    toRefund(row){
      //查询该订单是否已经退款
      advanceApi.findByOriginalId(row.id).then(res=>{
        let currentAmount = 0; //当前金额
        if(res.length > 0){
          for (let i = 0; i < res.length; i++) {
            currentAmount += res[i].refundAmount;
          }
          if(currentAmount >= row.amountPay){
            this.$message.error("该订单已全部退款，或已添加退款草稿未处理");
            return;
          }
        }
        this.$routerUtil.push(`/advance/list/refund?id=${row.id}&amount=${row.amountPay - currentAmount}`)
      })
    },
    setCustomer(data){
      this.searchInfo.customerId = data.id;
      this.searchInfo.customerName = data.customerName;
      this.resetSearch();
    },
    resetSearch(){
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList(){
      advanceApi.queryList(this.searchInfo).then(res=>{
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      })
      this.queryBottomList();
    },
    queryBottomList(){
      let searchVo = this.searchInfo;
      orderStatisticApi.querySurplusInfo(searchVo).then(res => this.bottomInfo = res);
    },

    toDelete(row){
      if(row.status !== 0){
        this.$message.warning("只能删除草稿");
        return;
      }
      advanceApi.delDraft(row.id).then(()=>{
        this.queryList();
      });
    },

    toShowPay(row){
      this.currentPayRow = row;
      this.showOrder = true;
    },
    successResult(){
      this.isPaying = false;
      this.disableToClickPay = false;
      this.preparePay = false;
      this.showOrder = false;
    },
    pay(){
      this.disableToClickPay = true;
      this.preparePay = true;
      this.isPaying = true;
      AdvanceApi.saveSubmit(this.currentPayRow).then((res)=>{
        this.findById(res.id).then(()=>{
          if (this.currentPayRow.status === 99 || this.currentPayRow.payStatus >= 50) {
            if(this.isAutoPrint) this.toPrint();
            this.successResult();
            this.$message.success("支付成功");
          } else if (this.currentPayRow.payStatus === 20){
            this.$message.warning("支付中，请稍后刷新支付状态！！！")
            this.toFindPayStatus();
          }
        });
        this.queryList();

      }).finally(()=>{
        this.disableToClickPay = false;
        this.preparePay = false;
        this.isPaying = false;
      });
    },
    findById(id){
      return AdvanceApi.findById(id).then(res=>{
        this.currentPayRow = res;
      })
    },
    openBefore() {
      this.$nextTick(() => {
        this.$refs.inputRef.$el.querySelector('input').focus();
      });
    },

    // 打印
    async toPrint() {
      // 组装打印数据
      const customerInfo = await memberApi.findById(this.currentPayRow.customerId);
      let data = this.getPrintData("print",customerInfo.customerName,customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2'));
      GjpLoaclPrint.Print(data);
    },
    // 打印设置
    async toSetPrint() {
      let customerInfo;
      if(this.currentPayRow.customerId){
        customerInfo = await memberApi.findById(this.currentPayRow.customerId);
      }
      let data = this.getPrintData("design",customerInfo.customerName,customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2'));
      GjpLoaclPrint.Print(data);
    },
    // 拼接打印数据
    getPrintData(type,customerName,customerPhone) {
      let data = {
        // reportName: "押金订单",
        amount: this.currentPayRow.amountPay,
        refundAmount: this.currentPayRow.refundAmount,
        customerName: customerName,
        customerPhone: customerPhone,
        // orderTime: this.currentPayRow.orderTime,
        employeeName: this.currentPayRow.employeeName,
        status: this.currentPayRow.status !== 99 ? '草稿' : '已支付',
        // depositType: this.currentPayRow.depositType,
        excuteType: type,
      }
      //判断当前需要打印的类型
      if(type === "design"){
        if(this.setPrintSelect === 1){
          data.reportName = "押金收款";
        }else if(this.setPrintSelect === 2){
          data.reportName = "押金退款";
        }
      }else if(type === "print"){
        if(this.currentPayRow.surplusPayType == 1 ){
          data.reportName = "押金收款";
          data.amountActual = this.currentPayRow.amountPay;
        }else if(this.currentPayRow.surplusPayType == 2){
          data.reportName = "押金退款";
          data.refundActual = this.currentPayRow.refundAmount;
        }
      }
      //操作人员
      for (let i = 0; i < this.employeeList.length; i++) {
        if (this.currentPayRow.createdId === this.employeeList[i].id) {
          data.createdName = this.employeeList[i].employeeName
        }
      }
      // 订单编号
      if(this.currentPayRow.id){
        data.orderNumber = this.currentPayRow.id;
      }
      // 支付时间
      if(this.currentPayRow.payTime){
        data.orderTime = this.currentPayRow.payTime;
      }else{
        data.orderTime = "暂未支付";
      }
      //科室名称
      for (let i = 0; i < this.departList.length; i++) {
        if (this.currentPayRow.departCode === this.departList[i].departCode) {
          data.departName = this.departList[i].departName;
        }
      }
      //职员名称
      for (let i = 0; i < this.employeeList.length; i++) {
        if (this.currentPayRow.employeeId === this.employeeList[i].id) {
          data.employeeName = this.employeeList[i].employeeName
        }
      }
      //押金类型
      for (let i = 0; i < this.dictionaryList.length; i++) {
        if(this.currentPayRow.depositType === this.dictionaryList[i].dictCode){
          data.depositType = this.dictionaryList[i].dictName;
        }
      }
      // 支付状态
      if(this.currentPayRow.payStatus === 0 || !this.currentPayRow.payStatus){
        data.payStatus = "未支付";
      }else if(this.currentPayRow.payStatus === 99){
        data.payStatus = "已支付";
      }else if(this.currentPayRow.payStatus === 30){
        data.payStatus = "支付失败";
      }
      // 支付方式
      for (let i = 0; i < this.payTypeList.length; i++) {
        if (this.payTypeList[i].id === this.currentPayRow.payTypeId) {
          data.payType = this.payTypeList[i].payName;
        }
      }
      data.productList = [];
      return data;
    },

    getSummary(param){
      const {columns} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '合  计',
          ])
          return
        }
        // if(index === 4){
        //   sums[index] = h('div', {style: {fontWeight: 'bolder'}},[Number(this.bottomInfo.totalQty).toFixed(2)])
        // }

        if(index === 5){
          sums[index] = h('div', {style: {fontWeight: 'bolder'}},[Number(this.bottomInfo.totalAmount).toFixed(2)])
        }
      })
      return sums
    },

  },

};
</script>

<style lang="scss" scoped>
.customer-select{
  width: 240px;
  margin-bottom: 10px;
  box-sizing: border-box;
  margin-right: 10px;
  vertical-align: top;
}
.btn-id:hover{
  text-decoration: underline;
}
.rotating-element {
  animation: rotate 1.5s linear infinite;
}
</style>