<template>
  <!-- 轮播图片组件 -->
  <div class="bg-common" :style="
  {height: dataInfo.pictureInfo[0].height,'margin-top': dataInfo.pictureInfo[0].marginTop,'width': dataInfo.pictureInfo[0].boxWidth}">
    <div style="width: 100%;position: relative">
      <img :style="{width: '100%',height: dataInfo.pictureInfo[0].height,}" :src="dataInfo.pictureInfo[0].imageUrl" alt="">

      <span :style="
      {position: 'absolute',
       'left': dataInfo.pictureInfo[0].left, top: dataInfo.pictureInfo[0].top,
       'font-size': dataInfo.pictureInfo[0].size,
       'color': dataInfo.pictureInfo[0].color,
       'white-space': dataInfo.pictureInfo[0].whiteSpace ? 'normal' : 'nowrap',
       'border-radius': dataInfo.pictureInfo[0].radius,}">{{ dataInfo.pictureInfo[0].text }}</span>
    </div>
  </div>
</template>

<script>

import {watch} from "vue";

export default {
  props: {
    // 修改信息
    configuration: {default: {}},
    // 索引位置，用来寻找匹配的配置信息作渲染遍历
    indexToFind: {default: null},
    // 初始化信息
    initData: {default: {}},
  },
  name: "CommonImg",
  components: {
  },
  data() {
    return {
      dataInfo: {},
    };
  },
  created() {
    // 监听这个配置信息
    watch([() => this.configuration], () => {
      this.init();
    },{ deep: true })
    // 刷新重载、只在页面初始化时执行一次
    if (this.initData !== null) {
      this.dataInfo = this.initData;
    }
  },
  methods: {
    // 初始化
    init() {
      if (this.indexToFind !== this.configuration.index && !this.dataInfo) {
        return;
      }
      if (this.indexToFind === this.configuration.index) {
        this.dataInfo = this.configuration;
      }
      console.log(this.dataInfo);
    },
  }
};
</script>

<style lang="scss" scoped>
.bg-common {
  width: 100%;
  //height: 120px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

</style>