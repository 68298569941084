<template>
  <!-- -->
  <div :id="id" style="height: 100%;" :style="{'--tabPanelHeight': tabPanelHeight}">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: "",
  components: {
  },
  data() {
    return {
      id: "",
      // 列表页面查询条件的高度
      conditionHeight: {

      },
      // 页面本身的高度
      mainHeight: 500,
      ready: false,
      // 使用el-tabs时的panel
      tabPanelHeight: "400px",
      divType: "list_page",
      timeOut: "",
    };
  },
  unmounted() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  },
  created() {
    this.mainHeight = 500;
    this.ready = false;
    this.id = 'list-page' + (Math.random()*9999).toFixed(0);
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      let doc = document.querySelector(`#${this.id}`);
      this.mainHeight = doc.clientHeight;
      // 未渲染完成,稍后再试
      if (this.mainHeight === 0) {
        this.timeOut = setTimeout(() => this.initPage(), 100);
        return;
      }
      this.setListHeight();
      this.ready = true;
    },
    setListHeight() {
      let h = 0;
      for (let key in this.conditionHeight) {
        h += this.conditionHeight[key];
      }
      this.tabPanelHeight = this.mainHeight - h - 10 + "px";
    },
    setConditionHeight(height, name) {
      this.conditionHeight[name] = height;
      this.setListHeight();
    }
  }
};
</script>

<style lang="scss" scoped>
</style>