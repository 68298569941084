
<template>
  <!-- 页面数据较长时，进行合并显示,不能单独使用，需要结合longForm，类似el-tab-panel-->
  <div class="" :id="'longFormPanel' + panelId">
    <div class="long-form-title">
      <span class="long-form-span" @click="trigger"><el-icon v-if="showData"><el-icon-caret-bottom/></el-icon><el-icon v-else ><el-icon-caret-right/></el-icon> {{label}}</span>
      <slot name="title"/>
    </div>
    <div class="long-form-content" :class="!showData?'long-form-content-close': ''">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    label: {default: ""},
  },
  watch: {
    label: {
      handler() {
        // label变更，需要更新数据
        this.updatePanelLabel();
      }
    }
  },
  name: "",
  components: {
  },
  data() {
    return {
      showData: true,
      panelId: 0,
    };
  },
  created() {
    this.addPanel();
  },
  beforeUnmount() {
    this.$parent.removePanel(this.label);
  },
  methods: {
    switchStatus() {
      return this.showData;
    },
    trigger() {
      this.showData = !this.showData;
      // 上级按钮处理
      if (this.$parent.triggerChild) {
        this.$parent.triggerChild();
      }
    },
    switchOpen(val) {
      this.showData = val;
    },
    addPanel() {
      if (this.$parent.addPanel) {
        this.panelId = this.$parent.addPanel(this.label);
      }
    },
    updatePanelLabel() {
      this.$parent.updatePanelLabel(this.label, this.panelId);
    }
  }
};
</script>

<style lang="scss" scoped>
.long-form-content{
  transition: all .3s;
  height: auto;
  overflow: hidden;

}
.long-form-content-close{
  height: 0;
}
.el-form{
  min-width: 600px;
  display: inline-block;
}
.long-form-content :deep( .el-form-item__content){
  white-space: nowrap;
}
.long-form-title{
  line-height: 36px;
  height: 36px;
  .long-form-span{
    cursor: pointer;
  }
}
</style>