<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <template #default>
          <page-button el-type="primary" type="read" @click="resetSearch">查询</page-button>
          <page-button type="write" @click="$routerUtil.push({path: `/product/relateDepart/detail`})" el-type="success">
            新建
          </page-button>
        </template>
      </page-header>

      <search-condition @search="resetSearch">
        <template #default>
          <el-select v-model="searchInfo.grantType" placeholder="请选择分类类型">
            <el-option label="分类" :value="1" />
            <el-option label="商品" :value="2" />
          </el-select>
          <el-input placeholder="请输入商品名称" v-model="searchInfo.productName" />
          <div style="display: inline-block;width: 240px">
            <dictionary v-model="searchInfo.categoryCode" :dictionaries="productCategoryList" place-holder="请选择商品类目"/>
          </div>
        </template>
      </search-condition>

      <table-list>
        <el-table :data="dataList" border height="100%">
          <el-table-column align="center" label="序号" type="index" min-width="60px" fixed/>
          <el-table-column align="center" label="授权类型" prop="" min-width="120px">
            <template v-slot="{row}">
              <el-tag v-if="row.grantType === 1" type="primary">分类</el-tag>
              <el-tag v-else type="success">商品</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="部门名称" prop="departName" min-width="120px">
            <template v-slot="{row}">
              <dictionary :disabled="true" v-model="row.departCode"
                          :dictionaries="departList"
                          dict-code="departCode"
                          dict-name="departName"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="商品分类" prop="category" min-width="120px">
            <template v-slot="{row}">
              <dictionary :dictionaries="productCategoryList" v-model="row.productCategory" :disabled="true"  />
            </template>
          </el-table-column>
          <el-table-column align="center" label="商品编号" prop="productCode" min-width="120px"/>
          <el-table-column align="center" label="商品名称" prop="productName" min-width="120px" show-overflow-tooltip/>
          <el-table-column align="center" label="规格" prop="productStandard" min-width="120px"/>
          <el-table-column align="center" label="型号" prop="productType" min-width="120px"/>
          <el-table-column align="center" label="操作" fixed="right">
            <template v-slot="{row}">
<!--              <table-button type="write" el-type="primary" @click="$routerUtil.push(`/product/relateDepart/detail?id=${row.id}`)">编辑</table-button>-->
              <table-button type="delete" el-type="delete" message="该操作可能会影响小程序商品展示，您确认继续吗？" @click="deleteRow(row)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>

    </list-page>
  </div>
</template>

<script>

import ListPage from "@/components/h5/layout/ListPage.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import commonApi from "@/api/commonApi";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import productGrantDepartApi from "@/api/main/product/productGrantDepartApi";

export default {
  name: "",
  components: {
    FormPage,
    Dictionary,
    PageButton, PageHeader, TableButton, TableList, SearchCondition, ListPage
  },
  data() {
    return {
      searchInfo: {},
      // 商品类目
      productCategoryList: [],
      dataList: [],
      departList: [],
    };
  },
  created() {
    // 查询商品分类
    commonApi.queryDictionaryTree("PRODUCT_CATEGORY").then(list => this.productCategoryList = list);
    // 查询部门，查询成树结构返回
    commonApi.queryDepartList().then(list => this.departList = list);
  },
  methods: {
    resetSearch(){
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList(){
      productGrantDepartApi.queryList(this.searchInfo).then(res=>{
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    deleteRow(data){
      productGrantDepartApi.deleteBatch([data.id]).then(()=>{
        this.resetSearch();
      })
    }
  }
};
</script>

<style lang="scss" scoped>

</style>