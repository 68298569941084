<template>
  <!-- 树形结构的字典组件，用于separator布局放在左边 -->
  <div style="height: 100%;overflow: auto">
    <el-tree :data="treeList" :props="{children: 'children', label: 'dictName'}" @node-click="clickCategory" empty-text="未添加分类" node-key="id" default-expand-all :expand-on-click-node="false">
      <template #default="{node, data}">
        <!--            <span :class="currentDepart.id === data.id? 'tree-on': ''">{{data.departName}}</span>-->
        <span class="custom-tree-node">
              <span class="node-name">{{ node.label }}</span>
              <span class="node-option">
                <table-button v-if="editAble" type="primary" @click="toAddNewCategory({parentId: data.id})"><el-icon><el-icon-plus/></el-icon></table-button>
                <table-button v-if="editAble && data.id" type="primary" @click="toAddNewCategory(data)"><el-icon><el-icon-edit/></el-icon></table-button>
                <table-button v-if="editAble && data.id" type="delete" class="btn" message="是否确认删除这个分类" @click="toDeleteCategory(data.id)"><el-icon><el-icon-delete/></el-icon></table-button>
              </span>
            </span>
      </template>
    </el-tree>
    <el-dialog title="商品分类管理" v-model="showProductDialog" width="40%" :close-on-click-modal="false">
      <el-form label-width="70px" >
        <el-form-item label="上级分类">
          <dictionary v-model="currentCategory.parentId" :dictionaries="treeList" dict-code="id" dict-name="dictName" style="width: 100%"/>
        </el-form-item>
        <el-form-item label="分类名称">
          <el-input v-model="currentCategory.dictName" />
        </el-form-item>
        <el-form-item label="分类编码">{{ currentCategory.dictCode }}</el-form-item>
      </el-form>
      <detail-page-btn>
        <el-button @click="showProductDialog = false">取消</el-button>
        <el-button type="primary" @click="saveCategory">确定</el-button>
      </detail-page-btn>
    </el-dialog>
  </div>

</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton.vue";
import commonApi from "@/api/commonApi";
import dictionaryApi from "@/api/main/sys/dictionaryApi";
import util from "@/util/util";
import DetailPageBtn from "@/components/h5/layout/DetailPageBtn.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import {watch} from "vue";
export default {
  emits: ["clickCategory", "update:dictionaryList"],
  props: {
    // 字典列表，这个列表将会反写
    dictionaryList: {default: []},
    // 字典类型，不能为空
    dictionaryType: {default: ""},
    // 不可编辑
    disabled: {default: false},
    // 部分字典将以总部字典为准，需指定id
    merchantId: {default: ""},
    // 当前数据对应的code,可以通过code限制显示的数据范围，比如商户分类，商户只能查看自己归属的分类，不能查看其它数据
    code: {default: ""},
  },
  name: "",
  components: {
    Dictionary, DetailPageBtn,
    TableButton,
    PageButton,
    ListPage, TableList, FormPage
  },
  data() {
    return {
      userInfo: {},
      treeList: [],
      editAble: true,
      currentCategory: {},
      showProductDialog: false,
    };
  },
  created() {
    util.getUserInfo().then(user => {
      this.userInfo = user;
      if (!this.dictionaryType) {
        // 没有类型，返回
        return;
      }
      this.init();
    })
    watch(() => this.code, () => this.init());
  },
  methods: {
    init() {
      if (this.merchantId && this.merchantId !== this.userInfo.merchantId) {
        // 指定了id，且id不同，不可编辑
        this.editAble = false;
      }
      if (this.disabled) {
        // 手动禁用了编辑，不可编辑
        this.editAble = false;
      }
      // 查询字典
      commonApi.queryDictionaryTree(this.dictionaryType, this.merchantId).then(list => {
        let validList = this.showLimitCategory(list);
        // 将数据反写到父组件，用于显示
        this.$emit("update:dictionaryList", validList);
      })
    },
    // 数据受限显示，如果指定了code,只显示code下级，否则显示全部
    showLimitCategory(list) {
      if (this.code) {
        let d = this.getDataByCode(list, this.code);
        if (d.dictCode) {
          this.treeList = [d];
          return [d];
        } else {
          this.treeList = [];
          return [];
        }
      } else {
        this.treeList = [{dictCode: "", dictName: "全部", children: list}];
        return list;
      }
    },
    getDataByCode(list, code) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].dictCode === code) {
          return list[i];
        }
        // 如果有下级
        if (list[i].children && list[i].children.length > 0) {
          let d = this.getDataByCode(list[i].children, code);
          if (d.dictCode) {
            return;
          }
        }
      }
      return {};
    },
    clickCategory(data) {
      this.$emit("clickCategory", data);
    },
    // 编辑，修改分类
    toAddNewCategory(data) {
      this.currentCategory = {...data};
      if (!this.currentCategory.type) {
        this.currentCategory.type = this.dictionaryType;
      }
      this.showProductDialog = true;
    },
    // 删除分类
    toDeleteCategory(id) {
      dictionaryApi.deleteById(id).then(() => this.init());
    },
    saveCategory() {
      dictionaryApi.saveTreeNode(this.currentCategory).then(() => {
        this.init();
        this.showProductDialog = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/tree";
</style>