<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary" type="read" @click="resetSearch">查询</page-button>
        <page-button el-type="success" type="write" @click="$routerUtil.push(`/alpine/customer/member/detail`)">添加</page-button>
      </page-header>
      <search-condition @search="resetSearch">
        <el-input v-model="searchInfo.searchKey" clearable placeholder="请输入会员名称/手机号码" @keydown.enter="resetSearch"/>
      </search-condition>
      <table-list>
        <el-table :data="dataList" height="100%" border>
          <el-table-column label="序号" type="index" width="60px" :align="'center'" fixed/>
          <el-table-column label="会员名称" prop="customerName"/>
          <el-table-column label="联系电话" prop="customerPhone"/>
          <el-table-column label="性别">

            <template v-slot="{row}">
              <span v-if="row.gender === 1">男</span>
              <span v-if="row.gender === 2">女</span>
              <span v-if="row.gender === 0">未知</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'" width="100px" fixed="right">
            <template v-slot="{row}">
              <table-button type="write" @click="$routerUtil.push(`/alpine/customer/member/detail?id=${row.id}`)">编辑</table-button>
              <table-button el-type="danger" type="delete" @click="deleteData(row)" message="是否确认删除数据">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList"/>
    </list-page>
  </div>

</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import TableButton from "@/components/h5/button/TableButton";
import PageButton from "@/components/h5/button/PageButton";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import memberApi from "@/api/alpine/customer/memberApi";
export default {
  name: "",
  components: {
    PageButton,
    TableButton,
    ListPage, FormPage, TableList, SearchCondition, PageHeader
  },
  data() {
    return {
      dataList: [],
      searchInfo: {
        searchKey: "",
      }
    };
  },
  created() {
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      memberApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    deleteData(data) {
      memberApi.delete(data.id).then(() => this.queryList());
    }
  }
};
</script>

<style lang="scss" scoped>

</style>