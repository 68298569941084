<template>
  <!-- 字典，列表结构 -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
        <page-button type="write" el-type="success" @click="toAddData">添加</page-button>
      </page-header>
      <search-condition>
        <el-input v-model="searchInfo.searchKey" placeholder="案例标题"/>
      </search-condition>
      <!--   客户案例列表-->
      <table-list>
        <el-table height="100%" border :data="dataList">
          <el-table-column label="序号" type="index"  width="60px"/>
          <el-table-column label="标题" prop="caseTitle" show-overflow-tooltip/>
          <el-table-column label="创建人员" prop="createdName" show-overflow-tooltip/>
          <el-table-column label="门店" prop="storeName" show-overflow-tooltip/>
          <el-table-column label="商户" prop="merchantName" show-overflow-tooltip/>
          <el-table-column label="创建时间" prop="createdTime" width="200px" show-overflow-tooltip :align="'center'"/>
          <el-table-column label="操作" :align="'center'" width="100px">
            <template v-slot="{row}">
              <table-button type="write" @click="$routerUtil.push( `/alpine/shop/customerCase/detail?id=${row.id}`)">编辑</table-button>
              <table-button el-type="danger" type="delete" message="是否确认删除此数据" @click="toDelete(row)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize" :total-count="searchInfo.totalCount" @onLoad="queryList"/>
    </list-page>
  </div>

</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import TableButton from "@/components/h5/button/TableButton";
import PageButton from "@/components/h5/button/PageButton";
import caseApi from "@/api/alpine/shop/caseApi"
import PageHeader from "@/components/h5/layout/PageHeader.vue";
export default {
  name: "",
  components: {
    PageButton,
    TableButton,
    ListPage, SearchCondition, TableList, FormPage, PageHeader
  },
  data() {
    return {
      searchInfo: {
        searchKey: "",
      },
      dataList: [{}],
      row:[]
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList(){
      caseApi.queryCustomerCaseList(this.searchInfo).then(res =>{
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    // 新增
    toAddData(){
      this.$routerUtil.push('/alpine/shop/customerCase/detail' )
    },
    // 删除
    toDelete(data){
      caseApi.delete(data.id).then(() =>
          this.queryList());
    }
  }
};
</script>

<style lang="scss" scoped>

</style>