<template>
  <!-- 由于数据层级过多，vue无法捕获数据，无法正常显示，所以添加一个组件来操作 -->
  <div class="setting-domain">
    <div class="name">
      <span> {{setting.settingName}}</span>
    </div>
    <div class="value">
      <span>{{prefDesc}}</span>
      <!-- 文本类型 -->
      <el-input  v-if="setting.settingDataType === 1" :style="{width: inputLength}" v-model="settingValue" size="small"  @change="save"/>
      <!-- 下拉框 -->
      <el-select v-else-if="setting.settingDataType === 2"  :style="{width: inputLength}" v-model="settingValue" size="small" @change="save">
        <el-option v-for="(item, idx) in optionList" :key="idx" :label="item.name" :value="item.code"/>
      </el-select>
      <!-- 单选类型 -->
      <el-radio  v-else-if="setting.settingDataType === 3" :style="{width: inputLength}" v-model="settingValue" v-for="(item, idx) in optionList" :label="item.code" :key="idx" @change="save">{{item.name}}</el-radio>
      <el-input v-if="setting.settingDataType === 6"  v-model="settingValue" size="small"  @change="save" type="textarea" :rows="5"/>
      <span>{{subDesc}}</span>
      <div class="desc">{{settingRemark}}</div>
    </div>
  </div>
</template>

<script>
import systemSettingApi from "@/api/main/sys/systemSettingApi";
import {watch} from "vue";

export default {
  emits: ["update"],
  props: {
    setting: {default: () => {
        return {
          settingDataType: 0,
          settingValue: "0",
          settingRemark: [],
          settingName: "",
          settingDescription: "",
          settingParams: "",
        };
      }
    }
  },
  name: "SystemComponent",
  components: {
  },
  data() {
    return {
      settingValue: "",
      // 数据库里面的数据
      databaseConfig: [],
      // 描述前缀
      prefDesc: "",
      // 描述后缀
      subDesc: "",
      // 文本\数字类的描述
      settingRemark: "",
      optionList: [],
      inputLength: "75px",
    };
  },
  created() {
    this.init();
    watch(() => this.setting, () =>{
      this.init();
    }, {deep: true})
  },
  methods: {
    init() {
      this.settingValue = this.setting.settingValue;
      if (this.setting.settingDescription) {
        let arr = this.setting.settingDescription.split("${value}");
        this.prefDesc = arr.length > 0? arr[0]: "";
        this.subDesc = arr.length > 1? arr[1]: "";
      } else {
        this.prefDesc = "";
        this.subDesc = "";
      }
      // 是下拉框时, radio
      if (this.setting.settingDataType === 2 || this.setting.settingDataType === 3) {
        this.optionList = JSON.parse(this.setting.settingParams);
        // 下拉或有参数设置时，description不再生效
      }
      this.settingRemark = this.setting.settingRemark;
      if (this.setting.inputLength) {
        this.inputLength = this.setting.inputLength * 75 + "px";
      } else {
        this.inputLength = "75px";
      }
    },
    save(data) {
      systemSettingApi.saveSetting({
        id: this.setting.id,
        settingValue: data
      }).then(() => {
        this.$emit("update");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/theme/handle.scss";
.setting-domain{
  .name{
    display: inline-block;
    height: 40px;
    line-height: 40px;
    text-align: right;
    width: 180px;
    font-size: 14px;
    @include font_color("infoDeep");
    vertical-align: top;
  }
  .value{
    display: inline-block;
    line-height: 40px;
    padding-left: 30px;
    font-size: 14px;
    @include font_color("infoDeep");
    width: calc(100% - 300px);
    :deep( .el-input),:deep( .el-select){
      width: 80px;
    }
    .desc{
      font-size: 12px;
      color: #ccc;
      line-height: 16px;
    }
  }
}

</style>