<template>
  <!-- 通用模板，列表数据 -->


  <list-page>

      <search-condition @search="resetSearch" :show-search="true">
        <date-range v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期"
                    v-model:end-time="searchInfo.endTime"/>
        <el-input v-model="searchInfo.id" placeholder="请扫描/输入单号"/>
        <template #moreCondition>
          <!--        <div v-if="currentTab === 'apply'" style="display: inline-block; margin-right: 10px; width: 240px">-->
          <!--          <dictionary @clearData="clearDic" v-model="searchInfo.departCode" :place-holder="'请选择部门'"-->
          <!--                      :dictionaries="departList"-->
          <!--                      dict-code="departCode"-->
          <!--                      dict-name="departName"/>-->
          <!--        </div>-->
          <el-select v-if="currentTab === 'apply'" v-model="searchInfo.stockId" placeholder="请选择仓库" clearable>
            <el-option v-for="(st, i) in stockList" :key="i + 's'" :label="st.stockName" :value="st.id"/>
          </el-select>
          <el-select v-else-if="currentTab === 'handle'" v-model="searchInfo.stockId" placeholder="请选择仓库"
                     clearable>
            <el-option v-for="(st, i) in stockList" :key="i + 's'" :label="st.stockName" :value="st.id"/>
          </el-select>
          <el-select placeholder="请选择单据状态" v-model="searchInfo.restockStatus" clearable>
            <el-option label="草稿" value="0"/>
            <el-option label="已申请" value="1"/>
            <el-option label="待确认收获" value="2"/>
            <el-option label="已完成" value="3"/>
          </el-select>
        </template>
        <template #option>
          <page-button v-if="currentTab === 'apply'" el-type="success" type="write"
                       @click="addNew">新建
          </page-button>
        </template>
      </search-condition>
    <table-list>
      <el-table :data="dataList" height="100%" border>
        <el-table-column align="center" label="序号" type="index" width="60px" fixed/>
        <el-table-column align="center" label="单据编号" prop="id" min-width="180px" fixed>
          <template v-slot="{row}">
            <table-button class="add" el-type="primary" type="write"
                          @click="viewDetail(row)">{{ row.id }}
            </table-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="单据日期" prop="createdTime" min-width="120px"/>
        <el-table-column align="center" label="创建人" prop="createdName" min-width="100px"/>
        <el-table-column align="center" label="数量" prop="skuQty" min-width="100px" />
        <el-table-column align="center" label="金额" prop="totalAmount" min-width="100px"/>
        <el-table-column align="center" label="订单状态" min-width="100px">
          <template v-slot="{row}">
            <!-- 调拨单列表 -->
            <el-tag type="primary" v-if="row.restockStatus === 0 && currentTab === 'apply'">草稿</el-tag>
            <el-tag type="primary" v-if="row.restockStatus === 1 && currentTab === 'apply'">已申请</el-tag>
            <el-tag type="primary" v-if="row.restockStatus === 2 && currentTab === 'apply'">待确认</el-tag>
            <el-tag type="success" v-if="row.restockStatus === 3 && currentTab === 'apply'">已完成</el-tag>
            <!-- 申请处理列表 -->
            <el-tag type="primary" v-if="row.restockStatus === 0 && currentTab === 'handle'">草稿</el-tag>
            <el-tag type="primary" v-if="row.restockStatus === 1 && currentTab === 'handle'">待处理</el-tag>
            <el-tag type="primary" v-if="row.restockStatus === 2 && currentTab === 'handle'">待收货</el-tag>
            <el-tag type="success" v-if="row.restockStatus === 3 && currentTab === 'handle'">已完成</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="入库部门" prop="departName" v-if="currentTab === 'apply'" min-width="150px" />
        <el-table-column align="center" label="入库仓库" prop="stockName" v-if="currentTab === 'apply'" min-width="150px" />
        <el-table-column align="center" label="目标部门" prop="targetDepartName" v-if="currentTab === 'handle'" min-width="150px" />
        <el-table-column align="center" label="目标仓库" prop="targetStockName" v-if="currentTab === 'handle'" min-width="150px" />
        <el-table-column label="操作" :align="'center'" fixed="right">
          <template v-slot="{row}">
            <table-button @click="handleInfo(row)" v-if="currentTab === 'apply'" :disabled="row.restockStatus !== 2">
              确认收货
            </table-button>
            <table-button @click="handleInfo(row)" v-if="currentTab === 'handle'" :disabled="row.restockStatus !== 1">
              同意
            </table-button>
            <table-button v-if="currentTab === 'apply'" :disabled="row.status !== 0" message="是否确认删除此数据"
                          @click="deleteDraft(row)" el-type="danger" type="delete">删除
            </table-button>
          </template>
        </el-table-column>
      </el-table>
    </table-list>
    <form-page v-if="currentTab === 'apply'" v-model:page-size="searchInfo.pageSize"
               v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryApplyList"
               :default-load="false"></form-page>
    <form-page v-if="currentTab === 'handle'" v-model:page-size="searchInfo.pageSize"
               v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount"
               @onLoad="queryHandleList" :default-load="false"></form-page>
  </list-page>


  <el-dialog v-model="showOther" width="30%" height="310px" :title="currentTab === 'apply' ?'确认收货' : '申请处理' ">
    <div>
      <div style="height: 238px;overflow: auto">
        <el-form label-width="70px" style="">
          <div style="height: 198px">
            <el-form-item label="创建人">
              <el-input disabled v-model="currentRowData.createdName"/>
            </el-form-item>
            <el-form-item label="金额合计">
              <el-input disabled v-model="currentRowData.totalAmount"/>
            </el-form-item>
            <el-form-item label="数量合计">
              <el-input disabled v-model="currentRowData.skuQty"/>
            </el-form-item>
            <el-form-item label="备注信息">
              <el-input disabled v-model="currentRowData.remark"/>
            </el-form-item>
          </div>
        </el-form>
        <div style="display: flex;justify-content: flex-end">
          <!--          <el-checkbox v-model="isAutoPrint" label="确认并打印" size="large"/>-->
          <div>
            <page-button el-type="info" @click="showOther = false">取消</page-button>
            <page-button el-type="primary" @click="saveSubmit">确认</page-button>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>

</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import PageButton from "@/components/h5/button/PageButton";
import FormPage from "@/components/h5/layout/FormPage.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import commonApi from "@/api/commonApi";
import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import util from "@/util/util";
import restockApi from "@/api/main/stock/restockApi";
import TableButton from "@/components/h5/button/TableButton.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import merchantOrderApi from "@/api/main/supplyChain/merchantOrderApi";

export default {
  name: "InventoryRestock",
  props: ['type'],
  components: {
    TableList,
    TableButton,
    DateRange,
    FormPage,
    PageButton,
    ListPage, SearchCondition
  },
  data() {
    return {
      showOther: false,
      currentRowData: {},
      searchInfo: {},
      dataList: [],
      currentTab: "apply",
      departList: [], // 部门列表
      stockList: [], // 仓库列表
      stockAllList: [],
      originalSwitch: '',
    };
  },
  created() {
    commonApi.findSetting("STOCK_ORIGINAL").then(setting => this.originalSwitch = setting.settingValue === "1");
    // 获取部门信息
    commonApi.queryDepartList().then(list => this.departList = list);
    // 查询全部仓库
    commonApi.queryAllStock().then(list => {
      // 默认只留下本部门的仓库
      util.getUserInfo().then(res => {
        list.forEach(item => {
          if (item.bankupId === res.mainDepartId) {
            this.stockList.push(item)
          }
        })
        this.stockAllList = list;
      })
    });
    // 初始化时间信息
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    if (this.currentTab === 'apply') {
      this.queryApplyList();
    }
  },
  watch: {
    'searchInfo.startTime': {
      handler() {
        this.resetSearch();
      }
    }
  },
  methods: {
    addNew(){
      if(this.originalSwitch){
        this.$routerUtil.push({path: `/stock/restock/original/detail`});
      }else{
        this.$routerUtil.push({path: `/stock/restock/detail`});
      }
    },
    clearDic() {
      this.searchInfo.departCode = "";
    },
    viewDetail(row){
      merchantOrderApi.findById(row.originalOrderId).then(res =>{
        if(res && res.orderType === 11){
          this.$routerUtil.push(`/stock/restock/original/detail?id=${row.id}`)
        }else{
          this.$routerUtil.push(`/stock/restock/detail?id=${row.id}`)
        }
      })
    },

    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.currentTab === "apply" ? this.queryApplyList() : this.queryHandleList();
    },

    findById(id) {
      return restockApi.findById(id).then(res => {
        let qty = 0;
        let amount = 0;
        for (let i = 0; i < res.detailList.length; i++) {
          qty += res.detailList[i].skuQty;
          amount += res.detailList[i].skuQty * res.detailList[i].buyPrice;
        }
        res.skuQty = qty;
        res.totalAmount = amount;
        this.currentRowData = res;
      })
    },

    handleInfo(row) {
      this.findById(row.id).then(() => {
        this.showOther = true;
      })
    },

    saveSubmit() {
      if (this.currentTab === 'apply') {
        this.currentRowData.restockStatus = 3;
        restockApi.saveSubmit(this.currentRowData);
        this.queryApplyList();
      } else if (this.currentTab === 'handle') {
        this.currentRowData.restockStatus = 2;
        restockApi.saveSubmit(this.currentRowData)
        this.queryHandleList();
      }
      this.showOther = false
    },

    queryApplyList() {
      this.currentTab = "apply";
      restockApi.queryApplyList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      });
    },

    queryHandleList() {
      this.currentTab = "handle";
      // 查询本部门待处理的调拨单时，部门默认为当前部门
      util.getUserInfo().then(res => {
        this.searchInfo.departCode = res.departCode
        restockApi.queryHandleList(this.searchInfo).then(res => {
          this.dataList = res.records;
          this.searchInfo.totalCount = res.totalCount;
        });
      });
    },

    deleteDraft(row) {
      restockApi.delete(row.id)
    }
  }
};
</script>

<style lang="scss" scoped>
.demo-tabs > .el-tabs__content {
  display: none;
  //padding: 32px;
  //color: #6b778c;
  //font-size: 32px;
  //font-weight: 600;
}

.add:hover {
  text-decoration: underline;
}
</style>