<template>
  <!-- -->
  <div class="defaultViewStyle">
    <separate-page>
      <template #category>
        <div v-for="ser in serviceList" :class="currentService.id === ser.id?'service-list on': 'service-list'" @click="toShowSetting(ser)">{{ser.serviceName}}</div>
      </template>
      <template #default>
        <el-empty description="当前服务没有配置项" v-if="currentService.settingList.length === 0"/>
        <div v-for="setting in currentService.settingList" v-else>
          <system-setting-components :setting="setting" @update="getList"/>
        </div>
      </template>
    </separate-page>
  </div>
</template>

<script>
import systemSettingApi from "@/api/main/sys/systemSettingApi";
import SystemSettingComponents from "@/views/main/plat/system/setting/SystemSettingComponents";
import SeparatePage from "@/components/h5/layout/SeparatePage";
export default {
  name: "",
  components: {
    SeparatePage, SystemSettingComponents
  },
  data() {
    return {
      serviceList: [],
      currentService: {
        settingList: [],
      },
    };
  },
  created() {
    // 商户开通了哪些服务
    systemSettingApi.queryAllService().then(list => {
      this.queryAllSettings(list);
    });
  },
  methods: {
    // 查询全部配置项
    queryAllSettings(list) {
      systemSettingApi.getSetting().then(settingList => {
        for (let i = 0; i < list.length; i++) {
          // 找到对应的配置
          let arr = [];
          for (let j = 0; j < settingList.length; j++) {
            if (settingList[j].serviceId === list[i].id) {
              arr.push(settingList[j]);
            }
          }
          list[i].settingList = arr;
        }
        this.serviceList = list;
      });
    },
    // 显示配置项
    toShowSetting(data) {
      this.currentService = {...data};
    },
    getList() {
      this.queryAllSettings(this.serviceList);
    }
  }
};
</script>

<style lang="scss" scoped>
.service-list{
  font-size: 14px;
  line-height: 36px;
  cursor: pointer;
  position: relative;
}
.service-list.on{
  color: #409EFF;
  border-bottom: 1px solid #eaeaea;
}
.service-list.on::after{
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #FFF;
  right: -6px;
  top: 13px;
  border-top: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  transform: rotateZ(45deg);
}
</style>