<template>
  <!-- 通用模板，列表数据 -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
        <page-button el-type="success" type="write" @click="toShowDetail({})">新建</page-button>
      </page-header>
      <search-condition >
        <el-input v-model="searchInfo.searchKey" placeholder="请输入查询条件"/>
      </search-condition>
      <table-list>
        <el-table :data="dataList" height="100%" border>
          <el-table-column label="序号" width="60px" type="index" :align="'center'"/>
          <el-table-column label="版本号" prop="versionId" />
          <el-table-column label="文档名称" prop="versionName"/>
          <el-table-column label="文档类型">
            <template v-slot="{row}">
              <span v-if="row.type === '1'">版本信息</span>
              <span v-if="row.type === '2'">操作手册</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'">
            <template v-slot="{row}">
              <table-button @click="toShowDetail(row)">编辑</table-button>
              <table-button message="是否确认删除此数据" type="delete" el-type="danger">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>
  </div>
  <el-dialog v-model="showDetail" title="版本文档管理" width="30%">
    <dialog-table :auto-height="true">
      <el-form label-width="70px">
        <el-form-item label="版本号">
          <el-input v-model="currentData.versionId"/>
        </el-form-item>
        <el-form-item label="版本名称">
          <el-input v-model="currentData.versionName"/>
        </el-form-item>
        <el-form-item label="文档地址">
          <el-input v-model="currentData.docUrl"/>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="currentData.type">
            <el-option label="版本信息" value="1"/>
            <el-option label="操作手册" value="2"/>
          </el-select>
        </el-form-item>
      </el-form>
    </dialog-table>
    <form-page :show-page="false">
      <page-button @click="showDetail = false">取消</page-button>
      <page-button type="write" @click="save" el-type="primary">保存</page-button>
    </form-page>
  </el-dialog>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import versionApi from "@/api/main/sys/versionApi";
export default {
  name: "",
  components: {
    TableButton, PageButton, ListPage, TableList, FormPage, SearchCondition, PageHeader, DialogTable
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      showDetail:false,
      currentData: {
        versionId: "",
        docUrl: "",
        versionName: "",
      },
    };
  },
  created() {
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      versionApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    toShowDetail(data) {
      this.currentData = {...data};
      this.showDetail = true;
    },
    save() {
      versionApi.save(this.currentData).then(res => {
        this.showDetail = false;
        this.queryList();
      })
    }
  }
};
</script>

<style lang="scss" scoped>

</style>