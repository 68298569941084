import axios from "../../../util/axios";

export default {
    /** 保存客户信息 */
    saveCustomer: (data) => axios.post({url: "/basedata/customer/memberSea/save", data: data}),
    /** 查询列表 */
    queryList: (data) => axios.get({url: "/basedata/customer/memberSea/queryList", data: data}),
    /** 删除客户 */
    delete: (id) => axios.del({url: "/basedata/customer/memberSea/delete", data: {id}}),
    /** 删除客户 */
    findById: (id) => axios.get({url: "/alpine/customer/member/findById", data: {id}}),
    /** 保存车辆档案 */
    saveCarInfo: (data) => axios.post({url: "/alpine/customer/member/saveCarInfo", data: data}),
    /** 删除车辆档案 */
    deleteCar: (id) => axios.del({url: "/alpine/customer/member/deleteCar", data: {id}}),

}