<template>
  <!-- -->
  <div class="">
    <el-form label-width="100px" size="small">
      <el-form-item label="规格类型">
        <el-input v-model="detail.columnName"/>
      </el-form-item>
      <el-form-item label="规格名称">
        <div class="standard-item" v-for="(c, i) in detail.optionList" :key="i">
          <span class="item-left">
            <el-input v-model="c.name"/>
          </span>
          <span class="item-right">
            <i @click="deleteOption(i)" class="el-icon-delete"/>
          </span>
        </div>
        <div>
          <div class="standard-item">
            <el-button size="small" style="width: 100%" icon="el-icon-plus" @click="addStandard"/>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <detail-page-btn>
      <el-button @click="$emit('close')">取消</el-button>
      <el-button type="primary" @click="saveData">保存</el-button>
    </detail-page-btn>
  </div>
</template>

<script>
import productApi from "@/api/main/product/productApi";
import DetailPageBtn from "@/components/h5/layout/DetailPageBtn";
import attributeApi from "@/api/main/product/attributeApi";
export default {
  emits: ["close", "submit"],
  props: {
    data: {default: () => {
      return {columnName: ""}
    }}
  },
  watch: {
    data: {
      handler() {
        this.init();
      }
    }
  },
  name: "",
  components: {
    DetailPageBtn
  },
  data() {
    return {
      detail: {

      }
    };
  },
  created() {
    this.init();
  },
  methods: {
    init(){
      this.detail = {
        ...this.data,
        optionList: this.data.options? JSON.parse(this.data.options): [],
      }
    },
    addStandard() {
      // code取最大，+1
      let i = 0;
      for (let j = 0; j < this.detail.optionList.length; j++) {
        let strs = this.detail.optionList[j].code.split("_");
        let num = parseInt(strs[strs.length - 1]);
        if (i < num) {
          i = num;
        }
      }
      i += 1;
      this.detail.optionList.push({code: "code_" + i, name: ""})
    },
    deleteOption(idx) {
      this.detail.optionList = this.detail.optionList.remove(idx);
    },
    saveData() {
      this.detail.options = JSON.stringify(this.detail.optionList);
      attributeApi.saveAttribute(this.detail).then(() => {
        this.$emit("submit");
        productApi.queryAttributeList().then(list=>{
          this.$emit("attributeList",list);
        })
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.standard-item{
  margin-bottom: 10px;
  .item-left{
    display: inline-block;
    width: calc(100% - 30px);
  }
  .item-right{
    display: inline-block;
    width: 28px;
    text-align: right;
  }
}
</style>