<template>
  <!-- 通用模板，列表数据 -->
  <div class="defaultViewStyle">
    <el-tabs v-model="active">
      <el-tab-pane label="已授权" name="active">
        <product-granted/>
      </el-tab-pane>
      <el-tab-pane label="未授权" name="notActive" lazy>
        <product-not-granted/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ProductGranted from "@/views/main/product/grant/ProductGranted";
import ProductNotGranted from "@/views/main/product/grant/ProductNotGranted";
export default {
  name: "",
  components: {
    ProductGranted, ProductNotGranted
  },
  data() {
    return {
      active: "active"
    };
  },
  created() {
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/tabsPane.scss";
</style>