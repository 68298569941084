<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <el-tabs v-model="activeName">
        <el-tab-pane label="商户服务" name="service">
          <el-descriptions :column="2" border>
            <el-descriptions-item label="商户名称">{{merchantInfo.merchantName}}</el-descriptions-item>
            <el-descriptions-item label="有效期"><date-range :disabled="true" v-model:start-time="merchantInfo.validStart" v-model:end-time="merchantInfo.validEnd"/> </el-descriptions-item>
            <el-descriptions-item label="负责人">{{merchantInfo.legalPerson}}</el-descriptions-item>
            <el-descriptions-item label="企业地址">{{merchantInfo.address}}</el-descriptions-item>
            <el-descriptions-item label="营业执照">{{merchantInfo.businessLicense}}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{merchantInfo.merchantPhone}}</el-descriptions-item>
            <el-descriptions-item label="商户简介">{{merchantInfo.merchantDesc}}</el-descriptions-item>
          </el-descriptions>
          <div style="display: flex;justify-content: space-between;">
            <el-text size="large">已开通服务</el-text>
            <page-button el-type="primary" @click="grantService">授权</page-button>
          </div>
          <div>
            <el-checkbox-group v-model="selectedService">
              <el-checkbox v-for="ser in serviceList" :label="ser.serviceName" :value="ser.id" />
            </el-checkbox-group>
          </div>
        </el-tab-pane>
        <el-tab-pane label="角色授权" name="role" lazy>
          <plat-merchant-roles :merchant-id="merchantInfo.id"/>
        </el-tab-pane>
        <el-tab-pane label="商户职员" name="employee" lazy>
          <plat-merchant-employees :id="merchantInfo.id"/>
        </el-tab-pane>
      </el-tabs>
    </list-page>
  </div>
</template>

<script>
import merchantApi from "@/api/saas-plat/merchant/merchantApi";
import DateRange from "@/components/h5/date/DateRange";
import ListPage from "@/components/h5/layout/ListPage";
import TableButton from "@/components/h5/button/TableButton";
import PlatMerchantEmployees from "@/views/main/plat/merchantManage/merchant/PlatMerchantEmployees";
import platMerchantRoles from "@/views/main/plat/system/role/PlatRoleComponent.vue";
import PageButton from "@/components/h5/button/PageButton";
export default {
  name: "",
  components: {
    PageButton,
    TableButton,
    DateRange, ListPage, PlatMerchantEmployees, platMerchantRoles
  },
  data() {
    return {
      merchantInfo: {},
      activeName: "service",
      serviceList: [],
      selectedService: [],
    };
  },
  created() {
    merchantApi.findById(this.$routerUtil.query.id).then(data => {
      this.merchantInfo = data;
    });
    // 查询全部的服务
    merchantApi.queryAllService().then(list => this.serviceList = list);
    this.queryGrantResult();
  },
  methods: {
    // 查询已被授权的服务
    queryGrantResult() {
      // 查询商户已被授权的服务
      merchantApi.queryMerchantService(this.$routerUtil.query.id).then(list => {
        let arr = [];
        list.forEach(m => arr.push(m.serviceId));
        this.selectedService = arr;
      });
    },
    // 对商户进行授权
    grantService() {
      merchantApi.saveMerchantService(this.$routerUtil.query.id, this.selectedService).then(() => this.queryGrantResult());
    }
  }
};
</script>

<style lang="scss" scoped>

</style>