import axios from "../../../util/axios";


export default {
    //查询由自身发出的调拨申请列表
    queryApplyList: (data) => axios.get({url: "/order/stock/restock/queryApplyList", data: data}),
    //查询需要由自身处理的调拨申请列表
    queryHandleList: (data) => axios.get({url: "/order/stock/restock/queryHandleList", data: data}),
    //保存草稿
    saveDraft: (data) => axios.post({url: "/order/stock/restock/saveDraft", data: data}),
    //保存单据：提交调拨申请
    saveSubmit: (data) => axios.post({url: "/order/stock/restock/saveSubmit", data: data}),
    //保存单据：质保小程序提交的采购单，需要上游商户发货，走调拨单，保存时直接开始调拨，不需要额外的确认步骤
    saveSubmitWarranty: (data) => axios.post({url: "/order/stock/restock/saveSubmitWarranty", data: data}),
    //修改调拨单的状态
    handleRestock: (id,restock) => axios.get({url: "/order/stock/restock/handleRestock", data: {id,restock}}),
    //删除
    delete: (id) => axios.del({url: "/order/stock/restock/delete", data: {id}}),
    //回显
    findById: (id)=>axios.get({url: "/order/stock/restock/findById",data:{id}})
}