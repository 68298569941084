<template>
<!--  -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary"  @click="resetSearch">查询</page-button>
        <page-button type="write" el-type="success" @click="$routerUtil.push(`/alpine/shop/productOffer/add`)">添加</page-button>
      </page-header>
      <search-condition>
        <el-input v-model="searchInfo.searchKey" placeholder="请输入标题名称" />
      </search-condition>
<!--      商品报价列表-->
      <table-list>
        <el-table height="100%" border :data="dataList">
          <el-table-column label="序号" type="index" width="60px"/>
          <el-table-column label="标题" prop="title" show-overflow-tooltip/>
          <el-table-column label="门店" prop="storeName" show-overflow-tooltip/>
          <el-table-column label="总价" prop="price" show-overflow-tooltip>
            <template v-slot="{row}">
              <money :value="row.price"/>
            </template>
          </el-table-column>
          <el-table-column label="汽车品牌"  show-overflow-tooltip>
            <template v-slot="{ row }">
              <dictionary :dictionaries="carBandList" v-model="row.carBrand" :disabled="true" />
            </template>
          </el-table-column>
<!--          <el-table-column label="创建人员" prop="createdName" show-overflow-tooltip/>-->
          <el-table-column label="操作" :align="'center'" width="100px">
            <template  v-slot="{row}">
              <table-button type="write" @click="$routerUtil.push(`/alpine/shop/productOffer/add?id=${row.id}`)">编辑</table-button>
              <table-button el-type="danger" type="delete" message="是否确认删除此数据" @click="toDelete(row)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize"
                 :total-count="searchInfo.totalCount"  @onLoad="queryList" />
    </list-page>
  </div>
</template>
<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import offerApi from "@/api/alpine/offer/offerApi";
import Money from "@/components/h5/Money.vue";
import commonApi from "@/api/commonApi";
import Dictionary from "@/components/h5/Dictionary.vue";
export default {
  setup(){

  },
  name: "productOfferList",
  components:{
    ListPage,PageHeader,PageButton,SearchCondition,DateRange,TableList,Money
    ,FormPage,TableButton,Dictionary
  },
  data(){
    return{
      searchInfo:{},
      dataList:[],
      storeList:[
        {storeName:""}
      ],
      createdIds:[],
      carBandList:[]

    }
  },
  created() {
    //获取车辆品牌字典
    commonApi.queryDictionaryTree("CAR_TYPE").then(list => {
      console.log(list);
      this.carBandList = list;
    });

    //获取商店信息
    commonApi.queryStoreList().then(res=>{
      this.storeList = res
      //console.log(this.storeList)
    })

  },
  methods:{
    // 查询报价
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.dataList = [];
      this.queryList();
    },
    // 查询商品报价列表
    queryList(){
      offerApi.queryOfferList(this.searchInfo).then(res => {
        //console.log(res);
        this.searchInfo.totalCount = res.totalCount;
        this.dataList= [...this.dataList, ...res.records];
        this.dataList.forEach(item =>{
          const carBrand = this.storeList.find(item1 => item1.id === item.storeId);
          if(carBrand){
            item.storeName = carBrand.storeName;
          }
        })
        //console.log(this.createdIds);
        console.log(this.dataList);
      })
    },
    toDelete(data){
      offerApi.deleteOffer(data.id).then(() =>
          this.queryList());
    },

  }
}
</script>

<style scoped>

</style>