<template>
  <div class="defaultViewStyle">
    <list-page>
      <page-header/>
      <table-list>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="案例标题" class="formlist-item">
            <el-input v-model="caseForm.caseTitle" ></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="汽车品牌" class="formlist-item">
            <el-input v-model="caseForm.caseCar"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="汽车型号" class="formlist-item">
            <el-input v-model="caseForm.caseCarModel"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="产品型号" class="formlist-item">
            <el-input v-model="caseForm.caseCustomerModel"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="施工单位" class="formlist-item">
            <el-select v-model="caseForm.storeId">
              <el-option v-for="(s, i) in storeList" :key="i" :label="s.storeName" :value="s.id"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="案例简介" class="formlist-item">
            <el-input v-model="caseForm.caseSummary"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="上传图片" :span="2">
            <image-upload v-model="caseForm.titleImage" folder="customerCase" limit="1"></image-upload>
          </el-descriptions-item>
          <el-descriptions-item label="上传视频" :span="2">
            <video-upload v-model="caseForm.titleVideo" folder="customerCase"></video-upload>
          </el-descriptions-item>
          <el-descriptions-item label="详情描述" :span="2">
            <rich-text v-model="caseForm.caseContent" folder="customerCase"></rich-text>
          </el-descriptions-item>
        </el-descriptions>
      </table-list>
      <form-page :show-page="false">
        <div>
          <page-button el-type="primary" type="write" @click="caseSave">保存</page-button>
        </div>
      </form-page>
    </list-page>
  </div>
</template>

<script>
import ImageUpload from "@/components/h5/image/ImageUpload.vue";
import RichText from "@/components/h5/richText/RichText.vue";
import VideoUpload from '@/components/h5/image/VideoUpload.vue';
import caseApi from "@/api/alpine/shop/caseApi"
import TableList from "@/components/h5/layout/TableList";
import ListPage from "@/components/h5/layout/ListPage.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
export default {
  name: "CustomerCaseAdd",
  components: { ImageUpload,RichText,VideoUpload,TableList, ListPage, FormPage, PageHeader},
  data(){
    return{
      storeList: [], // 门店信息
      caseForm: {
        caseTitle:'',
        caseCar:'',
        caseCarModel:'',
        caseCustomerModel:'',
        caseSummary:'',
        titleImage:'',
        caseContent:'',
        titleVideo:'',
        storeName:'',
      }
    }
  },

  mounted() {
    // 检查路由中是否有id参数
    if (this.$routerUtil.query.id) {
      // 查询数据
      this.init(this.$routerUtil.query.id);
    }
    // 查询案例
    caseApi.queryStoreList().then(list => this.storeList = list);
    console.log()
  },

  methods:{
    init(id) {
      caseApi.findById(id).then(data => this.caseForm = data);
    },

    // 点击保存按钮
    caseSave(){
      if(this.caseForm.caseTitle == null||this.caseForm.caseTitle.length ==0){
        this.$message({
          showClose: true,
          message: '请输入案例标题',
          type: 'warning'
        });
        return false;
      }if(this.caseForm.caseCarModel == null||this.caseForm.caseCarModel.length ==0){
        this.$message({
          showClose: true,
          message: '请输入汽车品牌',
          type: 'warning'
        });
        return false;
      }if(this.caseForm.caseCustomerModel == null||this.caseForm.caseCustomerModel.length ==0){
        this.$message({
          showClose: true,
          message: '请输入汽车型号',
          type: 'warning'
        });
        return false;
      }if(!this.caseForm.storeId){
        this.$message({
          showClose: true,
          message: '请输入施工单位',
          type: 'warning'
        });
        return false;
      }if(this.caseForm.caseSummary == null||this.caseForm.caseSummary ==0){
        this.$message({
          showClose: true,
          message: '请输入案例简介',
          type: 'warning'
        });
        return false;
      }
      caseApi.save(this.caseForm).then(()=>{
      this.$routerUtil.push({ path: '/alpine/shop/customerCase', })
      })
    },
  }
}
</script>

<style scoped>

.formlist-item{
  flex: 1 0 50%; /* 这意味着每个项目将占据容器宽度的50%，因此它们将在一行中排列两个 */
  box-sizing: border-box;
}
</style>