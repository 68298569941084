import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import receiptApi from "@/api/main/order/receiptApi";
import {ElLoading, ElMessageBox} from "element-plus";
import memberApi from "@/api/main/customer/memberApi";
import localPrint from "@/util/new/LocalPrint";

export default {
    data() {
        return {
            showOrder: false,
            isAutoPrint: true,
            isPaying: false,
            currentOrder: {},
            disableToClickPay: false,
            preparePay: false,
            isRefreshPay: false,
            fullscreenPayLoading: false,
            timerToPayResult: null,
            myTimer: null,
        }
    },
    unmounted() {
        if (this.myTimer !== null) {
            clearInterval(this.myTimer);
        }
    },
    methods: {
        dialogAutoFocus() {
            this.$nextTick(() => {
                this.$refs.autoFocus.initInput();
            })
        },
        findById(id) {
            return offlineOrderApi.findById(id).then(data => {
                this.currentOrder = data;
            })
        },
        toFindPayStatus() {
            if (this.isRefreshPay) this.$message.warning("正在努力获取结果，请耐心等待！！！")
            this.isRefreshPay = true;
            return offlineOrderApi.findPayStatus(this.currentOrder.id, this.currentOrder.payId).then(res => {
                this.findById(this.currentOrder.id).then(() => {
                    if (this.currentOrder.payStatus >= 50) {
                        this.successResult();
                        this.currentAmountCollect = this.currentOrder.amountCurrentToPrint;
                        this.$message.success(res);
                        clearInterval(this.timerToPayResult);
                        clearTimeout(this.myTimer);
                        this.loading.close();
                        if (this.isAutoPrint) {
                            this.toPrint();
                        }
                        this.updateRoomData();
                        return;
                    }
                    if (this.currentOrder.payStatus === 30) {
                        this.successResult();
                        this.$message.error(res);
                        clearInterval(this.timerToPayResult);
                        clearTimeout(this.myTimer);
                        this.loading.close();
                    }
                })
                if (res === "订单状态更新失败") {
                    this.$message.error(res);
                    clearTimeout(this.myTimer);
                    clearInterval(this.timerToPayResult);
                    this.loading.close();
                    return;
                } else if (res !== "支付成功,订单状态已刷新" && res !== "支付失败,请重试") {
                    // this.$message.warning("正在努力获取结果，请耐心等待！！！");
                }
                this.isRefreshPay = false;
                clearTimeout(this.timerToPayResult);
                this.timerToPayResult = setTimeout(() => {
                    this.toFindPayStatus();
                }, 1000);
            }).catch(() => {
                this.isRefreshPay = false;
                this.loading.close();
            })
        },
        changePay(data) {
            if(this.currentOrder.id){
                receiptApi.updateRefundType(this.currentOrder.id, data.id, data.payCode);
            }
        },
        batchPay(row) {
            this.findById(row.id).then(() => {
                if (this.currentOrder.amountCurrent !== 0) {
                    ElMessageBox.confirm('您上次的提交的支付信息还未结算，确认继续吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.showOrder = true;
                    })
                } else {
                    this.showOrder = true;
                }
                this.isActual = false;
                if(this.detail.subscribe.amountShould){
                    this.currentOrder.amountCollect = this.detail.subscribe.amountShould;
                }else{
                    this.currentOrder.amountCollect = this.currentOrder.amountShould;
                }
            })
        },
        payReceipt(data) {
            let myCount = 60;
            this.myTimer = setInterval(() => {
                if (myCount > 0) {
                    myCount--;
                    this.loading.text = `获取支付结果中，${myCount}秒后自动更新...`;
                } else {
                    clearInterval(this.myTimer);
                    this.loading.close();
                }
            }, 1000)
            this.loading = ElLoading.service({
                lock: true,
                text: `获取支付结果中，${myCount}秒后自动更新...`,
                background: 'rgba(0, 0, 0, 0.7)',
            })
            // 赋值
            this.isActual = true;
            this.currentAmountCollect = data.amountCollect;
            this.currentOrder.amountCollect = data.amountCollect;
            this.currentOrder.qrCode = data.qrCode;
            // 收费标签
            this.currentOrder.paymentType = 2;
            // 锁定操作状态
            if (this.preparePay) {
                console.log("正在支付中，不能进行操作")
                return;
            }
            this.preparePay = true;
            this.disableToClickPay = true;
            this.isPaying = true;
            offlineOrderApi.preparePay(this.currentOrder).then(saleOrder => {
                // 刷新数据
                this.findById(saleOrder.id).then(() => {
                    if (this.isAutoPrint && this.currentOrder.payStatus >= 50) {
                        this.toPrint();
                        this.loading.close();
                    }
                    if (this.currentOrder.payStatus === 99 || this.currentOrder.payStatus === 50) {
                        this.successResult();
                        this.$message.success("支付成功");
                        this.updateRoomData();
                        clearTimeout(this.myTimer);
                        this.loading.close();
                    } else {
                        // this.$message.warning("支付中，请稍后刷新支付状态！！！")
                        this.toFindPayStatus();
                    }
                })
            }).catch(() => {
                this.preparePay = false;
                this.disableToClickPay = false;
                this.loading.close();
                this.isPaying = false;
            })
        },
        batchSubmit(data) {
            this.preparePay = true;
            this.disableToClickPay = true;
            this.currentAmountCollect = data.amountCollect;
            offlineOrderApi.updateAmount(this.currentOrder.id, data.amountCollect).then(() => {
                this.preparePay = false;
                this.disableToClickPay = false;
                this.$message.success("提交成功");
                if (this.isAutoPrint) {
                    this.findById(this.currentOrder.id).then(() => {
                        this.toBatchPrint();
                    })
                }
                this.showOrder = false;
            }).catch(() => {
                this.preparePay = false;
                this.disableToClickPay = false;
            });
        },
        // 提交的打印
        async toBatchPrint() {
            // 组装打印数据
            const customerInfo = await memberApi.findById(this.currentOrder.customerId);
            let data = this.getPrintData("print", customerInfo.customerName, customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/, '$1****$2'), "销售凭证");
            localPrint.print("销售凭证", data);
        },
        async toPrint() {
            // 组装打印数据
            const customerInfo = await memberApi.findById(this.currentOrder.customerId);
            let data = this.getPrintData("print", customerInfo.customerName, customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/, '$1****$2'), "收费凭证");
            localPrint.print("收费凭证", data);
        },
        successResult() {
            this.showOrder = false;
            this.disableToClickPay = false;
            this.isPaying = false;
            this.preparePay = false;
        },
        // 获取打印的参数
        getPrintData(type, customerName, customerPhone, name) {
            let data = {
                reportName: name,
                amount: this.currentOrder.amount,
                amountActual: this.isActual ? this.currentAmountCollect : 0,
                amountCollect: this.currentAmountCollect, // 需要打印本次支付的金额
                amountShould: this.currentOrder.amountShould,
                customerName: customerName,
                customerPhone: customerPhone,
                orderNumber: this.currentOrder.id,
                amountAlreadyReceived: this.currentOrder.amountCollect,
                orderTime: this.currentOrder.orderTime,
                employeeName: this.currentOrder.employeeName,
                createdName: this.currentOrder.createdName,
                countQty: this.currentOrder.qty,
                excuteType: type,
            }

            //科室名称
            for (let i = 0; i < this.departList.length; i++) {
                if (this.currentOrder.departCode === this.departList[i].departCode) {
                    data.departName = this.departList[i].departName;
                }
            }
            //仓库名称
            for (let i = 0; i < this.stockList.length; i++) {
                if (this.currentOrder.stockId === this.stockList[i].id) {
                    data.stockName = this.stockList[i].stockName;
                }
            }
            //职员名称
            for (let i = 0; i < this.employeeList.length; i++) {
                if (this.currentOrder.employeeId === this.employeeList[i].id) {
                    data.employeeName = this.employeeList[i].employeeName
                }
                if (this.currentOrder.payeeId === this.employeeList[i].id) {
                    data.payeeName = this.employeeList[i].employeeName
                }
            }
            // 支付状态
            if (this.currentOrder.payStatus === 0) {
                data.payStatus = "未支付";
            } else if (this.currentOrder.payStatus === 99) {
                data.payStatus = "已支付";
            } else if (this.currentOrder.payStatus === 30) {
                data.payStatus = "支付失败";
            }
            // 支付方式
            for (let i = 0; i < this.payType.length; i++) {
                if (this.payType[i].id === this.currentOrder.payTypeId) {
                    data.payType = this.payType[i].payName;
                    break;
                }
            }
            let arr = [];
            for (let i = 0; i < this.currentOrder.productList.length; i++) {
                if (this.currentOrder.productList[i].qty === 0) {
                    continue;
                }
                let p = this.currentOrder.productList[i];
                arr.push({
                    productIndex: i + 1,
                    productName: p.skuName,
                    productNumber: p.barCode,
                    productPrice: p.skuOriginalPrice, //商品原价
                    productCount: p.qty,
                    productTotalPrice: p.qty * p.skuSalePrice,
                    productDisCount: p.rate,
                    productUnitName: p.skuUnitName,
                    productSalePrice: p.skuSalePrice, //售价
                })
            }
            data.productList = arr;
            return data;
        },
    }
}
