<!-- 发票配置 -->
<template>
  <!-- -->
  <div class="defaultViewStyle">
    <page-header/>
    <list-page>
      <search-condition @search="resetSearch">
        <el-input v-model="searchInfo.invoiceName" placeholder="请输入支付类型名称"/>
        <el-select v-model="searchInfo.status" placeholder="请选择状态">
          <el-option label="禁用" value="0"/>
          <el-option label="启用" value="1"/>
        </el-select>
        <template #option>
          <page-button type="write" el-type="success" @click="toAddInvoiceType = true">添加</page-button>
          <page-button el-type="primary" @click="resetSearch">查询</page-button>
        </template>
      </search-condition>
      <table-list>
        <el-table :data="dataList" height="100%" border>
          <el-table-column label="序号" min-width="60px" show-overflow-tooltip type="index" :align="'center'"/>
          <el-table-column label="接口名称" min-width="60px" prop="invoiceName" show-overflow-tooltip
                           :align="'center'"/>
          <el-table-column label="接口类型" min-width="60px" show-overflow-tooltip type="index" :align="'center'">
            <template #default>
             线上对接
            </template>
          </el-table-column>
          <el-table-column label="状态" min-width="60px" show-overflow-tooltip type="index" :align="'center'">
            <template v-slot="{row}">
              {{ row.status === 0 ? '禁用' : '启用' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'">
            <template v-slot="{row}">
              <table-button @click="editInfo(row)">编辑</table-button>
              <table-button el-type="danger" @click="deleteRow(row)" :disabled="row.invoiceCode === 1" type="delete" message="确认删除该条数据？">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>
  </div>

  <el-dialog title="新增发票类型" @close="openBefore" width="40%" v-model="toAddInvoiceType">
    <el-form label-width="110px">
      <el-form-item label="发票名称">
        <el-input placeholder="请填写发票名称" v-model="invoiceVo.invoiceName"/>
      </el-form-item>

      <el-form-item label="发票类型">
        <el-select v-model="invoiceVo.invoiceType" placeholder="请选择发票类型">
          <el-option label="线上对接" :value="1"/>
        </el-select>
      </el-form-item>

      <el-form-item label="状态">
        <el-select v-model="invoiceVo.status" placeholder="请选择状态">
          <el-option label="禁用" :value="0"/>
          <el-option label="启用" :value="1"/>
        </el-select>
      </el-form-item>

      <el-form-item label="对接接口">
        <el-select @change="findTemplate" v-model="invoiceVo.invoiceCode" placeholder="请选择接口类型">
          <el-option v-for="item in invoiceInterfaceList" :key="item.dictCode" :label="item.dictName" :value="item.dictCode"/>
        </el-select>
      </el-form-item>

      <div>
        <el-form-item v-for="item in invoiceVo.detail" :key="item.id+new Date()" :label="item.paramName">
          <el-input v-model="item.paramValue" type="textarea" :rows="1" clearable/>
        </el-form-item>
      </div>
    </el-form>

    <div style="display: flex; justify-content: flex-end">
      <div>
        <page-button el-type="info" @click="toAddInvoiceType = false">取消</page-button>
        <page-button type="write" @click="saveSubmit" el-type="primary">保存</page-button>
      </div>
    </div>

  </el-dialog>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import systemInvoiceApi from "@/api/main/sys/systemInvoiceApi";
import TableButton from "@/components/h5/button/TableButton.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";

export default {
  name: "SystemInvoice",
  components: {
    FormPage, TableList, TableButton,
    PageButton, SearchCondition,
    PageHeader, ListPage
  },
  data() {
    return {
      // dialog新增、修改
      toAddInvoiceType: false,
      searchInfo: {},
      dataList: [],
      // 发票数据
      invoiceVo: {
        invoiceType: 1,
        detail: [], // 具体配置
      },
      invoiceInterfaceList: [],
      invoiceTemplate: [],
    };
  },
  created() {
    this.queryInterfaceList();
  },
  methods: {
    resetSearch(){
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      systemInvoiceApi.queryList(this.searchInfo).then(res => {

        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      })
    },
    // 获取所有的发票接口集合
    queryInterfaceList(){
      systemInvoiceApi.queryInvoiceTypeList().then(res => this.invoiceInterfaceList = res);
    },
    findTemplate(){
      systemInvoiceApi.findInvoiceTemplate(this.invoiceVo.invoiceCode).then(res => {
        this.invoiceTemplate = res;
        this.handleData();
      });
    },
    openBefore(){
      this.invoiceVo = {}
    },
    // 保存
    saveSubmit(){
      systemInvoiceApi.saveSubmit(this.invoiceVo).then(()=>{
        this.toAddInvoiceType = false;
        this.queryList();
      })
    },
    // 数据处理
    handleData(){
      let arr = [];
      for (let i = 0; i < this.invoiceTemplate.length; i++) {
        let obj = {
          paramName: this.invoiceTemplate[i].dictName,
          paramColumn: this.invoiceTemplate[i].dictCode
        }
        arr.push(obj);
      }
      this.invoiceVo.detail = arr;
    },
    // 编辑
    editInfo(row){
      systemInvoiceApi.findById(row.id).then(res=>{
        res.invoiceCode = res.invoiceCode.toString();
        this.invoiceVo = res;
        this.toAddInvoiceType = true;
        console.log(this.invoiceVo);
      })
    },
    // 删除
    deleteRow(row){
      systemInvoiceApi.delete(row.id);
    },
  }
};
</script>

<style lang="scss" scoped>

</style>