import axios from "../../../util/axios";


export default {
   /**
     * 获取该商户可以进货的所有商品列表
     * @returns {Promise | Promise<unknown>}
     */
    queryProductAuthorizeList: (pageInfo)=> axios.get({url: `/order/stock/outStorage/queryProductAuthorizeList`,data: pageInfo}),

    findUser: ()=> axios.get({url: `/order/stock/outStorage/findUser`}),

    findDepart: (stockId) => axios.get({url: `/order/stock/outStorage/findDepart`, data: {stockId: stockId}}),

    saveDraft: (data) => axios.post({url: `/order/stock/outStorage/saveDraft`,data: data}),

    saveSubmit: (data) => axios.post({url: `/order/stock/outStorage/saveSubmit`,data: data}),

    queryList: (data,stockId) => axios.get({url: `/order/stock/outStorage/queryList?stockId=${stockId}`,data: data}),

    findByInOrderId: (inoutStorageId) => axios.get({url: `/order/stock/outStorage/findByInOrderId`, data: {inoutStorageId: inoutStorageId}}),

    // 查询商品在库存中是否存在
    checkoutSkuOriginalCode: (list, stockId, skuId)=> axios.post({url: `/order/stock/outStorage/checkoutSkuOriginalCode?stockId=${stockId}&skuId=${skuId}`, data: list}),

}