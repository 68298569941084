<template>
  <!-- -->
  <div class="defaultViewStyle">
    <separate-page>
      <template #category>
        <el-tree :data="menuList" :props="{children: 'children', label: 'moduleName'}" @node-click="clickMenu" empty-text="菜单为空" node-key="id" default-expand-all :expand-on-click-node="false" style="height: 100%;overflow: auto">
          <template #default="{node, data}">
              <span :class="data.id === currentData.id? 'custom-tree-node on': 'custom-tree-node'">
                <span class="node-name">{{ node.label }}</span>
              </span>
          </template>
        </el-tree>
      </template>
      <template #default>
        <list-page>
          <table-list>
            <el-descriptions :column="2" border>
              <el-descriptions-item label="id">{{currentData.id}}</el-descriptions-item>
              <el-descriptions-item label="功能名称">
                <el-input v-model="currentData.moduleName"/>
              </el-descriptions-item>
              <el-descriptions-item label="菜单图标">
                <el-input v-model="currentData.moduleIcon" />
              </el-descriptions-item>
              <el-descriptions-item label="上级菜单">
                <el-select v-model="currentData.parentId">
                  <el-option v-for="(m, i) in menuList" :key="i" :label="m.moduleName" :value="m.id"/>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item label="WEB端功能">
                <el-select v-model="currentData.moduleWeb">
                  <el-option :value="0" label="无"/>
                  <el-option :value="1" label="有"/>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item label="WEB路由">
                <el-input v-model="currentData.moduleComponentUri"/>
              </el-descriptions-item>
              <el-descriptions-item label="企业微信功能">
                <el-select v-model="currentData.moduleQywx">
                  <el-option :value="0" label="无"/>
                  <el-option :value="1" label="有"/>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item label="企业微信路由">
                <el-input v-model="currentData.moduleQywxUri"/>
              </el-descriptions-item>
              <el-descriptions-item label="小程序功能">
                <el-select v-model="currentData.moduleMini">
                  <el-option :value="0" label="无"/>
                  <el-option :value="1" label="有"/>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item label="小程序路由">
                <el-input v-model="currentData.moduleMiniUri"/>
              </el-descriptions-item>
              <el-descriptions-item label="权限接口">
                <el-input v-model="currentData.interfaceUri" />
              </el-descriptions-item>
              <el-descriptions-item label="排序序号">
                <el-input v-model="currentData.moduleIndex"/>
              </el-descriptions-item>
              <el-descriptions-item label="开放权限" :span="2">
                <el-checkbox-group v-model="currentData.accessList">
                  <el-checkbox v-for="(a, i) in rightList" :label="a.name" :key="i" :value="a.id + ''"/>
                </el-checkbox-group>
              </el-descriptions-item>
            </el-descriptions>
          </table-list>
          <form-page :show-page="false">
            <page-button el-type="primary" @click="selectAll">全选</page-button>
            <page-button type="write" el-type="success" @click="createNew">添加</page-button>
            <page-button type="delete" :disabled="!currentData.id" el-type="danger" message="是否确认删除功能" @click="deleteModule">删除</page-button>
            <page-button type="write" el-type="primary" @click="save">保存</page-button>
          </form-page>
        </list-page>
      </template>
    </separate-page>
  </div>
</template>

<script>
import SeparatePage from "@/components/h5/layout/SeparatePage.vue";
import systemModuleApi from "@/api/main/sys/SystemModuleApi";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import ListPage from "@/components/h5/layout/ListPage.vue";
import TableList from "@/components/h5/layout/TableList.vue";
export default {
  name: "",
  components: {
    PageButton,
    TableButton,
    SeparatePage, FormPage, ListPage, TableList
  },
  data() {
    return {
      menuList: [],
      rightList: [],
      currentData: {
        moduleWeb: "",
        moduleComponentUri: "",
        moduleQywx: "",
        moduleQywxUri: "",
        moduleMini: "",
        moduleMiniUri: "",
        interfaceUri: "",
        moduleIndex: "",
        moduleAccess: "",
        accessList: [],
      },
    };
  },
  created() {
    // 查询功能权限
    systemModuleApi.queryAccessRight().then(list => this.rightList = list);
    this.init();
  },
  methods: {
    init() {
      // 查询菜单列表
      systemModuleApi.queryModuleTree().then(list => this.menuList = list)
    },
    clickMenu(data) {
      let d = {...data};
      d.accessList = d.moduleAccess? d.moduleAccess.split(","): [];
      this.currentData = d;
    },
    createNew() {

      this.currentData = {
        accessList: [],
        system: 1,
        parentId: this.currentData.parentId || this.currentData.id || "",
      }
    },
    deleteModule() {
      systemModuleApi.delete(this.currentData.id).then(() => {
        this.init();
      });
    },
    save() {
      let d = {...this.currentData};
      d.moduleAccess = d.accessList.join(",");
      systemModuleApi.saveModule(d).then(() => {
        this.init();
      })
    },
    selectAll() {
      let arr = [];
      for (let i = 0; i < this.rightList.length; i++) {
        arr.push(this.rightList[i].id + "")
      }
      this.currentData.accessList = arr;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/tree.scss";
</style>