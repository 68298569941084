<template>
  <!-- -->
  <div class="defaultViewStyle">
    <el-tabs v-model="activeName">
      <el-tab-pane name="test1" title="byProduct" label="按商品" lazy>
        <plat-order-statistic-by-type :type="1"/>
      </el-tab-pane>
      <el-tab-pane name="test2" title="byDepart" label="按仓库" lazy>
        <plat-order-statistic-by-type :type="2"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PlatOrderStatisticByType from "@/views/main/plat/finance/order/PlatOrderStatisticByType.vue";
export default {
  name: "",
  components: {
    PlatOrderStatisticByType
  },
  data() {
    return {
      activeName: "test1"
    };
  },
  created() {
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>

</style>