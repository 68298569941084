import axios from "../../../util/axios";

export default {
    // 查询列表
    queryList: (data)=> axios.get({url: `/order/supplyChain/dealPrice/queryList`, data: data}),
    // 保存
    save: (data)=> axios.post({url: `/order/supplyChain/dealPrice/save`, data: data}),
    // 查询明细
    findById: (id)=> axios.get({url: `/order/supplyChain/dealPrice/findById`, data: {id}}),
    // 删除
    delete: (id)=> axios.del({url: `/order/supplyChain/dealPrice/delete`, data: {id}}),
    // 查询商品列表
    queryProductList: (data)=> axios.get({url: `/order/supplyChain/dealPrice/queryProductList`, data: data}),


}