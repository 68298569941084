<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header/>
      <table-list>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="会员头像" :span="2">
            <image-upload v-model="customerInfo.iconUrl" :limit="1" folder="customer"/>
          </el-descriptions-item>
          <el-descriptions-item label="会员名称">
            <el-input v-model="customerInfo.customerName"/>
          </el-descriptions-item>
          <el-descriptions-item label="手机号码">
            <el-input v-model="customerInfo.customerPhone"/>
          </el-descriptions-item>
          <el-descriptions-item label="个人微信">
            <span v-if="customerInfo.wxOpenId">已关联</span>
            <span v-else>未关联</span>
          </el-descriptions-item>
          <el-descriptions-item label="出生日期">{{customerInfo.birthday}}</el-descriptions-item>
          <el-descriptions-item label="性别">
            <el-select v-model="customerInfo.gender" clearable placeholder="请选择性别">
              <el-option :value="1" label="男" />
              <el-option :value="2" label="女" />
              <el-option :value="0" label="未知" />
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="最近登录">{{customerInfo.lastLogin}}</el-descriptions-item>
        </el-descriptions>
        <div v-if="customerInfo.id">
          <div v-for="(c, i) in customerInfo.carList" class="customer-car" :key="i">
            <el-card >
              <template #header>
                <span>{{c.carNo}}</span>
              </template>
              <template #default>
                <div style="text-align: center">
                  <image-upload :disabled="true" v-model="c.carImage" :show-limit="1" height="140px" width="100%"/>
                </div>
              </template>
              <template #footer>
                <div style="text-align: right">
                  <page-button el-type="primary" @click="toShowWarranty(c.id)">质保卡</page-button>
                  <page-button el-type="danger" type="delete" message="是否确认删除数据" @click="deleteCarInfo(c)">删除</page-button>
                  <page-button el-type="primary" type="write" @click="toShowCarEdit(c)">编辑</page-button>
                </div>
              </template>
            </el-card>
          </div>
          <div class="customer-car">
            <el-card>
              <div style="display: flex;align-items: center;justify-content: space-between;height: 200px">
                <div style="width: 100%;text-align: center">
                  <el-icon size="60px" style="color: #999"><el-icon-plus/></el-icon>
                </div>
              </div>
              <template #footer>
                <div style="text-align: right">
                  <page-button el-type="success" type="write" @click="toShowCarEdit({})">添加</page-button>
                </div>
              </template>
            </el-card>
          </div>
        </div>
      </table-list>
      <form-page :show-page="false">
        <page-button el-type="danger" type="delete" message="是否确认删除此数据，删除后将无法正常查询到会员" @click="deleteCustomer" v-if="customerInfo.id">删除</page-button>
        <page-button el-type="success" type="write" @click="saveCustomer">保存</page-button>
      </form-page>
    </list-page>
  </div>

  <el-dialog v-model="showCarEdit" title="会员车辆档案" width="460px">
    <el-form label-width="100px">
      <el-form-item label="车辆品牌">
        <el-select v-model="currentCarInfo.brandId" @change="changeBrand">
          <el-option v-for="d in carBrandList" :value="d.dictCode" :label="d.dictName"/>
        </el-select>
      </el-form-item>
      <el-form-item label="车辆型号">
        <el-select v-model="currentCarInfo.brandModelId">
          <el-option v-for="d in carModelList" :value="d.dictCode" :label="d.dictName"/>
        </el-select>
      </el-form-item>
      <el-form-item label="车架号码">
        <el-input v-model="currentCarInfo.frameNumber"/>
      </el-form-item>
      <el-form-item label="车牌号">
        <el-input v-model="currentCarInfo.carNo"/>
      </el-form-item>
      <el-form-item label="车辆照片">
        <image-upload folder="customer/car" v-model="currentCarInfo.carImage" :limit="5"/>
      </el-form-item>
    </el-form>
    <div style="text-align: right">
      <page-button @click="showCarEdit = false">取消</page-button>
      <page-button el-type="success" type="write" @click="saveCarInfo">保存</page-button>
    </div>
  </el-dialog>
</template>

<script>
import ImageUpload from "@/components/h5/image/ImageUpload";
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import PageButton from "@/components/h5/button/PageButton";
import FormPage from "@/components/h5/layout/FormPage";
import PageHeader from "@/components/h5/layout/PageHeader.vue";

import memberApi from "@/api/alpine/customer/memberApi";
import commonApi from "@/api/commonApi";
export default {
  name: "",
  components: {
    PageButton,
    ImageUpload, ListPage, TableList, FormPage, PageHeader
  },
  data() {
    return {
      showCarEdit: false,
      currentCarInfo: {
        carNo: "",
        brandId: "",
        brandModelId: "",
        frameNumber: "",
        carImage: ""
      },
      customerInfo: {
        customerName: "",
        customerPhone: "",
        wxOpenId: "",
        iconUrl: "",
        videoUrl: "",
        birthday: "",
        gender: "",
        lastLogin: "",
        carList: [
        ],
      },
      // 车辆品牌列表
      carBrandList: [],
      // 车辆型号列表
      carModelList: [],
    };
  },
  created() {
    commonApi.queryDictionaryTree("CAR_TYPE", "10000").then(list => this.carBrandList = list);
  },
  mounted() {
    if (this.$routerUtil.query.id) {
      // 查询数据
      this.init(this.$routerUtil.query.id);
    }
  },
  methods: {
    init(id) {
      memberApi.findById(id).then(data => this.customerInfo = data);
    },
    // 保存客户
    saveCustomer() {
      memberApi.saveCustomer(this.customerInfo).then(data => {
        this.customerInfo = {
          ...data,
          carList: this.customerInfo.carList
        }
      });
    },
    toShowCarEdit(data) {
      this.currentCarInfo = {
        ...data
      };
      this.showCarEdit = true;
    },
    // 保存车辆信息
    saveCarInfo() {
      let data = {
        ...this.currentCarInfo,
        customerId: this.customerInfo.id
      }
      memberApi.saveCarInfo(data).then(() => {
        this.init(this.customerInfo.id);
        this.showCarEdit = false;
      });
    },
    deleteCarInfo(data) {
      memberApi.deleteCar(data.id).then(() => this.init(this.customerInfo.id));
    },
    // 下拉框联动
    changeBrand(data) {
      // 找到下级
      let arr = [];
      for (let i = 0; i < this.carBrandList.length; i++) {
        if (data === this.carBrandList[i].dictCode) {
          arr = this.carBrandList[i].children;
          break;
        }
      }
      this.carModelList = arr;
    },
    // 删除会员
    deleteCustomer() {
      memberApi.delete(this.customerInfo.id).then(() => {
        this.router.push("/customer/member")
      });
    },
    // 根据车辆信息查询质保
    toShowWarranty(carId) {
      this.$routerUtil.push({
        path: "/report/warrantyList",
        query: {
          customerId: this.customerInfo.id,
          customerName: this.customerInfo.customerName
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/dialog.scss";
// 限定，一排三个card
.customer-car{
  display: inline-block;
  margin: 0;
  width: 25%;
  box-sizing: border-box;
  padding: 0 10px;
  .el-card{
    height: 300px;
  }
}
.customer-car:nth-child(4n+1){
  padding-left: 0;
}
.customer-car:nth-child(4n){
  padding-right: 0;
}
</style>