import axios from "../../../../util/axios";

export default {
    // 手动新增一个附件订单关联
    addAttachment: (data) => axios.post({url: "/order/saleOrder/offlineOrder/addAttachment", data: data}),
    // 查询附件
    findAttachment: (orderId) => axios.get({url: "/order/saleOrder/offlineOrder/findAttachment", data: {orderId}}),
    // 查询订单列表
    queryList: (searchInfo) => axios.get({url: "/order/saleOrder/offlineOrder/queryList", data: searchInfo}),
    // 查询小程序的订单
    queryListForMini: (searchInfo) => axios.get({url: "/order/saleOrder/offlineOrder/queryListForMini", data: searchInfo}),
    // 保存草稿
    saveDraft: (data) => axios.post({url: "/order/saleOrder/offlineOrder/saveDraft", data: data}),
    // 提交单据
    saveSubmit: (data) => axios.post({url: "/order/saleOrder/offlineOrder/saveSubmit", data: data}),
    // 查询仓库
    queryAllStock: (bankupId) => axios.get({url: "/order/saleOrder/offlineOrder/queryAllStock", data: {bankupId}}),
    // 删除
    delete: (id) => axios.del({url: "/order/saleOrder/offlineOrder/delete", data: {id}}),
    // 查询详情
    findById: (id) => axios.get({url: "/order/saleOrder/offlineOrder/findById", data: {id}}),
    //准备支付提交草稿
    preparePay: (data) => axios.post({url: "/order/saleOrder/offlineOrder/preparePay", data: data}),
    //支付
    pay: (orderId, payId, qrCode) => axios.get({url: "/order/saleOrder/offlineOrder/pay", data: {qrCode,orderId,payId}}),
    // 查询订单状态
    findPayStatus: (orderId,payId) => axios.get({url: "/order/saleOrder/offlineOrder/findPayStatus", data: {orderId,payId}}),
    // 取消订单
    cancelOrder: (data) => axios.post({url: "/order/saleOrder/offlineOrder/cancelOrder", data: data}),
    // 手动添加支付流水号
    savePaySerial: (orderId,paySerial) => axios.get({url: "/order/saleOrder/offlineOrder/savePaySerial", data: {orderId: orderId,paySerial: paySerial}}),
    // 更新提交的金额信息
    updateAmount: (id,amount) => axios.get({url: "/order/saleOrder/offlineOrder/updateAmount", data: {id,amount}}),
    // 查询该订单是否存在未处理完成的退货单
    findHasRefundOrder: (orderId) => axios.get({url: "/order/saleOrder/offlineOrder/findHasRefundOrder", data: {orderId}}),
    // 查询当前订单开具的所有正红票
    findTicketsByOrderId: (orderId) => axios.get({url: "/order/saleOrder/offlineOrder/findTicketsByOrderId", data: {orderId}}),
    // 终止当前订单
    termination: (id) => axios.get({url: "/order/saleOrder/offlineOrder/termination", data: {id}}),
    // 查询最后一次的支付方式
    findFinalPayType: (payId) => axios.get({url: "/order/saleOrder/offlineOrder/findFinalPayType", data: {payId}}),
    // 用户修改订单已完成
    updateOrderStatus: (orderId) => axios.get({url: "/order/common/mini/order/updateOrderStatus", data: {orderId}}),
}