import axios from "../../../util/axios";

export default {
    // 保存领用单
    saveSubmit: (data) => axios.post({url: "/order/requisite/form/saveSubmit", data: data}),

    // 保存领用单草稿
    saveDraft: (data) => axios.post({url: "/order/requisite/form/saveDraft", data: data}),

    // 查询领用单列表
    queryList: (data) => axios.get({url: "/order/requisite/form/queryList", data: data}),

    // 查询领用单详情
    findById: (inoutStorageId) => axios.get({url: "/order/requisite/form/findById", data: {inoutStorageId: inoutStorageId}}),

    // 查询未归还的列表信息
    queryNotBackList: (data) => axios.get({url: "/order/requisite/form/queryNotBackList", data: data}),
}