import axios from "../../../util/axios";

export default {
    // 列表查询
    queryList: (data) => axios.get({url: `/order/product/relateDepart/queryList`, data: data}),

    // 保存权限
    saveGrant: (data) => axios.post({url: `/order/product/relateDepart/saveGrant`, data: data}),

    // 批量删除
    deleteBatch: (data) => axios.post({url: `/order/product/relateDepart/deleteBatch`, data: data}),

    // 根据id回显数据
    findById: (id) => axios.get({url: `/order/product/relateDepart/findById?id=${id}`}),

    // 授权的分类信息
    queryAllByDepartCode: (departCode) => axios.get({url: `/order/product/relateDepart/queryAllByDepartCode?departCode=${departCode}`}),
}