import axios from "../../../util/axios";

export default {
    // 保存一个草稿
    saveDraft: (data) => axios.post({url: "/order/change/document/toSaveDraft", data: data}),

    // 提交单据
    saveSubmit: (data) => axios.post({url: "/order/change/document/saveSubmit", data: data}),

    // 查询列表信息
    queryList: (data) => axios.get({url: "/order/change/document/queryList", data: data}),

    // 根据id回显
    findById: (id) => axios.get({url: "/order/change/document/findById", data: {id}}),

    // 添加一笔变更信息
    addDoc: (data) => axios.post({url: "/order/change/document/addDoc", data: data}),

    // 更新状态
    updateStatus: (id) => axios.get({url: "/order/change/document/updateStatus", data: {id}}),
}