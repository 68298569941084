<template>
  <!-- 轮播图片组件 -->
  <div class="bg-banner" :style="{height: dataInfo.pictureInfo[0].height,'border-radius': dataInfo.pictureInfo[0].radius,'margin-top': dataInfo.pictureInfo[0].marginTop,}">
<!--
！！！！！！！！！！！！！！！！----swiper这东西是真的傻逼，一用一个不吱声，没有最难用只有更难用----！！！！！！！！
                ！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！
-->
<!--    <swiper ref="mySwiper" :options="swiperOption">-->
<!--      <swiper-slide v-for="(item,idx) in dataInfo.pictureInfo" :key="idx">-->
<!--          <img style="width: 100%; height: 120px;" :src="item.imageUrl" alt="">-->
<!--          -->
<!--      </swiper-slide>-->
<!--      <div class="swiper-pagination" slot="pagination"></div>-->
<!--    </swiper>-->
    <el-carousel :style="{height: dataInfo.pictureInfo[0].height}">
      <el-carousel-item v-for="(item,idx) in dataInfo.pictureInfo" :key="idx">
        <img :style="{width: '100%', height: dataInfo.pictureInfo[0].height}" :src="item.imageUrl" alt="">
        <span :style="
        {
        'position': 'absolute',
        'left': item.left,
        'top': item.top,
        'font-size': item.size,
        'color': item.color,
        'white-space': item.whiteSpace ? 'normal' : 'nowrap',
        'width': item.width
        }">{{ item.text }}</span>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>

import {watch} from "vue";

export default {
  props: {
    // 修改信息
    configuration: {default: {}},
    // 索引位置，用来寻找匹配的配置信息作渲染遍历
    indexToFind: {default: null},
    // 初始化信息
    initData: {default: {}},
  },
  name: "BannerImg",
  components: {
  },
  data() {
    return {
      dataInfo: {},
    };
  },
  created() {
    // 监听这个配置信息
    watch([() => this.configuration], () => {
      this.init();
    },{ deep: true })
    // 刷新重载、只在页面初始化时执行一次
    if (this.initData !== null) {
      this.dataInfo = this.initData;
    }
  },
  methods: {
    // 初始化
    init() {
      if (this.indexToFind !== this.configuration.index && !this.dataInfo) {
        return;
      }
      if (this.indexToFind === this.configuration.index) {
        this.dataInfo = this.configuration;
      }
      console.log(this.dataInfo);
    },
  }
};
</script>

<style lang="scss" scoped>
.bg-banner {
  width: 100%;
  height: 120px;
  position: relative;
  overflow: hidden;
}

:deep(.el-carousel__indicator--horizontal){
  padding: 2px !important;
}
:deep(.el-carousel__button){
  width: 5px;
}
</style>