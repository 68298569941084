<template>
  <!-- 通用模板，列表数据 -->
  <list-page>
    <search-condition @search="resetSearch" :show-search="true">
      <product-one-select v-model="searchInfo.skuId" style="display: inline-block;width: 23%" @selectOne="resetSearch"/>
      <el-select v-model="searchInfo.merchantId" placeholder="请选择商户" clearable filterable>
        <el-option v-for="item in merchantList" :key="item.id" :label="item.merchantName" :value="item.id"/>
      </el-select>
    </search-condition>
    <table-list>
      <el-table ref="tableList" :data="dataList" height="100%" border @row-click="clickRow" @selection-change="selectionChange" empty-text="请选择商户后查询授权">
        <el-table-column type="selection" :align="'center'"/>
        <el-table-column label="被授权商户" prop="merchantName" min-width="100px" show-overflow-tooltip>
          <template v-slot="{row}">
            <span v-for="(m,i) in merchantList" :key="i"><span v-if="row.merchantId === m.id">{{m.merchantName}}</span></span>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" prop="skuName" show-overflow-tooltip min-width="150px"/>
        <el-table-column label="商品单位" prop="unitName" show-overflow-tooltip width="100px" :align="'center'"/>
        <el-table-column label="商品编码" prop="skuCode" min-width="100px" show-overflow-tooltip/>
        <el-table-column label="拼音码" prop="pinyinCode" min-width="100px" show-overflow-tooltip/>
        <el-table-column label="条码" prop="barCode" min-width="100px" show-overflow-tooltip/>
        <el-table-column label="成本价格" min-width="120px">
          <template v-slot="{row}">
            <el-input v-model="row.authorizeBuyPrice"/>
          </template>
        </el-table-column>
        <el-table-column label="销售价格" min-width="120px">
          <template v-slot="{row}">
            <el-input v-model="row.authorizeSalePrice"/>
          </template>
        </el-table-column>
        <el-table-column label="操作" :align="'center'" width="100px" fixed="right">
          <template v-slot="{row}">
            <table-button @click="saveSingle(row)" type="write">保存</table-button>
            <table-button message="是否确认解除授权" type="delete" el-type="danger" @click="deleteGrant(row)">删除</table-button>
          </template>
        </el-table-column>
      </el-table>
    </table-list>
    <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList" :default-load="false">
      <page-button el-type="danger" type="write" message="是否确认删除选中的全部授权" @click="deleteBatch">删除</page-button>
<!--      <page-button el-type="primary" type="write" @click="saveBatch">保存</page-button>-->
    </form-page>
  </list-page>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import grantApi from "@/api/main/grant/grantApi";
import ProductOneSelect from "@/components/h5/product/ProductOneSelect";
export default {
  name: "",
  components: {
    TableButton,
    PageButton,
    ListPage, TableList, FormPage, SearchCondition, ProductOneSelect
  },
  data() {
    return {
      searchInfo: {
        isGrant: 1,
      },
      dataList: [],
      merchantList: [],
      elSelected: [],
    };
  },
  created() {
    grantApi.queryMerchantList().then(res => {
      this.merchantList = res;
    });
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      grantApi.queryList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      });
    },
    // 保存一条数据
    saveSingle(data) {
      grantApi.saveGrantByList([data], data.merchantId).then(() => this.queryList());
    },
    // 删除授权
    deleteGrant(data) {
      grantApi.deleteGrantSku(data.id, data.authorizeMerchantId, data.merchantId).then(() => this.queryList());
    },
    clickRow(data) {
      data.checked = !data.checked;
      this.$refs.tableList.toggleRowSelection(data, data.checked);
    },
    selectionChange(list) {
      this.elSelected = list;
    },
    // 批量删除
    deleteBatch() {
      grantApi.deleteGrantByList(this.elSelected).then(() => this.queryList());
    },
    // 批量更新
    saveBatch() {
      grantApi.saveGrantByList(this.elSelected).then(() => this.queryList());
    }
  }
};
</script>

<style lang="scss" scoped>

</style>