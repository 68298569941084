import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

// 远程组件
import componentPublic from "@/router/componentPublic";
// 微服务端
// web端的路由
import webRouter from "@/router/WebRouter";
let routers = [
    {path: '/', name: '登录', component: () => import("../views/main/login/MerchantLogin")},
    {path: '/useradmin', name: 'saas登录', component: () => import("../views/main/login/SaasLogin")},
    {path: '/login/qywxcode', name: '企业微信code', component: () => import("../views/main/login/QywxLogin.vue")},
    {path: '/login/qywxbind', name: '企业微信绑定', component: () => import("../views/main/login/QywxBindEmployee.vue")},

];

// const arr = routers.concat(cloudRouter).concat(componentPublic).concat(webRouter);
const router = createRouter({
    history: createWebHashHistory(),
    routes: routers.concat(webRouter)
})

export default router