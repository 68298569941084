<template>
  <!-- 在el-button的type基础上增加了两个类型，write和delete类型，write为写入权限，delete为删除权限，此类型可以关联到系统读写权限 -->
  <span :class="`table-button ${elType} access-${accessDisabled || disabled}`"   :style="{color: color? color:''}"><span @click.stop="clickEvent"><slot></slot></span></span>
</template>

<script>
import {ElMessageBox} from 'element-plus';
import util from "@/util/util";
export default {

  props: {
    // 提示消息，如果有消息，将阻止点击事件
    message: {default: ""},
    type: {default: "read"},
    elType: {default: "primary"},
    color: {default: ""},
    disabled: {default: false},
  },
  components: {
  },
  data() {
    return {
      accessDisabled: false,
    };
  },
  created() {
    util.getUserInfo().then(user => {
      let access = user.accessModules;
      // 获取path
      let path = this.$routerUtil.path;
      for (let i = 0; i < access.length; i++) {
        if (access[i].url.indexOf(path) > -1) {
          if (this.type === "write") {
            // 写入按钮，如果没有权限，则禁用按钮
            let ch = access[i].accessModel.charAt(1);
            if (ch === "0") {
              // 没有写入权限,禁用
              this.accessDisabled = true;
            }
          } else if (this.type === "delete") {
            // 删除按钮
            let ch = access[i].accessModel.charAt(2);
            if (ch === "0") {
              // 没有写入权限,禁用
              this.accessDisabled = true;
            }
          }
          break;
        }
      }
    });
  },
  methods: {
    clickEvent() {
      // 权限被禁用
      if (this.accessDisabled || this.disabled) {
        return;
      }
      if (this.message) {
        ElMessageBox.confirm(this.message, "提示", {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$emit("click");
        }).catch(() => {
          this.$emit("cancel")
        })
      } else {
        this.$emit("click");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.table-button{
  margin-right: 10px;
  font-size: 12px;
  cursor: pointer;
  //font-weight: 600;
}
.table-button:last-child{
  margin-right: 0;
}
.primary{
  color: #409EFF;
}
.danger{
  color: #F56C6C;
}
.write{
  color: #409EFF;
}
.delete{
  color: #F56C6C;
}
.success{
  color: #67C23A;
}
.warning{
  color: #E6A23C;
}
.access-true{
  color: #999;
}
</style>