<template>
  <!-- -->
  <div class="defaultViewStyle">
    <page-header>
      <template #default>
        <page-button v-if="isCostDetail" @click="isCostDetail = false">返回</page-button>
        <page-button el-type="primary" type="print" @click="exportData">导出</page-button>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
      </template>
    </page-header>
    <list-page v-if="!isCostDetail">
      <search-condition @search="resetSearch" :show-search="false">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期"
                      v-model:end-time="searchInfo.endTime"/>
          <!--           label-width="auto" style="max-width: 600px"-->

          <div style="display: inline-block;">
            <el-form style="display: flex;flex-direction: row">
              <el-form-item label="机构：">
                <el-select v-model="searchInfo.departCode" clearable placeholder="全部" style="width: 240px">
                  <el-option
                      v-for="item in departList"
                      :key="item.id"
                      :label="item.departName"
                      :value="item.departCode"
                  />
                </el-select>
              </el-form-item>
              <el-form-item style="margin-left: 15px; width: 240px" label="账户：">
                <!--                <el-select v-model="searchInfo.payType" clearable placeholder="全部" style="width: 240px">-->
                <!--                  <el-option-->
                <!--                      v-for="item in payTypeList"-->
                <!--                      :key="item.value"-->
                <!--                      :label="item.label"-->
                <!--                      :value="item.value"-->
                <!--                  />-->
                <!--                </el-select>-->
                <pay-type-select v-model="searchInfo.payTypeId"/>
              </el-form-item>
            </el-form>
          </div>
        </template>
      </search-condition>
      <table-list>
        <el-table :data="dataList" height="100%" border show-summary :summary-method="getSummaries">
          <el-table-column align="center" label="序号" type="index" min-width="60px" fixed/>
          <el-table-column align="center" label="账户名称" show-overflow-tooltip min-width="150px">
            <template v-slot="{row}">
              <span v-for="(p, i) in payTypeList" :key="i">
                <span v-if="row.payTypeId === p.id">{{ p.payName }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="上期余额" prop="openBalance" show-overflow-tooltip min-width="100px"/>
          <el-table-column align="center" label="本期增加" prop="addAmount" show-overflow-tooltip min-width="100px"/>
          <el-table-column align="center" label="本期减少" prop="reduceAmount" min-width="150px"/>
          <el-table-column align="center" label="账户余额" min-width="100px">
            <template v-slot="{row}">
              <span>{{ (Number(row.openBalance) + Number(row.addAmount) - Number(row.reduceAmount)).toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'" min-width="200px" fixed="right">
            <template v-slot="{row}">
              <table-button @click="toShowDetail(row)">查看明细</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber"
                 :total-count="searchInfo.totalCount" @onLoad="queryAccountList"></form-page>
    </list-page>

    <list-page v-else-if="isCostDetail">
      <search-condition @search="resetSearch" :show-search="false">
        <template #default>
          <date-range v-model:start-time="searchInfoDetail.startTime" placeholder="请选择开始日期"
                      v-model:end-time="searchInfoDetail.endTime"/>

          <div style="display: inline-block;">
            <el-form style="display: flex;flex-direction: row">
              <el-form-item label="机构：">
                <el-select v-model="searchInfoDetail.departCode" clearable placeholder="全部" style="width: 240px">
                  <el-option
                      v-for="item in departList"
                      :key="item.id"
                      :label="item.departName"
                      :value="item.departCode"
                  />
                </el-select>
              </el-form-item>
              <el-form-item style="margin-left: 15px" label="账户：">
                <pay-type-select v-model="searchInfoDetail.payTypeId" style="width: 240px"/>
              </el-form-item>
              <el-form-item style="margin-left: 15px" label="期初余额：">
                <span style="margin: 0 10px 9px -15px">￥{{ currentAccountInfo.openBalance }}</span>
              </el-form-item>
            </el-form>
          </div>
        </template>
        <template #moreCondition>
          <div style="display: inline-block;">
            <el-form style="display: flex;flex-direction: row; align-items: center">
              <el-form-item label="单据编号：">
                <el-input title="单据编号" v-model="searchInfoDetail.orderId"/>
              </el-form-item>
              <el-form-item style="margin: -9px 0 0 15px" label="客户：">
                <customer-select :cus-title="'选择客户'" v-model="searchInfoDetail.customerId"
                                 :name="searchInfoDetail.customerName" @selectOne="setCustomer"/>
              </el-form-item>
            </el-form>
          </div>
        </template>
      </search-condition>
      <table-list>
<!--        show-summary :summary-method="summaryDataDetail"-->
        <el-table :data="dataDetailList" height="100%" border>
          <el-table-column align="center" label="序号" type="index" min-width="60px" fixed/>
          <el-table-column align="center" label="订单编号" prop="id" show-overflow-tooltip min-width="200px" fixed/>
          <el-table-column align="center" label="单据日期" prop="payTime" show-overflow-tooltip min-width="200px"
                           fixed/>
          <el-table-column align="center" label="机构" prop="departName" show-overflow-tooltip min-width="120px"/>
          <el-table-column align="center" label="仓库" min-width="120px" show-overflow-tooltip>
            <template v-slot="{row}">
              <show-specific :info-list="stockList" :value="row.stockId" :name-column="'stockName'"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="客户" prop="customerName" min-width="150px"/>
          <el-table-column align="center" label="职员" prop="employeeName" min-width="150px"/>
          <el-table-column align="center" label="数量" min-width="150px">
            <template v-slot="{row}">
              <!-- 销售单 -->
              <span v-if="row.orderType === 1">{{ row.qty }}</span>
              <!-- 退货单 -->
              <span v-else-if="row.orderType === 2">-{{ row.qty }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="销售金额" min-width="150px">
            <template v-slot="{row}">
              <!-- 销售单 -->
              <span v-if="row.orderType === 1">{{ row.amount }}</span>
              <!-- 退货单 -->
              <span v-else-if="row.orderType === 2">-{{ row.amount }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="账户名称" show-overflow-tooltip min-width="150px">
            <template v-slot="{row}">
              <pay-type-select :echo-pay-type-id="row.payTypeId" :echo-pay-type-list="payTypeList"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="实收金额" min-width="150px">
            <template v-slot="{row}">
              {{ row.amountCollect }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="本期增加" min-width="150px">
            <template v-slot="{row}">
              <span v-if="row.orderType === 1">{{ row.amountCollect }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="本期减少" min-width="150px">
            <template v-slot="{row}">
              <span v-if="row.orderType === 2">{{ row.amount }}</span>
            </template>
          </el-table-column>

          <el-table-column align="center" label="账户余额" min-width="150px">
            <template v-slot="{row}">
              {{ row.balance }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="单据类型" prop="orderTypeName" show-overflow-tooltip min-width="150px" />
          <el-table-column label="操作" :align="'center'" min-width="200px" fixed="right">
            <template v-slot="{row}">
              <table-button @click="showOrderDetail(row)">查看单据</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfoDetail.pageSize" v-model:page-number="searchInfoDetail.pageNumber"
                 :total-count="searchInfoDetail.totalCount" @onLoad="queryAccountDetailList"></form-page>
    </list-page>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import commonApi from "@/api/commonApi";
import TableButton from "@/components/h5/button/TableButton.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import orderStatisticApi from "@/api/main/order/orderStatisticApi";
import FormPage from "@/components/h5/layout/FormPage.vue";
import {h} from "vue";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import ShowSpecific from "@/components/h5/product/ShowSpecific.vue";
import offlineOrderApi from "@/api/main/order/offline/OfflineOrderApi";
import PayTypeSelect from "@/components/h5/PayTypeSelect.vue";
import systemPayApi from "@/api/main/sys/systemPayApi";
import util from "@/util/util";

export default {
  name: "PlatCost",
  components: {
    PayTypeSelect,
    FormPage,
    TableList, TableButton,
    DateRange, SearchCondition,
    PageButton, PageHeader,
    ListPage, CustomerSelect, ShowSpecific
  },
  data() {
    return {
      searchInfo: {}, // 搜索条件-主列表
      searchInfoDetail: {},   // 搜索条件-明细
      stockList: [],    // 仓库列表
      departList: [],   // 部门列表
      payTypeList: [],  // 账户列表
      dataList: [],   // 数据列表
      dataDetailList: [],   // 明细列表数据
      isCostDetail: false,   // 是否是查看明细
      currentAccountInfo: {}, //查看详情时，存储当前账户的信息
      // currentRowBalance: 0, // 当前行的账户余额、（记录当前计算的上一行的数据，避免每次都需要重头进行计算）
    };
  },
  watch: {
    'searchInfo.startTime': {
      handler() {
        this.resetSearch();
      }
    },
    'searchInfoDetail.startTime': {
      handler() {
        this.resetSearch();
      }
    },
  },
  created() {
    this.isCostDetail = false;
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    //查询部门
    commonApi.queryDepartList().then(res => this.departList = res);
    //查询账户
    systemPayApi.queryAllList().then(res => this.payTypeList = res);
    // 初始化
    this.queryAccountList();
    // 仓库
    orderStatisticApi.queryStockList().then(res => {
      this.stockList = res;
    })
  },
  methods: {
    exportData(){
      if(!this.isCostDetail){
        util.exportExcel("/order/saleOrder/statistic/exportAccountList", this.searchInfo);
      }else{
        util.exportExcel("/order/saleOrder/statistic/exportAccountDetailList", this.searchInfoDetail);
      }
    },
    // 查询账户列表
    queryAccountList() {
      orderStatisticApi.queryAccountList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
        //显示未出现的账户
        if (!this.searchInfo.payTypeId) {
          this.showNotExistAccount();
        }
      });
    },

    // 查询账户明细 --- s
    queryAccountDetailList() {
      orderStatisticApi.queryAccountDetailList(this.searchInfoDetail).then(res => {
        this.dataDetailList = res.records;
        this.searchInfoDetail.totalCount = res.totalCount;
        // 处理账户上期余额
        this.handleBalance();
      })
    },

    // 处理账户上期余额 --- s
    handleBalance() {
      for (let i = 0; i < this.dataDetailList.length; i++) {
        if (i === 0) {
          //第一个数据时
          if (this.dataDetailList[i].orderType === 1) {
            this.dataDetailList[i].balance = Number(this.currentAccountInfo.openBalance) + this.dataDetailList[i].amountCollect;
          } else if (this.dataDetailList[i].orderType === 2) {
            this.dataDetailList[i].balance = Number(this.currentAccountInfo.openBalance) - this.dataDetailList[i].amount;
          }
        } else {
          if (this.dataDetailList[i].orderType === 1) {
            this.dataDetailList[i].balance = Number(this.dataDetailList[i - 1].balance) + this.dataDetailList[i].amountCollect;
          } else if (this.dataDetailList[i].orderType === 2) {
            this.dataDetailList[i].balance = Number(this.dataDetailList[i - 1].balance) - this.dataDetailList[i].amount;
          }
        }
        this.dataDetailList[i].balance = Number(this.dataDetailList[i].balance).toFixed(2);
      }
    },

    // 展示未具有上期余额的账户信息
    showNotExistAccount() {
      if (this.dataList.length !== this.payTypeList.length) {
        for (let i = 0; i < this.payTypeList.length; i++) {
          let isExist = false;
          for (let j = 0; j < this.dataList.length; j++) {
            if (this.payTypeList[i].id === this.dataList[j].payTypeId) {
              isExist = true;
              break;
            }
          }
          if (!isExist) {
            this.dataList.push({
              payTypeId: this.payTypeList[i].id,
              openBalance: 0,
              addAmount: 0,
              reduceAmount: 0,
            })
          }
        }
      }
    },

    // 按条件查询
    resetSearch() {
      if (this.isCostDetail) {
        this.searchInfoDetail.pageNumber = 1;
        // 重新加载上期余额信息
        this.reloadInitBalance();
        // 查询列表
        this.queryAccountDetailList();
      } else {
        this.searchInfo.pageNumber = 1;
        this.queryAccountList();
      }
    },

    // 自定义统计-账户
    getSummaries(param) {
      const {columns, data} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '总  计',
          ])
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => Number.isNaN(value))) {
          sums[index] = `${values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!Number.isNaN(value)) {
              return (Number(prev) + Number(curr)).toFixed(2)
            } else {
              return Number(prev).toFixed(2)
            }
          }, 0)}`
        }
        // else {
        //   sums[index] = 'N/A'
        // }
        if (index === 5) {
          let curBalance = 0;
          let curAddAmount = 0;
          let curReduceAmount = 0;
          let finalAmount = 0;
          for (let i = 0; i < data.length; i++) {
            curBalance += data[i].openBalance;
            curAddAmount += data[i].addAmount;
            curReduceAmount += data[i].reduceAmount;
          }
          finalAmount = curBalance + curAddAmount - curReduceAmount;
          sums[index] = h('div', [(finalAmount.toFixed(2))])
        }
        //   Number(sums[2].substring(1, sums.length)) +   - Number(sums[4].substring(1, sums.length))
      })
      return sums
    },

    // 自定义统计-账户明细 --- s
    summaryDataDetail(param) {
      const {columns,data} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = h('div', {style: {fontWeight: 'bolder'}}, [
            '总  计',
          ])
          return
        }

      if(index === 11){
        let addAmount = 0; //增加
        for (let i = 0; i < data.length; i++) {
          addAmount += data[i].amountCollect;
        }
        sums[index] = h('div', [(addAmount.toFixed(2))])
      }

      if(index === 12){
        let reduceAmount = 0; //减少
        for (let i = 0; i < data.length; i++) {
          if(data[i].orderType === 2){
            reduceAmount += data[i].amount;
          }
        }
        sums[index] = h('div', [(reduceAmount.toFixed(2))])
      }

      if(index === 13){

        sums[index] = h('div', [((Number(data[data.length - 1].balance).toFixed(2)))])
      }

      })
      return sums
    },

    // 设置客户 --- s
    setCustomer(data) {
      this.searchInfoDetail.customerId = data.id;
      this.searchInfoDetail.customerName = data.customerName;
      this.resetSearch();
    },

    // 跳转账户明细 --- s
    toShowDetail(data) {
      //赋值
      this.searchInfoDetail.startTime = this.searchInfo.startTime;
      this.searchInfoDetail.endTime = this.searchInfo.endTime;
      this.searchInfoDetail.payTypeId = data.payTypeId;
      this.searchInfoDetail.departCode = this.searchInfo.departCode;
      this.currentAccountInfo = data;
      //查询
      this.queryAccountDetailList();
      this.isCostDetail = true;
    },

    // 重新初始化上期余额 --- s
    reloadInitBalance() {
      orderStatisticApi.findBalanceByPayType(this.searchInfoDetail).then(res => {
        // res保留两位小数
        res = Number(res).toFixed(2);
        this.currentAccountInfo.openBalance = res
      });
    },

    // 跳转订单详情 --- s
    showOrderDetail(row) {
      if (row.orderType === 1) {
        // 销售单
        this.$routerUtil.push(`/report/offline-order/detail?id=${row.id}`)
      } else if (row.orderType === 2) {
        // 退货单
        this.$routerUtil.push(`/report/refund-order/detail?id=${row.id}&isFinish=2`)
      }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>