import ElementPlus from 'element-plus' //全局引入Element
import {ElMessage} from 'element-plus';
import 'element-plus/dist/index.css'
import zhCn from "element-plus/es/locale/lang/zh-cn"; // 引入中文语言包
import {
    User,
    Lock,
    Fold,
    Expand,
    Setting,
    ArrowDown,
    ArrowUp,
    Goods,
    Location,
    Menu,
    Document,
    Refresh,
    Check,
    CaretLeft,
    CaretRight,
    DCaret,
    Search,
    Plus,
    CreditCard,
    Key,
    SwitchButton,
    Edit,
    Avatar,
    OfficeBuilding,
    MessageBox,
    ShoppingTrolley,
    DataLine,
    ShoppingCart,
    Close,
    Delete,
    InfoFilled,
    Upload,
    WarningFilled,
    DArrowRight,
    DocumentAdd,
    Coin,
    PriceTag,
    ReadingLamp,
    DataAnalysis,
    HomeFilled,
    CaretBottom,
    CaretTop,
    Warning,
    Odometer, Compass,
    Link,
    Postcard, ShoppingBag, Bicycle
} from '@element-plus/icons-vue'


export default {
    loadElementUi: (app) => {
        // elementUi使用中文
        app.use(ElementPlus, {locale: zhCn});
        // 添加element的图标
        app.component("ElIconUser", User);
        app.component("ElIconLock", Lock);
        app.component("ElIconFold", Fold);
        app.component("ElIconExpand", Expand);
        app.component("ElIconSetting", Setting);
        app.component("ElIconArrowDown", ArrowDown);
        app.component("ElIconArrowUp", ArrowUp);
        app.component("ElIconGoods", Goods);
        app.component("ElIconLocation", Location);
        app.component("ElIconMenu", Menu);
        app.component("ElIconDocument", Document);
        app.component("ElIconRefresh", Refresh);
        app.component("ElIconCaretLeft", CaretLeft);
        app.component("ElIconCaretRight", CaretRight);
        app.component("ElIconDCaret", DCaret);
        app.component("ElIconSearch", Search);
        app.component("ElIconPlus", Plus);
        app.component("ElIconCreditCard", CreditCard);
        app.component("ElIconKey", Key);
        app.component("ElIconSwitchButton", SwitchButton);
        app.component("ElIconEdit", Edit);
        app.component("ElIconAvatar", Avatar);
        app.component("ElIconOfficeBuilding", OfficeBuilding);
        app.component("ElIconCheck", Check);
        app.component("ElIconShoppingTrolley", ShoppingTrolley);
        app.component("ElIconMessageBox", MessageBox);
        app.component("ElIconDataLine", DataLine);
        app.component("ElIconShoppingCart", ShoppingCart);
        app.component("ElIconClose", Close);
        app.component("ElIconDelete", Delete);
        app.component("ElIconInfoFilled", InfoFilled);
        app.component("ElIconUpload", Upload);
        app.component("ElIconWarningFilled",WarningFilled);
        app.component("ElIconDArrowRight",DArrowRight);
        app.component("ElIconDocumentAdd",DocumentAdd);
        app.component("ElIconCoin",Coin);
        app.component("ElIconPriceTag",PriceTag);
        app.component("ElIconReadingLamp",ReadingLamp);
        app.component("ElIconDataAnalysis",DataAnalysis);
        app.component("ElIconHomeFilled",HomeFilled);
        app.component("ElIconCaretBottom",CaretBottom);
        app.component("ElIconCaretTop",CaretTop);
        app.component("ElIconWarning",Warning);
        app.component("ElIconOdometer",Odometer);
        app.component("ElIconCompass",Compass);
        app.component("ElIconLink",Link);
        app.component("ElIconPostcard",Postcard);
        app.component("ElIconShoppingBag",ShoppingBag);
        app.component("ElIconBicycle",Bicycle);
        // 注册一个全局的$message方法
        app.config.globalProperties.$message = ElMessage;
    }
}
