<template>
  <!-- 导入数据 -->
  <el-dialog v-model="showDialog" :title="title" width="600px" :close-on-click-modal="false" @closed="closeDialog" append-to-body>
    <div>
      <el-upload drag :multiple="false" :auto-upload="false" :limit="1" :on-remove="removeUpload" :on-change="changeUpload" :file-list="fileList">
        <el-icon><el-icon-upload/></el-icon>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </div>
    <div style="text-align: right;padding-top: 10px;display: flex;justify-content: space-between">
      <div style="font-size: 12px;color: #999;line-height: 36px">
        温馨提示：请按照模板格式填写导入的数据
      </div>
      <div style="text-align: right">
        <page-button @click="showDialog = false">取消</page-button>
        <page-button @click="exportTemp" el-type="primary">下载模板</page-button>
        <page-button type="write" el-type="success" @click="importData">导入</page-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>

import PageButton from "@/components/h5/button/PageButton.vue";
import {watch} from "vue";

export default {
  emits: ["update:modelValue", "exportTemp"],
  props: {
    modelValue: {default: false},
    title: {default: "批量导入"},
    // 导入数据，导入数据需要一个异常函数
    importByExcel: {default: null},
  },
  name: "",
  components: {
    PageButton
  },
  data() {
    return {
      showDialog: false,
      fileList: [],
      file: ""
    };
  },
  created() {
    watch(() => this.modelValue, () => {
      this.showDialog = this.modelValue})
  },
  methods: {
    closeDialog() {
      this.$emit("update:modelValue", false)
    },
    removeUpload() {
      this.file = "";
    },
    changeUpload(file) {
      this.file = file.raw;
    },
    exportTemp() {
      this.$emit("exportTemp");
    },
    importData() {
      if (!this.file) {
        this.$message.error("请添加文件");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.file);
      if (this.importByExcel) {
        this.importByExcel(formData).then(() => {
          this.showDialog = false;
          this.$emit("update:modelValue", false)
        });
      }
    },
  }
};
</script>

<style lang="scss" scoped>

</style>