import axios from "@/util/axios";

export default {
    queryList: (data) => axios.get({url: "/order/material/ledger/queryList", data: data}),

    findLastUseDate: (code) => axios.get({url: "/order/material/ledger/findLastUseDate", data: {code}}),

    toGenerate: (data) => axios.post({url: "/order/material/ledger/generate", data: data}),

    exportData: (data) => axios.post({url: "/order/material/ledger/exportData", data: data}),
}