<template>
  <!--库存列表 -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary" @click="generateQrcode">生成二维码</page-button>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
      </page-header>
      <search-condition>
        <template #default>
          <el-input v-model="originalCode" placeholder="请输入溯源码并点击查询按钮" @keydown.enter="resetSearch"/>
        </template>
      </search-condition>
      <table-list>
        <el-table :data="list" height="100%" border>
          <el-table-column label="序号" width="60px" type="index" :align="'center'"/>
          <el-table-column label="商品名称" prop="skuName" show-overflow-tooltip/>
          <el-table-column label="机构名称" prop="merchantName" :align="'center'"/>
          <el-table-column label="仓库名称" prop="stockName" :align="'center'"/>
          <el-table-column label="变更类型" prop="" :align="'center'">
            <template v-slot="{row}">
              <span v-if="row.type === 1">入库</span>
              <span v-else-if="row.type === 2">出库</span>
              <span v-else-if="row.type === 3">调拨入库</span>
              <span v-else-if="row.type === 4">调拨出库</span>
              <span v-else-if="row.type === 5">库存盘点</span>
              <span v-else-if="row.type === 97">归还入库</span>
              <span v-else-if="row.type === 98">领用出库</span>
              <span v-else-if="row.type === 99">生成质保</span>
              <span v-else-if="row.type === 100">退货入库</span>
            </template>
          </el-table-column>
          <el-table-column label="变更时间" prop="changeTime" :align="'center'"/>

        </el-table>
      </table-list>
    </list-page>


    <el-dialog title="二维码生成" width="40%" v-model="showQrcode">

    </el-dialog>
  </div>

</template>

<script>
import ListPage from "@/components/h5/layout/ListPage.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";

import transProductApi from "@/api/alpine/trace/transProductApi";
import commonApi from "@/api/commonApi";
export default {
  name: "",
  components: {
    ListPage, TableList, SearchCondition, FormPage, PageButton, TableButton, PageHeader
  },
  data() {
    return {
      list: [],
      originalCode:"",
      originalType: "",
      showQrcode: false,
    };
  },
  created() {
    // 查询溯源码类型
    commonApi.findSetting("STOCK_ORIGINAL_TYPE").then(setting => this.originalType = setting.settingValue)
  },
  methods: {
    generateQrcode(){},
    resetSearch() {
      if (!this.originalCode) {
        this.$message.error("溯源码不能为空");
        return;
      }
      if (!this.originalType) {
        this.$message.error("溯源码类型配置异常");
        return;
      }
      transProductApi.queryHistory(this.originalCode, this.originalType).then(list => this.list = list);
    },
  }
};
</script>

<style lang="scss" scoped>

</style>