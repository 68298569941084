<template>
  <!-- -->
  <div class="defaultViewStyle">
    <page-header>
      <template #default>
        <page-button el-type="primary" @click="exportData">导出</page-button>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
      </template>
    </page-header>
    <search-condition @search="resetSearch">
      <template #default>
        <el-input v-model="code" placeholder="请扫描或输入维保码" clearable/>
      </template>
    </search-condition>
    <h4 style="margin: 5px 0">物资信息</h4>
    <div class="pro_content">
      <div class="left-content">
        <div>
          <el-form-item label="物资分类">
            <el-input v-model="dataDetail.categoryName" :disabled="true"/>
          </el-form-item>
          <el-form-item label="物资规格">
            <el-input v-model="dataDetail.skuStandard" :disabled="true"/>
          </el-form-item>
          <el-form-item label="物资状态">
            <el-input v-model="dataDetail.statusName" :disabled="true"/>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="物资编号">
            <el-input v-model="dataDetail.skuCode" :disabled="true"/>
          </el-form-item>
          <el-form-item label="物资型号">
            <el-input v-model="dataDetail.skuType" :disabled="true"/>
          </el-form-item>
          <el-form-item label="维保编码">
            <el-input v-model="dataDetail.originalCode" :disabled="true"/>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="物资名称">
            <el-input v-model="dataDetail.skuName" :disabled="true"/>
          </el-form-item>
          <el-form-item label="供&nbsp;&nbsp;应&nbsp;&nbsp;商">
            <el-input v-model="dataDetail.supplierName" :disabled="true"/>
          </el-form-item>
          <el-form-item label="品牌名称">
            <el-input v-model="dataDetail.bradeName" :disabled="true"/>
          </el-form-item>
        </div>
      </div>
    </div>
    <h4 style="margin: 20px 0">历程详情</h4>
    <div class="btm-info">
      <div class="left">
        <el-timeline style="max-width: 600px">
          <el-timeline-item
              v-for="item in dataList"
              :key="item.id"
              :timestamp="item.changeTime"
              placement="top"
              color="#409EFF"
              @click="infoClick(item)"
          >
            <span v-if="item.type === 1">入库</span>
            <span v-if="item.type === 2">出库</span>
            <span v-if="item.type === 97">归还入库</span>
            <span v-if="item.type === 98">领用出库</span>
            <span v-if="item.type === 93">报损出库</span>
            <span v-if="item.type === 91">日常巡检</span>
            <span v-if="item.type === 94">维修完成</span>
            <span v-if="item.type === 95">巡检报损</span>
            <span v-if="item.type === 96">维修</span>
            <span style="margin-left: 10px">经办人：{{ item.employeeName }}</span>
          </el-timeline-item>
        </el-timeline>
      </div>
      <div class="right">
        <el-card class="box">
          <template #header>
            历程摘要
          </template>
          <div class="detail">
            <div>
              <span>日期：</span>
              <span>{{ otherData.changeTime }}</span>
            </div>
            <div>
              <span>事件：</span>
              <span v-if="otherData.type === 1">入库</span>
              <span v-if="otherData.type === 2">出库</span>
              <span v-if="otherData.type === 97">归还入库</span>
              <span v-if="otherData.type === 98">领用出库</span>
              <span v-if="otherData.type === 93">报损出库</span>
              <span v-if="otherData.type === 91">正常巡检</span>
              <span v-if="otherData.type === 94">维修完成</span>
              <span v-if="otherData.type === 95">巡检报损</span>
              <span v-if="otherData.type === 96">维修</span>
            </div>
            <div>
              <span>经办人：</span>
              <span>{{ otherData.employeeName }}</span>
            </div>
            <div>
              <span>仓库：</span>
              <span>{{ infoData.stockName }}</span>
            </div>
            <div>
              <span>使用机构：</span>
              <span>{{ infoData.recipientDepartName }}</span>
            </div>
            <div>
              <span>使用人：</span>
              <span>{{ infoData.recipientName }}</span>
            </div>
            <div>
              <span>维保机构：</span>
              <span>{{ infoData.unitName }}</span>
            </div>
            <div>
              <span>维保人：</span>
              <span>{{ infoData.mtEmployeeName }}</span>
            </div>
            <div>
              <span>维保状态：</span>
              <span v-if="infoData.mtResult === 0 || otherData.type === 94">正常</span>
              <span v-else-if="infoData.mtResult === 1">维修</span>
              <span v-else-if="infoData.mtResult === 2">报废</span>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/h5/layout/PageHeader";
import SearchCondition from '@/components/h5/layout/SearchCondition.vue';
import mtProcessApi from "@/api/main/material/mtProcessApi";
import productApi from "@/api/main/product/productApi";
import stockInStorageApi from "@/api/main/stock/stockInStorageApi";
import mtRecordApi from "@/api/main/material/mtRecordApi";
import util from "@/util/util";

export default {
  name: "",
  components: {
    PageHeader, SearchCondition
  },
  data() {
    return {
      code: "",
      dataDetail: {},
      dataList: [],
      infoData: {},
      otherData: {},
    };
  },
  created() {
  },
  methods: {
    // 导出
    exportData(){
      if(!this.code){
        this.$message.error("维保码不能为空！");
        return;
      }
      util.export3Excel("/order/material/process/exportData", this.code);
    },
    resetSearch() {
      if (!this.code) {
        this.$message.error("维保码不能为空！");
        return;
      }
      mtProcessApi.queryByCode(this.code).then(list => {
        if(list.length === 0){
          this.$message.error("请输入正确的维保码！");
          this.dataList = [];
          this.dataDetail = {};
          this.infoData = {};
          this.otherData = {};
          return;
        }
        this.dataList = list;
        this.findSkuDetail();
      });
    },
    findSkuDetail() {
      productApi.findByMtInfo(this.dataList[0].skuId, "").then(data => {
        this.dataDetail = data;
        this.dataDetail.originalCode = this.code;
        // 查询物资状态
        mtProcessApi.findStatus(this.code).then(status => {
          if (status === "1") {
            this.dataDetail.statusName = "在库";
          } else if (status === "2") {
            this.dataDetail.statusName = "维修";
          } else if (status === "3") {
            this.dataDetail.statusName = "领用";
          } else if (status === "4") {
            this.dataDetail.statusName = "报损";
          }
        })
      })
    },
    infoClick(data) {
      this.otherData = data;
      if (data.type === 1 || data.type === 2 || data.type === 97 || data.type === 98 || data.type === 93) {
        // 查询出入库记录表
        stockInStorageApi.findByInOrderId(data.orderId).then(res => {
          this.infoData = {...res};
        });
      } else {
        mtRecordApi.queryList({searchKey: data.orderId}).then(res => {
          this.infoData = res.records[0];
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>

:deep(.condition){
  overflow: initial;
}

.wz-info {
  height: 500px;
  border: 1px solid #c5c4c4;
}

.pro_content {
  height: 132px;
  border: 1px solid #dbdada;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;

  .left-content {
    width: 100%;
    display: flex;
    //justify-content: space-between;
    div {
      width: 90%;

      :deep(.el-input) {
        width: 100%;
      }

      :deep(.el-form-item__label) {
        font-size: 14px;
        color: #606266;
        font-weight: bold;
      }
    }
  }

  .right-img {
    width: 15%;
    overflow: hidden;
    display: flex;
    justify-content: center;

    img {
      width: 184px;
      height: 184px;
    }
  }
}

.btm-info {
  display: flex;
  justify-content: space-between;
  overflow: scroll;
  height: 100%;

  .left {
    width: 50%;

  }

  .right {
    width: 50%;

    .box {
      margin: 0 auto;
      width: 400px;
      position: fixed;
    }
  }
}

.detail{
  >div{
    margin-top: 10px;
    span:nth-child(1){
      margin: 0 10px;
      font-size: 14px;
      color: #606266;
      font-weight: 600;
    }
  }
  >div:nth-child(1){
    margin-top: 0;
  }
}
</style>