<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
        <page-button el-type="success" type="write">结算</page-button>
      </page-header>
      <search-condition>
        <el-input v-model="searchInfo.searchKey" placeholder="请输入门店名称"/>
      </search-condition>
      <table-list>
        <el-table :data="dataList" height="100%" border>
          <el-table-column align="center" label="序号" width="60px" type="index"/>
          <el-table-column align="center" label="门店名称" prop="storeName"/>
          <el-table-column align="center" label="开始时间" prop="startTime">
            <template v-slot="{row}">
              {{ row.startTime.split(" ")[0] }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="结束时间" prop="endTime">
            <template v-slot="{row}">
              {{ row.endTime.split(" ")[0] }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="结算金额" prop="rebateAmount"/>
          <el-table-column align="center" label="结算状态">
            <template v-slot="{row}">
              <el-tag :type="row.status === 1 ? 'danger' : 'success'">
                {{row.status === 1 ? '未结算' : '已结算'}}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="销售金额" prop="totalAmount"/>
          <el-table-column label="操作" :align="'center'">
            <template v-slot="{row}">
              <table-button message="该结算金额不包含今日数据，您确认结算吗？" type="write" @click="settlement(row)">结算</table-button>
              <table-button type="read" @click="findDetail(row)">详情</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize" :total-count="searchInfo.totalCount" @onLoad="queryList"/>
    </list-page>

    <el-dialog title="销售详情" v-model="showSaleDetail" width="60%">
      <dialog-table>
        <el-table :data="orderList" height="100%" border>
          <el-table-column align="center" label="序号" type="index" width="60px"/>
          <el-table-column align="center" label="商品名称" min-width="200px" prop="productName" show-overflow-tooltip/>
          <el-table-column label="销售数量" property="qty" min-width="80px" :align="'center'"/>
          <el-table-column label="销售价格" property="skuSalePrice" width="120px" show-overflow-tooltip/>
          <el-table-column label="成本价格" property="skuBuyPrice" width="120px" show-overflow-tooltip/>
        </el-table>
      </dialog-table>
      <form-page v-model:page-size="orderSearchInfo.pageSize" v-model:page-number="orderSearchInfo.pageNumber"
                 :total-count="orderSearchInfo.totalCount" :default-load="false"></form-page>
    </el-dialog>
  </div>
</template>

<script>

import ListPage from "@/components/h5/layout/ListPage.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import rebateApi from "@/api/alpine/rebate/rebateApi";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import DateRange from "@/components/h5/date/DateRange.vue";

export default {
  name: "",
  components: {
    DateRange,
    SearchCondition, TableButton, PageButton, TableList, FormPage, PageHeader, ListPage, DialogTable
  },
  data() {
    return {
      // 查询条件
      searchInfo: {},
      // 数据列表
      dataList: [],
      // 展示销售详情
      showSaleDetail: false,
      orderSearchInfo: {},
      orderList: [],
    };
  },
  created() {
  },
  methods: {
    resetSearch(){
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList(){
      rebateApi.queryListForReport(this.searchInfo).then(data => {
        this.searchInfo.totalCount = data.totalCount;
        this.dataList = data.records;
      })
    },
    settlement(row){
      rebateApi.settlement(row.storeId, row.index).then(()=> {
        this.$message.success("结算成功");
        this.resetSearch();
      })
    },
    findDetail(row){
      this.orderSearchInfo.storeId = row.storeId;
      this.orderSearchInfo.index = row.index
      rebateApi.findDetail(this.orderSearchInfo).then(data => {
        this.orderSearchInfo.totalCount = data.totalCount;
        this.orderList = data.records;
        this.showSaleDetail = true;
      })
    },
  }
};
</script>

<style lang="scss" scoped>

</style>