<template>
  <!-- 软键盘,需要在使用时通过样式指定宽高 -->
  <div class="soft-key-bord">
    <div @click="sendData(item)" v-for="(item, index) in dataList" :key="index">{{ item }}</div>
    <div @click="deleteData">
      <el-icon><el-icon-close/></el-icon>
    </div>
  </div>
</template>

<script>

export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {default: ""},
    max: {default: 9999999}
  },
  name: "",
  components: {
  },
  data() {
    return {
      dataList: [1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0]
    };
  },
  created() {
  },
  methods: {
    // 软件键盘输入数据
    sendData(data) {
      let value = "";
      if(this.modelValue === 0){
        value = '' + data;
      }else{
        value = this.modelValue + '' + data;
      }
      // 超过最大数
      if (parseFloat(value) > this.max) {
        return;
      }
      this.$emit("update:modelValue", value);
    },
    // 删除数据
    deleteData() {
      if (!this.modelValue) {
        // 没有数据，不更新
        return;
      }
      if(this.modelValue.length === 1){
        this.$emit("update:modelValue", 0);
        return;
      }
      let value = this.modelValue + "";
      this.$emit("update:modelValue", value.substring(0, value.length - 1));
    }
  }
};
</script>

<style lang="scss" scoped>
.soft-key-bord{
  box-sizing: border-box;
  user-select: none;
  div{
    display: inline-block;
    width: 33%;
    border: 1px solid transparent;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
  }
  div{
    border-left-color: #dedede;
    border-top-color: #dedede;
  }
  div:nth-child(3n){
    border-right-color: #dedede;
  }
  div:nth-child(10),div:nth-child(11),div:nth-child(12){
    border-bottom-color: #dedede;
  }
}
</style>