<template>
  <!-- 页面数据较长时，进行合并显示,不能单独使用，需要结合longFormPanel，类似el-tabs -->
  <div :style="{overflow: 'hidden', height: height}">
    <div style="height: 45px;line-height: 45px;border-bottom: 1px solid #DCDFE6;margin-bottom: 10px;">
      <a class="title" :class="selected === idx + 1? 'on': ''" v-for="(item, idx) in panels" :key="idx" @click="selectData(idx)">{{item.label}}</a>
      <span style="float: right;">
        <slot name="append" />
        <!-- <el-button size="small" @click="trigger">{{showAll?'全部收起': '全部展开'}}</el-button> -->
      </span>
    </div>
    <div :id="selfId" style="height: calc(100% - 65px);overflow: auto;position: relative;">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "",
  components: {
  },
  data() {
    return {
      showAll: true,
      selected: 1,
      panels: [],
      // 最后一个id
      lastId: 0,
      selfId: "longFrom",
      height: "100%"
    };
  },
  created() {
    this.lastId = 0;
    this.selfId = "longForm" + new Date().getTime();
    // 等到页面渲染完成再添加事件
    this.$nextTick(() => {
      document.querySelector("#" + this.selfId).onscroll = ()=>{
        this.windowScroll();
      };
    });
  },
  mounted() {
    // 看是否有上下文其它组件
    // let brothers = this.$parent.$children;
    // let brotherCount = 0;
    // for (let i = 0; i < brothers.length; i++) {
    //   if (brothers[i]._uid !== this._uid) {
    //     // 在前面有组件
    //     brotherCount++;
    //   }
    // }
    // // 如果有就更改一下高度
    // if (brotherCount) {
    //   this.height = "calc(100% - 40px)";
    // } else {
    //   this.height = "100%";
    // }
  },
  methods: {
    removePanel(label){
      let idx = this.panels.findIndex(item => item.label === label);
      this.panels.splice(idx, 1);
    },
    selectData(idx) {
      this.selected = idx + 1;
      // 锚点跳转
      document.querySelector('#longFormPanel' + this.selected).scrollIntoView(true)
    },
    // 当页面滚动到xxx功能时，选中这个功能
    windowScroll() {
      // 只需要找到第一个top大于0的panel即可
      for (let i = 0; i < this.panels.length; i++) {
        let top = this.isInViewPortOfOne(document.querySelector("#longFormPanel" + (i+1)), document.querySelector("#" + this.selfId));
        if (top >= 0) {
          this.selected = (i + 1);
          break;
        }
      }
    },
    // 判断元素是否在可见区域,返回top值，即某元素相对父元素的位置
    isInViewPortOfOne (el, container) {
      let viewPortHeight = null;
      if (!container) {
        // 没有容器，取window
        // viewPortHeight 兼容所有浏览器写法
        viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      } else {
        viewPortHeight = container.innerHeight || container.clientHeight;
      }
      const offsetTop = el.offsetTop
      const scrollTop = container? container.scrollTop : document.documentElement.scrollTop;
      const top = offsetTop - scrollTop
      // 当一个元素，top小于0，即top在屏幕上方，且bottom在屏幕下方时，返回1
      if (top < 0 && top + el.offsetHeight > viewPortHeight) {
        return 1;
      }
      return top;
    },
    addPanel(label) {
      let data = {label: label, id: this.lastId + 1};
      this.panels.push(data);
      this.lastId++;
      return data.id;
    },
    updatePanelLabel(label, id) {
      // 找到对应的label，更新
      for (let i = 0; i < this.panels.length; i++) {
        if (this.panels[i].id === id) {
          this.panels[i].label = label;
        }
      }
    },
    // panel展开变更时
    triggerChild() {
      // 从1开始，0是el-button
      let status = null;
      // 是否全部相同
      let allTheSame = true;
      // 全部展开/收起
      for (let i = 1; i < this.$children.length; i++) {
        if (this.$children[i].switchStatus) {
          // 获得开关状态
          if (status === null) {
            status = this.$children[i].switchStatus();
          } else {
            if (status !== this.$children[i].switchStatus()) {
              // 状态不同
              allTheSame = false;
              break;
            }
          }
        }
      }
      if (allTheSame) {
        this.showAll = status;
      }
    },
    // 展开，收起
    // trigger() {
    //   this.showAll = !this.showAll;
    //   // 从1开始，0是el-button
    //   // 全部展开/收起
    //   for (let i = 1; i < this.$children.length; i++) {
    //     if (this.$children[i].switchOpen) {
    //       this.$children[i].switchOpen(this.showAll);
    //     }
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
.title{
  padding: 0 5px;
  cursor: pointer;
  margin-right: 10px;
  line-height: 34px;
  display: inline-block;
  border-radius: 4px;
}
.on{
  background: #f1f1f1;
  //color: #FFF;
}
</style>