import axios from '../../../util/axios'
export default {
    //查询论坛列表
    queryList:(data) => axios.get({url: "/alpine/reply/queryList",data:data}),
    //保存
    saveSubmit: (data) => axios.post({url: "/alpine/reply/saveSubmit", data: data}),
    //查看详情
    findById: (id) => axios.get({url: "/alpine/reply/findById", data: {id}}),
    //删除
    delete: (id) => axios.del({url: "/alpine/reply/delete", data: {id}}),
}