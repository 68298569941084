import axios from '../../../util/axios'
export default {
//     查询商店信息
    queryStoreList:() => axios.get({url: "/alpine/offer/queryStoreList"}),
//查询仓库信息
    queryAllStock: (data) => axios.get({url: "/alpine/offer/queryStockList", data: data}),
    // 查询商品库存中的sku列表，包涵溯源码
    queryStockProductsAndOriginal: (originalCode) => axios.get({url: "/alpine/offer/queryStockProductsAndOriginal", data: originalCode}),

    //保存
    saveSubmit: (data) => axios.post({url: "/alpine/offer/save", data: data}),
    //查询列表
    queryOfferList: (data) => axios.get({url: "/alpine/offer/queryList", data: data}),
    //删除
    deleteOffer: (id) => axios.del({url: "/alpine/offer/deleteOffer", data: {id}}),
//查询详情
    queryOfferDetail: (id) => axios.get({url: "/alpine/offer/findById", data: {id}}),

    //查询商户列表
    queryEmployeesByErpId:(data)=>axios.get({url:"/alpine/offer/queryEmployeesByErpId",data}),

    queryCategory:()=>axios.get({url:"/alpine/offer/queryCategory",})

}