<template>
  <!-- -->
  <div style="height: 100%">
    <separate-page>
      <template #category>
        <el-tree :data="departTree" :props="{children: 'children', label: 'departName'}" @node-click="clickDepart" empty-text="部门为空" node-key="id" default-expand-all :expand-on-click-node="false">
          <template #default="{node, data}">
            <span class="custom-tree-node">
              <span class="node-name">{{ data.departName }}</span>
              <span class="node-option">
                <table-button el-type="primary" @click="addNewDepart(data)"><el-icon><el-icon-plus/></el-icon></table-button>
                <table-button el-type="primary" @click="editDepartAndShow"><el-icon><el-icon-edit/></el-icon></table-button>
                <table-button el-type="danger" type="delete" class="btn" message="是否确认删除部门，删除部门将移除部门下的所有职员关系" @click="toDeleteDepart"><el-icon><el-icon-delete/></el-icon></table-button>
              </span>
            </span>
          </template>
        </el-tree>
      </template>
      <template #default>
        <list-page style="{border: none;box-shadow: none; padding: 0}">
          <search-condition @search="resetSearch" :show-search="true">
            <template #default>
              <el-input v-model="searchInfo.searchKey" placeholder="请输入职员名称/手机号"/>
            </template>
            <template #option>
<!--              <el-dropdown split-button type="primary" style="margin-right: 10px" @command="toEditDepart">-->
<!--                部门管理-->
<!--                <template #dropdown>-->
<!--                  <el-dropdown-menu>-->
<!--                    <el-dropdown-item command="addDepart">添加部门</el-dropdown-item>-->
<!--                    <el-dropdown-item :disabled="!currentDepart.id" command="editDepart">编辑部门</el-dropdown-item>-->
<!--                    <el-dropdown-item :disabled="!currentDepart.id" command="deleteDepart">删除部门</el-dropdown-item>-->
<!--                    <el-dropdown-item :disabled="!currentDepart.id" command="addEmployee">添加部门人员</el-dropdown-item>-->
<!--                  </el-dropdown-menu>-->
<!--                </template>-->
<!--              </el-dropdown>-->
              <page-button el-type="success" @click="toEditEmployee({})">添加职员</page-button>
            </template>
          </search-condition>
          <table-list>
            <el-table :data="employeeList" height="100%" border>
              <el-table-column align="center"  label="序号" type="index" width="60px"/>
              <el-table-column align="center"  label="职员名称" prop="employeeName"/>
              <el-table-column align="center"  label="职员电话" prop="employeePhone"/>
              <el-table-column align="center"  label="状态">
                <template v-slot="{row}">
                  <el-tag :type="row.status === 1 ? 'success' : 'danger'">
                    {{ row.status === 1 ? '在职' : '离职' }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column align="center"  label="操作" :align="'center'">
                <template v-slot="{row}">
                  <table-button @click="toEditEmployee(row)">编辑</table-button>
                  <table-button v-if="isAdmin" @click="superLogin(row)">登录</table-button>
                  <table-button el-type="danger" type="delete" message="是否确认删除数据" @click="toDeleteEmployee(row)">删除</table-button>
                </template>
              </el-table-column>
            </el-table>
          </table-list>
          <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize" :total-count="searchInfo.totalCount" @onLoad="queryList"/>
        </list-page>
      </template>
    </separate-page>
    <el-dialog v-model="showEditDepart" width="400px" title="编辑部门信息">
      <el-form label-width="100px">
        <el-form-item label="部门名称">
          <el-input v-model="editDepart.departName"/>
        </el-form-item>
        <el-form-item label="部门编码">
          <el-input v-model="editDepart.departCode" disabled/>
        </el-form-item>
        <el-form-item label="上级部门">
          <el-tree-select v-model="editDepart.parentId" :disabled="currentDepart.id && currentDepart.id === editDepart.id" :data="departTree"  check-strictly :render-after-expand="false" placeholder="请选择上级部门" :props="{children: 'children', label: 'departName'}" node-key="id"/>
        </el-form-item>
      </el-form>
      <div style="text-align: right">
        <page-button @click="showEditDepart = false">取消</page-button>
        <page-button @click="toSaveDepart" el-type="primary" type="write">保存</page-button>
      </div>
    </el-dialog>
    <el-dialog v-model="showEditEmployee" width="400px" title="编辑职员信息">
      <el-form label-width="100px">
        <el-form-item label="职员名称">
          <el-input v-model="editEmployee.employeeName"/>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="editEmployee.employeePhone"/>
        </el-form-item>
        <el-form-item label="登录密码">
          <el-input type="password" v-model="editEmployee.password" autocomplete="new-password"/>
        </el-form-item>
        <el-form-item label="职员状态">
          <el-select v-model="editEmployee.status">
            <el-option :value="1" label="在职"/>
            <el-option :value="2" label="离职"/>
          </el-select>
        </el-form-item>
        <el-form-item label="主部门">
          <el-tree-select v-model="editEmployee.mainDepartCode" :data="departTree"  check-strictly :render-after-expand="false" placeholder="请选择所在部门" :props="{children: 'children', label: 'departName'}" node-key="departCode" default-expand-all/>
        </el-form-item>
        <el-form-item label="角色">
          <el-select multiple v-model="selectedRoles" placeholder="请选择角色">
            <el-option v-for="r in roleList" :value="r.id" :label="r.roleName"/>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="text-align: right">
        <page-button @click="showEditEmployee = false">取消</page-button>
        <page-button el-type="primary" type="primary" @click="toSaveEmployee">保存</page-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import merchantApi from "@/api/saas-plat/merchant/merchantApi";
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import SeparatePage from "@/components/h5/layout/SeparatePage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";

import {ElMessageBox} from 'element-plus';

import util from "@/util/util";
import {useRoute, useRouter} from "vue-router";
export default {
  setup() {
    return {
      router: useRouter(),
      route: useRoute(),
    }
  },
  props: {
    id: {default: ""},
  },
  name: "",
  components: {
    TableButton,
    PageButton,
    ListPage, TableList, FormPage, SearchCondition, SeparatePage
  },
  data() {
    return {
      merchantId: "",
      searchInfo: {},
      employeeList: [],
      currentEmployee: {},
      editEmployee: {
        employeeName: "",
        employeePhone: "",
        password: "",
        mainDepartCode: "",
        status: 1,
      },
      showEditEmployee: false,
      departTree: [],
      currentDepart: {},
      editDepart: {
        departName: "",
        departCode: "",
      },
      showEditDepart: false,
      roleList: [],
      // 选中的角色
      selectedRoles: [],
      // 页面表格高度
      tableHeight: 300,
      // 是否是超级管理员
      isAdmin: true,
    };
  },
  created() {
    this.merchantId = this.$routerUtil.query.id;
    this.isAdmin = this.$routerUtil.query.type !== "client";
    this.searchInfo.merchantId = this.merchantId;
    // 查询机构部门
    this.queryDepartList();
    merchantApi.queryAllRoles(this.merchantId).then(list => this.roleList = list);
  },
  mounted() {
    // 获取高度
    let doc = document.querySelector(".defaultViewStyle");
    this.tableHeight = doc.clientHeight - 85;
  },
  methods: {
    // 保存职员
    toSaveEmployee() {
      let arr = [];
      this.selectedRoles.forEach(id => arr.push({id: id}))
      let data = {
        ...this.editEmployee,
        roleList: arr,
        merchantId: this.merchantId,
      }
      merchantApi.saveEmployee(data).then(() => {
        this.queryList();
        this.showEditEmployee = false;
      });
    },
    queryDepartList() {
      merchantApi.queryDepartList(this.merchantId).then(list => {
        this.departTree = [{
          departName: "全部",
          id: "",
          children: list
        }];
      });
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      merchantApi.queryEmployeeList(this.searchInfo).then(res => {
        this.employeeList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      });
    },
    clickDepart(data) {
      this.currentDepart = {...data};
      // 查询部门职员
      this.searchInfo.departCode = data.departCode;
      this.resetSearch();
    },
    addNewDepart(data) {
      this.editDepart = {merchantId: this.merchantId, parentId: data.id};
      this.showEditDepart = true;
    },
    editDepartAndShow() {
      this.editDepart = {...this.currentDepart, merchantId: this.merchantId};
      this.showEditDepart = true;
    },
    // 开始编辑部门
    toEditDepart(command) {
      if (command === "addDepart") {
        // 添加新的部门
        this.addNewDepart();
      } else if (command === "editDepart") {
        // 编辑选中的部门

      } else if (command === "deleteDepart") {
        // 删除部门
        ElMessageBox.confirm("是否确认删除部门，删除部门将移除部门下的所有职员关系", "提示", {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.toDeleteDepart();
        }).catch(() => {})
      } else if (command === "addEmployee") {
        // 向部门内添加职员

      }


    },
    // 保存部门信息
    toSaveDepart() {
      merchantApi.saveDepart(this.editDepart).then(() => {
        this.queryDepartList();
        this.showEditDepart = false;
      });
    },
    // 删除部门
    toDeleteDepart() {
      merchantApi.deleteDepart(this.currentDepart.id).then(() => this.queryDepartList());
    },
    // 开始打开编辑用户页面
    toEditEmployee(data) {
      this.editEmployee = {
        ...data,
        password: ""
      };
      if (this.editEmployee.id) {
        // 修改数据
        merchantApi.findEmployeeById(this.editEmployee.id, this.merchantId).then(employee => {
          this.editEmployee = {
            ...employee,
            password: "",
          }
          let arr = [];
          if (employee.roleList) {
            employee.roleList.forEach(r => arr.push(r.id));
          }
          this.selectedRoles = arr;
          this.showEditEmployee = true;
        });
      } else {
        this.selectedRoles = [];
        this.showEditEmployee = true;
      }

    },
    toDeleteEmployee(data) {
      merchantApi.deleteEmployee(data.id).then(() => this.queryList());
    },
    superLogin(data) {
      merchantApi.loginEmployeeByAdmin(data.id).then(user => {
        util.setUserInfo(user);
        // 清空缓存
        util.clearCacheData();
        this.$routerUtil.push({path: "/main"}, () => {
          // f5刷新页面
          this.router.go(0);
        });
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/tree.scss";
</style>