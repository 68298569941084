<template>
  <!-- -->
  <el-tag v-if="echoPayTypeId !== ''" tpye="primary">{{currentSelect}}</el-tag>
  <el-select v-else clearable filterable :disabled="disabled" v-model="payTypeId" :placeholder="placeholderTit" @change="changeData" style="width: 100%">
    <el-option v-for="(e, i) in payTypeList" :label="e.payName" :value="e.id" :key="i"/>
  </el-select>
</template>

<script>
import {watch} from "vue";
import systemPayApi from "@/api/main/sys/systemPayApi";
export default {
  emits: ["update:modelValue", "selectOne"],
  props: {
    modelValue: {default: ""},  // 默认聚合支付
    placeholderTit: {default: "请选择支付方式"},
    echoPayTypeId: {default: ""},
    disabled: {default: false},
    echoPayTypeList: {default: []},
    defaultPay: {default: false},
  },
  components: {
  },
  data() {
    return {
      payTypeId: "",
      payTypeList: [],
      currentSelect: "",
    };
  },
  created() {
    if(this.echoPayTypeId !== ""){
      this.echoInfo();
    }else{
      this.init();
    }
    watch(() => this.modelValue, () => this.init());
    if(this.defaultPay && this.modelValue === ""){
      this.payTypeId = "20240515191223949211";
      this.$emit("update:modelValue", "20240515191223949211");
    }
  },
  methods: {
    echoInfo(){
      if(this.echoPayTypeList.length > 0){
        for (let i = 0; i < this.echoPayTypeList.length; i++) {
          if(this.echoPayTypeId === this.echoPayTypeList[i].id){
            this.currentSelect = this.echoPayTypeList[i].payName;
            return;
          }
        }
      }else{
        systemPayApi.queryAllList().then(list => {
          for (let i = 0; i < list.length; i++) {
            if(this.echoPayTypeId === list[i].id){
              this.currentSelect = list[i].payName;
              break;
            }
          }
        });
      }
    },
    init() {
      if (!this.payTypeList || this.payTypeList.length === 0) {
        systemPayApi.queryAllList().then(list => {
          this.payTypeList = list;
        });
      }
      this.payTypeId = this.modelValue;
    },
    changeData() {
      let result = false;
      for (let i = 0; i < this.payTypeList.length; i++) {
        if (this.payTypeList[i].id === this.payTypeId) {
          result = true
          this.$emit("update:modelValue", this.payTypeList[i].id);
          this.$emit("selectOne", this.payTypeList[i]);
          break;
        }
      }
      if(!result){
        this.$emit("update:modelValue", "");
      }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>