<template>
  <!-- 通用模板，列表数据 -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
        <page-button el-type="success" type="write" @click="showAdd = true">新建</page-button>
      </page-header>
      <search-condition >
        <el-input v-model="searchInfo.searchKey" placeholder="请输入内容"/>
      </search-condition>
      <table-list>
        <el-table :data="dataList" height="100%" border>
          <el-table-column align="center" label="序号" width="60px" type="index"/>
          <el-table-column align="center" label="标题" min-width="120px" prop="title" show-overflow-tooltip />
          <el-table-column label="内容" min-width="180px" prop="content" show-overflow-tooltip />
          <el-table-column align="center" label="首页显示" min-width="120px">
            <template v-slot="{row}">
              {{ row.showHome === 1 ? '开启' : '关闭'}}
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'" width="120px">
            <template v-slot="{row}">
              <table-button type="write" @click="editRow(row)">编辑</table-button>
              <table-button message="是否确认删除此数据" type="delete" el-type="danger" @click="deleteRow(row.id)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>



    <el-dialog title="新建" v-model="showAdd" width="40%" @closed="dataDetail = {}">
      <el-form label-width="70px">
        <el-form-item label="公告标题">
          <el-input type="text" v-model="dataDetail.title"/>
        </el-form-item>
        <el-form-item label="公告内容">
          <el-input type="textarea" v-model="dataDetail.content" :rows="5"/>
        </el-form-item>
        <el-form-item label="首页显示">
          <el-select v-model="dataDetail.showHome" placeholder="是否在首页显示">
            <el-option :value="1" label="开启" />
            <el-option :value="0" label="关闭" />
          </el-select>
        </el-form-item>
      </el-form>
      <div style="margin-top: 15px;display: flex;justify-content: flex-end">
        <page-button el-type="info" @click="showAdd = false">取消</page-button>
        <page-button el-type="primary" @click="save">保存</page-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import miniNoticeApi from "@/api/main/mini/miniNoticeApi";
export default {
  name: "",
  components: {
    TableButton, PageButton, ListPage, TableList, FormPage, SearchCondition, PageHeader
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      showAdd: false,
      dataDetail: {},
    };
  },
  created() {
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    // 查询列表
    queryList() {
      miniNoticeApi.queryList(this.searchInfo).then(res => {
        this.dataList = res.records;
        this.searchInfo.totalCount = res.totalCount;
      })
    },
    // 保存提交
    save(){
      miniNoticeApi.saveSubmit(this.dataDetail).then(() => {
        this.resetSearch();
        this.showAdd = false;
      });
    },
    deleteRow(id){
      miniNoticeApi.delete(id).then(() => this.queryList());
    },
    editRow(data){
      this.dataDetail = data;
      this.showAdd = true;
    }
  }
};
</script>

<style lang="scss" scoped>

</style>