import axios from "../../../util/axios";

export default {
    /**查询列表  */
    queryList: (data) => axios.post({url: "/order/stock/stock/queryList", data: data}),
    /**删除  */
    delete: (id) => axios.del({url: "/order/stock/stock/delete", data: {id}}),
    /**保存  */
    save: (data) => axios.post({url: "/order/stock/stock/save", data: data}),
    /**查询部门  */
    queryDepartListTree: () => axios.get({url: `/order/stock/stock/queryDepartListTree`}),
    /**无条件查询所有列表  */
    queryListByNotCondition: ()=> axios.get({url: "/order/stock/stock/queryListByNotCondition"}),
    // 根据部门查询仓库
    findByDepartId: (departId) => axios.get({url: "/order/stock/stock/findByDepartId", data: {departId}}),
    // 删除
    deleteStorage: (id) => axios.get({url: "/order/stock/stock/deleteStorage", data: {id}}),
    saveStorage: (data) => axios.post({url: "/order/stock/stock/saveStorage", data: data}),
    queryStorage: (stockId) => axios.get({url: "/order/stock/stock/queryStorage", data: {stockId}}),
}