<template>
  <!-- -->
  <div class="">
    <page-button class="pg-btn" style="margin: 0 auto;" @click="showControl = true">{{ title }}</page-button>
    <el-dialog width="50%" title="图片管理" v-model="showControl" @opened="openBefore" @close="$emit('beforeClose')">
      <search-condition @dragover.prevent @drop="updateBatch($event,-1)">
        <template #default>
          <div style="width: 240px;display: inline-block">
            <table-button>{{ topTitle.join("/") }}</table-button>
          </div>
        </template>
        <template #option>
          <page-button @click="returnSuperior">返回上级</page-button>
          <page-button el-type="danger" @click="deleteBatch">批量删除</page-button>
          <page-button el-type="warning" @click="editBatch">批量修改</page-button>
          <page-button el-type="success" @click="showFolder = true">添加文件夹</page-button>
          <page-button el-type="success" @click="showPic = true">上传图片</page-button>
          <page-button el-type="primary" @click="resetSearch">查询</page-button>
        </template>
      </search-condition>
      <dialog-table>
        <div class="img-control" ref="imgControl" :style="{marginLeft: left+'px'}">
          <div v-for="(item,idx) in dataList" :key="idx">
            <div ref="imgBanner" v-if="item.type === 1" class="img-banner" @dragover.prevent @drop="updateBatch($event,item)"
                 @dblclick="doubleClick(item)">
              <img src="../../../assets/image/folder.png"/>
              <span v-show="!item.isEditName" @click="editName(idx)">{{ item.name }}</span>
              <el-input ref='input' v-show="item.isEditName" @blur="saveName(item.name,idx)" v-model="item.name"/>
            </div>

            <div v-if="item.type === 2" class="img-banner" style="position: relative;">

              <div @click="selectImg(item,idx)" draggable="true" @dragstart="handleDragStart($event, item)">
                <img :src="item.imageUrl"/>
              </div>

              <img style="position: absolute;width: 40px;height: 40px;right: 12px;top: -13px;" v-if="item.isSelected"
                   src="../../../assets/image/pro-selected.png"/>

              <span v-show="!item.isEditName" @click="editName(idx)">{{ item.name }}</span>
              <el-input :ref="'input'+idx" v-show="item.isEditName" @blur="saveName(item.name,idx)" v-model="item.name"/>
            </div>
          </div>
        </div>

      </dialog-table>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize"
                 :total-count="searchInfo.totalCount" @onLoad="queryList" :default-load="true">
        <template #default>
          <page-button @click="showControl = false">取消</page-button>
          <page-button el-type="primary" @click="selected">选中</page-button>
        </template>
      </form-page>
      <!--      <el-tree-select v-model="editDepart.parentId" :disabled="editDepart.id" :data="departTree"  check-strictly :render-after-expand="false" placeholder="请选择上级部门" :props="{children: 'children', label: 'departName'}" node-key="id"/>-->
      <el-dialog title="添加文件夹" v-model="showFolder" width="30%" @opened="queryFolderTree">
        <el-form>
          <el-form-item label="文件夹名称">
            <el-input v-model="folderInfo.name" placeholder="请输入文件夹名称" clearable/>
          </el-form-item>
          <el-form-item label="上级文件夹">
            <el-tree-select v-model="folderInfo.parentId" :disabled="folderTree.id" :data="folderTree" check-strictly
                            :render-after-expand="false" placeholder="请选择上级文件夹"
                            :props="{children: 'children', label: 'name'}" node-key="id"/>
          </el-form-item>
        </el-form>
        <div style="display: flex; justify-content: flex-end">
          <page-button el-type="info" @click="showFolder = false">取消</page-button>
          <page-button el-type="primary" @click="saveFolder">保存</page-button>
        </div>
      </el-dialog>

      <el-dialog title="添加图片" v-model="showPic" width="30%" @close="closeBefore" @opened="queryFolderTree">
        <el-form>
          <el-form-item v-if="showPicText" label="图片名称">
            <el-input v-model="picInfo.name" placeholder="请输入图片名称" clearable/>
          </el-form-item>
          <el-form-item label="上级目录">
            <el-tree-select v-model="picInfo.parentId" :disabled="folderTree.id" :data="folderTree" check-strictly
                            :render-after-expand="false" placeholder="请选择上级文件夹"
                            :props="{children: 'children', label: 'name'}" node-key="id"/>
          </el-form-item>
          <el-form-item v-if="showPicAdd" label="图片添加">
            <image-upload class="show-image" :limit="1" v-model="picInfo.imageUrl"/>
          </el-form-item>
        </el-form>
        <div style="display: flex;justify-content: flex-end">
          <page-button el-type="info" @click="showPic = false">取消</page-button>
          <page-button el-type="primary" @click="savePic">保存</page-button>
        </div>
      </el-dialog>
    </el-dialog>

  </div>
</template>

<script>
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import imageControlApi from "@/api/main/imageControl/imageControlApi";
import Dictionary from "@/components/h5/Dictionary.vue";
import imageUpload from "@/components/h5/image/ImageUpload.vue";
import {ElMessageBox} from "element-plus";
import {watch} from "vue";

export default {
  emits: ["selected","beforeClose"],
  props: {
    title: {
      type: String,
      default: '添加图片'
    },
    showDefault: {
      type: Boolean,
      default: false
    },
  },
  name: "ImageControl",
  components: {
    Dictionary,
    FormPage,
    PageButton, DialogTable, SearchCondition, TableButton, imageUpload,
  },
  data() {
    return {
      searchInfo: {},
      showControl: false,
      dataList: [],
      folderTree: [],
      // 文件夹信息
      folderInfo: {
        parentId: 0,
      },
      // 图片信息
      picInfo: {},
      showPic: false,
      showPicAdd: true,
      showPicText: true,
      showFolder: false,
      pageMap: new Map(),
      // 当前文件夹
      currentFolder: {
        name: '全部'
      },
      // 头部
      topTitle: ['全部'],
      // 选中的图片
      selectedList: [],
      // 左边距
      left: "",
    };
  },
  created() {
    watch(()=>this.showDefault, (nv, ov) => {
      if(nv){
        this.showControl = true;
      }
    })
  },
  mounted() {

  },
  methods: {
    editName(idx) {
      this.dataList[idx].isEditName = true;
      this.$refs['input'+idx][0].focus();
    },
    saveName(name, idx) {
      this.dataList[idx].isEditName = false;
      this.dataList[idx].name = name;
      this.showPicAdd = false;
      this.picInfo = this.dataList[idx];
      this.savePic();
    },
    // 开始拖动
    handleDragStart(event, item) {
      event.dataTransfer.setData('text/plain', JSON.stringify(item)); // 传递数据
    },
    // 选择图片
    selectImg(item, idx) {
      let isSelected = this.dataList[idx].isSelected
      if (isSelected) {
        // 取消选中
        this.dataList[idx].isSelected = false;
        this.selectedList.splice(this.selectedList.indexOf(item), 1);
      } else {
        // 选中
        this.dataList[idx].isSelected = true;
        this.selectedList.push(item);
      }
    },
    // 返回上级
    async returnSuperior() {
      if (this.topTitle.length === 1) {
        this.$message.warning("已经是最后一级了");
        return;
      }
      this.topTitle.pop();
      this.searchInfo.parentId = this.currentFolder.parentId;
      this.resetSearch();
    },
    // 双击文件夹
    doubleClick(item) {
      this.topTitle.push(item.name);
      this.currentFolder = item;
      this.searchInfo.parentId = item.id;
      this.resetSearch();
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    // 查询图片列表
    queryList() {
      if (!this.searchInfo.parentId || this.searchInfo.parentId === '') {
        this.searchInfo.parentId = '0';
      }
      imageControlApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
        // 处理图片路径
        for (let i = 0; i < this.dataList.length; i++) {
          if (this.dataList[i].type === 2) {
            this.dataList[i].imageUrl = this.$getImageUrl(this.dataList[i].imageUrl);
          }
          // 判断是否选中
          if (this.compareAndSelected(this.dataList[i])) {
            this.dataList[i].isSelected = true;
          } else {
            this.dataList[i].isSelected = false;
          }
          this.dataList[i].isEditName = false;
        }
        this.$nextTick(()=>{
          // 计算宽度
          let parentWidth = this.$refs.imgControl.clientWidth;
          let innerWidth = this.$refs.imgBanner[0].clientWidth;
          this.left = (parentWidth % innerWidth) / 2;
        })
      })
    },
    compareAndSelected(data) {
      return this.selectedList.findIndex(item => item.id === data.id) >= 0;
    },
    // 查询树结构
    queryFolderTree() {
      imageControlApi.queryByTree().then(list => {
        this.folderTree = [{name: "全部", id: 0, children: list}];
      })
    },
    openBefore() {
      this.queryFolderTree();
      this.queryList();
    },
    // 保存文件夹
    saveFolder() {
      imageControlApi.createFolder(this.folderInfo).then(() => {
        this.$message.success("创建成功");
        this.queryFolderTree();
        this.showFolder = false;
      });
    },
    // 保存图片
    savePic() {
      if (!this.showPicAdd && !this.showPicText) {
        let ids = [];
        for (let i = 0; i < this.selectedList.length; i++) {
          ids.push(this.selectedList[i].id);
        }
        imageControlApi.updateBatch({ids: ids, parentId: target.id}).then(() => {
          this.$message.success("修改成功");
          this.queryList();
          this.queryList();
        });
      } else if (!this.showPicAdd) {
        // 修改单个
        imageControlApi.updateSingle(this.picInfo).then(() => {
          this.queryList();
          this.showPic = false;
        });
      } else {
        this.picInfo.imageUrl = JSON.parse(this.picInfo.imageUrl)[0].url;
        imageControlApi.savePic(this.picInfo).then(() => {
          this.queryList();
          this.showPic = false;
        });
      }
      this.showPicAdd = true;
      this.showPicText = true;
    },
    // 批量修改
    updateBatch(event, target) {
      const data = JSON.parse(event.dataTransfer.getData('text/plain'));
      // 判断
      let ids = [];
      if (this.selectedList.length === 0) {
        ids.push(data.id);
      } else {
        for (let i = 0; i < this.selectedList.length; i++) {
          ids.push(this.selectedList[i].id);
        }
      }
      let parentId = '';
      if (target === -1) {
        parentId = this.currentFolder.parentId;
      } else {
        parentId = target.id;
      }
      imageControlApi.updateBatch({ids: ids, parentId: parentId}).then(() => {
        this.$message.success("修改成功");
        this.queryList();
      });
    },
    // 批量删除
    deleteBatch(row) {
      ElMessageBox.confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = [];
        if (row) {
          ids.push(row.id);
        } else {
          for (let i = 0; i < this.selectedList.length; i++) {
            ids.push(this.selectedList[i].id);
          }
        }
        imageControlApi.delete(ids).then(() => {
          this.queryList();
        });
      })

    },
    // dialog关闭
    closeBefore() {
      this.pageMap.clear();
      this.picInfo = {};
      this.showPicAdd = true;
      this.showPicText = true;
    },
    // 修改图片
    editPic(row) {
      this.picInfo = row;
      // this.picInfo.parentId = Number(row.parentId);
      this.showPicAdd = false;
      this.showPic = true;
    },
    // 批量修改
    editBatch() {
      this.showPicAdd = false;
      this.showPicText = false;
      this.showPic = true;
    },
    // 选中
    selected() {
      //将map转为数组
      let arr = [];
      for (let i = 0; i < this.selectedList.length; i++) {
        arr.push(this.selectedList[i].imageUrl);
      }
      if (arr.length === 0) {
        this.$message.error("图片数据不能为空");
        return;
      }
      this.$emit("selected", arr);
      this.selectedList = [];
      this.showControl = false;
    },
  }
}
;
</script>

<style lang="scss" scoped>

.image-upload {

  :deep(.el-upload-list__item-delete) {
    display: none !important;
  }

  :deep(.el-upload-list__item-status-label) {
    display: none !important;
  }

}

.show-image {
  :deep(.el-upload-list__item-delete) {
    display: block !important;
  }

  :deep(.el-upload-list__item-status-label) {
    display: flex !important;
  }
}


.pg-btn {
  width: 100%;

  :deep(.el-button) {
    background-color: #f8f6f6;
    width: 100%;
    letter-spacing: 4px;
    font-weight: 700;
    font-size: 14px;
  }
}

.img-control {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .img-banner {
    width: 150px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
    box-sizing: border-box;

    img {
      width: 100px;
      height: 100px;
      margin: 0 auto;
    }

    span {
      text-align: center;
      display: block;
      /* justify-content: center; */
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

</style>