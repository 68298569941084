import {ElLoading} from "element-plus";
import AdvanceApi from "@/api/main/advance/advanceApi";
import advanceApi from "@/api/main/advance/advanceApi";
import memberApi from "@/api/main/customer/memberApi";
import GjpLoaclPrint from "@/util/GjpLoaclPrint";
import commonApi from "@/api/commonApi";
import systemPayApi from "@/api/main/sys/systemPayApi";

export default {
    data() {
        return {
            isRefreshPay: false,
            showAdvance: false,
            isPaying2: false,
            isPay: false,
            dataDetail: {
                amountPay: 0,
                surplusPayType: 1,
                status: -1,
                surplusPay: 2,
                refundAmount: null,
                paymentType: 4, // 押金
            },
            fullscreenLoadingAdvance: false,
            dictionaryList: [],
            employeeList: [],
            departList: [],
            payType: [],
        }
    },
    created() {
        systemPayApi.queryAllList().then(res => this.payType = res);
        //获取职员
        commonApi.queryAllEmployee().then(list => this.employeeList = list)
        //获取字典列表
        commonApi.queryDictionaryList("DEPOSIT_TYPE").then(list => {
            this.dictionaryList = list;
        });
        //获取部门列表
        commonApi.queryDepartList().then(list => this.departList = list);
    },
    watch: {
        'dataDetail.payType'(val) {
            this.$nextTick(() => {
                this.$refs.inputRef.$el.querySelector('input').focus();
            });
        }
    },
    methods: {
        successResultAdvance() {
            this.disableToClickPay = false;
            this.isPaying = false;
            this.preparePay = false;
            this.showAdvance = false;
        },
        openBefore() {
            this.$nextTick(() => {
                this.$refs.inputRef.$el.querySelector('input').focus();
            });
        },
        changeAdvancePay(data) {
            this.dataDetail.payTypeId = data.id;
            this.dataDetail.payType = data.payCode;
        },
        findAdvanceById(id) {
            return AdvanceApi.findById(id).then(res => {
                this.dataDetail = res;
            })
        },
        pay() {
            // 赋值
            let myCount = 60;
            this.myTimer = setInterval(() => {
                if (myCount > 0) {
                    myCount--;
                    this.loading.text = `获取支付结果中，${myCount}秒后自动更新...`;
                } else {
                    clearInterval(this.myTimer);
                    clearTimeout(this.timerToPayResult);
                    this.loading.close();
                }
            }, 1000)
            this.loading = ElLoading.service({
                lock: true,
                text: `获取支付结果中，${myCount}秒后自动更新...`,
                background: 'rgba(0, 0, 0, 0.7)',
            })

            this.isActual = true;
            this.disableToClickPay = true;
            this.preparePay = true;
            this.isPaying = true;

            AdvanceApi.saveSubmit(this.dataDetail).then((res) => {

                this.findAdvanceById(res.id).then(() => {
                    if (this.isAutoPrint && this.dataDetail.payStatus >= 50) {
                        try {
                            this.toAdvancePrint();
                        } catch (e) {
                            console.error(e);
                        }
                        this.loading.close();
                    }
                    if (this.dataDetail.status === 99 || this.dataDetail.payStatus >= 50) {
                        this.successResultAdvance();
                        this.$message.success("支付成功");
                        this.loading.close();
                    } else if (this.dataDetail.payStatus === 20) {
                        // this.$message.warning("支付中，请稍后刷新支付状态！！！")
                        this.toFindAdvanceStatus();
                    }
                });
            }).catch(() => {
                this.loading.close();
                this.preparePay = false;
                this.disableToClickPay = false;
                this.isPaying = false;
            })
        },
        // 打印
        async toAdvancePrint() {
            // 组装打印数据
            const customerInfo = await memberApi.findById(this.dataDetail.customerId);
            let data = this.getPrintDataAdvance("print", customerInfo.customerName, customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/, '$1****$2'));
            GjpLoaclPrint.Print(data);
        },
        // 拼接打印数据
        getPrintDataAdvance(type, customerName, customerPhone) {
            let data = {
                // reportName: "押金订单",
                amount: this.dataDetail.amountPay,
                refundAmount: this.dataDetail.refundAmount,
                customerName: customerName,
                customerPhone: customerPhone,
                // orderTime: this.dataDetail.orderTime,
                employeeName: this.dataDetail.employeeName,
                status: this.dataDetail.status !== 99 ? '草稿' : '已支付',
                // depositType: this.dataDetail.depositType,
                excuteType: type,
            }
            //判断当前需要打印的类型
            if (type === "design") {
                if (this.setPrintSelect === 1) {
                    data.reportName = "押金收款";
                } else if (this.setPrintSelect === 2) {
                    data.reportName = "押金退款";
                }
            } else if (type === "print") {
                if (this.dataDetail.surplusPayType === 1) {
                    data.reportName = "押金收款";
                    data.amountActual = this.dataDetail.amountPay;
                } else if (this.dataDetail.surplusPayType === 2) {
                    data.reportName = "押金退款";
                    data.refundActual = this.dataDetail.refundAmount;
                }
            }
            //操作人员
            for (let i = 0; i < this.employeeList.length; i++) {
                if (this.dataDetail.createdId === this.employeeList[i].id) {
                    data.createdName = this.employeeList[i].employeeName
                }
            }
            // 订单编号
            if (this.dataDetail.id) {
                data.orderNumber = this.dataDetail.id;
            }
            // 支付时间
            if (this.dataDetail.payTime) {
                data.orderTime = this.dataDetail.payTime;
            } else {
                data.orderTime = "暂未支付";
            }
            //科室名称
            for (let i = 0; i < this.departList.length; i++) {
                if (this.dataDetail.departCode === this.departList[i].departCode) {
                    data.departName = this.departList[i].departName;
                }
            }
            //职员名称
            for (let i = 0; i < this.employeeList.length; i++) {
                if (this.dataDetail.employeeId === this.employeeList[i].id) {
                    data.employeeName = this.employeeList[i].employeeName
                }
            }
            //押金类型
            for (let i = 0; i < this.dictionaryList.length; i++) {
                if (this.dataDetail.depositType === this.dictionaryList[i].dictCode) {
                    data.depositType = this.dictionaryList[i].dictName;
                }
            }
            // 支付状态
            if (this.dataDetail.payStatus === 0 || !this.dataDetail.payStatus) {
                data.payStatus = "未支付";
            } else if (this.dataDetail.payStatus === 99) {
                data.payStatus = "已支付";
            } else if (this.dataDetail.payStatus === 30) {
                data.payStatus = "支付失败";
            }
            // 支付方式
            for (let i = 0; i < this.payTypeList.length; i++) {
                if (this.payTypeList[i].id === this.dataDetail.payTypeId) {
                    data.payType = this.payTypeList[i].payName;
                }
            }
            data.productList = [];
            return data;
        },

        toFindAdvanceStatus() {
            if (this.isRefreshPay) this.$message.warning("正在获取最新状态，请耐心等待！！！");
            this.isRefreshPay = true;
            AdvanceApi.findSurplusStatus(this.dataDetail.payId).then(res => {
                this.findAdvanceById(this.dataDetail.id).then(() => {
                    if (this.dataDetail.payStatus >= 50) {
                        this.successResultAdvance();
                        if (this.isAutoPrint) this.toAdvancePrint();
                        this.$message.success(res);
                        clearInterval(this.timerToPayResult);
                        this.loading.close();
                        return;
                    }
                    if (this.dataDetail.payStatus === 30) {
                        this.successResultAdvance();
                        this.$message.error(res);
                        clearInterval(this.timerToPayResult);
                        this.loading.close();
                        return;
                    }
                })
                if (res === "订单状态更新失败") {
                    this.$message.error(res);
                    clearInterval(this.timerToPayResult);
                    this.loading.close();
                    return;
                } else if (res !== "支付成功,订单状态已刷新" && res !== "支付失败,请重试") {
                    // this.$message.warning("正在努力获取结果，请耐心等待！！！");
                }
                this.isRefreshPay = false;
                clearTimeout(this.timerToPayResult);
                this.timerToPayResult = setTimeout(() => {
                    this.toFindPayStatus();
                }, 2000);
            }).catch(() => {
                this.isRefreshPay = false;
                this.loading.close();
            })
        },

        refund() {
            if(!this.dataDetail.originalId){
                this.disableToClickPay = true;
                this.isActual = true;
                this.dataDetail.originalId = this.dataDetail.id;
                this.dataDetail.id = null;
                this.dataDetail.status = 0;
                this.dataDetail.surplusPayType = 2;
                this.dataDetail.surplusPay = 2;
                this.dataDetail.payStatus = 0;
                this.preparePay = true;
                AdvanceApi.saveDraft(this.dataDetail).then(res => {
                    this.findAdvanceById(res.id).then(() => {
                        this.dataDetail.surplusPayType = 2; //为退款
                        AdvanceApi.saveSubmit(this.dataDetail).then((res) => {
                            this.findAdvanceById(res.id).then(() => {
                                if (this.isAutoPrint) {
                                    this.toAdvancePrint();
                                }
                            });
                            this.showAdvance = false;
                            this.preparePay = false;
                            this.$message.success("退款成功");
                        }).catch(() => {
                            this.preparePay = false;
                        }).finally(() => {
                            this.disableToClickPay = false;
                        });
                    })
                }).finally(() => this.disableToClickPay = false);
            }else{
                AdvanceApi.saveSubmit(this.dataDetail).then((res) => {
                    this.findById(res.id).then(()=>{
                        if(this.isAutoPrint){
                            this.toPrint();
                        }
                        this.$message.success("退款成功");
                    });
                    this.showAdvance = false;
                    this.preparePay = false;
                }).catch(()=>{
                    this.preparePay = false;
                }).finally(() => {
                    this.disableToClickPay = false;
                });
            }

        }
    }
}
