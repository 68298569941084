import axios from "../../../util/axios";

export default {
    // 单独订单明细表
    queryOrderDetailInfo: (searchInfo) => axios.get({url: "/order/saleOrder/statistic/queryOrderDetailInfo", data: searchInfo}),
    // 查询订单明细列表
    queryOrderDetailList: (searchInfo) => axios.get({url: "/order/saleOrder/statistic/queryOrderDetailList", data: searchInfo}),
    // 查询统计结果汇总表
    queryCountList: (searchInfo) => axios.get({url: "/order/saleOrder/statistic/queryCountList", data: searchInfo}),
    // 查询统计结果统计
    queryCountStatistic: (searchInfo) => axios.get({url: "/order/saleOrder/statistic/queryCountStatistic", data: searchInfo}),
    // 查询统计结果汇总表
    queryStockList: (bankupId) => axios.get({url: "/order/saleOrder/statistic/queryStocks", data: {bankupId}}),
    // 查询账户余额信息
    queryAccountList: (searchInfo) => axios.get({url: "/order/saleOrder/statistic/queryAccountList", data: searchInfo}),
    // 查询账户余额详情信息
    queryAccountDetailList: (searchInfo)=> axios.get({url: "/order/saleOrder/statistic/queryAccountDetailList", data: searchInfo}),
    // 重新加载上期余额
    findBalanceByPayType: (searchInfo)=> axios.get({url: "/order/saleOrder/statistic/findBalanceByPayType", data: searchInfo}),
    // 查询日结报表信息
    queryDailyList: (searchInfo) => axios.get({url: "/order/saleOrder/statistic/queryDailyList", data: searchInfo}),
    // 查询押金详情
    querySurplusList: (searchInfo) => axios.get({url: "/order/saleOrder/statistic/querySurplusList", data: searchInfo}),
    // 库存列表
    queryStockInfo: (data)=> axios.get({url: `/order/saleOrder/statistic/queryStockInfo`, data: data}),
    // 订单列表
    querySaleStatisticInfo: (data)=> axios.post({url: `/order/saleOrder/statistic/querySaleStatisticInfo`, data: data}),
    // 订单统计
    queryOrderDetailStatistic: (data)=> axios.get({url: `/order/saleOrder/statistic/queryOrderDetailStatistic`, data: data}),
    // 押金列表
    querySurplusInfo: (data)=> axios.get({url: `/order/saleOrder/statistic/querySurplusInfo`, data: data}),
}