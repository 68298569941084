<template>
  <!-- -->
  <div class="client-list">
    <span v-if="disabled">{{name}}</span>
    <el-autocomplete v-else ref="autoComplete"  class="auto-complete"  style="width: 100% !important;" v-model="name" @change="changeData" :fetch-suggestions="queryData" @select="selectData" value-key="merchantName" clearable :placeholder="placeholder" :debounce="500">
      <template #default="{ item }">
        <div v-if="item.id !== '-1'">
          <span>{{ item.merchantName }}</span>
          <span>{{ item.merchantPhone }}</span>
        </div>
        <div v-else class="more-condition">
          <span>更多查询</span>
        </div>
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import commonApi from "@/api/commonApi";
import {watch} from "vue";
export default {
  emits: ["update:modelValue", "selectOne"],
  props: {
    placeholder: {default: "请选择"},
    queryApi: {default: null},
    modelValue: {default: ""},
    merchantName: {default: ""},
    disabled: {default: false},
  },
  name: "",
  components: {
    PageButton,
    ListPage, TableList, FormPage
  },
  data() {
    return {
      name: ""
    };
  },
  created() {
    watch(() => this.merchantName, () => this.init());
  },
  methods: {
    init() {
      this.name = this.merchantName;
    },
    queryData(keyWord, callBack) {
      if (this.queryApi) {
        this.queryApi({searchKey: keyWord}).then(list => {
          if(list.length === 0){
            this.$message.warning("未查询到对应数据");
          }
          callBack(list);
        });
      } else {
        commonApi.queryClientList({searchKey: keyWord}).then(list => {
          if(list.length === 0){
            this.$message.warning("未查询到对应数据");
          }
          callBack(list);
        })
      }
    },
    selectData(data) {
      this.$emit("update:modelValue", data.id);
      this.$emit("selectOne", data);
    },
    changeData(data) {
      console.log("变更数据")
      console.log(data)
    }
  }
};
</script>

<style lang="scss" scoped>
  .client-list{
    display: inline-block;
    width: 100%;
  }
</style>