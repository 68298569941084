<template>
  <!-- -->
<!--  <el-input class="input-line" v-model="customerName" @change="searchCustomer" placeholder="条码/拼音码/名称/编码" clearable autocomplete>-->
<!--    <template #append>-->
<!--      <page-button type="primary" @click="searchCustomer">查询</page-button>-->
<!--    </template>-->
<!--  </el-input>-->
  <el-autocomplete ref="autoComplete" :disabled="disabled" class="auto-complete" @focus="onFocus" @blur="setCacheData" style="width: 100% !important;" v-model="customerName" :fetch-suggestions="setCallBack" @select="selectCustomer" value-key="customerName" clearable @change="changeData" :placeholder="cusTitle" :debounce="500">
    <template #default="{ item }">
      <div v-if="item.id !== '-1'" style="display: flex;justify-content: space-between">
        <span>{{ item.customerName }}</span>
        <span>{{ item.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2') }}</span>
      </div>
      <div v-else class="more-condition">
        <span>更多查询</span>
      </div>
    </template>
  </el-autocomplete>
  <el-dialog v-model="showMore" title="查询客户" width="60%" :close-on-click-modal="false">
    <search-condition :show-search="true" @search="resetSearch">
      <el-input v-model="searchInfo.searchKey" placeholder="请输入客户名称/手机号" @keydown.enter="resetSearch"/>
    </search-condition>
    <dialog-table>
      <el-table :data="listResult" height="100%" border>
        <el-table-column align="center" label="序号" type="index" width="60px"/>
        <el-table-column align="center" label="会员名称" prop="customerName"/>
        <el-table-column align="center" label="联系电话" prop="customerPhone"/>
        <el-table-column align="center" label="性别">
          <template v-slot="{row}">
            <span v-if="row.gender === 1">男</span>
            <span v-if="row.gender === 2">女</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="60px">
          <template #default="scope">
            <table-button type="text" @click="selectOne(scope.row)">选择</table-button>
          </template>
        </el-table-column>
      </el-table>
    </dialog-table>
    <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList" />
  </el-dialog>

</template>

<script>
import commonApi from "@/api/commonApi";
import {watch} from "vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import TableButton from "@/components/h5/button/TableButton.vue";

export default {
  emits: ["update:modelValue", "selectOne"],
  props: {
    disabled: {default: false},
    modelValue: {default: ""},
    name: {default: ""},
    cusTitle: {default: "请输入客户名称/手机号并按回车"}
  },
  name: "CusSelect",
  components: {
    TableButton,
    DialogTable, FormPage, SearchCondition
  },
  data() {
    return {
      customerId: "",
      customerName: "",
      // 回调事件，设置输入内容
      setDataList: null,
      showMore: false,
      // 以下为复杂查询
      searchInfo: {},
      listResult: [],
    };
  },
  created() {
    this.init();
    // this.searchCustomer();
    watch(() => this.modelValue, () => this.init());
  },
  methods: {
    init() {
      if (this.modelValue === "10000") {
        this.customerName = "临时客户";
      } else {
        this.customerName = this.name;
      }
      this.customerId = this.modelValue;
    },
    setResultList(list) {
      let arr = [...list];
      // 增加一个更多查询
      arr.push({
        id: "-1",
      })
      this.selectedCustomerList = arr;
      if (this.setDataList) {
        this.setDataList(arr);
      }
    },

    // 这里会触发事件
    setCallBack(val, callBack) {
      this.setDataList = callBack;
      commonApi.queryCustomerByCode(this.customerName || "").then(list => {
        this.setResultList(list);
      });
    },
    selectCustomer(data) {
      if (data.id === "-1") {
        // 查询更多
        this.showMore = true;
        this.$refs.autoComplete.blur();
        return;
      }
      this.$emit("update:modelValue", data.id);
      this.$emit("selectOne", data);
    },
    selectOne(data) {
      this.$emit("update:modelValue", data.id);
      this.$emit("selectOne", data);
      this.showMore = false;
    },
    // 数据变更，主要用于清空
    changeData() {
      this.$nextTick().then(() => {
        if (!this.customerName) {
          this.$emit("update:modelValue", "");
          this.$emit("selectOne", {});
        }
      });
    },
    onFocus() {
      // 如果是临时客户，清空
      if (this.modelValue === "10000") {
        this.customerName = "";
      }
    },
    setCacheData() {
      // 如果是临时客户，清空
      if (this.modelValue === "10000") {
        this.customerName = "临时客户";
      }
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      commonApi.queryCustomerList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.listResult = res.records;
        for (let i = 0; i < this.listResult.length; i++) {
          this.listResult[i].customerPhone = this.handlePhone(this.listResult[i].customerPhone);
        }
      })
    },
    // 处理客户手机号
    handlePhone(phone) {
      if (phone.split('').length === 11) {
        return phone.replace(/(\d{3})\d*(\d{4})/,'$1****$2');
      }
      return phone;
    }
  }
};
</script>

<style lang="scss" scoped>
.input-line{
  width: 50%;
  margin-right: 10px;
  :deep(.el-input-group__append) {
    color: #409eff;
  }
}
.auto-complete{
  :deep(.el-input){
    width: 100%;
  }
}
.more-condition{
  text-align: right;
  color: #409eff;
  cursor: pointer;
}
</style>