import axios from "../../../util/axios";

export default {
    /**
     * 保存草稿
     */
    saveDraft: (data)=>axios.post({url: `/order/advance/toSaveDraft`,data: data}),
    //查询列表
    queryList: (searchInfo) => axios.get({url: `/order/advance/queryList`,data: searchInfo}),
    //删除草稿
    delDraft: (id) => axios.del({url: `/order/advance/delete`,data: {id: id}}),
    //通过id查询
    findById: (id) => axios.get({url: `/order/advance/findById`,data: {id: id}}),
    //保存支付
    saveSubmit: (data) => axios.post({url: `/order/advance/toSaveSubmit`,data: data}),
    //查询客户的余额 信息
    findCusInfo: (customerId) => axios.get({url: `/order/advance/findCusInfo`,data: {customerId :customerId}}),
    //根据朔源订单id进行查询
    findByOriginalId: (originalId) => axios.get({url: `/order/advance/findByOriginalId`,data: {originalId}}),
    //查询支付状态
    findSurplusStatus: (payId) => axios.get({url: `/order/advance/findSurplusStatus`,data: {payId}}),
    // 通过客户id查询
    queryByCustomerId: (customerId) => axios.get({url: `/order/advance/queryByCustomerId`,data: {customerId}}),
}