<template>
  <!-- -->
  <div :class="`table-list table-list${itemCount}`" :style="{height: 'var(--tabPanelHeight)'}">
    <slot/>
  </div>
</template>

<script>
import {watch} from "vue";

export default {
  props: {
    itemCount:{default: 1},
  },
  name: "",
  components: {
  },
  data() {
    return {
      tableHeight: 100,
      interval: "",
    };
  },
  created() {
    watch([() => this.$parent.$data.mainHeight, () => this.$parent.$data.conditionHeight], ([height, conditionHeight]) => {
      this.tableHeight = height - conditionHeight - 50;
    })
  },
  unmounted() {
    if (this.interval) {
      clearTimeout(this.interval)
    }
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/description";
@import "@/assets/css/components/dialog";
.table-list{
  box-sizing: border-box;
  overflow: auto;
}

:deep(.el-table--border th.el-table__cell){
  background: #f5f7fa;
  color: #333;
  font-size: 14px;
  text-align: center;
}

:deep(.el-descriptions__label) {
  width: 10%;
}
:deep(.el-descriptions__label:after) {
}
:deep(.el-descriptions__content) {
  width: 23%;
}
:deep(.el-descriptions__content:first-child) {
  width: 24%;
}
:deep(.el-textarea__inner) {
  box-sizing: border-box;
  padding: 10px;
}
:deep(.el-form-item.require .el-form-item__label::before) {
  content: "*";
  font-size: 13px;
  color: #F56C6C;
  padding-right: 4px;
}
</style>