<template>
  <div class="defaultViewStyle">
    <list-page>
      <table-list>
        <el-descriptions :column="2" border size="small">
          <el-descriptions-item v-if="dataDetail.type === 99" label="客户名称">
            <customer-select v-model="dataDetail.customerId" :name="dataDetail.customerName" @selectOne="setCustomer"/>
          </el-descriptions-item>
          <el-descriptions-item v-if="dataDetail.type === 99" label="联系电话">
            <el-input v-model="dataDetail.customerPhone"></el-input>
          </el-descriptions-item>
          <el-descriptions-item v-if="dataDetail.type === 2" label="职员名称">
            {{dataDetail.employeeName || userInfo.userName}}
          </el-descriptions-item>
          <el-descriptions-item v-if="dataDetail.type === 2" label="联系电话">
            {{dataDetail.employeePhone || userInfo.userPhone}}
          </el-descriptions-item>
          <el-descriptions-item label="内容">
            <el-input v-model="dataDetail.forumTitle" type="textarea" :rows="1"/>
          </el-descriptions-item>
          <el-descriptions-item label="编号">{{dataDetail.id}}</el-descriptions-item>
          <el-descriptions-item label="发布时间">
            <el-date-picker v-model="dataDetail.forumTime" style="width: 100%"/>
          </el-descriptions-item>
          <el-descriptions-item label="审核状态">
            <span v-if="dataDetail.status === 0">待审核</span>
            <span v-else-if="dataDetail.status === 1">通过</span>
            <span v-else-if="dataDetail.status === 2">未通过</span>
          </el-descriptions-item>
          <el-descriptions-item label="论坛图片" :span="2">
            <image-upload v-model="dataDetail.forumImage" folder="customerForum" :limit="9"></image-upload>
          </el-descriptions-item>
          <el-descriptions-item label="详情描述" :span="2">
            <rich-text v-model="dataDetail.forumContent" folder="customerForum"></rich-text>
          </el-descriptions-item>
        </el-descriptions>
      </table-list>
      <form-page :show-page="false">
        <div>
          <page-button :disabled="isDisabled" el-type="primary" type="write" @click="save">保存</page-button>
        </div>
      </form-page>
    </list-page>
  </div>
</template>

<script>
import TableButton from "@/components/h5/button/TableButton.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import ListPage from "@/components/h5/layout/ListPage.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import ImageUpload from "@/components/h5/image/ImageUpload.vue";
import RichText from "@/components/h5/richText/RichText.vue";
import ReplyApi from "@/api/alpine/replay/ReplyApi";
import Util from "@/util/util";
import util from "@/util/util";
import {useRoute, useRouter} from "vue-router";
export default {
  setup() {
    return {
      router: useRouter(),
      route: useRoute(),
    }
  },
  name: "forumReplyDetail",
  components: {
    TableButton,
    PageButton,
    SearchCondition,
    ListPage, TableList, FormPage, Dictionary,PageHeader,CustomerSelect,ImageUpload,RichText
  },
  data(){
    return{
      dataDetail:{
        type: 0,
        forumTime:new Date(),
      },
      userType:"",
      isDisabled:false,
      userInfo: {}
    }
  },
  created() {
    util.getUserInfo().then((user) => {
      this.userInfo = user;
      this.userType = user.userType;
      if (!this.$routerUtil.query.id) {
        // 没有id,2是职员
        this.dataDetail.type = 2;
        this.dataDetail.employeeId = this.userInfo.id;
      }
    })
  },
  mounted() {
    if (this.$routerUtil.query.id) {
      // 查询数据
      this.findById(this.$routerUtil.query.id);
    }
  },
  methods:{
    //查看详情
    findById(id){
      ReplyApi.findById(id).then((data)=>{
        this.dataDetail = data;
      })
    },
    //获取用户格式
    setCustomer(data) {
      this.dataDetail.customerId = data.id;
      this.dataDetail.customerName = data.customerName;
      this.dataDetail.customerPhone = data.customerPhone;
    },
    //保存
    save(){
      if( this.dataDetail.status==null){
        if (this.userType===2){
          this.dataDetail.status = 1
        }else {
          this.dataDetail.status = 0
        }
      }
      ReplyApi.saveSubmit(this.dataDetail).then((data)=>{
        let id = data.id;
        this.findById(id);
      })
    },
  }
}

</script>

<style scoped>

</style>