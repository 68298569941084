<template>
  <!-- -->
  <div class="form-page" :id="id">
    <div>
      <el-pagination
          v-if="showPage"
          v-model:current-page="currentPage"
          v-model:page-size="currentSize"
          :page-sizes="[10, 20, 50, 100]"
          :layout="showSimple? layoutSimple: layoutDefault"
          background
          small
          :total="totalCount"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          lang="zh-cn"
      />
      <slot name="left" v-else></slot>
    </div>
    <div><slot></slot></div>
  </div>
</template>

<script>

export default {
  emits: ["onLoad", "update:pageNumber", "update:pageSize"],
  props: {
    pageNumber: {default: 1},
    pageSize: {default: 20},
    totalCount: {default: 0},
    // 是否加载完成后自动查询数据
    defaultLoad: {default: true},
    showPage: {default: true},
    // 显示简单模式
    showSimple: {default: false},
  },
  watch: {
    pageNumber: {
      handler() {
        this.currentPage = this.pageNumber
      }
    },
    pageSize: {
      handler() {
        this.currentSize = this.pageSize;
      }
    }
  },
  name: "FormPage",
  components: {
  },
  data() {
    return {
      id: "",
      currentPage: 1,
      currentSize: 20,
      timeOut: "",
      layoutDefault: "prev, pager, next, jumper, total, sizes",
      layoutSimple: "prev, pager, next, total"
    };
  },
  created() {
    this.currentSize = this.pageSize;
    this.currentPage = this.pageNumber;
    // 自动加载数据
    if (this.defaultLoad) {
      this.$emit("update:pageNumber", this.pageNumber);
      this.$emit("update:pageSize", this.pageSize);
      this.$emit("onLoad");
    }
    this.id = "form_page" + (Math.random()*9999).toFixed(0);
  },
  unmounted() {
    clearTimeout(this.timeOut);
  },
  mounted() {
    // 获取当前组件的高度
    // 由于微服务框架本身的问题，mounted无法获取到dom对象
    this.$nextTick().then(() => this.resetParentHeight());
  },
  methods: {
    resetParentHeight() {
      if (this.$parent.setConditionHeight) {
        let docHeight = document.querySelector(`#${this.id}`).clientHeight;
        if (docHeight === 0) {
          this.timeOut = setTimeout(() => this.resetParentHeight(), 100);
          return;
        }
        this.$parent.setConditionHeight(docHeight, "formPage");
      }
    },
    handleSizeChange(size) {
      this.$emit("update:pageSize", size);
      this.$emit("onLoad");
    },
    handleCurrentChange(page) {
      this.$emit("update:pageNumber", page);
      this.$emit("onLoad");
    }
  }
};
</script>

<style lang="scss" scoped>
.form-page{
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #f1f1f1;
  // 去掉condition的影响
  :deep(.el-select) {
    margin-bottom: 0;
    width: 100px;
  }
}
</style>