import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// 页面初始化
const app = createApp(App);
app.use(router);

// 系统通用组件
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import routerUtil from "@/util/RouterUtil";
app.component("PageButton", PageButton);
app.component("TableButton", TableButton);
app.config.globalProperties.$routerUtil = routerUtil;
// 加载自定义的函数
import SelfFunction from "@/selfFunction";
SelfFunction.register(app);

// 加载elementUI相关组件，封装到一个文件中进行
import Element from "@/LoadElement";
// 加载ElementUI相关组件
Element.loadElementUi(app);

// 加载微服务
require("@/LoadCloudApp") ;

// 屏蔽错误信息
app.config.errorHandler = () => null;

// 屏蔽黄色警告信息
app.config.warnHandler = () => null;


app.mount('#app')

