<template>
  <!--报价详情 -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header/>
      <table-list>
        <el-descriptions :column="2" border size="small">
          <el-descriptions-item label="标题">
            <el-input v-model="dataDetail.title"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="汽车品牌">
            <dictionary v-model="dataDetail.carBrand" :dictionaries="carBradeList" @changeData ="setDepartCode"/>
          </el-descriptions-item>
          <el-descriptions-item label="产品型号">
            <dictionary v-model="dataDetail.category" :dictionaries="productCategories"
                        style="display: inline-block;width: 100%"/>
          </el-descriptions-item>
          <el-descriptions-item label="总金额">
            <el-input v-model="dataDetail.price"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="门店">
            <el-select v-model="dataDetail.storeId" style="width: 100%">
              <el-option v-for="item in storeList" :key="item.id" :label="item.storeName" :value="item.id"/>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item label="仓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;库">
            <el-select v-model="dataDetail.stockId" style="width: 100%">
              <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
            </el-select>
          </el-descriptions-item>
<!--          <el-descriptions-item label="创建人员">{{dataDetail.createdName}}</el-descriptions-item>-->
          <el-descriptions-item label="报价编号">{{dataDetail.id}}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{dataDetail.createdTime}}</el-descriptions-item>
          <el-descriptions-item label="内容信息" :span="2">
            <el-input v-model="dataDetail.summary" type="textarea" :rows="1"/>
          </el-descriptions-item>
          <el-descriptions-item label="效果照片" :span="2">
            <image-upload v-model="dataDetail.titleImage" folder="offer" limit="1"></image-upload>
          </el-descriptions-item>
          <el-descriptions-item label="详情描述" :span="2">
            <rich-text v-model="dataDetail.content" folder="offer"></rich-text>
          </el-descriptions-item>
        </el-descriptions>

<!--        商品信息-->
        <div style="text-align: right" v-if="!isDisabled">
          <div style="display: inline-block;width: 300px;padding-bottom: 10px">
            <product-list-select sku-id-column="skuOriginalCode" product-id-column="originalCode" :column-display="productDisplayColumns" :stock-id="dataDetail.stockId" :is-stock="true" :query-list-api="queryProductApi" @select="selectProduct" @selectOne="selectOneProduct"  :selected-product-list="dataDetail.productList"/>
          </div>
        </div>
        <el-table :data="dataDetail.productList" border>
          <el-table-column label="序号" width="60px" type="index"/>
          <el-table-column show-overflow-tooltip prop="skuName" label="商品名称"/>
          <el-table-column show-overflow-tooltip prop="barCode" label="条码"/>
          <el-table-column show-overflow-tooltip prop="skuOriginalCode" label="溯源码"/>
          <el-table-column prop="offerPrice" label="单价" :align="'center'" width="100px">
            <template v-slot="{row}">
              <el-input v-model="row.offerPrice"/>
            </template>
          </el-table-column>
          <el-table-column prop="offerQty" label="数量" :align="'center'" width="100px">
            <template v-slot="{row}">
              <el-input v-model="row.offerQty"/>
            </template>
          </el-table-column>
          <el-table-column prop="offerTotle" label="小计" :align="'center'" width="100px">
            <template v-slot="{row}">
              {{row.offerQty*row.offerPrice}}
<!--              <el-input v-model="row.offerTotle" >{{row.offerQty*row.offerPrice}}</el-input>-->
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'" width="120px">
            <template v-slot="{row}">
              <table-button  :disabled="isDisabled" el-type="danger" message="是否确认删除此数据" type="delete"  @click="deleteRow(row,$index)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page :show-page="false">
        <div>
          <page-button el-type="primary" type="write" @click="offerSave">保存</page-button>
        </div>
      </form-page>
    </list-page>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import Dictionary from "@/components/h5/Dictionary";
import ImageUpload from "@/components/h5/image/ImageUpload.vue";
import CustomerSelect from "@/components/h5/customer/CustomerSelect";
import OfferApi from "@/api/alpine/offer/offerApi";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import util from "@/util/util"
import {useRoute, useRouter} from "vue-router";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import commonApi from "@/api/commonApi";
import DateRange from "@/components/h5/date/DateRange.vue";
import RichText from "@/components/h5/richText/RichText.vue";
export default {
  setup() {
    return {
      router: useRouter(),
      route: useRoute(),
    }
  },
  name: "", components: {
    PageButton,
    ListPage, TableList, FormPage, Dictionary,ImageUpload,CustomerSelect,ProductListSelect, PageHeader, DateRange,RichText
  }, data() {
    return {
      // 商品分类字典
      productCategories: [],
      searchInfo:{
        pageNumber:1,
        pageSize:10
      },
      dataDetail: {
        productList: [],
        storeId:"",
      },
      stockList: [],//仓库信息
      storeList:[],//商店信息
      queryProductApi: {},
      isDisabled: false,
      // 查询商品时要显示的字段
      productDisplayColumns: [
        {prop: "skuName", label: "商品名称", width: ""},
        {prop: "skuSalePrice", label: "商品价格", width: "100px"},
        {prop: "originalCode", label: "溯源码", width: "250px"},
        {prop: "skuStandard", label: "规格", width: "100px"},
        {prop: "skuType", label: "型号", width: "100px"},
      ],
      carBradeList: [],
    };
  },
  mounted() {
    if (this.$routerUtil.query.id) {
      // 查询数据
      this.findById(this.$routerUtil.query.id);
    }
  },
  created() {
    // 查询商品分类
    OfferApi.queryCategory("PRODUCT_CATEGORY").then(list => this.productCategories = list);
    //查询汽车品牌分类
    commonApi.queryDictionaryTree("CAR_TYPE", "10000").then(list => this.carBradeList = list);
    this.queryProductApi = this.queryProductSkuOriginal;
    // 溯源码的类型/格式
    commonApi.findSetting("STOCK_ORIGINAL_TYPE").then(setting => this.dataDetail.originalCodeType = setting.settingValue);
    // 查询仓库
// 获取用户信息
    util.getUserInfo().then((user) => {
      //console.log(user.mainDepartId);
      //  根据departId查询仓库，如果只有一个仓库，添加创建
      OfferApi.queryAllStock(this.searchInfo).then(list => {
        this.stockList = list;
        if (!this.$route.query.id) {
          // 新建的时候
          this.dataDetail.createdName = user.userName;
          this.dataDetail.employeeId = user.id;
          this.dataDetail.departCode = user.mainDepartCode;
          this.dataDetail.customerId = "10000";
          // 如果只有一个仓库，选择这个仓库
          if (list.length === 1) {
            this.dataDetail.stockId = list[0].id;
          }
        }
      });
    });
    //   查询门店信息
    OfferApi.queryStoreList().then(list =>{
      this.storeList = list
      if (!this.dataDetail.id) {
        this.dataDetail.storeId = this.storeList[0].id
      }
    })
  },

  methods:{
//查询详情
    findById(id){
      OfferApi.queryOfferDetail(id).then(data => {
        this.dataDetail = data;
      })
    },

    //
    queryProductSkuOriginal(param) {
      param.stockId = this.dataDetail.stockId;
      return OfferApi.queryStockProductsAndOriginal(param);
    },
    selectProduct(list) {
      let arr = [...this.dataDetail.productList];
      // 有哪些商品，看是否存在，不存在就添加
      for (let i = 0; i < list.length; i++) {
        let contains = false;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].skuId === list[i].id) {
            // 存在，更新数量
            contains = true
            break;
          }
        }
        // 不存在，添加
        if (!contains) {
          arr.push({
            ...this.createOrderDetail(list[i])
          });
          console.log(arr)
        }
      }
      this.dataDetail.productList = arr;
      this.totalPrice();
      this.calculateLineTotal();
      // 更新数据
      // this.updateOrderData();
    },
    createOrderDetail(data) {
      return {
        skuId: data.id,
        skuName: data.skuName,
        skuType:data.skuType,
        skuOriginalCode: data.originalCode,
        skuCode: data.skuCode,
        barCode: data.barCode,
        skuUnitId: data.skuUnitId,
        qty: 1,
        offerQty:1,
        skuUnitName: data.skuUnitName,
        offerPrice: data.skuSalePrice,
        skuBuyPrice: data.skuBuyPrice,
        skuOriginalPrice: data.skuSalePrice,
        rate: 100,
      }
    },
    // 商品信息
    selectOneProduct(data) {
      let contains = false;
      // 商品列表是否有数据，没有已添加，有就数量+1
      if (!contains) {
        this.dataDetail.productList.push(this.createOrderDetail(data));
        this.totalPrice();
      }
    },

    //删除当前行数据
    deleteRow(row, index) {
      this.dataDetail.productList.splice(index, 1);
    },
    //计算商品总价
    calculateLineTotal(row){
      const  totle = row.offerQty * row.offerPrice;
      console.log(totle);
      row.offerTotle = totle;
    },
//计算所有总价
    totalPrice() {
      let total = 0;
      for (let i = 0; i < this.dataDetail.productList.length; i++) {
        total += this.dataDetail.productList[i].offerQty * this.dataDetail.productList[i].offerPrice;
      }
     this.dataDetail.price = total;
    },

    //数据校验
    checkData() {
      if(this.dataDetail.customerId === null || this.dataDetail.customerId.length === 0){
        this.$message({
          showClose: true,
          message: '请输入用户名称',
          type: 'warning'
        });
        return false;
      }
      if(this.dataDetail.carNo === null||this.dataDetail.carNo.length === 0){
        this.$message({
          showClose: true,
          message: '请选择车牌号',
          type: 'warning'
        });
        return false;
      }
      return true;
    },
    // 提交保存
    offerSave(){
      // if (!this.checkData()) {
      //   return;
      // }
      OfferApi.saveSubmit(this.dataDetail).then((data) => {
        let id = data.id;
       this.findById(id);
      });
      console.log(this.dataDetail);
    },

  }};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/description";
.custom-info{
  min-width: 400px;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  display: flex;
  justify-content: space-between;
  padding-right: 100px;
}
</style>