import axios from "../../../util/axios";


export default {
    // 查询列表
    queryList: (data) => axios.get({url: "/order/stock/inoutType/queryList", data: data}),
    // 删除
    deleteData: (id)=> axios.del({url: "/order/stock/inoutType/delete",data:{id}}),
    // 保存
    saveData: (data)=> axios.post({url: "/order/stock/inoutType/save",data:data}),
    // 回显
    findById: (id)=> axios.get({url: "/order/stock/inoutType/findById",data:{id}}),
    // 无条件查询所有
    queryAllTypeList: (type) => axios.get({url: `/order/stock/inoutType/queryAllTypeList`, data:{type}}),
}