import axios from "../../../util/axios";

export default {
    /** 查询所有角色,不分页*/
    queryAllRoles: (merchantId) => axios.get({url: "/basedata/system/role/queryAllRoles", data: {merchantId}}),
    /** 查询商户授权的功能*/
    queryAllModules: (merchantId) => axios.get({url: "/basedata/system/role/queryAllModules", data: {merchantId}}),
    /** 查询商户授权的功能*/
    queryGrantModules: (roleId) => axios.get({url: "/basedata/system/role/queryGrantModules", data: {roleId}}),
    /** 保存角色信息*/
    saveRole: (role) => axios.post({url: "/basedata/system/role/save", data: role}),
    /** 删除角色*/
    delRole: (id) => axios.del({url: "/basedata/system/role/delete", data: {id}}),
    /** 全部的权限*/
    queryAccessRight: () => axios.get({url: "/basedata/system/role/queryAccessRight"}),
    /** 角色授权*/
    grantModules: (roleId, RoleModules) => axios.post({url: `/basedata/system/role/grantModules/?roleId=` + roleId, data: RoleModules}),
    /** 全部授权*/
    allGrant: (roleId, right, merchantId) => axios.get({url: `/basedata/system/role/allGrant`, data: {roleId, right, merchantId}}),
    /** 复制角色的权限*/
    copyGrant: (from, to) => axios.get({url: `/basedata/system/role/copyGrant`, data: {from, to}}),
    /** 保存授权信息 */
    saveGrant: (data) => axios.post({url: `/basedata/system/role/saveGrant`, data: data}),
    /** 删除授权信息 */
    deleteGrant: (id) => axios.del({url: `/basedata/system/role/deleteGrant`, data: {id}}),
}