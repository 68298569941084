<template>
  <!--溯源码 -->
  <div class="defaultViewStyle">
    <page-header/>
    <el-descriptions :column="2" border>
      <el-descriptions-item label="入库日期">
        <el-date-picker style="width: 100%"
                        :disabled="isDisabled"
                        v-model="headInfo.createdTime"
                        type="date"
                        placeholder="选择日期时间"
        />
      </el-descriptions-item>
      <el-descriptions-item label="入库仓库">
        <el-select v-model="headInfo.stockId" :disabled="isDisabled" placeholder="选择仓库" size="default" @change="toSelectStockChange">
          <el-option v-for="item in stockList" :key="item.id" :label="item.stockName" :value="item.id"/>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item label="仓库货位">
        <el-select v-model="headInfo.storageId" :disabled="isDisabled" placeholder="选择仓库" size="default">
          <el-option v-for="item in storageList" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item label="部门">
        <dictionary :disabled="isDisabled" v-model="headInfo.departCode" :dictionaries="departList" dict-code="departCode" dict-name="departName"/>
      </el-descriptions-item>
      <el-descriptions-item label="创建人">
        {{ headInfo.createdName }}
      </el-descriptions-item>
      <el-descriptions-item label="备注" :span="2">
        <el-input :disabled="isDisabled" v-model="headInfo.remark" type="textarea" :rows="1" class="el-input-text"/>
      </el-descriptions-item>
    </el-descriptions>
    <div style="display: flex; justify-content: flex-end;margin-bottom: 5px" v-if="!isDisabled">
      <div style="width: 400px">
        <product-list-select :query-list-api="queryListApi" :selected-product-list="headInfo.detailList" @select="selectProduct" @clear="deleteBatch"/>
      </div>

    </div>

    <list-page>
      <table-list>
        <el-table :data="headInfo.detailList" height="100%" border ref="finalTable">
          <el-table-column type="selection" width="55"/>
          <el-table-column align="center" type="index" label="序号" :index="indexMethod" width="80"/>
          <el-table-column align="center" prop="skuName" label="商品名称" min-width="150px" show-overflow-tooltip/>
          <el-table-column align="center" prop="skuUnitName" label="基本单位" width="120px"/>
          <el-table-column align="center" prop="skuCode" label="商品条码" min-width="150px"/>
          <el-table-column align="center" label="数量" width="200" prop="skuQty" />
          <el-table-column align="center" prop="buyPrice" label="成本单价">
            <template v-slot="{row}">
              <span v-if="isDisabled">{{ row.buyPrice }}</span>
              <el-input v-else v-model="row.buyPrice" type="number"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="成本金额">
            <template v-slot="{row}">
              <money :value="row.skuQty * row.buyPrice"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right">
            <template v-slot="{row,$index}">
              <order-original-code :original-code-type="headInfo.originalCodeType" :disabled="isDisabled" @addOriginalCode="addOriginalCode2List($index, $event)" :code-list="row.originalCodeList" :sku-name="row.skuName"/>
              <table-button  type="danger" el-type="danger" :disabled="isDisabled" @click="deletedetailList(row,$index)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page :show-page="false">
        <page-button :disabled="isDisabled" type="write" el-type="success" @click="saveDraft">保存草稿</page-button>
        <page-button :disabled="isDisabled" type="write" el-type="primary" @click="saveSubmit">提交入库</page-button>
      </form-page>
    </list-page>
  </div>

  <el-drawer v-model="showOriginalDialog" :title="currentProduct.skuName + '溯源码'" dir="ltr" size="500px" :close-on-click-modal="false">
<!--    自动生成溯源码-->
    <div style="height: calc(100vh - 180px)">
      <table-button v-if="!isDisabled" @click="tosheng">自动生成溯源码</table-button>
      <el-table id="skuOriginalTable" :data="skuOriginalList" height="100%" border>
        <el-table-column label="序号" type="index" width="60px" :align="'center'"/>
        <el-table-column label="溯源码" prop="originalCode" v-if="isDisabled" :align="'center'"/>
        <el-table-column label="溯源码" v-else>
          <template v-slot="{row}">
            <el-input v-model="row.originalCode" placeholder="请输入溯源码" @change="addTableOriginalCode"/>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="text-align: right;padding-top: 10px;margin-top: 20px">
      <page-button @click="showOriginalDialog = false">返回</page-button>
      <page-button v-if="!isDisabled" el-type="primary" @click="addOriginalCode2List">确定</page-button>
    </div>
  </el-drawer>

<!--  生成溯源码-->
  <el-drawer  v-model="shengOriginalDialog" size="500px">
    <div>
      <table-list>
        <el-descriptions :column="1" border >
          <el-descriptions-item label="开始编码值">
            <el-input v-model="startValue" placeholder="请输入开始编码的值" />
          </el-descriptions-item>
          <el-descriptions-item label="截至编码值">
            <el-input v-model="endValue" placeholder="请输入截至编码的值" />
          </el-descriptions-item>
        </el-descriptions>
      </table-list>
      <page-button v-if="!isDisabled" el-type="primary" @click="toShengOriginalCode">生成</page-button>
    </div>
  </el-drawer>
</template>
<script>

import ListPage from '@/components/h5/layout/ListPage.vue';
import TableList from "@/components/h5/layout/TableList.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import stockApi from "@/api/main/stock/stockApi";
import PageButton from "@/components/h5/button/PageButton.vue";
import stockInoutApi from "@/api/main/stock/stockInStorageApi";
import elementElMessage from "@/util/elementElMessage";
import ProductListSelect from "@/components/h5/product/ProductListSelect.vue";
import commonApi from "@/api/commonApi";
import Money from "@/components/h5/Money.vue";
import Dictionary from "@/components/h5/Dictionary.vue";
import util from "@/util/util";
import DialogTable from "@/components/h5/layout/DialogTable";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import OrderOriginalCode from "@/views/main/supplyChain/merchantOrder/components/OrderOriginalCode.vue";
export default {
  computed: {},
  components: {
    FormPage,
    TableButton, PageButton,
    ListPage, TableList, ProductListSelect, Money, Dictionary, DialogTable, PageHeader, OrderOriginalCode
  },
  data() {
    return {
      shengOriginalDialog:false,
      organValue:"",
      yearValue:"",
      startValue:"",//溯源码起始值
      endValue:"",//溯源码结束值
      isDisabled: false, //是否禁用部分组件
      searchInfo: {},
      showSaveProduct: false,
      productList: [],  //该商户可以入库的所有商品
      skuList: [], //入库商品对应的所有sku
      stockList: [], //仓库列表
      stockProduct: {}, //入库商品信息
      headInfo: {
        remark: "", //备注
        createdName: "", //创建
        createdTime: new Date(), //时间
        stockId: "",  //选择仓库
        status: null,
        depart: "",
        detailList: [], //需要入库的商品
        originalCodeType: "", // 溯源码规则
      },
      queryListApi: {},
      allUnits: [],
      // 是否开启溯源码功能
      // 是否开启仓库挂载部门功能
      stockDepartSwitch: false,
      // 部门
      departList: [],
      // 当前选中的商品
      currentProduct: {
        skuOriginalCode: "",
      },
      showOriginalDialog: false,
      skuOriginalList: [],
      storageList: [],
    };
  },
  created() {
    // 查询单位
    commonApi.queryUnits().then(list => this.allUnits = list);
    // 查询配置项
    commonApi.findSetting("STOCK_BANKUP").then(setting => this.stockDepartSwitch = setting.settingValue === "1");
    // 查询部门，查询成树结构返回
    commonApi.queryDepartList().then(list => this.departList = list);
    this.queryListApi = stockInoutApi.queryProductAuthorizeList;
    //获取仓库列表
    this.queryStockList();
    if (this.$routerUtil.query.inoutStorage) {
      this.echoInoutProduct(this.$routerUtil.query.inoutStorage);
    } else {
      // 新建
      commonApi.findSetting("STOCK_ORIGINAL_TYPE").then(setting => this.headInfo.originalCodeType = setting.settingValue);
      util.getUserInfo().then(user => {
        this.headInfo.createdName = user.userName;
      })
    }

  },
  methods: {
    //序号
    indexMethod(index) {
      return index + 1;
    },
    //商品信息回显
    echoInoutProduct(id) {
      //判断该页面是否有参数传入
      stockInoutApi.findByInOrderId(id).then(res => {
        this.headInfo = res;
        this.isDisabled = this.headInfo.status === 99;
      })
    },
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryProductList();
    },
    //获取仓库列表
    queryStockList() {
      stockApi.queryListByNotCondition().then(res => {
        this.stockList = res;
      })
    },

    deleteBatch() {
      let rows = this.$refs.finalTable.getSelectionRows();
      for (let i = 0; i < rows.length; i++) {
        this.headInfo.detailList.splice(this.headInfo.detailList.indexOf(rows[i]), 1);
      }
    },
    toSelectStockChange() {
      // 查询货位
      stockApi.queryStorage(this.headInfo.stockId).then(list => this.storageList = list);
      // 仓库没有挂载部门
      if (!this.stockDepartSwitch) {
        return;
      }
      // 部门id
      for (let i = 0; i < this.stockList.length; i++) {
        if (this.headInfo.stockId === this.stockList[i].id) {
          // 部门
          this.headInfo.departId = this.stockList[i].bankupId;
          break;
        }
      }
      let idx = this.departList.findIndex(item => item.id === this.headInfo.departId);
      if(idx !== -1){
        this.headInfo.departCode = this.departList[idx].departCode;
      }
    },
    deletedetailList(row, index) {
      this.headInfo.detailList.splice(index, 1);
    },
    handlerCount(){
      for (let i = 0; i < this.headInfo.detailList.length; i++) {
        this.headInfo.detailList[i].qtyPackage = this.headInfo.detailList[i].skuQty;
      }
    },
    saveDraft(){
      this.handlerCount();
      this.headInfo.status = 0;
      this.headInfo.inoutType = "CGRK";
      if (this.checkData()) {
        stockInoutApi.saveDraft(this.headInfo).then(res => this.headInfo = res);
      }
    },
    saveSubmit(){
      this.handlerCount();
      this.headInfo.status = 99;
      this.headInfo.inoutType = "CGRK";
      if (this.checkData()) {
        stockInoutApi.saveSubmit(this.headInfo).then(res => {
          this.headInfo = res;
          this.isDisabled = true;
        });
      }
    },
    checkData() {
      if (!this.headInfo.departCode) {
        this.$message.error("请选择部门");
        return false;
      }
      if (!this.headInfo.stockId) {
        this.$message.error("请选择仓库");
        return false;
      }
      if (this.headInfo.detailList.length === 0) {
        elementElMessage.toShowMessage("入库商品不能为空", "warning");
        return false;
      }
      // 查看商品。如果没有溯源码不能提交
      for (let i = 0; i < this.headInfo.detailList.length; i++) {
        if (!this.headInfo.detailList[i].originalCodeList) {
          this.$message.error(this.headInfo.detailList[i].skuName + "商品未添加溯源码")
          return false;
        }
      }
      return true;
    },
    // 商品组件选中商品后，回调显示
    selectProduct(productSkuList) {
      // 向detail中添加数据
      let arr = [...this.headInfo.detailList];
      console.log(arr)
      for (let i = 0; i < productSkuList.length; i++) {
        // 去重添加
        let contains = false;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].skuId === productSkuList[i].id) {
            contains = true;
            break;
          }
        }
        if (!contains) {
          arr.push({
            skuId: productSkuList[i].id,
            qtyPackage: 0,
            skuQty: 0,
            skuName: productSkuList[i].skuName,
            skuCode: productSkuList[i].skuCode,
            buyPricePackage: productSkuList[i].skuBuyPrice,
            buyPrice: productSkuList[i].skuBuyPrice,
            salePrice: productSkuList[i].skuSalePrice,
            salePricePackage: productSkuList[i].skuSalePrice,
            unitIdPackage: productSkuList[i].skuUnitId,
            skuUnitId: productSkuList[i].skuUnitId,
            skuUnitName: productSkuList[i].skuUnitName,
          });
        }
      }
      this.headInfo.detailList = arr;
    },
    toAddOriginalCode(data) {
      this.currentProduct = {...data};
      this.showOriginalDialog = true;
      // 默认添加
      this.skuOriginalList = [...data.originalCodeList||[], {}];
    },
    toShowOriginalCode(data) {
      this.currentProduct = {...data};
      this.showOriginalDialog = true;
      this.skuOriginalList = [...data.originalCodeList||[]];
    },
    addTableOriginalCode() {
      // 溯源码校验
      if(!this.checkOriginalCode()){
        return;
      }
      // 添加一个溯源码
      this.skuOriginalList.push({});
      this.$nextTick().then(() => {
        // 让新增的input进行聚焦
        let input = document.querySelector("#skuOriginalTable tr:last-child input:last-child");
        input.focus();
      });
    },
    // 检查溯源码格式信息
    checkOriginalCode(){
      // 规则
      let codeType = this.headInfo.originalCodeType;
      if(codeType === "XYYQGJ"){
        // 最后一个添加的溯源码
        let code = this.skuOriginalList[this.skuOriginalList.length - 1].originalCode;
        // 前8为必须为数字且要与当月的月份相符合 例如：20240827
        if(isNaN(Number(code.substring(0,8)))){
          this.$message.error("溯源码格式错误");
          return false;
        }
        // 当前月份
        let date = new Date().format("yyyyMM").toString();
        if(!code.startsWith(date)){
          this.$message.error("溯源码格式错误");
          return false;
        }
      }
      return true;
    },
    // 溯源码数据添加到表格中
    addOriginalCode2List(idx, resList) {
      let arr = [...this.headInfo.detailList];
      arr[idx].originalCodeList = resList;
      arr[idx].qtyPackage = resList.length;
      arr[idx].skuQty = resList.length;
      this.headInfo.detailList = arr;
      this.showOriginalDialog = false;
    },
    tosheng(){
      this.shengOriginalDialog = true;
    },
    getCode(str) {
      let i = parseInt(str.charAt(str.length - 1));
      if (isNaN(i)) {
        return str;
      }
      return this.getCode(str.substring(0, str.length - 1))
    },
    toShengOriginalCode(){
      if(this.headInfo.originalCodeType === "XYYQGJ"){
        this.toGenerateOriginalCodeXYYQGJ();
      }else{
        this.toGenertOriginalCode();
      }
    },
    toGenerateOriginalCodeXYYQGJ(){
      if(this.startValue.length < 15 || this.endValue.length < 15){
        this.$message.error("编号异常，请检查编号1");
        return;
      }
      if(this.startValue.length > 19 || this.endValue.length > 19){
        this.$message.error("编号异常，请检查编号2");
        return;
      }
      let start = this.startValue.substring(14);
      if(isNaN(Number(start))){
        this.$message.error("编号异常，请检查编号3");
        return;
      }
      let end = this.endValue.substring(14);
      if(isNaN(Number(end))){
        this.$message.error("编号异常，请检查编号4");
        return;
      }
      // 生成编号
      for (let i = 0; i < end; i++) {
        let count = "";
        if(i.toString().length < 5){
          for (let j = 0; j < 5 - i.toString().length; j++) {
            count += "0";
          }
        }
        count += i.toString();
        this.skuOriginalList.push({originalCode: start + count});
      }
    },
    //自动生成溯源码
    toGenertOriginalCode(){
      let start = this.startValue.substring(0, this.startValue.length - 2);
      let end = this.endValue.substring(0, this.endValue.length - 2);
      let startYear = this.startValue.substring(this.startValue.length - 2);
      let endYear = this.endValue.substring(this.endValue.length - 2);
      if (startYear !== endYear) {
        this.$message.error("编号异常，请检查编号");
        return;
      }
      // 获取编号
      let strStart = this.getCode(start);
      let strEnd = this.getCode(end);
      if (strStart !== strEnd) {
        this.$message.error("编号异常，请检查编号3333");
        return;
      }
      // ALHZ0470PPF000124
      // 开始和结束，除code外其它应该一样
      let startValue = parseInt(start.replace(strStart, ""));
      let endValue = parseInt(end.replace(strEnd, ""));
      let org = [];
      for (let o = startValue; o <= endValue; o++) {
        const codeObject = {
          originalCode: `${strStart}${o.toString().padStart(4, '0')}${startYear}`
        };
        org.push(codeObject);
      }
      this.skuOriginalList = org;
      this.shengOriginalDialog = false;
      this.addOriginalCode2List();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/components/description.scss";
@import "@/assets/css/components/table";
.head-sty {
  display: flex;
  justify-content: space-between;
}
.el-input-text{
  :deep(textarea){
    padding: 10px;
    box-sizing: border-box;
  }
}
.el-drawer__body {
  padding-top: 0;
}
</style>