import axios from '../../../util/axios';

export default {
    // 查询返佣配置列表
    queryList: (data) => axios.get({url: "/alpine/rebate/queryList", data: data}),
    // 保存返佣配置
    saveSubmit: (data) => axios.post({url: "/alpine/rebate/saveSubmit", data: data}),
    // 删除一条返佣配置
    delete: (id) => axios.del({url: "/alpine/rebate/delete", data: {id: id}}),

    // 返佣报表，查询
    queryListForReport: (data) => axios.get({url: "/alpine/report/queryList", data: data}),
    // 结算
    settlement: (storeId, index) => axios.get({url: "/alpine/report/settlement", data: {storeId, index}}),
    // 查询详情
    findDetail: (data) => axios.get({url: "/alpine/report/findDetail", data: data}),
}