<template>
  <!-- -->
  <div class="defaultViewStyle">
    <el-tabs v-model="activeName" @tab-change="change">
      <el-tab-pane name="apply" label="调拨单列表">
        <inventory-restock ref="inventoryRestockApply"/>
      </el-tab-pane>
      <el-tab-pane name="handle" label="待处理列表">
        <inventory-restock ref="inventoryRestockHandle"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import inventoryRestock from "@/views/main/stock/restock/InventoryRestock.vue";
export default {
  name: "RestockType",
  components: {
    inventoryRestock
  },
  data() {
    return {
      activeName: "apply",
    };
  },
  watch:{
  },
  created() {
  },

  methods: {
    change(currntName){
      if(currntName === "apply"){
        this.$refs.inventoryRestockApply.queryApplyList();
      }else if(currntName === "handle"){
        this.$refs.inventoryRestockHandle.queryHandleList();
      }
    },
  }
};
</script>

<style lang="scss" scoped>

</style>