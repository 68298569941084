<template>
  <div :class="autoHeight? 'dialog-table-auto': 'dialog-table'">
    <slot></slot>
  </div>
</template>

<script>

// 通用的dialog中显示table，使用height=100%可限制table高度，不需再去计算
export default {
  props: {
    // 高度自适应
    autoHeight: {default: false},
  },
  name: "",
  components: {
  },
  data() {
    return {

    };
  },
  created() {
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
@media (max-height: 1200px) {
  .dialog-table{
    height: calc(100vh - 480px);
    overflow: auto;
  }
  .dialog-table-auto{
    max-height: calc(100vh - 480px);
    overflow: auto;
  }
}

@media (max-height: 800px) {
  .dialog-table{
    height: calc(100vh - 320px);
    overflow: auto;
  }
  .dialog-table-auto{
    max-height: calc(100vh - 320px);
    overflow: auto;
  }
}

:deep(.el-table--border th.el-table__cell){
  background: #f5f7fa;
  color: #333;
  font-size: 14px;
  text-align: center;
}

:deep(.el-descriptions__label) {
  width: 10%;
}
:deep(.el-descriptions__label:after) {
}
:deep(.el-descriptions__content) {
  width: 23%;
}
:deep(.el-descriptions__content:first-child) {
  width: 24%;
}
:deep(.el-textarea__inner) {
  box-sizing: border-box;
  padding: 10px;
}
</style>