// import GjpLocalPrint from "./GjpLocalPrint";
import GjpLocalPrint from "@/util/GjpLoaclPrint";
import {ElMessage} from "element-plus";
import util from "@/util/util";
/**
 * 打印管理器，
 * 固定方法1：打印配置
 * printDesign();
 * 固定方法2：打印
 * print();
 */
let LocalPrint = {
    /**
     * 打印配置
     * @param name
     * @param setting 配置项，以下是示例 string表示是字段，obj表示是list
     * {customerPhone: "客户电话", customerName: "客户名称", productList: {productName: "商品名称", price: "单价"}, clientList: {name: "客户名称"}}
     * 只支持一个表体
     */
    printDesign(name, setting) {
        let dataSetting = GjpLocalPrint.printDesign(name, setting);
        util.setCookie("print_design_" + name, JSON.stringify(dataSetting), 365);
    },
    /**
     * 预览，预览参数和打印一样
     * @param name
     * @param data
     */
    printPreview(name, data) {
        let setting = util.getCookie("print_design_" + name);
        if (!setting) {
            ElMessage.error("请先进行打印配置");
            return;
        }
        // 重新刷新cookie的过期时间
        util.setCookie("print_design_" + name, setting, 365);
        // GjpLocalPrint.printPreview(name, JSON.parse(setting), data);
    },
    /**
     * 打印，打印需要和打印配置使用相同的name
     * @param name
     * @param data data数据和setting中保持一致，如果少了字段，自动补全为""示例,所有数据只接受string
     * {customerPhone: "1500", customerName: "成都八零科技", productList: [{productName: "商品1", price: "1.50"}, {}]}
     */
    print(name, data) {
        // let setting = util.getCookie("print_design_" + name);
        // if (!setting) {
        //     ElMessage.error("请先进行打印配置");
        //     return;
        // }
        // 重新刷新cookie的过期时间
        // util.setCookie("print_design_" + name, setting, 365);
        // GjpLocalPrint.print(name, JSON.parse(setting), data);
        GjpLocalPrint.Print(data);
    }
};
// 管家婆打印管理器初始化
GjpLocalPrint._initWebsocket();
export default LocalPrint;