<template>
  <!-- -->
  <div :style="{height: height + 'px'}" class="txmap-container">
    <div style="z-index: 1;height: 100%;position: relative;">
      <div id="txMapContainer" style="width: 100%; height: 100%;min-height: 300px"></div>
    </div>
    <div class="option-area">
      <el-input style="width: 240px;margin-right: 10px;" v-model="mapSearchKey" :placeholder="placeHolder" @keydown.enter="searchByKey"/>
      <page-button el-type="primary" @click="searchByKey">搜索</page-button>
      <page-button el-type="primary" :loading="getMarkerFromMap" @click="getMarkerFromMap = true">定位</page-button>
    </div>
  </div>
</template>

<script>
import PageButton from "@/components/h5/button/PageButton.vue";
export default {
  emits: ["getLocation"],
  props: {
    // 地图的高度，不能小于300
    height: {default: 300},
    // 定位信息, 使用string
    lat: {default: ""},
    lng: {default: ""},
    address: {default: ""},
  },
  name: "",
  components: {
    PageButton
  },
  data() {
    return {
      // 查询关键字
      mapSearchKey: "",
      // 地图对象
      map: {},
      // 地图搜索对象
      mapSearch: {},
      // 地址解析对象
      mapGeo: {},
      mapWindowInfo: null,
      mapMarkerLayer: null,
      timeout: "",
      // 点击地图时获取坐标
      getMarkerFromMap: false,
      placeHolder: "请输入查询关键字",
      markerList: [], // 显示在地图上的点
    };
  },
  created() {
    if (this.address) {
      this.placeHolder = this.address;
    }
  },
  mounted() {
    this.$nextTick(() => this.initMap());
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  methods: {
    initMap() {
      if (!TMap) {
        this.timeout = setTimeout(() => {
          this.initMap();
        }, 100);
      }
      // F5刷新时显示地图有问题
      this.timeout = setTimeout(() => {
        // 如果有定位，显示定位
        if (this.lat && this.lng) {
          this.showMapAndCenter(parseFloat(this.lat), parseFloat(this.lng));
          // 添加点标记
          this.addMapMarker([this.createMarker(parseFloat(this.lat), parseFloat(this.lng), this.address, "markerCurrent")])
        } else {
          // 没有定位，通过ip获取定位
          new TMap.service.IPLocation().locate().then(res => {
            let {lat, lng} = res.result.location;
            this.showMapAndCenter(lat, lng);
          });
        }
      }, 300);
    },
    showMapAndCenter(lat, lng) {
      //定义地图中心点坐标
      let center = new TMap.LatLng(lat, lng)
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      this.map = new TMap.Map(document.getElementById('txMapContainer'), {
        center: center,//设置地图中心点坐标
        zoom: 17.2,   //设置地图缩放级别
        pitch: 43.5,  //设置俯仰角
        rotation: 45    //设置地图旋转角度
      });
      // 定义地址解析服务对象
      this.mapGeo = new TMap.service.Geocoder();
      // 定义搜索服务对象
      this.mapSearch = new TMap.service.Search();
      // 添加事件，点击地图获取坐标
      this.map.on("click", evt => {
        if (!this.getMarkerFromMap) {
          // 不获取坐标
          return;
        }
        // 添加点标记
        this.mapClick(evt);
        this.getMarkerFromMap = false;
      })
    },
    // 添加点标记到地图上
    addMapMarker(markerList) {
      // 先缓存起来
      this.markerList = markerList;
      if (this.mapMarkerLayer) {
        this.mapMarkerLayer.destroy();// 删除点标记
      }
      this.mapMarkerLayer = new TMap.MultiMarker({
        id: 'marker-layer',
        map: this.map,
        geometries: markerList,
        styles: {
          "markerDefault": new TMap.MarkerStyle({
            "width": 32,
            "height": 32,
            "anchor": { x: 16, y: 32 },
            "src": 'https://file.alpinestyle.vip/common/position_red.png'
          }),
          "markerCurrent": new TMap.MarkerStyle({
            "width": 32,
            "height": 32,
            "anchor": { x: 16, y: 32 },
            "src": 'https://file.alpinestyle.vip/common/position_blue.png'
          })
        }
      });
      // 添加点击事件
      this.mapMarkerLayer.on("click", (evt) => {
        // 信息窗，如果不存在就添加
        if (!this.mapWindowInfo) {
          this.mapWindowInfo = new TMap.InfoWindow({
            map: this.map,
            position: evt.latLng,
            offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素
            enableCustom: true,
          });
        }
        this.mapWindowInfo.open();
        this.mapWindowInfo.setPosition(evt.geometry.position);
        this.mapWindowInfo.setContent(evt.geometry.title);
        // 当前点设置为选中，其它非选中
        let arr = [];
        for (let i = 0; i < this.markerList.length; i++) {
          if (this.markerList[i].id === evt.geometry.id) {
            arr.push(this.createMarker(evt.geometry.position.lat, evt.geometry.position.lng, evt.geometry.title, "markerCurrent"));
          } else {
            arr.push(this.createMarker(this.markerList[i].position.lat, this.markerList[i].position.lng, this.markerList[i].title, "markerDefault"))
          }
        }
        this.addMapMarker(arr);
        this.$emit("getLocation", {lat: evt.geometry.position.lat, lng: evt.geometry.position.lng, address: evt.geometry.title});
      });
    },
    // 点击地图
    mapClick(evt) {
      // 通过坐标，获取中文地址名称
      this.mapGeo.getAddress({location: evt.latLng}).then(res => {
        this.placeHolder = res.result.address;
        this.mapSearchKey = "";
        // 向地图添加一个坐标点
        this.addMapMarker([this.createMarker(evt.latLng.lat, evt.latLng.lng, this.placeHolder, "markerCurrent")]);
        this.$emit("getLocation", {lat: evt.latLng.lat, lng: evt.latLng.lng, address: res.result.address});
      });
    },
    // 通过关键字查询
    searchByKey() {
      if (!this.mapSearchKey) {
        this.$message.error("请输入查询关键字")
        return;
      }
      // debugger
      this.mapSearch.searchRectangle({keyword: this.mapSearchKey, bounds: this.map.getBounds()}).then(res => {
        // debugger
        let arr = [];
        if (this.lat && this.lng) {
          // 添加一个点
          arr.push(this.createMarker(this.lat, this.lng, this.address, "markerCurrent"));
        }
        // 添加查询结果
        for (let i = 0; i < res.data.length; i++) {
          arr.push(this.createMarker(res.data[i].location.lat, res.data[i].location.lng, res.data[i].address));
        }
        // 把点添加到地图上
        this.addMapMarker(arr);
      });
    },
    createMarker(lat, lng, address, style) {
      if (!style) {
        style = "markerDefault";
      }
      return {
        position: new TMap.LatLng(lat, lng),
        title: address,
        styleId: style
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.txmap-container{
  position: relative;
  .option-area{
    position: absolute;
    left: 10px;
    top: 10px;
    background: #FFF;
    padding: 10px;
    z-index: 2;
    white-space: nowrap;
  }
}
</style>