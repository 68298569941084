<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
      </page-header>
      <search-condition @search="resetSearch" :show-search="false">
        <template #default>
          <date-range v-model:start-time="searchInfo.startTime" placeholder="请选择开始日期"
                      v-model:end-time="searchInfo.endTime"/>
          <el-input ref="orderNumberInput" v-model="searchInfo.orderId" placeholder="请输入订单编号"
                    @change="resetSearch"/>
          <div style="display: inline-block;" class="customer-select">
            <customer-select v-model="searchInfo.customerId" :name="searchInfo.customerName" @selectOne="setCustomer"/>
          </div>
        </template>
        <template #moreCondition>
          <el-select v-model="searchInfo.orderStatus" placeholder="选择订单状态" clearable>
            <el-option label="草稿" :value="0"/>
            <el-option label="已取消" :value="20"/>
            <el-option label="已完成" :value="99"/>
          </el-select>
          <el-select v-model="searchInfo.stockId" placeholder="选择仓库" clearable>
            <el-option v-for="(st, i) in stockList" :key="i + 's'" :label="st.stockName" :value="st.id"/>
          </el-select>
        </template>
      </search-condition>
      <table-list>
        <el-table :data="dataList" height="100%" border>
          <el-table-column label="序号" type="index" width="60px" :align="'center'" fixed/>
          <el-table-column label="订单编号" prop="id" fixed width="250px" show-overflow-tooltip/>
          <el-table-column label="单据时间" prop="createdTime" :align="'center'" width="250px"/>
<!--          <el-table-column label="客户名称" show-overflow-tooltip min-width="150px">-->
<!--            <template v-slot="{ row }">-->
<!--              {{  }}-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column label="客户电话" prop="customerPhone" show-overflow-tooltip min-width="150px" :align="'center'"/>-->
          <el-table-column label="门店" width="250px" :align="'center'" show-overflow-tooltip>
            <template v-slot="{ row }">
              <dictionary :dictionaries="storeList" :dict-code="'id'" :dict-name="'storeName'" v-model="row.customerId" :disabled="true" />
            </template>
          </el-table-column>
          <el-table-column label="仓库名称" prop="stockName" min-width="150px" show-overflow-tooltip :align="'center'"/>
          <el-table-column label="单据状态" width="100px" :align="'center'">
            <template v-slot="{ row }">
              <el-tag type="warning" v-if="row.status === 0">草稿</el-tag>
              <el-tag type="warning" v-else-if="row.status === 10">等待支付</el-tag>
              <el-tag type="danger" v-else-if="row.status === 20">订单取消</el-tag>
              <el-tag type="warning" v-else-if="row.status === 30">待出库</el-tag>
              <el-tag type="success" v-else-if="row.status === 99">已完成</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="创建人员" prop="createdName" width="100px" show-overflow-tooltip :align="'center'"/>
          <el-table-column label="操作" :align="'center'" width="100px" fixed="right">
            <template v-slot="{row}">
              <table-button @click="$routerUtil.push(`/warranty/saleOrder/detail?id=${row.id}`)">查看</table-button>
              <table-button message="是否确认删除此数据" el-type="danger" type="delete" @click="toDelete(row)">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-number="searchInfo.pageNumber" v-model:page-size="searchInfo.pageSize" :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage.vue";
import SearchCondition from "@/components/h5/layout/SearchCondition.vue";
import TableList from "@/components/h5/layout/TableList.vue";
import FormPage from "@/components/h5/layout/FormPage.vue";
import PageButton from "@/components/h5/button/PageButton.vue";
import TableButton from "@/components/h5/button/TableButton.vue";
import SaleOder from "@/api/alpine/shop/saleOder";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import DateRange from "@/components/h5/date/DateRange.vue";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import config from "@/config/config";
import offerApi from "@/api/alpine/offer/offerApi";
import Dictionary from "@/components/h5/Dictionary.vue";
import commonApi from "@/api/commonApi";
export default {
  setup() {
  },
  name: "",
  components: {
    Dictionary,
    CustomerSelect, DateRange,
    TableButton,
    PageButton,
    ListPage, TableList, FormPage, SearchCondition, PageHeader
  },
  data() {
    return {
      searchInfo: {
        searchKey: ""
      },
      storeList: [],
      dataList: [],
      payType: []
    };
  },
  created() {
    this.searchInfo.startTime = new Date(new Date().getTime()).format("yyyy-MM-dd") + " 00:00:00";
    this.searchInfo.endTime = new Date().format("yyyy-MM-dd") + " 23:59:59";
    this.payType = config.offlinePayType;
    commonApi.queryStoreList().then(res=> this.storeList = res);
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      SaleOder.queryListWarranty(this.searchInfo).then(res =>{
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    // 删除
    toDelete(data){
      SaleOder.delete(data.id).then(() => this.queryList());
    },
    setCustomer(customer) {
      this.searchInfo.customerName = customer.customerName;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>