<template>
  <!-- -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header/>
      <table-list>
        <el-descriptions :column="2" border size="default">

          <el-descriptions-item label="客户名称">
            <customer-select disabled v-model="dataDetail.customerId" :name="dataDetail.customerName"
                             @selectOne="setCustomer"/>
          </el-descriptions-item>

          <el-descriptions-item label="客户电话" label-class-name="require">
            <span>{{ dataDetail.customerPhone ? dataDetail.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2') : '空' }}</span>
          </el-descriptions-item>

          <el-descriptions-item label="经办职员">
            <employee-select disabled v-model="dataDetail.employeeId" @selectOne="setEmployee"/>
          </el-descriptions-item>

          <el-descriptions-item label="部&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;门">
            <dictionary disabled v-model="dataDetail.departCode" :dictionaries="departList" dict-code="departCode"
                        dict-name="departName"/>
          </el-descriptions-item>

          <el-descriptions-item label="单据时间">
            <el-date-picker style="width: 100%"
                            v-model="dataDetail.advanceTime"
                            type="datetime"
                            placeholder="选择时间"
                            disabled
            />
          </el-descriptions-item>
          <el-descriptions-item label="单据编号" v-if="dataDetail.status !== 0 && dataDetail.status !== -1">
            {{ dataDetail.id }}
          </el-descriptions-item>
          <el-descriptions-item label="押金类型">
            <dictionary disabled v-model="dataDetail.depositType" :dictionaries="dictionaryList"/>
          </el-descriptions-item>
          <el-descriptions-item label="单据状态">
            <el-select v-model="dataDetail.status" disabled placeholder="订单状态" style="width: 100%" clearable>
              <el-option label="未提交" :value="-1"/>
              <el-option label="草稿" :value="0"/>
              <el-option label="已完成" :value="99"/>
            </el-select>
          </el-descriptions-item>
          <!-- 押金类型从字典获取 -->

          <el-descriptions-item label="收费标签">
            押金退费
          </el-descriptions-item>

          <el-descriptions-item label="备注" :span="2">
            <el-input v-model="dataDetail.remark" type="textarea" :rows="2"
                      :disabled="dataDetail.status !== 0 && dataDetail.status !== -1"/>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions border :column="2">
          <el-descriptions-item :label="dataDetail.surplusPayType === 1 ? '支付方式' : '退款方式'">
<!--            <el-select v-model="dataDetail.payType" placeholder="选择支付方式" style="width: 100%" clearable disabled>-->
<!--              <el-option v-for="(p, i) in payType" :value="p.value" :label="p.label" :key="i"/>-->
<!--            </el-select>-->
            <pay-type-select v-model="dataDetail.payTypeId" @select-one="changePay"  />
          </el-descriptions-item>
          <el-descriptions-item label="金&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;额">
            <el-input type="number" v-model="dataDetail.amountPay" disabled/>
          </el-descriptions-item>
          <el-descriptions-item label="退款金额">
            <el-input type="number" v-model="dataDetail.refundAmount"
                      :max="upperAmount === 0 ? dataDetail.amountPay : upperAmount"
                      :disabled="dataDetail.status === 99"/>
          </el-descriptions-item>
        </el-descriptions>
      </table-list>
      <form-page :show-page="false">
        <page-button el-type="primary" @click="toSaveSubmit" :disabled="dataDetail.status > 0">结算</page-button>
        <page-button el-type="success" @click="toSaveDraft" :disabled="dataDetail.status > 0">提交</page-button>

        <el-dropdown split-button type="primary" @click="toPrint" @command="handlerSelect" style="margin-top: 1px">
          打印
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="Edit" command="setPrint">打印设置</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

<!--        <page-button el-type="primary" @click="toPrint" style="margin-right: -5px">打印</page-button>-->
<!--        <page-button @click="toSetPrint" el-type="primary">-->
<!--          <el-icon>-->
<!--            <el-icon-setting/>-->
<!--          </el-icon>-->
<!--        </page-button>-->
      </form-page>
    </list-page>
  </div>
  <el-dialog v-model="showOrder" width="500px" :title="isRefund ? '押金结算' : '提交收款' " height="310px" @opened="openBefore">

      <div style="height: 238px;overflow: auto">
        <el-form label-width="70px" style="">
          <div style="height: 198px">
            <el-form-item label="扫码支付">
              <div style="position: relative;width: 100%;overflow: hidden">
                <el-input v-model="dataDetail.qrCode" ref="inputRef" placeholder="支付码" style="width: 100%"
                          @change="pay" disabled/>
              </div>
            </el-form-item>
            <el-form-item label="客户名称">
              <div style="position: relative;width: 100%;overflow: hidden">
                <el-input v-model="dataDetail.customerName" disabled style="width: 100%"/>
              </div>
            </el-form-item>
            <el-form-item label="金额合计">
              <el-input disabled v-model="dataDetail.refundAmount"/>
            </el-form-item>
            <el-form-item label="退款方式">
              <pay-type-select v-model="dataDetail.payTypeId" @select-one="changePay"  />
<!--              <el-select v-model="dataDetail.payType" style="width: 100%">-->
<!--                <el-option v-for="(p, i) in payType" :value="p.value" :label="p.label" :key="i"/>-->
<!--              </el-select>-->
            </el-form-item>
          </div>
        </el-form>
        <div style="display: flex;justify-content: space-between">
          <el-checkbox v-model="isAutoPrint" label="打印押金凭证" size="large"/>
          <div>
            <page-button el-type="info" @click="showOrder = false">取消</page-button>
            <page-button v-if="isRefund" el-type="primary" @click="pay" :loading="preparePay" :disabled="disableToClickPay">确认</page-button>
            <page-button v-else el-type="primary" @click="saveDraft" :loading="preparePay" :disabled="disableToClickPay">确认</page-button>
          </div>
        </div>
      </div>
  </el-dialog>
</template>
<script>

import EmployeeSelect from "@/components/h5/employee/EmployeeSelect.vue";
import CustomerSelect from "@/components/h5/customer/CustomerSelect.vue";
import ListPage from "@/components/h5/layout/ListPage.vue";
import commonApi from "@/api/commonApi";
import PageButton from "@/components/h5/button/PageButton.vue";
import AdvanceApi from "@/api/main/advance/advanceApi";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageHeader from "@/components/h5/layout/PageHeader";
import Dictionary from "@/components/h5/Dictionary.vue";
import GjpLoaclPrint from "@/util/GjpLoaclPrint";
import PayTypeSelect from "@/components/h5/PayTypeSelect.vue";
import systemPayApi from "@/api/main/sys/systemPayApi";
import {Edit} from "@element-plus/icons-vue";
import memberApi from "@/api/main/customer/memberApi";

export default {
  name: "AdvanceListDetail",
  computed: {
    Edit() {
      return Edit
    }
  },
  components: {
    PayTypeSelect,
    Dictionary,
    PageButton,
    CustomerSelect, EmployeeSelect, ListPage, TableList, FormPage, PageHeader
  },
  data() {
    return {
      dataDetail: {
        amountPay: 0,
        surplusPayType: 2,
        status: 0,
        payType: 2,
        depositBalance: 0,
        advanceBalance: 0,
        paymentType: 5, // 押金退费
      },
      employeeList: [],
      departList: [],
      showOrder: false,
      payType: [],
      disableToClickPay: false,
      dictionaryList: [],
      upperAmount: 0,
      preparePay: false,
      isActual: false,
      isAutoPrint: true,
      isRefund: false,
    };
  },
  created() {
    //获取职员
    commonApi.queryAllEmployee().then(list => this.employeeList = list)
    //获取字典列表
    commonApi.queryDictionaryList("DEPOSIT_TYPE").then(list => this.dictionaryList = list);
    //获取部门列表
    commonApi.queryDepartList().then(list => this.departList = list);
    //初始化时间
    this.dataDetail.advanceTime = new Date();
    //数据回显，通过路由,说明当前是数据回显
    if (this.$routerUtil.query.id) {
      this.findById(this.$routerUtil.query.id);
    }
    if (this.$routerUtil.query.amount) {
      this.upperAmount = this.$routerUtil.query.amount;
    }
    systemPayApi.queryAllList().then(res => this.payType = res);
  },
  // watch:{
  //   'dataDetail.customerId': {
  //     handler(){
  //       this.toShowCusInfo();
  //     }
  //   }
  // },
  methods: {
    handlerSelect(command){
      if(command === "setPrint"){
        this.toSetPrint();
      }
    },
    changePay(data){
      this.dataDetail.payTypeId = data.id;
      this.dataDetail.payType = data.payCode;
    },
    pay() {
      this.disableToClickPay = true;
      this.isActual = true;
      if(this.dataDetail.surplusPayType === 1){
        this.dataDetail.originalId = this.dataDetail.id;
        this.dataDetail.id = null;
        this.dataDetail.status = 0;
        this.dataDetail.surplusPayType = 2;
        this.dataDetail.surplusPay = 2;
        this.dataDetail.payStatus = 0;
        this.preparePay = true;
        AdvanceApi.saveDraft(this.dataDetail).then(res => {
          this.findById(res.id).then(()=>{
            this.dataDetail.surplusPayType = 2; //为退款
            AdvanceApi.saveSubmit(this.dataDetail).then((res) => {
              this.findById(res.id).then(()=>{
                if(this.isAutoPrint){
                  this.toPrint();
                }
                this.$routerUtil.push("/advance/list");
              });
              this.showOrder = false;
              this.preparePay = false;
              this.$message.success("退款成功");
            }).catch(()=>{
              this.preparePay = false;
            }).finally(() => {
              this.disableToClickPay = false;
            });
          })
        });
      }else{
        AdvanceApi.saveSubmit(this.dataDetail).then((res) => {
          this.findById(res.id).then(()=>{
            if(this.isAutoPrint){
              this.toPrint();
            }
            this.$routerUtil.push("/advance/list");
          });
          this.showOrder = false;
          this.preparePay = false;
          this.$message.success("退款成功");
        }).catch(()=>{
          this.preparePay = false;
        }).finally(() => {
          this.disableToClickPay = false;
        });
      }

    },
    setCustomer(data) {
      this.dataDetail.customerId = data.id;
      this.dataDetail.customerName = data.customerName;
      // 备份一个customerInfo用于其它组件
      this.dataDetail.customerInfo = {...data};
    },
    setEmployee(data) {
      this.dataDetail.employeeName = data.employeeName;
    },
    saveDraft(){
      if (!this.checkParams()) {
        return;
      }
      this.dataDetail.originalId = this.dataDetail.id;
      this.dataDetail.id = null;
      this.dataDetail.status = 0;
      this.dataDetail.surplusPayType = 2;
      this.dataDetail.surplusPay = 2;
      this.dataDetail.payStatus = 0;
      this.preparePay = true;
      return AdvanceApi.saveDraft(this.dataDetail).then(res => {
        this.findById(res.id).then(()=>{
          this.toPrint();
        })
        this.$routerUtil.push("/advance/list");
        this.preparePay = false;
      }).catch(()=>{
        this.preparePay = false;
      })
    },
    toSaveDraft() {
      this.isActual = false;
      this.isRefund = false;
      this.showOrder = true;
    },
    findById(id) {
      return AdvanceApi.findById(id).then(res => {
        this.dataDetail = res;
        if (res.refundAmount === 0) {
          this.dataDetail.refundAmount = this.upperAmount;
        }
        if (res.surplusPayType === 1) {
          this.dataDetail.status = 0;
        }
      })
    },
    //检查参数
    checkParams() {
      if (!this.dataDetail.customerId || !this.dataDetail.employeeId || !this.dataDetail.surplusPay || !this.dataDetail.payType) {
        this.$message.warning("请填写完整的头部信息");
        return false;
      }
      if (this.dataDetail.surplusPay === 1) {
        if (this.dataDetail.amountPay > this.dataDetail.advanceBalance) {
          this.$message.warning("不能超过预收款余额上限");
          return false;
        }
      } else {
        if (this.dataDetail.amountPay > this.dataDetail.depositBalance) {
          this.$message.warning("不能超过押金余额上限");
          return false;
        }
      }
      return true;
    },
    toSaveSubmit() {
      this.isActual = true;
      this.showOrder = true;
      this.isRefund = true;
    },
    openBefore() {
      this.$nextTick(() => {
        this.$refs.inputRef.$el.querySelector('input').focus();
      });
    },

    // 打印
    async toPrint() {
      // 组装打印数据
      const customerInfo = await memberApi.findById(this.dataDetail.customerId);
      let data = this.getPrintData("print",customerInfo.customerName,customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2'));
      GjpLoaclPrint.Print(data);
    },
    // 打印设置
    async toSetPrint() {
      let customerInfo;
      if(this.dataDetail.customerId){
        customerInfo = await memberApi.findById(this.dataDetail.customerId);
      }
      let data = this.getPrintData("design",customerInfo.customerName,customerInfo.customerPhone.replace(/(\d{3})\d*(\d{4})/,'$1****$2'));
      GjpLoaclPrint.Print(data);
    },
    // 拼接打印数据
    getPrintData(type,customerName,customerPhone) {
      if(this.dataDetail.status === 99){
        this.isActual = true;
      }
      let data = {
        reportName: "退押金订单",
        // amount: this.dataDetail.amountPay,
        refundAmount: this.dataDetail.refundAmount, // 押金退款金额
        customerName: customerName,
        customerPhone: customerPhone,
        refundActual: this.isActual ? this.dataDetail.refundAmount : 0,  // 实退金额
        // orderTime: this.dataDetail.orderTime,
        employeeName: this.dataDetail.employeeName,
        status: this.dataDetail.status !== 99 ? '草稿' : '已支付',
        // depositType: this.dataDetail.depositType,
        excuteType: type,
      }

      //操作人员
      for (let i = 0; i < this.employeeList.length; i++) {
        if (this.dataDetail.createdId === this.employeeList[i].id) {
          data.createdName = this.employeeList[i].employeeName
        }
      }
      // 订单编号
      if (this.dataDetail.id) {
        data.orderNumber = this.dataDetail.id;
      } else {
        data.orderNumber = "暂未支付";
      }
      // 支付时间
      data.orderTime = this.dataDetail.createdTime;
      //科室名称
      for (let i = 0; i < this.departList.length; i++) {
        if (this.dataDetail.departCode === this.departList[i].departCode) {
          data.departName = this.departList[i].departName;
        }
      }
      //职员名称
      for (let i = 0; i < this.employeeList.length; i++) {
        if (this.dataDetail.employeeId === this.employeeList[i].id) {
          data.employeeName = this.employeeList[i].employeeName
        }
      }
      //押金类型
      for (let i = 0; i < this.dictionaryList.length; i++) {
        if (this.dataDetail.depositType === this.dictionaryList[i].dictCode) {
          data.depositType = this.dictionaryList[i].dictName;
        }
      }
      // 支付状态
      if (this.dataDetail.payStatus === 0 || !this.dataDetail.payStatus) {
        data.payStatus = "未支付";
      } else if (this.dataDetail.payStatus === 99) {
        data.payStatus = "已支付";
      } else if (this.dataDetail.payStatus === 30) {
        data.payStatus = "支付失败";
      }
      // 支付方式
      for (let i = 0; i < this.payType.length; i++) {
        if (this.payType[i].id === this.dataDetail.payTypeId) {
          data.payType = this.payType[i].payName;
          break;
        }
      }
      data.productList = [];
      return data;
    },


  }
};
</script>

<style lang="scss" scoped>

</style>