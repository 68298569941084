<template>
  <!-- 通用模板，列表数据 -->
  <div class="defaultViewStyle">
    <list-page>
      <page-header>
        <page-button el-type="primary" @click="resetSearch">查询</page-button>
      </page-header>
      <search-condition >
        <el-input v-model="searchInfo.searchKey" placeholder="请输入查询条件" @keydown.enter="resetSearch"/>
      </search-condition>
      <table-list>
        <el-table :data="dataList" height="100%" border>
          <el-table-column label="序号" width="60px" type="index" :align="'center'"/>
          <el-table-column label="商品名称" prop="skuName"/>
          <el-table-column label="报价" prop="price" align="center">
            <template v-slot="{row}">
              <money v-if="row.price" :value="row.price"/>
              <span v-else>暂无报价</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" :align="'center'" width="100px">
            <template v-slot="{row}">
              <table-button type="write" el-type="primary" @click="toShowEdit(row)">编辑</table-button>
              <table-button :disabled="!row.id" message="是否确认删除此数据" @click="toDelete(row)" type="delete" el-type="danger">删除</table-button>
            </template>
          </el-table-column>
        </el-table>
      </table-list>
      <form-page v-model:page-size="searchInfo.pageSize" v-model:page-number="searchInfo.pageNumber" :total-count="searchInfo.totalCount" @onLoad="queryList"></form-page>
    </list-page>
    <el-dialog v-model="showEdit" :title="currentData.skuName + '报价'" width="30%" :close-on-click-modal="false">
      <dialog-table>
        <el-form label-width="90px">
          <el-form-item v-for="(item, idx) in carPartList" :key="idx" :label="item.dictName">
            <el-input v-model="quotePriceDetail[item.dictCode]"/>
          </el-form-item>
        </el-form>
      </dialog-table>
      <form-page :show-page="false">
        <page-button @click="showEdit = false">取消</page-button>
        <page-button type="write" el-type="primary" @click="savePrice">保存</page-button>
      </form-page>
    </el-dialog>
  </div>
</template>

<script>
import ListPage from "@/components/h5/layout/ListPage";
import SearchCondition from "@/components/h5/layout/SearchCondition";
import TableList from "@/components/h5/layout/TableList";
import FormPage from "@/components/h5/layout/FormPage";
import PageButton from "@/components/h5/button/PageButton";
import TableButton from "@/components/h5/button/TableButton";
import PageHeader from "@/components/h5/layout/PageHeader.vue";
import Money from "@/components/h5/Money.vue";
import DialogTable from "@/components/h5/layout/DialogTable.vue";

import quotePriceApi from "@/api/alpine/shop/quotePriceApi";
import commonApi from "@/api/commonApi";
export default {
  name: "",
  components: {
    TableButton, PageButton, ListPage, TableList, FormPage, SearchCondition, PageHeader, Money, DialogTable
  },
  data() {
    return {
      searchInfo: {},
      dataList: [],
      currentData: {},
      showEdit: false,
      carPartList: [],
      quotePriceDetail: {}
    };
  },
  created() {
    // 查询车辆部位
    commonApi.queryDictionaryList("CAR_PART").then(list => this.carPartList = list);
  },
  methods: {
    resetSearch() {
      this.searchInfo.pageNumber = 1;
      this.queryList();
    },
    queryList() {
      quotePriceApi.queryList(this.searchInfo).then(res => {
        this.searchInfo.totalCount = res.totalCount;
        this.dataList = res.records;
      })
    },
    toShowEdit(data) {
      this.currentData = {...data};
      if (this.currentData.partPrice) {
        this.quotePriceDetail = JSON.parse(this.currentData.partPrice);
      } else {
        this.quotePriceDetail = {};
      }
      this.showEdit = true;
    },
    toDelete(data) {
      if (!data.id) {
        this.$message.error("尚未添加报价")
        return;
      }
      quotePriceApi.delete(data.id).then(() => {
        this.queryList();
      });
    },
    savePrice() {
      // 计算价格
      let amount = 0;
      for (let key in this.quotePriceDetail) {
        amount += parseFloat(this.quotePriceDetail[key]);
      }
      if (isNaN(amount)) {
        this.$message.error("您输入的报价价格有误，请检查价格")
        return;
      }
      this.currentData.price = amount;
      this.currentData.partPrice = JSON.stringify(this.quotePriceDetail);
      quotePriceApi.save(this.currentData).then(() => {
        this.queryList();
        this.showEdit = false;
      })
    }
  }
};
</script>

<style lang="scss" scoped>

</style>