import axios from "@/util/axios";

export default {
    // 查询列表
    queryList: (data) => axios.post({url: "/basedata/base/common/mini/activity/queryList", data: data}),

    // 查询详情
    findById: (id) => axios.get({url: "/basedata/base/common/mini/activity/findById", data: {id}}),

    // 保存提交
    saveSubmit: (data) => axios.post({url: "/basedata/base/common/mini/activity/saveSubmit", data: data}),
}