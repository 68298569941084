import axios from "../../../util/axios";

export default {
    /**查询列表  */
    queryList: (data) => axios.post({url: "/alpine/stock/stock/queryList", data: data}),
    /**删除  */
    delete: (id) => axios.del({url: "/alpine/stock/stock/delete", data: {id}}),
    /**保存  */
    save: (data) => axios.post({url: "/alpine/stock/stock/save", data: data}),
    /**查询部门  */
    queryAllStores: () => axios.get({url: "/alpine/stock/stock/queryStoreList"}),
}